import facebookLogo from 'v2/components/shared/Image/Icon/social/facebook_colorfull.svg';
import instagramLogo from 'v2/components/shared/Image/Icon/social/instagram_colorful.svg';
import twitterLogo from 'v2/components/shared/Image/Icon/social/twitter_colorfull.svg';

const socialLinks = [
  { id: 1, logo: twitterLogo, url: 'https://www.twitter.com/' },
  { id: 2, logo: instagramLogo, url: 'https://www.instagram.com/' },
  { id: 0, logo: facebookLogo, url: 'https://www.facebook.com/' },
];

export default socialLinks;
