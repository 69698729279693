// @flow

import React from 'react';
import { prop } from 'ramda';
import styled from 'styled-components';

import Header from './shared/Header';
import PlayerInfo from './shared/PlayerInfo';
import GamesTable from './shared/GamesTable';
import HitsCounter from './shared/HitsCounter';
import Footer from './shared/Footer';
import withController from './shared/Controller';

type SuccessPageProps = {
  hitsCount: number,
  gamesCount: number,
  season: string,
  rate: string,
  photo: string,
  name: string,
  lastGames?: Array<{
    startsAt: string,
    homeTeam: string,
    awayTeam: string,
    started: boolean,
    missGame: boolean,
    hitInGame: boolean,
  }>,
  teamName: string,
  categoryName: string,
  position: string,
  handleClick: () => void,
  handleClose: () => void,
};

const defaultProps = {
  lastGames: [],
};

function SuccessPage({
  gamesCount,
  hitsCount,
  name,
  photo,
  teamName,
  lastGames,
  categoryName,
  position,
  contest,
  handleClick,
  handleClose,
}: SuccessPageProps) {
  const kind = prop('kind', contest);
  // const league = prop('league', contest)

  return (
    <Container onClick={(e) => e.stopPropagation()} kind={kind}>
      <Header handleClose={handleClose} categoryName={categoryName} />
      <PlayerInfo name={name} avatar={photo} teamName={teamName} position={position} />
      <HitsCounter hitsCount={hitsCount} gamesCount={gamesCount} kind={kind} />
      <GamesTable games={lastGames} />
      {!!handleClick && <Footer handleClick={handleClick} />}
    </Container>
  );
}

SuccessPage.defaultProps = defaultProps;
export default withController(SuccessPage);

// styles

const Container = styled.div`
  max-width: 350px;
  /* min-width: 350px; */

  /* background-color: ${({ theme }) => theme.colors.gray}; */
  background-color: ${({ theme, kind }) => theme.colors.getColorByKind(kind)};
  border-radius: 12px;
  overflow: hidden;
`;
