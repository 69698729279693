import { isNil, prop, pipe, adjust, join, toUpper } from 'ramda';

export function entityGetter(name, entities) {
  const entity = entities[name];
  return entity || {};
}

export function entityKeysGetter(name, entities) {
  const entity = entityGetter(name, entities);
  return Object.keys(entity);
}

export function entityDetailGetter(name, id, entities) {
  const entity = entityGetter(name, entities);
  return entity[id];
}

export function entityListGetter(name, ids, entities) {
  if (isNil(ids)) return [];
  return ids.map((id) => entityDetailGetter(name, id, entities));
}

export function entityFieldGetter(field, entity) {
  return prop(field, entity);
}

export function entityActualFieldGetter(field, entity) {
  if (isNil(field)) return undefined;

  const actualField = `actual${pipe(
    adjust(0, toUpper),
    join(''),
  )(field)}`;
  return prop(actualField, entity);
}
