/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import React, { Fragment, useState, useEffect } from 'react';
import { animateScroll } from 'react-scroll';
import * as R from 'ramda';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import { history } from 'v2/stores/configureStore';
import { noGamesIcons } from 'v2/constants/UI/noGamesIcons';
import { getInOr, getIn } from 'v2/helpers/ramda';

import useWindowSize from 'v2/services/useWindowSize';
import * as actions from 'v2/actions';
import {
  getResourceContestBreackdownOverallCollection,
  getResourceContestBreackdownWeekPickCollection,
  getBreackdownOverallCurrentCategory,
  getBreackdownWeekCurrentCategory,
} from 'v2/selectors/contests';
// import { ModalLoader } from 'v2/components/shared';

import { requestInProcess } from 'v2/selectors/request';
import * as requestTypes from 'v2/constants/requestTypes';
import { Loader } from 'v2/components/shared';

import GameStatus from './shared/GameStatus';
import { CONTEST_BREAKDOWN_CATEGORY_PATH, getPath } from '../../../constants/router';
import Player from './shared/PlayerBreakdown';
import { Category } from '../shared';
import { search } from '../shared/Image';
import MobileStatCategories from '../MakePicks/shared/StatCategories';

const propTypes = {};

const defaultProps = {
  breackdownOverallCollection: [
    {
      players: [],
    },
  ],
  breackdownWeekPickCollection: [
    {
      players: [],
    },
  ],
  searchOverAllPlayers: [],
  searchWeekCurrentPlayers: [],
};

const getPermissionToShow = (props) =>
  !R.compose(R.isEmpty, R.symmetricDifference([1, 1, 1]), R.props(['currentWeek', 'nextWeek', 'previousWeek']))(props);

const getCheckBackImg = ({ league, kind }) =>
  R.compose(
    R.prop('logo'),
    R.ifElse(() => R.and(league, kind), R.path(['checkBack', league, kind]), R.path(['checkBack', 'nfl', 'survive'])),
  )(noGamesIcons);
function PickBreakdown(props) {
  const {
    statCategories,
    categoryId,
    contest,
    breackdownOverallCollection,
    breackdownWeekPickCollection,
    contestPlayers,
    overAllCurrentCategory = {},
    weekCurrentCategory,
    onCurrentCategoryId,
    weeks,
    isLoading,
    match: { params },
  } = props;

  useEffect(() => {
    const toTop = async () => animateScroll.scrollToTop();
    toTop();
  }, []);

  const [documentWidth] = useWindowSize();
  const [isQuery, setIsQuery] = useState('');
  const [isOverAll, setIsOverAll] = useState(false);
  const [showOverall, setShowOverall] = useState(getPermissionToShow(weeks));

  useEffect(() => {
    setShowOverall(getPermissionToShow(weeks));
  }, [weeks]);

  const showStats = R.or(
    getInOr(false, 'isUserEliminated', contest),
    R.or(getInOr(false, 'isContestLive', contest), R.not(getIn('isContestActive', contest))),
  );

  const isMobile = documentWidth < 480;

  const { id, urlContest, selectionsRequired, kind } = contest;

  const categoryIdInt = parseInt(categoryId, 10);
  // const res = breackdownOverallCollection.find((e) => (e.id = parseInt(params.categoryId)));

  // const [isQuery, setIsQuery] = useState('');
  // const searchOverAllPlayers = overAllCurrentCategory.players.filter((player) =>
  //   R.toLower(player.name).includes(R.toLower(isQuery)),
  // );

  // const searchWeekCurrentPlayers = weekCurrentCategory.players.filter((player) =>
  //   R.toLower(player.name).includes(R.toLower(isQuery)),
  // );

  const categories = R.sortWith([R.ascend(R.prop('order'))])(breackdownWeekPickCollection.categories || []);

  const navigateToCategory = (categoryId) =>
    R.compose(history.push, getPath(CONTEST_BREAKDOWN_CATEGORY_PATH), R.assoc('id', id))({ categoryId });

  return (
    <>
      {/* <ModalLoader isLoading={isLoading} /> */}
      <MakePicksSection>
        {showStats ? (
          <>
            <MakePicksHeader>
              {isMobile && (
                <MobileStatCategories
                  navigateToCategory={navigateToCategory}
                  selectionsRequired={selectionsRequired}
                  league={contest.league}
                  currentCategoryId={categoryIdInt}
                  categories={categories}
                />
              )}

              {!isMobile && (
                <>
                  <PickBreakdownCategories>
                    {categories.slice(0, 3).map((category) => (
                      <Category
                        to={getPath(CONTEST_BREAKDOWN_CATEGORY_PATH, { id, categoryId: category.id })}
                        onCurrentCategoryId={onCurrentCategoryId}
                        contest={contest}
                        id={category.id}
                        key={category.id}
                        isSelectedTop={category.id === categoryIdInt}
                        subject={category.subject}
                        amount={category.amount}
                        isFill
                      />
                    ))}
                  </PickBreakdownCategories>
                  <PickBreakdownCategories>
                    {categories.slice(3, 6).map((category) => (
                      <Category
                        to={getPath(CONTEST_BREAKDOWN_CATEGORY_PATH, { id, categoryId: category.id })}
                        onCurrentCategoryId={onCurrentCategoryId}
                        contest={contest}
                        id={category.id}
                        key={category.id}
                        isSelectedBottom={category.id === categoryIdInt}
                        subject={category.subject}
                        amount={category.amount}
                        isFill
                      />
                    ))}
                  </PickBreakdownCategories>
                </>
              )}
              {/* <MakePicksButton
          onClick={() => onSubmitPicks(categoryWithSelectedPlayers, id, urlContest.weekOrder, notFilledCategoryId)}
          className={selected && selected.length === selectionsRequired ? 'primary' : 'disabled'}
          disabled={selected && selected.length !== selectionsRequired}
        >
          submit picks {selectedPlayersCount} / {totalSelectionsRequired}
        </MakePicksButton> */}
            </MakePicksHeader>
            <MakePicksBody>
              {contest.kind === 'survive' && (
                <StyledGamesFilter>
                  <GameStatus
                    showOverall={showOverall}
                    isOverAll={isOverAll}
                    setIsOverAll={setIsOverAll}
                    selectedPercentOverall={overAllCurrentCategory && overAllCurrentCategory.selectedPercent}
                    selectedPercentweek={weekCurrentCategory && weekCurrentCategory.selectedPercent}
                  />
                  {/* <TextFilter>Statistics for all the time of the game</TextFilter> */}
                </StyledGamesFilter>
              )}
              <div style={{ textAlign: 'center' }}>
                <StatisticText>
                  {/* <strong>
                {isOverAll
                  ? `${overAllCurrentCategory && overAllCurrentCategory.selectedPercent}`
                  : `${weekCurrentCategory && weekCurrentCategory.selectedPercent}`}
                %{' '}
              </strong> */}
                  {/* of players that were selected */}% BREAKDOWN OF PLAYERS SELECTED
                </StatisticText>
                <InputForm>
                  <SearchIcon src={search} alt="search" />
                  <SearchPlayer type="text" placeholder="Search player" onChange={(e) => setIsQuery(e.target.value)} />
                </InputForm>
              </div>

              {isLoading ? (
                <Loader />
              ) : (
                <Fragment>
                  {isOverAll && contest.kind === 'survive' ? (
                    <Players>
                      {overAllCurrentCategory &&
                        overAllCurrentCategory.players.map((player) => (
                          <Player key={player.id} {...player} isOverAll={isOverAll} />
                        ))}
                    </Players>
                  ) : (
                    <Players>
                      {weekCurrentCategory &&
                        weekCurrentCategory.players.map((player) => (
                          <Player key={player.id} {...player} iconStatus isOverAll={isOverAll} />
                        ))}
                    </Players>
                  )}
                </Fragment>
              )}
            </MakePicksBody>
          </>
        ) : (
          <CheckBackWrapper>
            <CheckBackImg
              src={R.compose(R.prop('src'), getCheckBackImg)(contest)}
              alt={R.compose(R.prop('alt'), getCheckBackImg)(contest)}
            />
          </CheckBackWrapper>
        )}
      </MakePicksSection>
    </>
  );
}

const mapStateToProps = (state, props) => {
  const categoryId = getIn('match.params.categoryId', props);
  return {
    breackdownOverallCollection: getResourceContestBreackdownOverallCollection(state),
    breackdownWeekPickCollection: getResourceContestBreackdownWeekPickCollection(state),
    overAllCurrentCategory: getBreackdownOverallCurrentCategory(state, categoryId),
    weekCurrentCategory: getBreackdownWeekCurrentCategory(state, categoryId),
    isLoading:
      requestInProcess(state, { requestType: requestTypes.CONTEST_BREACKDOWN_OVERALL_FETCH }) ||
      requestInProcess(state, { requestType: requestTypes.CONTEST_BREAKDOWN_WEEK_PICK_FETCH }),
    categoryId,
    // breackdownOverallPlayers: getResourceContestBreackdownOverallPlayers(state, { categoryId }),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchContestBreackdownOverall: (params) => dispatch(actions.fetchContestBreackdownOverall(params)),
  fetchContestBreakdownWeekPick: (params) => dispatch(actions.fetchContestBreakdownWeekPick(params)),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { fetchContestBreackdownOverall, fetchContestBreakdownWeekPick, match } = this.props;
      const { id } = match.params;

      fetchContestBreackdownOverall(id);
      fetchContestBreakdownWeekPick(id);
    },
  }),
);

PickBreakdown.propTypes = propTypes;
PickBreakdown.defaultProps = defaultProps;

export default enhance(React.memo(PickBreakdown));

// styles

const CheckBackWrapper = styled.div`
  max-width: 100%;
  min-height: 45vh;
  display: flex;
  align-items: center;
`;
const CheckBackImg = styled.img`
  max-width: 100%;
  width: 100%;
`;
const StyledGamesFilter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  /* padding-right: 15px; */
  border-bottom: 1px solid #26262e;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    background: #1b1b21;
    border-radius: 8px;
    border: none;
    padding-bottom: 0;
  }
`;

const PickBreakdownCategories = styled.div`
  display: flex;
  /* margin: 20px 0; */
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const MakePicksSection = styled.div`
  width: 100%;
  background: #1b1b21;
  margin-right: 30px;
  @media (max-width: 901px) {
    margin-right: 0;
  }
`;

const MakePicksHeader = styled.div`
  padding: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: #1b1b21;
  padding-bottom: 0;
  @media (max-width: 480px) {
    padding: 10px 17px;
    padding-bottom: 0;
    background-color: rgb(19, 19, 23);
  }
`;

const MakePicksBody = styled.div`
  padding: 20px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #1b1b21;
  padding-top: 0;
  @media (max-width: 480px) {
    padding: 0 10px;
    padding-top: 0;
    padding-bottom: 50px;
  }
`;

const StatisticText = styled.span`
  padding: 20px 10px;
  color: #fff;
  text-transform: none;
  font-size: 14px;
`;

const InputForm = styled.div`
  position: relative;
  input {
    margin-top: 0;
  }
`;

const SearchIcon = styled.img`
  position: absolute;
  top: 12px;
  left: 10px;
`;

const SearchPlayer = styled.input`
  display: block;
  width: 100%;
  padding: 10px;
  padding-left: 35px;
  margin: 10px 0;
  border-radius: 8px;
  background: #26262e;
  color: #fff;
  outline: none;
  border: none;
  box-sizing: border-box;
  &::placeholder {
    color: #656579;
  }
`;

const Players = styled.div`
  @media (max-width: 768px) {
    padding-right: 10px;
  }
`;

const TextFilter = styled.span`
  width: 100%;
  text-transform: none;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  font-weight: 500;
  text-align: right;
  @media (max-width: 768px) {
    margin-top: 15px;
    text-align: center;
  }
`;

// const Container = styled.div`
//   width: 100%;
//   position: relative;
//   max-width: 1200px;
//   padding: 0px 15px;
//   margin: 0 auto;
//   overflow: visible;
//   flex: 1 0 auto;
//   box-sizing: border-box;
//   margin-bottom: 30px;
// `;

// const MakePicksWraper = styled.div`
//   position: relative;
//   width: 100%;
//   display: flex;
// `;

// const WeeksSwitch = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 0 15px;
//   margin-bottom: 20px;
// `;

// const ArrowButton = styled(Link)`
//   display: flex;
//   justify-content: center;
//   width: 32px;
//   height: 32px;
//   background: #26262e;
//   padding: 0;
//   margin: 0;
// `;

// const WeeksCurrent = styled.span`
//   color: #fff;
// `;

// const MakePicksButton = styled.button`
//   width: 100%;
//   padding: 10px;
//   background: #26262e;
//   border: 1px solid transparent;
//   border-radius: 8px;
//   margin-top: 30px;
//   color: #fff;
//   text-transform: uppercase;
//   font-weight: 700;
//   letter-spacing: 0.5px;
//   cursor: pointer;
//   font-family: 'Roboto Condensed', sans-serif;
//   font-size: 14px;
//   &.primary {
//     background: #5d5ce2;
//     &:hover {
//       border: 1px solid #26264c;
//     }
//   }
//   &.disabled {
//     cursor: no-drop;
//   }
// `;

// const MakePicksContestDrawer = styled.div`
//   position: relative;
//   padding: 15px;
//   box-sizing: border-box;
//   background: #26262e;
//   border-radius: 8px;
//   text-align: center;
//   text-transform: uppercase;
//   font-size: 14px;
//   font-weight: 700;
//   line-height: 18px;
//   margin: 10px 0;
//   cursor: pointer;
//   display: none;
//   @media (max-width: 901px) {
//     display: block;
//   }
//   svg {
//     position: absolute;
//     right: 15px;
//     top: 21px;
//   }
// `;

// const MakePicksText = styled.div`
//   margin-bottom: 0;
//   text-align: center;
//   @media (min-width: 768px) {
//     padding: 0 20px;
//     text-align: left;
//     margin-bottom: 30px;
//   }
// `;
