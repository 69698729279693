import * as actionTypes from '../../constants/actionTypes';
import { initialState } from '../../selectors/players';

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SUCCESS_PLAYER_INFO_FETCH_SUCCESS:
      return {
        ...state,
        show: action.payload,
      };

    default:
      return state;
  }
};
