// @flow

import React from 'react';
import styled from 'styled-components';
import { Field } from 'react-final-form';

import FormGroupAdapter from '../FormGroup/Adapter';
import RadioButton from '../RadioButton';

type SwitcherProps = {};

function Switcher({ title, options }: SwitcherProps) {
  return (
    <SwitcherContainer>
      <SwitchTitle>{title}</SwitchTitle>
      <SwitcherTabs>
        {options.map((option) => (
          <Field
            {...option}
            type="radio"
            key={option.id}
            component={FormGroupAdapter}
            inputComponent={(props) => <RadioButton {...props}>{option.value}</RadioButton>}
          />
        ))}
      </SwitcherTabs>
    </SwitcherContainer>
  );
}

const SwitcherTabs = styled.div`
  display: flex;
  width: 100%;
  background: #26262e;
  border-radius: 4px;
  div {
    margin-bottom: 0;
    position: relative;
    &:first-child {
      &:after {
        content: '';
        display: block;
        height: 50%;
        width: 1px;
        background: #1b1b21;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-50%, -45%);
      }
    }
  }
`;

const SwitcherContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`;

const SwitchTitle = styled.span`
  display: inline-block;
  color: #656579;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 18px;
  text-transform: uppercase;
  margin: 0 0 8px 12px;
  cursor: pointer;
`;

export default Switcher;
