import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import moment from 'moment';
import defaultImage from 'v2/components/shared/Image/special_offer_image_2.png';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import LogoImage from '../../../../shared/Image';

const propTypes = {};

const defaultProps = {};

const settings = {
  className: '',
  infinite: true,
  centerMode: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  rows: 2,
  centerPadding: '0',
  arrows: false,
  dots: true,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: { slidesToShow: 2, rows: 1, dots: true, centerPadding: '0', variableWidth: false },
    },
    { breakpoint: 768, settings: { slidesToShow: 1, dots: true, rows: 1 } },
  ],
};

function OffersSlider({ data }) {
  const offernavigation = (url) => (window.location = url);

  return (
    <Slider {...settings}>
      {data &&
        data.map((offer, index) => (
          <div className="slider_wrap" key={offer.id}>
            <SpecialOfferContainer onClick={() => offer.url && offernavigation(offer.url)}>
              <OffersText>
                <span>{moment(offer.date).format('MMMM YYYY')}</span>
                <h3>{offer.title}</h3>
              </OffersText>
              <OffersImage src={offer.pictureUrl || defaultImage} />
            </SpecialOfferContainer>
          </div>
        ))}
    </Slider>
  );
}

const OffersText = styled.div`
  height: fit-content;
  padding: 0 0 0 20px;
  max-width: 65%;
`;

const OffersImage = styled.img`
  position: absolute;
  position: absolute;
  max-height: 100%;
  right: 0;
`;

const SpecialOfferContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-radius: 8px;
  height: 175px;
  background: rgba(38, 38, 46, 0.15);
  overflow: hidden;

  @media (min-width: 768px) {
    height: 230px;
  }
`;

OffersSlider.propTypes = propTypes;
OffersSlider.defaultProps = defaultProps;

// export default enhance(HomePage);
export default OffersSlider;
