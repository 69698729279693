import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

import { StatisticItem } from '../../blocks';

const propTypes = {
  gameState: PropTypes.string,
  cost: PropTypes.number,
  participantsCount: PropTypes.number,
  participantsMaxCount: PropTypes.number,
  endsAt: PropTypes.string.isRequired,
  startsAt: PropTypes.string.isRequired,
};

const defaultProps = {
  gameState: '',
  cost: 0,
  participantsCount: 0,
  participantsMaxCount: 0,
};

function Statistics(props) {
  const { gameState, kind, status, cost, endsAt, participantsCount, participantsMaxCount, startsAt } = props;

  const contestStartAt = moment(startsAt).format('M.DD | kk:mm');
  const contestEndsAt = moment(endsAt).format('M.DD | kk:mm');

  return (
    <StyledStatistics>
      <Fragment>
        {kind === 'weekly' ? (
          <StatisticItem title="Streakin’" text={`${participantsCount}`} />
        ) : (
          <StatisticItem title="Streakin’" text={`${participantsCount}/${participantsMaxCount}`} />
        )}
        <StatisticItem title="Cost" text={cost && cost > 0 ? `$ ${cost}` : 'FREE'} />
        <StatisticItem title={kind === 'survive' ? 'WEEK ENDS' : 'Starts At'} text={contestStartAt} />
      </Fragment>

      {/* {gameState === 'recent' ? (
        <Fragment>
          <StatisticItem title="Status" text={status} className={status === 'Eliminated' ? 'danger' : 'success'} />
          <StatisticItem title="Cost" text={cost && cost === 0 ? cost : 'FREE'} />
          <StatisticItem title="End At" text={contestEndsAt} />
        </Fragment>
      ) : (
        <Fragment>
          <StatisticItem title="Streakin’" text={`${participantsCount}/${participantsMaxCount}`} />
          <StatisticItem title="Cost" text={cost && cost === 0 ? cost : 'FREE'} />
          <StatisticItem title="Starts At" text={contestStartAt} />
        </Fragment>
      )} */}
    </StyledStatistics>
  );
}

const StyledStatistics = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
`;

Statistics.propTypes = propTypes;
Statistics.defaultProps = defaultProps;

export default Statistics;
