import React from 'react';
import { toast as toastify } from 'react-toastify';
import { history } from 'v2/stores/configureStore';
import { Notification } from 'v2/components/shared';
import getTypeByKind from './getTypeByKind';
// import ToastTemplate from '../ToastTemplate';

const toast = ({ type, ...props }, config) => {
  // fixme
  history.listen(({ key, pathname }) => {
    toastify.dismiss();
  });

  return toastify(<Notification toast {...props} />, { type: getTypeByKind(props), ...config });
};

export default toast;
