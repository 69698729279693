// @flow

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { map, compose, head, prop } from 'ramda';
import Slider from 'react-slick';

import { getPath, BLOG_CATEGORY_PATH } from 'v2/constants/router';
import { addclass } from 'v2/helpers/common';

import ExploreMoreBlock from '../../ExploreMoreBlock';

type DefaultSliderProps = {};

const defaultProps = {
  checkValidArrows: () => null,
};

function DefaultSlider({
  children,
  forwardedRef,
  checkValidArrows,
  league,
  id: categoryId,
  ...rest
}: DefaultSliderProps) {
  const seeMoreNavigateUrl = categoryId && getPath(BLOG_CATEGORY_PATH, { categoryId });

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    rows: 1,
    dots: false,
    arrows: false,
    swipeToSlide: true,
    afterChange: checkValidArrows && checkValidArrows(3),
    responsive: [
      {
        breakpoint: 1080,
        settings: { slidesToShow: 2 },
        afterChange: checkValidArrows && checkValidArrows(2),
      },
      {
        breakpoint: 700,
        settings: { slidesToShow: 1.2 },
        afterChange: checkValidArrows && checkValidArrows(1),
      },
    ],
  };

  useEffect(() => {
    try {
      map(compose(addclass('default_slide'), head, prop('childNodes')))(document.getElementsByClassName('slick-slide'));
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <Container>
      <Slider {...settings} ref={forwardedRef}>
        {children}
        {seeMoreNavigateUrl && (
          <ExploreMoreBlockWrapper>
            <ExploreMoreBlock league={league} navigateUrl={seeMoreNavigateUrl} />
          </ExploreMoreBlockWrapper>
        )}
      </Slider>
    </Container>
  );
}

DefaultSlider.defaultProps = defaultProps;
export default DefaultSlider;

// styles

const ExploreMoreBlockWrapper = styled.div`
  display: flex !important;
  align-items: center;
`;

const Container = styled.div`
  .slick-track {
    display: flex;
    min-width: 100%;
  }
  .slick-track .slick-slide {
    display: flex;
    height: auto;
  }

  .default_slide {
    width: 100%;
    display: flex;
  }

  @media (max-width: 700px) {
    .slick-slide {
      transform: translateX(10px);
      padding: 5px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
`;
