import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: ${({ isRow }) => (isRow ? 'row' : 'column')};
  justify-content: flex-start;
  margin-right: ${({ noMarginRight }) => (noMarginRight ? 0 : '25px')};
  flex-wrap: wrap;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 755px) {
    margin: 0 auto;

    &:last-child {
      margin-right: auto;
    }
  }

  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;
