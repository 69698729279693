// @flow

import React from 'react';
import { join, without, tap, compose, dissoc, concat, map, ifElse, prop, or } from 'ramda';
import styled from 'styled-components';

import { KIND_TABS, LEAGUE_TABS } from 'v2/constants/UI/tabs';
import STATUS_TABS from 'v2/constants/UI/tabs/statusTabs';
import { LEAGUE_TABS_WHITHOUT_LABEL } from 'v2/constants/UI/tabs/leagueTabs';
import DropDown from 'v2/components/DropDown';
import allTypeLogo from 'v2/components/shared/Image/kind_icons/all.svg';

import { statusKey, kindKey, leagueKey } from '../helpers/params';

const defaultLeagueKey = 'all';
const defaultKindKey = 'all';
const defaultStatusKey = 'active';
type NavigationBlockProps = {
  getStatus: (v: string) => string,
  onChange: (v: string) => void,
};

function NavigationBlock({ onChange, getStatus }: NavigationBlockProps) {
  const myGamesRef = document.querySelector('#games_container');
  const toogleClass = 'remove_accent';

  function addAccent() {
    if (myGamesRef) {
      const arr = myGamesRef.className.split(' ');
      myGamesRef.className = compose(join(' '), without(toogleClass))(arr);
    }
  }

  function hideAccent() {
    if (myGamesRef) {
      const arr = myGamesRef.className.split(' ');
      if (arr.indexOf(name) == -1) {
        myGamesRef.className += ' ' + toogleClass;
      }
    }
  }

  const handleOpenDropDown = ifElse(prop('isOpen'), hideAccent, addAccent);

  const prepareKindTabs = compose(concat([['all', { label: 'all', img: allTypeLogo }]]));

  return (
    <Container>
      <DropDown
        onChange={onChange(leagueKey)}
        activeKey={or(getStatus(leagueKey), defaultLeagueKey)}
        items={LEAGUE_TABS_WHITHOUT_LABEL}
        // showLegend={or(getStatus(leagueKey), defaultLeagueKey) == 'all'}
        legend="sport"
        onStateChange={handleOpenDropDown}
        selectedImgOnly
      />
      <Separate>{`|`}</Separate>
      <DropDown
        onChange={onChange(kindKey)}
        activeKey={or(getStatus(kindKey), defaultKindKey)}
        items={prepareKindTabs(KIND_TABS)}
        hideImg
        isCenter
        onlyImgForSelect
        legend="type"
        dropDownStyles={{ left: '-30px', top: '125%' }}
        onStateChange={handleOpenDropDown}
      />
      <Separate>{`|`}</Separate>
      <DropDown
        onChange={onChange(statusKey)}
        activeKey={or(getStatus(statusKey), defaultStatusKey)}
        items={STATUS_TABS}
        hideImg
        isCenter
        onlyImgForSelect
        legend="type"
        dropDownStyles={{ left: '-30px', top: '125%' }}
        onStateChange={handleOpenDropDown}
      />
    </Container>
  );
}

export default React.memo(NavigationBlock);

// styles

const Separate = styled.span`
  padding: 0 7px;
  font-size: 15px;
`;

const Container = styled.div`
  /* background: ${({ theme }) => theme.colors.dark}; */
  /* max-width: 350px;
  flex-wrap: wrap; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 0;
  max-width: 1100px;
  /* margin-bottom: 0.75rem; */
`;
