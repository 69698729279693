// @flow

function isBasketball(league?: string): boolean {
  if (league && league.toLowerCase() === 'nba') {
    return true;
  }

  return false;
}

function isFootball(league?: string): boolean {
  if (league && league.toLowerCase() === 'nfl') {
    return true;
  }

  return false;
}

function isBaseball(league?: string): boolean {
  if (league && league.toLowerCase() === 'mlb') {
    return true;
  }

  return false;
}

export { isBasketball, isFootball, isBaseball };
