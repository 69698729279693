// @flow

import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';

import Avatar from 'v2/components/Contests/shared/Avatar';

const defaultProps = {
  photo: null,
  name: null,
  hideName: false,
};
type PlayerHeadshotProps = {
  photo?: string,
  name?: string,
  league: string,
  hideName?: boolean,
};

const getPlayerName = R.compose(R.join('\n'), R.split(' '));

function PlayerHeadshot({ photo, name, league, isCenter, hideName, large }: PlayerHeadshotProps) {
  return (
    <Container center={isCenter} large={large}>
      <AvatarContainer>
        <Avatar league={league} source={photo} />
      </AvatarContainer>
      {!hideName && name && <PlayerName>{getPlayerName(name)}</PlayerName>}
    </Container>
  );
}

PlayerHeadshot.defaultProps = defaultProps;
export default PlayerHeadshot;

// styles

const Center = styled.div``;

const PlayerName = styled.span`
  color: #fff;
  font-size: 7px;
  text-align: center;
  line-height: 9px;
  white-space: break-spaces;
  margin-top: 5px;
`;

const AvatarContainer = styled.div`
  display: flex;
  width: 50px;
  z-index: 1;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50px;

  width: ${({ center }) => center && '60px'};
  position: ${({ center }) => center && 'absolute'};
  position: ${({ center }) => center && 'absolute'};
  transform: ${({ center }) => center && 'translate(-50%, 0)'};
  z-index: ${({ center }) => center && '2'};
  top: 0;
  left: 50%;

  ${AvatarContainer} {
    width: ${({ large }) => large && '55px'};
    width: ${({ center }) => center && '60px'};
  }

  ${PlayerName} {
    font-size: ${({ large }) => large && '10px'};
    line-height: ${({ large }) => large && '11px'};

    font-size: ${({ center }) => center && '9px'};
    line-height: ${({ center }) => center && '10px'};
  }
`;
