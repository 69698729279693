import React from 'react';
import styled from 'styled-components';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import * as actions from 'v2/actions';
import FormGroupAdapter from 'v2/components/shared/formDefault/FormGroup/Adapter';
import Input from 'v2/components/shared/formDefault/Input';
import Button from 'v2/components/shared/formDefault/Button';
import ErrorMessage from 'v2/components/shared/formDefault/ErrorMessage';
import ForgotLink from 'v2/components/shared/formDefault/ForgotLink';

import { composeValidators, validateRequired, validateEmail } from 'v2/components/shared/formDefault/validations';

const defaultProps = {
  contest: null,
};

const propTypes = {
  contest: PropTypes.shape({
    id: PropTypes.number.isRequired,
    urlContest: PropTypes.shape({
      categoryId: PropTypes.number.isRequired,
      weekOrder: PropTypes.number.isRequired,
    }),
  }),
  postCredentials: PropTypes.func.isRequired,
};

function SignInForm({ postCredentials, contest }) {
  return (
    <Form
      initialValues={{ contest }}
      onSubmit={(props) => postCredentials(props)}
      render={({ handleSubmit, hasSubmitErrors, submitError, submitting }) => (
        <StyledForm onSubmit={handleSubmit}>
          <FieldsCollection isErrors={hasSubmitErrors || submitError}>
            <Field
              component={FormGroupAdapter}
              inputComponent={Input}
              name="userSession.email"
              help=""
              placeholder="Enter your email"
              type="email"
              label="Email"
              validate={composeValidators(validateRequired(), validateEmail())}
            />

            <Field
              component={FormGroupAdapter}
              inputComponent={Input}
              name="userSession.password"
              placeholder="Your password"
              type="password"
              label="Password"
              autoComplete="password"
              append={<ForgotLink />}
              // prepend={}
              validate={validateRequired()}
            />
            {hasSubmitErrors && submitError && (
              <ErrorMessageWrapper>
                <ErrorMessage>{submitError}</ErrorMessage>
              </ErrorMessageWrapper>
            )}
          </FieldsCollection>

          <Button type="submit" disable={submitting}>
            {submitting ? 'Submitting...' : 'Submit'}
          </Button>
        </StyledForm>
      )}
    />
  );
}

const FieldsCollection = styled.div`
  margin-bottom: ${({ isErrors }) => (isErrors ? '25px' : '50px')};
`;

const ErrorMessageWrapper = styled.div`
  /* margin-bottom: 25px; */
  text-align: center;
`;

const StyledForm = styled.form`
  width: 100%;
  /* margin-top: 15px; */
  margin-bottom: 25px;
`;

const mapDispatchToProps = (dispatch) => ({
  postCredentials: (userData) => dispatch(actions.signIn(userData)),
});

const enhance = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
);

SignInForm.defaultProps = defaultProps;
SignInForm.propTypes = propTypes;

export default enhance(SignInForm);
