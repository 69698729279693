// @flow

import React from 'react';
import styled from 'styled-components';

import { Counter } from 'v2/components/shared';

type GameStatusProps = {
  isOverAll: boolean,
  setIsOverAll: () => void,
};

const defaultProps = {};

function GameStatus({ isOverAll, setIsOverAll, showOverall }: GameStatusProps) {
  // console.log('showOverall :>> ', showOverall);
  return (
    <GamesStatus>
      <Status
        className={!isOverAll ? 'active' : ''}
        onClick={() => {
          setIsOverAll(false);
        }}
      >
        This Week
        {/* <StyledCounter isUnActive={isOverAll}>{selectedPercentweek}%</StyledCounter> */}
      </Status>

      {showOverall && (
        <Status
          className={isOverAll ? 'active' : ''}
          onClick={() => {
            setIsOverAll(true);
          }}
        >
          Overall
          {/* <StyledCounter isUnActive={!isOverAll}>{selectedPercentOverall}%</StyledCounter> */}
        </Status>
      )}
    </GamesStatus>
  );
}

const GamesStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (max-width: 768px) {
    border-bottom: 1px solid #26262e;
    padding-bottom: 15px;
  }
`;

const Status = styled.div`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-weight: 700;
  color: #656579;
  margin-right: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: #fff;
  }
  &.active {
    position: relative;
    color: #fff;
    &:before {
      position: absolute;
      content: '';
      height: 4px;
      width: 100%;
      bottom: -15px;
      background: #5d5ce2;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
  @media (max-width: 768px) {
    width: 50%;
    text-align: center;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &.active {
      &:before {
        left: 0;
      }
    }
  }
`;

const StyledCounter = styled(Counter)`
  font-size: 10px;
  padding: 3px 8px;
`;

// const mapStateToProps = (state, props) => {
//   return {
//     overAllCurrentCategory: getBreackdownOverallCurrentCategory(state, categoryId),
//     weekCurrentCategory: getBreackdownWeekCurrentCategory(state, categoryId),
//   };
// };
GameStatus.defaultProps = defaultProps;

export default GameStatus;
