import React from 'react';
import styled from 'styled-components';
import { useLocation, withRouter } from 'react-router';
import { getPath, ROOT_PATH, LEAGUE_PATH } from 'v2/constants/router';

import { ButtonsContestKind } from 'v2/constants/UI/buttons';
import Button from '../../Button';

// type BtnGroupProps = {};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function BtnGroup() {
  // const { search } = location;
  const query = useQuery();
  const currentType = query.get('type');

  // console.log(query.get('status'));
  if (ButtonsContestKind.length > 0) {
    return (
      <BtnGroupContainer>
        <AvailableKind>
          {ButtonsContestKind.filter((button) => !button.inactive).map((button) => (
            <ButtonContainer key={button.id}>
              <Button
                isLink
                logo={button.logo.src}
                alt={button.logo.alt}
                title={button.title}
                hrefTo={currentType ? `?type=${currentType}&&kind=${button.kind}` : `?kind=${button.kind}`}
                activeStatus={query.get('kind') === button.kind}
              />
            </ButtonContainer>
          ))}
        </AvailableKind>
        <UnAvailableKind>
          {ButtonsContestKind.filter((button) => button.inactive).map((button) => (
            <ButtonContainer key={button.id}>
              <ButtonWithNotify>
                <ButtonNotify>
                  <ButtonNotifyText>coming soon</ButtonNotifyText>
                </ButtonNotify>
                <Button
                  logo={button.logo.src}
                  alt={button.logo.alt}
                  title={button.title}
                  disabled={button.inactive}
                  activeStatus={query.get('kind') === button.kind}
                />
              </ButtonWithNotify>
            </ButtonContainer>
          ))}
        </UnAvailableKind>
      </BtnGroupContainer>
    );
  }

  return null;
}

const ButtonNotify = styled.div`
  position: absolute;
  top: -40px;
  margin-left: 60px;
  background-color: #26262e;
  padding: 2px 10px 5px 10px;
  border-radius: 5px;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
  transform: translate(-19%, 0);

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    left: 25%;
    transform: rotate(45deg);
    border-left: 12px solid transparent;
    border-top: 12px solid transparent;
    border-right: 12px solid #26262e;
  }

  @media (max-width: 500px) {
    margin-left: 30px;
  }
`;

const ButtonWithNotify = styled.div`
  height: 100%;
  &:hover {
    ${ButtonNotify} {
      visibility: visible;
      opacity: 1;
    }
  }
`;

const ButtonNotifyText = styled.span`
  font-size: 8px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin-left: 10px;

  &:first-child {
    margin-left: 0;
  }

  @media (max-width: 568px) {
    width: 100%;
  }
`;

const AvailableKind = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  margin-left: 30px;

  &:before {
    content: '';
    display: block;
    width: 1px;
    background-color: #26262e;
    height: 50%;
    position: absolute;
    top: 25%;
    left: -15px;
  }

  @media (max-width: 680px) {
    margin-left: 0;
    &:before {
      display: none;
    }
  }

  @media (max-width: 568px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const UnAvailableKind = styled(AvailableKind)`
  position: relative;
  display: flex;

  @media (max-width: 680px) {
    margin-left: 10px;
  }
`;

const BtnGroupContainer = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 568px) {
    width: 100%;
    justify-content: space-between;
  }
`;

export default withRouter(BtnGroup);
