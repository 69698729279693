// @flow
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import * as React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

type CarouselItemsProps = {
  children: React.Node[],
  speed?: number,
};

const defaultProps = {
  speed: 10000,
};

function CarouselItems({ children, speed }: CarouselItemsProps) {
  // const numberRepetitions = children.length > 5 ? 3 : 6;
  const settings = {
    infinite: true,
    centerMode: true,
    centerPadding: '60px',
    focusOnSelect: true,
    slidesToShow: 5,
    autoplay: true,
    autoplaySpeed: 2500,
    speed: 850,
    dots: false,
    arrows: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 475,
        settings: { centerPadding: '90px', slidesToShow: 1, rows: 1 },
      },
      {
        breakpoint: 575,
        settings: { centerPadding: '70px', slidesToShow: 2, rows: 1 },
      },
      { breakpoint: 768, settings: { centerPadding: '30px', slidesToShow: 3, rows: 1 } },
      { breakpoint: 980, settings: { centerPadding: '50px', slidesToShow: 4, rows: 1 } },
    ],
  };

  return (
    <CarouselItemsSplit speed={speed}>
      {/* <CarouselItemsContainer>{children}</CarouselItemsContainer> */}
      <Slider {...settings}>{children}</Slider>
    </CarouselItemsSplit>
  );
}

CarouselItems.defaultProps = defaultProps;
export default CarouselItems;

// styles
const CarouselItemsContainer = styled.div`
  /* display: flex; */
  /* flex-wrap: no-wrap; */

  /* animation-timing-function: linear;
  animation-name: slick-slide;
  animation-iteration-count: infinite;

  @keyframes slick-slide {
    0% {
      transform: translateX(-15%);
    }
    100% {
      transform: translateX(-115%);
    }
  } */
`;

export const CarouselItemsSplit = styled.div`
  /* display: flex; */

  /* ${CarouselItemsContainer} {
    animation-duration: ${({ speed }) => `${speed}ms`};
  } */

  @media (max-width: 375px) {
    height: 175px;
    .slick-slider {
      width: 375px;
      position: absolute;
      left: 0px;
    }
  }
`;
