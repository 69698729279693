import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getPath, CONTEST_LEADERBOARD_PATH, CONTEST_PAY_PATH } from 'v2/constants/router';
import { history } from 'v2/stores/configureStore';
import linkStyles from './linkStyles';
import getLinkColors from './getLinkColors';


const propTypes = {
  id: PropTypes.number.isRequired,
  kind: PropTypes.string,
  buttonText: PropTypes.string,
  urlContest: PropTypes.shape({
    weekOrder: PropTypes.number,
    categoryId: PropTypes.number,
  }),
  isStartSubmitPicks: PropTypes.bool,
  isProgressResult: PropTypes.bool,
};

const defaultProps = {
  kind: '',
  buttonText: '',
  urlContest: {},
  isStartSubmitPicks: false,
  isProgressResult: false,
};

function LinkGame(props) {
  const {
    isUserWin, kind, urlContest, id, buttonText, isProgressResult, isStartSubmitPicks, isEliminated,
  } = props;

  const weekOrderUrl = `/contests/${id}/make_picks/week-${urlContest.weekOrder}/category-${urlContest.categoryId}`;
  const leaderBoardUrl = `/contests/${id}/game_stats/leaderboard`;
  const btnText = buttonText;
  let linkUrl = urlContest.weekOrder ? weekOrderUrl : leaderBoardUrl;

  if (isUserWin || isEliminated) {
    linkUrl = getPath(CONTEST_LEADERBOARD_PATH, { id });
  }

  return (
    <StyledLinkGame>
      <Fragment>
        <StyledLink
          isEliminated={isEliminated}
          color={getLinkColors(kind, isStartSubmitPicks, isProgressResult)}
          to={linkUrl}
          isSurvive={kind == 'survive'}
          href={getPath(CONTEST_PAY_PATH, { id, weekOrder: urlContest.weekOrder, categoryId: urlContest.categoryId })}
        >
          {btnText}
        </StyledLink>
      </Fragment>
    </StyledLinkGame>
  );
}

const StyledLinkGame = styled.div`
  width: 100%;
  text-align: center;
  /* margin-bottom: 20px; */
  @media (min-width: 768px) {
    margin-bottom: 0px;
  }
`;

const StyledLink = styled.a`
  ${linkStyles}
  background-color: ${({ isSurvive, theme, isEliminated }) =>
    /* if (isEliminated) return theme.colors.redAccent; */
    isSurvive ? theme.colors.purpleAccent : theme.colors.greenAccent
}
`;

LinkGame.propTypes = propTypes;
LinkGame.defaultProps = defaultProps;

export default LinkGame;
