import * as actionTypes from '../../constants/actionTypes';
import { initialState } from '../../selectors/myGamesActive';

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MY_GAMES_ACTIVE_FETCH_SUCCESS:
      return {
        ...state,
        active: action.payload,
      };
    default:
      return state;
  }
};
