// @flow

import React from 'react';
import momenttz from 'moment-timezone';
import moment from 'moment';
import styled from 'styled-components';
import { replace } from 'ramda';

import { isSafari } from 'v2/utils/detectPlatform';

type StatisticsProps = {
  startsAt: string,
  participantsCount: number,
  participantsMaxCount: number,
};

const subdivide = (number) => number.toLocaleString('en');

function Statistics({
  startsAt,
  participantsCount,
  isContestActive,
  activeUserCount,
  participantsMaxCount,
  live,
  kind,
  league,
  ...props
}: StatisticsProps) {
  const isFootbal = league == 'nfl';

  const contestStartAt = replace(
    'EDT',
    'ET',
    momenttz(startsAt)
      .tz('America/New_York')
      .format(isFootbal ? 'ddd M/DD @ hh:mm z' : 'ddd M/DD'),
  );

  const isContestEnded = moment(new Date()).isAfter(props.endsAt);

  const isSurvive = () => kind === 'survive';
  return (
    <StatisticsContainer>
      <StatisticItem>
        {live ? (
          <StatisticItemTitle>{isSurvive() ? 'ADVANCED' : 'ENTRIES'}</StatisticItemTitle>
        ) : (
          <StatisticItemTitle>ENTRIES</StatisticItemTitle>
        )}
        {live ? (
          <StatisticItemValue>{subdivide(participantsCount)}</StatisticItemValue>
        ) : !isContestActive && isSurvive ? (
          <StatisticItemValue>{subdivide(activeUserCount)}</StatisticItemValue>
        ) : (
          <StatisticItemValue>{`${subdivide(participantsCount)}/${subdivide(
            participantsMaxCount,
          )}`}</StatisticItemValue>
        )}
      </StatisticItem>
      <StatisticItem>
        <StatisticItemTitle>{live || isContestEnded ? 'started' : 'Starts'}</StatisticItemTitle>
        <StatisticItemValue>{contestStartAt}</StatisticItemValue>
      </StatisticItem>
    </StatisticsContainer>
  );
}

const StatisticItemContent = styled.span`
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  margin-right: 5px;
  white-space: nowrap;
  line-height: 1;

  @media (max-width: 476px) {
    font-size: 11px;
  }
`;

const StatisticItemValue = styled(StatisticItemContent)`
  color: #fff;
  margin-right: 0;
  font-size: ${() => isSafari && '11px'};
`;

const StatisticItemTitle = styled(StatisticItemContent)`
  color: #656579;
  @media (max-width: 476px) {
    margin-right: 3px;
  }
`;

const StatisticItem = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;

  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

const StatisticsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1px;
  justify-content: flex-start;

  /* @media (max-width: 476px) {
    margin-left: 60px;
  } */
`;

export default Statistics;
