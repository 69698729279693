import React from 'react';
import PhotoBlock from 'v2/components/PromoPages/shared/PhotoBlock';
import GsLogo from 'v2/components/shared/Image/promo/GS_logo.svg';

import BlockDescription from 'v2/components/PromoPages/2/shared/DescriptionBlock';

function block() {
  return (
    <PhotoBlock.Wrapper style={{ height: '160px' }}>
      <PhotoBlock src={GsLogo}>
        <BlockDescription>
          WIN VIP EXPERIENCES,{'\n'}
          AUTOGRAPHED MEMORABILIA,{'\n'}
          CASH PRIZES & MORE!
        </BlockDescription>
      </PhotoBlock>
    </PhotoBlock.Wrapper>
  );
}

export default block;
