import React from 'react';
import styled from 'styled-components';

function Arrow(props) {
  const { className, style, handler, next, prev } = props;

  if (prev) {
    return <ArrowContainer className={className} onClick={handler} prev />;
  }

  if (next) {
    return <ArrowContainer className={className} onClick={handler} next />;
  }

  return null;
}

export default Arrow;

const ArrowContainer = styled.div`
  position: absolute;
  height: 100%;
  left: ${({ prev }) => prev && '-5px'};
  right: ${({ next }) => next && '-5px'};
  top: 50%;
  z-index: 8;

  &:before {
    opacity: 1;
    line-height: 1;
    z-index: 7;
    display: flex;
    width: 25px;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
  }

  &:after {
    content: '';
    display: block;
    width: 20px;
    height: 100%;
    /* background: #26262e;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5); */
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
    z-index: -1;
    opacity: 0.5;
  }
`;
