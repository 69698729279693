// @flow

import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const defaultProps = {
  isActive: false,
  url: null,
  title: 'View Prizes',
};

type PrizeItemProps = {
  isActive?: boolean,
  primaryPhotoUrl: string,
  secondaryPhotoUrl: string,
  url?: string,
  title?: string,
};

function PrizeItem({ isActive, primaryPhotoUrl, secondaryPhotoUrl, url, title }: PrizeItemProps) {
  return (
    <PrizeItemContainer active={isActive} mainImg={primaryPhotoUrl}>
      <PrizeItemContent active={isActive}>
        <PrizeItemSecondaryImg src={secondaryPhotoUrl} alt="secondary" />
        <StyledPrizeButton to={url}>{title}</StyledPrizeButton>
      </PrizeItemContent>
    </PrizeItemContainer>
  );
}

const StyledPrizeButton = styled(NavLink)`
  background: #5d5ce2;
  color: #fff;
  font-size: 16px;
  font-weight: bolder;
  padding: 1em 1.5em;
  border-radius: 4px;
  text-decoration: none !important;
  transition: filter 0.25s linear;

  position: absolute;
  left: 50%;
  bottom: 5%;
  transform: translate(-50%, -100%);
  z-index: 9;

  &:hover {
    filter: brightness(1.1);
  }
`;

const PrizeItemContent = styled.div`
  opacity: ${({ active }) => (active ? '1' : '0')};
  transition: opacity 0.75s ease;
  z-index: 999;
`;

const PrizeItemSecondaryImg = styled.img`
  width: 270px;
  height: 234px;
  display: block;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%);
`;

const PrizeItemContainer = styled.div`
  position: relative;
  width: 350px;
  height: 561px;
  border-radius: 8px;
  cursor: pointer;
  background-image: url(${({ mainImg }) => mainImg});
  background-size: cover;
  /* margin: 0 15px; */

  &:after {
    content: '';
    display: block;
    position: absolute;
    transition: opacity 0.4s ease;
    background: rgba(19, 19, 23, 0.8);
    opacity: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: ${({ active }) => !active && '1'};
  }

  &:hover {
    &:after {
    }
    &:before {
    }
  }

  @media (max-width: 420px) {
    width: 100%;
  }
`;
PrizeItem.defaultProps = defaultProps;
export default PrizeItem;
