// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';
import Footer from '.';

const withFooter = (WrappedComponent) => {
  return (props) => (
    <Fragment>
      <WrappedComponent {...props} />
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </Fragment>
  );
};

export default withFooter;

// styles
const FooterWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
`;
