import React from 'react';
import PhotoBlock from 'v2/components/PromoPages/shared/PhotoBlock';
import promoImg4 from 'v2/components/shared/Image/promo/2/4.png';
import BlockDescription, { BlockDescriptionaAccent, BlockDescriptionContainer } from '../../shared/DescriptionBlock';

function block() {
  return (
    <PhotoBlock.Wrapper>
      <PhotoBlock src={promoImg4} contentDirection="bottom">
        <BlockDescriptionContainer>
          <BlockDescription blackQuotes style={{ color: '#131317' }}>
            WHATCHA WANNA DO,{'\n'} WHATCHA GOT? CHECK IT OUT!{'\n'} GONE STREAKIN’ YA’LL!
          </BlockDescription>
          <BlockDescriptionaAccent style={{ color: '#e1073a' }}>STEVEN RIDLEY</BlockDescriptionaAccent>
          <BlockDescriptionaAccent style={{ color: '#e1073a' }}>@STEVENRIDLEY</BlockDescriptionaAccent>
        </BlockDescriptionContainer>
      </PhotoBlock>
    </PhotoBlock.Wrapper>
  );
}

export default block;
