import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import { withRouter, useLocation } from 'react-router';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import { isNotDefined } from 'v2/helpers/ramda';
import * as actions from 'v2/actions';
import { getMyGamesRecentList } from 'v2/selectors/myGamesRecent';
import { getMyGamesActiveList } from 'v2/selectors/myGamesActive';
import GamesCollection from 'v2/components/HomePage/shared/AvailableGames/Games';

import * as paramsHelpers from './shared/helpers/params';
import NavigationBlock from './shared/NavigationBlock';
// import NavigationPanel from './shared/NavigationPanel';
// import DesktopDescription from './shared/DesktopDescription';

const propTypes = {};

const defaultProps = {
  myGamesActive: [],
  myGamesRecent: [],
};

const { statusKey, kindKey, leagueKey, activeIdKey } = paramsHelpers;

const getLens = (key) => R.lens(R.prop(key), R.assoc(key));
const setParam = R.curry((params, lensKey, key) => R.set(getLens(lensKey), key, params));
const getParam = R.curry((params, lensKey) => R.view(getLens(lensKey), params));

const filterByLeague = R.curry((league, games) =>
  R.filter((game) => {
    if (league === 'all') return game;
    return R.propEq('league', league)(game);
  })(games));

const filterByKind = R.curry((kind, games) =>
  R.filter((game) => {
    if (kind === 'all') return game;
    return R.propEq('kind', kind)(game);
  })(games));

function MyGames(props) {
  const {
    session,
    myGamesActive,
    myGamesRecent,
    myGamesLink,
    match: {
      params: { league },
    },
  } = props;
  const [params, setParams] = useState(paramsHelpers.parseParams());

  function onParamsChange(input) {
    setParams(input);
    paramsHelpers.stringifyParams(input);
  }

  const onChange = R.curry((lensKey, key) => onParamsChange(setParam(params, lensKey, key)));

  const contestToShow = R.compose((p) =>
    R.compose(
      // R.filter(R.propEq('id', 223)), // delete this
      R.when(() => R.prop(kindKey, p), filterByKind(R.prop(kindKey, p))),
      R.when(() => R.prop(leagueKey, p), filterByLeague(R.prop(leagueKey, p))),
      R.ifElse(
        R.compose(R.equals('recent'), R.prop(statusKey)),
        () => myGamesRecent || [],
        () => myGamesActive || [],
      ),
      R.reject(isNotDefined),
      R.reject(R.isEmpty),
    )(p))(params);

  return (
    <StyledPanel>
      <Container>
        <NavigationBlock onChange={onChange} getStatus={getParam(params)} />
        <GamesCollection
          session={session}
          gamesCollection={contestToShow}
          myGamesCollection={contestToShow}
          activeId={R.prop(activeIdKey, params)}
          kind={R.propOr('survive', kindKey, params)}
          league={R.prop(leagueKey, params)}
          isMyGames
          showHeadshots
          showFAQ={compose(R.equals('active'), R.propOr('active', statusKey))(params)}
        />
      </Container>
    </StyledPanel>
  );
}

const mapStateToProps = (state) => ({
  myGamesActive: getMyGamesActiveList(state),
  myGamesRecent: getMyGamesRecentList(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchMyGamesActive: () => dispatch(actions.fetchMyGamesActive()),
  fetchMyGamesRecent: () => dispatch(actions.fetchMyGamesRecent()),
});

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { fetchMyGamesActive, fetchMyGamesRecent } = this.props;
      fetchMyGamesActive();
      fetchMyGamesRecent();
    },
  }),
);

const StyledPanel = styled.div`
  overflow: hidden;
  flex: 1 0 auto;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* margin-top: 50px; */

  @media (max-width: 425px) {
    margin-top: -20px;
    /* margin-top: -70px; */
  }
`;

const Container = styled.div`
  width: 100%;
  position: relative;
  max-width: 1200px;
  padding: 0px 5px;
  margin: 0 auto;
  overflow: visible;
  box-sizing: border-box;
  /* margin-bottom: 30px; */

  @media (max-width: 425px) {
    /* margin-bottom: 60px; */
    /* padding-bottom: 80px; */
  }
`;

MyGames.propTypes = propTypes;
MyGames.defaultProps = defaultProps;

export default enhance(React.memo(MyGames));
