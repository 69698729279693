import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, add, head, and, not, equals } from 'ramda';
import { NavLink } from 'react-router-dom';

import { getPath, CONTEST_LEADERBOARD_PATH, CONTEST_CATEGORY_PATH } from 'v2/constants/router';

import ProgressSubmit from './ProgressSubmit';
import ProgressInGame from './ProgressInGame';
import ProgressResult from './ProgressResult';

const propTypes = {
  kind: PropTypes.string,
};

const defaultProps = {
  kind: '',
};

function Progress(props) {
  const {
    id,
    isContestLive,
    isUserAdvanced,
    isUserWin,
    correctPicks,
    uncorrectPicks,
    isProgressResult,
    currentWeek,
    isUserEliminated,
    kind,
    urlContest,
    statCategories: statCategoriesIds,
  } = props;

  let redirectUrl = getPath(CONTEST_CATEGORY_PATH, {
    id,
    weekOrder: isUserAdvanced ? add(urlContest.weekOrder, 1) : urlContest.weekOrder,
    categoryId: head(statCategoriesIds),
  });

  if (compose(and(not(isUserWin)), equals('weekly'))(kind)) {
    redirectUrl = getPath(CONTEST_LEADERBOARD_PATH, { id });
  }

  if (isUserWin) {
    redirectUrl = getPath(CONTEST_LEADERBOARD_PATH, { id });
  }

  const getProgressComponent = () => {
    if (isUserWin || isUserEliminated || isUserAdvanced || isProgressResult) {
      return <ProgressResult {...props} />;
    }

    if (!isContestLive && correctPicks === 0 && !isProgressResult) {
      return <ProgressSubmit {...props} />;
    }

    if (!isUserAdvanced && isContestLive && !isProgressResult) {
      return <ProgressInGame {...props} />;
    }
  };

  return <ProgressLink to={redirectUrl}>{getProgressComponent()}</ProgressLink>;
}

Progress.propTypes = propTypes;
Progress.defaultProps = defaultProps;

export default Progress;

// styles
const ProgressLink = styled(NavLink)`
  text-decoration: none !important;
  width: 100%;
`;
