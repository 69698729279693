/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import getIcon from './getIcon';

const propTypes = {};

const defaultProps = {};
// FIXME
function Category(props) {
  const {
    to,
    subject,
    amount,
    isSelectedTop,
    isFill,
    isSelectedBottom,
    onCurrentCategoryId,
    contest,
    id,
    className,
    isBig,
  } = props;

  const subjects = subject.split(' ');
  const selectedTop = isSelectedTop ? 'selectedTop' : '';
  const selectedBottom = isSelectedBottom ? 'selectedBottom' : '';
  return (
    <StyledCategories
      onClick={() => onCurrentCategoryId(contest, id)}
      to={to}
      className={`${className} ${selectedTop || selectedBottom}`}
      isBig={isBig}
    >
      <CategoryNameContainer>
        <CategoryName>{subjects[0]}</CategoryName>
        <CategoryName>{subjects[1]}</CategoryName>
      </CategoryNameContainer>
      <CategoryText>
        {amount}
        <StyledCategoryImage src={getIcon({ subject, isFill, league: contest.league })} alt="" />
      </CategoryText>
    </StyledCategories>
  );
}

const StyledCategoryImage = styled.img`
  position: absolute;
  right: 5px;
  bottom: 10px;
  width: 42px;
  height: 42px;

  @media (max-width: 768px) {
    width: 34px;
    height: 34px;
  }
`;

const StyledCategories = styled(Link)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  position: relative;
  width: 100%;
  text-transform: uppercase;
  font-weight: 700;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #26262e;
  &:hover {
    text-decoration: none;
    border: 1px solid #26264c;
  }
  &:not(:first-child) {
    margin-left: 20px;
  }
  @media (max-width: 480px) {
    &:not(:first-child) {
      margin-left: 5px;
    }
  }
  &.selectedTop {
    border: 1px solid #0bd855;
  }
  &.selectedBottom {
    border: 1px solid #0bd855;
  }

  ${StyledCategoryImage} {
    @media (max-width: 768px) {
      width: ${({ isBig }) => isBig && '36px'};
      height: ${({ isBig }) => isBig && '36px'};
    }
  }

  @media (max-width: 480px) {
    max-width: 108px;
    max-width: ${({ isBig }) => isBig && '185px'};
  }
  /* &.selectedTop {
    position: relative;
    border: 1px solid #0bd855;
    &:before {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      border: 1px solid #0bd855;
      bottom: -5px;
      left: calc(50% - 4px);
      right: 0;
      transform: rotate(45deg);
      background: #1b1b20;
      z-index: 0;
      border-left-color: transparent;
      border-top-color: transparent;
    }
  }
  &.selectedBottom {
    position: relative;
    border: 1px solid #0bd855;
    &:after {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      border: 1px solid #0bd855;
      top: -5px;
      left: calc(50% - 4px);
      right: 0;
      transform: rotate(45deg);
      background: #1b1b20;
      z-index: 0;
      border-right-color: transparent;
      border-bottom-color: transparent;
    }
  } */
`;
const CategoryNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 5px;
`;

const CategoryName = styled.span`
  font-size: 12px;
  letter-spacing: 0.5px;
  color: #fff;
  /* margin-bottom: 15px; */
`;

const CategoryText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: -4px;
  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

Category.propTypes = propTypes;
Category.defaultProps = defaultProps;

// export default enhance(HomePage);
export default Category;
