// @flow

import React from 'react';
import styled from 'styled-components';
import { not } from 'ramda';

import { BLOG_ARTICLE_PATH, getPath } from 'v2/constants/router';

import SocialLinkSContainer from './Container';

type SocialLinkProps = {};

function SocialLink({ Adapter, title, icon, isVertical, handler }: SocialLinkProps) {
  const { origin } = window.location;

  if (Adapter) {
    return (
      <Adapter
        subject="subject"
        body="body"
        separator="separator"
        url={`${origin}${getPath(BLOG_ARTICLE_PATH, { articleId: 1 })}`}
      >
        <Container isVertical={isVertical}>
          <SocialImg src={icon} alt="img" />
        </Container>
      </Adapter>
    );
  }

  return (
    <Container isVertical={isVertical} onClick={handler}>
      <SocialImg src={icon} alt="img" />
    </Container>
  );
}

SocialLink.Container = SocialLinkSContainer;
export default SocialLink;

// styles
const SocialImg = styled.img`
  width: 100%;
  height: 100%;
  filter: opacity(0.3);
`;

const Container = styled.span`
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.gray};
  cursor: pointer;

  margin-right: ${({ isVertical }) => not(isVertical) && '12px'};
  margin-bottom: ${({ isVertical }) => isVertical && '12px'};
`;
