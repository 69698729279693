import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Field } from 'react-final-form';
// import { connect } from 'react-redux';
// import { compose } from 'recompose';
import PropTypes from 'prop-types';

// import * as actions from 'v2/actions';
import FormGroupAdapter from 'v2/components/shared/formDefault/FormGroup/Adapter';
import Input from 'v2/components/shared/formDefault/Input';
import TextArea from 'v2/components/shared/formDefault/TextArea';
import Button from 'v2/components/shared/formDefault/Button';
import ErrorMessage from 'v2/components/shared/formDefault/ErrorMessage';
import { SessionApiAdapter } from 'v2/apiAdapters';
import toast from 'v2/components/PushToasts/toast';
import { getUser } from 'v2/selectors/session';
import { camelizeKeys } from 'v2/utils/humps';
import { connect } from 'react-redux';

import { composeValidators, validateRequired, validateEmail } from 'v2/components/shared/formDefault/validations';

const defaultProps = {};

const propTypes = {};

function SignInForm({ currentUser }) {
  const [isSended, setSendedStatus] = useState(false);

  const sendFeedbackHandler = async (props, form) => {
    try {
      await SessionApiAdapter.sendFeedback(props);
      // toast({ title: <span>Success</span>, body: 'Feedback has been sent successfully.', kind: 'success' });
      setSendedStatus(true);
      form.reset();
      console.log('form :>> ', form);
    } catch (e) {
      console.log('e :>> ', e);
      // toast({ title: <span>Error</span>, body: 'Something went wrong', kind: 'danger' });
    }
  };

  if (isSended) {
    return (
      <Container>
        <Title>Thank you for contacting us!</Title>
      </Container>
    );
  }

  return (
    <Container>
      <Title>Contact Us</Title>
      <Form
        initialValues={{ username: currentUser.username, email: currentUser.email }}
        onSubmit={sendFeedbackHandler}
        render={({ form, handleSubmit, hasSubmitErrors, submitError, submitting }) => (
          <StyledForm
            // onSubmit={handleSubmit}
            onSubmit={async (event) => {
              handleSubmit(event, form);
            }}
          >
            <FieldsCollection isErrors={hasSubmitErrors || submitError}>
              <Field
                component={FormGroupAdapter}
                inputComponent={Input}
                name="username"
                help=""
                placeholder="Enter your username"
                type="text"
                label="Username"
              />

              <Field
                component={FormGroupAdapter}
                inputComponent={Input}
                name="email"
                help=""
                placeholder="Enter your email"
                type="email"
                label="Email"
                validate={composeValidators(validateRequired(), validateEmail())}
              />

              <Field
                component={FormGroupAdapter}
                inputComponent={TextArea}
                name="message"
                placeholder="Enter your message"
                type="text"
                label="Message"
                rows="5"
                validate={composeValidators(validateRequired())}
              />
              {hasSubmitErrors && submitError && (
                <ErrorMessageWrapper>
                  <ErrorMessage>{submitError}</ErrorMessage>
                </ErrorMessageWrapper>
              )}
            </FieldsCollection>

            <ButtonWrapper>
              <Button type="submit" disable={submitting}>
                {submitting ? 'Loading...' : 'Send'}
              </Button>
            </ButtonWrapper>
          </StyledForm>
        )}
      />
    </Container>
  );
}

const FieldsCollection = styled.div`
  margin-bottom: ${({ isErrors }) => (isErrors ? '25px' : '50px')};
`;

const ErrorMessageWrapper = styled.div`
  /* margin-bottom: 25px; */
  text-align: center;
`;

const StyledForm = styled.form`
  width: 100%;
  /* margin-top: 15px; */
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  font-size: 30px;
  font-weight: bold;
`;

const Container = styled.div`
  padding: 20px;
  margin: 20px;
  background: #1b1b21;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 730px;

  @media (min-width: 425px) {
    margin: 50px auto 0;
    padding: 30px 90px 50px;
  }
`;

const ButtonWrapper = styled.div`
  display: inline-flex;
  margin: 0 auto;
`;

// const mapDispatchToProps = (dispatch) => ({
//   postCredentials: (userData) => dispatch(actions.signIn(userData)),
// });

// const enhance = compose(connect(null, mapDispatchToProps));

const mapStateToProps = (store) => ({
  currentUser: camelizeKeys(getUser(store)),
});

SignInForm.defaultProps = defaultProps;
SignInForm.propTypes = propTypes;

export default connect(mapStateToProps)(SignInForm);
