import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import entities from './entities';
import request from './request';
import refs from './refs';
import contests from './contests';
import session from './session';
import myGamesActive from './myGamesActive';
import myGamesRecent from './myGamesRecent';
import gamesCount from './gamesCount';
import players from './players';
// import makePicks from './makePicks';

export default (history) =>
  combineReducers({
    contests,
    entities,
    refs,
    request,
    myGamesActive,
    myGamesRecent,
    gamesCount,
    players,
    // makePicks,
    router: connectRouter(history),
    session,
  });
