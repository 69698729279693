import React, { useEffect } from 'react';
import { compose } from 'ramda';
import { withRouter } from 'react-router';
import PromoPage1 from './1';
import PromoPage2 from './2';
import PromoPage3 from './3';

import withFooter from './shared/Footer/withFooter';
import withArrows from './shared/withArrows';

function PromoPages({ match: { params } }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (params.id === '1') return <PromoPage1 />;
  if (params.id === '2') return <PromoPage2 />;
  if (params.id === '3') return <PromoPage3 />;

  return null;
}

export default compose(withArrows, withFooter, withRouter)(PromoPages);
