// @flow

import React from 'react';
import styled from 'styled-components';

type AccordItemProps = {
  number: number,
  title: string,
};

function AccordItem({ number, title, children }: AccordItemProps) {
  return (
    <Container>
      <Info>
        <Counter>{number}</Counter>
        <Title>{title}</Title>
      </Info>
      {children && <ChildContent>{children}</ChildContent>}
    </Container>
  );
}

export default AccordItem;

// styles
const Container = styled.div`
  background: ${({ theme }) => theme.colors.gray};
  padding: 12px;
  border-radius: 20px;
  margin-bottom: 10px;
`;

const Title = styled.span`
  font-size: 17px;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
  line-height: 1.3;
  white-space: pre-line;
`;

const Counter = styled.span`
  background: ${({ theme }) => theme.colors.purpleAccent};
  height: 24px;
  width: 24px;
  min-width: 24px;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;

  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
`;

const Info = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const ChildContent = styled.div`
  margin-top: 12px;
`;
