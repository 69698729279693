import React from 'react';
import PropTypes from 'prop-types';

import useSimpleValueSelect from 'v2/hooks/useSimpleValueSelect';

import ReactSelect from './index';

const valuePropType = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

const propTypes = {
  onChange: PropTypes.func.isRequired,
  value: valuePropType,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: valuePropType,
      label: PropTypes.string,
    }),
  ),
};

const defaultProps = {
  value: undefined,
  options: [],
};

function SimpleValueAdapter(props) {
  const [value, onChange] = useSimpleValueSelect(props);
  return <ReactSelect {...props} onChange={onChange} value={value} />;
}

SimpleValueAdapter.propTypes = propTypes;
SimpleValueAdapter.defaultProps = defaultProps;

export default SimpleValueAdapter;
