// @flow
import React from 'react';
import styled from 'styled-components';
import footballIcon from 'v2/components/shared/Image/Sports/color/football.svg';
import baseballIcon from 'v2/components/shared/Image/Sports/color/baseball.svg';
import basketballIcon from 'v2/components/shared/Image/Sports/color/new_basketball.svg';
import { isBasketball, isFootball, isBaseball } from 'v2/services/defineSport';

type ContestIconProps = {
  league: string,
};

function ContestIcon({ league }: ContestIconProps) {
  const getLogo = (): string | null => {
    if (isBasketball(league)) {
      return basketballIcon;
    }

    if (isFootball(league)) {
      return footballIcon;
    }

    if (isBaseball(league)) {
      return baseballIcon;
    }

    return null;
  };

  return <StyledContestIcon src={getLogo()} />;
}

const StyledContestIcon = styled.img`
  width: 100%;
`;

export default ContestIcon;
