import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import useWindowSize from 'v2/services/useWindowSize';
import { getUserFullName } from 'v2/selectors/session';
import { DropDownNotifications } from 'v2/components/shared';
import bell from './bell.svg';
import DropDown from '../DropDown';

const propTypes = {};

const defaultProps = {};

function Profile(props) {
  const { user, session, userFullName, setMenuOpen, isMenuOpen } = props;
  const [isOpenNotification, setIsOpenNotification] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const [documentWidth] = useWindowSize();

  const isMobile = documentWidth < 560;

  const showNotification = () => {
    isOpenNotification ? setIsOpenNotification(false) : setIsOpenNotification(true);
    if (!isOpenNotification) {
      document.body.className = 'body_overflow_hidden';
    } else {
      document.body.className = 'body_overflow_show';
    }
  };

  // <CountNotification onClick={showNotification}>
  //         {session.notifications.length}
  //         <Notification isOpenNotification={isOpenNotification} session={session} />
  //       </CountNotification>
  return (
    <StyledProfile>
      {/* {session.user && <DropDownNotifications />} */}
      <UserProffile onClick={() => setMenuOpen((old) => !old)} ref={wrapperRef}>
        {/* <img alt="" src={user} /> */}
        <Wrap className="wrapper">
          <UserName className={isMenuOpen ? 'white' : ''} isMobile={isMobile}>
            {userFullName && isMobile
              ? userFullName
                  .split(' ')
                  .map((word) => word[0])
                  .join('')
              : userFullName}
            {/* <FirstName>{user.first_name}</FirstName> <LastName>{user.last_name}</LastName> */}
          </UserName>
          <IconDropdown className={isMenuOpen ? 'arrow_open' : ''}>
            <svg
              className="rotate"
              width="8px"
              height="4px"
              viewBox="0 0 8 4"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="ico_dropdown" transform="translate(-4.000000, -6.000000)" fill="#656579" fillRule="nonzero">
                  <path
                    d="M4.68284271,6.68284271 L7.71715729,9.71715729 C7.873367,9.873367 8.126633,9.873367 8.28284271,9.71715729 L11.3171573,6.68284271 C11.473367,6.526633 11.473367,6.273367 11.3171573,6.11715729 C11.2421427,6.04214274 11.1404012,6 11.0343146,6 L4.96568542,6 C4.74477153,6 4.56568542,6.1790861 4.56568542,6.4 C4.56568542,6.5060866 4.60782816,6.60782816 4.68284271,6.68284271 Z"
                    id="arrow"
                  />
                </g>
              </g>
            </svg>
          </IconDropdown>
        </Wrap>
      </UserProffile>
    </StyledProfile>
  );
  function useOutsideAlerter(ref) {
    // function handleClickOutside(event) {
    //   if (ref.current && !ref.current.contains(event.target)) {
    //     setIsOpen(false);
    //   }
    // }
    // useEffect(() => {
    //   document.addEventListener('mousedown', handleClickOutside);
    //   return () => {
    //     document.removeEventListener('mousedown', handleClickOutside);
    //   };
    // });
  }
}

const StyledProfile = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const UserProffile = styled.div`
  display: flex;
  align-items: center;
`;

const Wrap = styled.span`
  display: flex;
  align-items: center;
  margin-left: 5px;
  @media (min-width: 768px) {
    margin-left: 10px;
  }
  #arrow {
    &:hover {
      fill: #fff;
    }
  }
`;

const Bell = styled.span`
  display: flex;
`;

const IconDropdown = styled.div`
  margin-left: 0;
  line-height: 0px;
  @media (min-width: 768px) {
    margin-left: 10px;
  }
`;

const UserName = styled.div`
  font-size: 10px;
  font-size: ${({ isMobile }) => isMobile && '16px'};
  line-height: 12px;
  margin-right: 5px;
  margin-top: 0;
  margin-bottom: 0;
  @media (min-width: 768px) {
    margin-right: 0;
    display: block;
    font-size: 14px;
    line-height: 18px;
  }
  &:hover {
    color: #fff;
  }
`;

const CountNotification = styled.div`
  font-size: 14px;
  margin-left: 5px;
  padding: 1px 6px;
  border: 1px solid #e80c51;
  border-radius: 20px;
  letter-spacing: 0px;
  color: #fff;
`;

const FirstName = styled.div`
  display: inline-block;
`;

const LastName = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: inline-block;
  }
`;

Profile.propTypes = propTypes;
Profile.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  userFullName: getUserFullName(state),
});

export default connect(mapStateToProps)(Profile);
