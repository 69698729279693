import { SIGN_OUT } from 'v2/constants/router';

import { deleteRequest } from '../requests';
import fetch from '../fetch';

export default class SignOutApiAdapter {
  static getSignOut() {
    return fetch(SIGN_OUT, deleteRequest());
  }
}
