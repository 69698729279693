// @flow

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { useLocation } from 'react-router-dom';

import { history } from 'v2/stores/configureStore';
import { getUser, getUserFullName } from 'v2/selectors/session';
import * as actions from 'v2/actions';
import { SessionApiAdapter } from 'v2/apiAdapters';
import FormGroupAdapter from 'v2/components/shared/formDefault/FormGroup/Adapter';
import Input from 'v2/components/shared/formDefault/Input';
import InputMask from 'v2/components/shared/formDefault/Mask';
import Button from 'v2/components/shared/formDefault/Button';
import ReactSelect from 'v2/components/shared/formDefault/ReactSelect';
import ErrorMessage from 'v2/components/shared/formDefault/ErrorMessage';
import Toogle from 'v2/components/shared/formDefault/Toogle';
import Switch from 'v2/components/shared/formDefault/Switch';
import { camelizeKeys } from 'v2/utils/humps';
import {
  composeValidators,
  validateRequired,
  validatePhone,
  validateDate,
  validateEmail,
} from 'v2/components/shared/formDefault/validations';

import Title from '../Title';
import ModalChangeEmail from './ModalChangeEmail';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const defaultProps = {};

// type InviteFriendsFormProps = {};

function InviteFriendsForm({ currentUser, userFullName, updateUserData }) {
  const [teams, setTeams] = useState([]);
  const query = useQuery();

  useEffect(() => {
    async function fetchTeams() {
      const res = await SessionApiAdapter.fetchFavoriteTeams();
      setTeams(res);
    }

    fetchTeams();
  }, []);

  return (
    <>
      {query.get('form') === 'change-email' && <ModalChangeEmail />}
      <div>
        <Title>{userFullName}</Title>
        <Form
          initialValues={{ user: currentUser }}
          onSubmit={(props) => updateUserData(props)}
          // validate={({ user }) => {
          //   const errors = {};

          //   return errors;
          // }}
          render={({ handleSubmit, hasSubmitErrors, submitError, submitting }) => (
            <InviteFriendsFormContainer onSubmit={handleSubmit}>
              <InviteFriendsFormGroupCollection>
                <Field
                  onClick={() => {
                    window.scrollTo(0, 0);
                    history.push('?form=change-email');
                  }}
                  component={FormGroupAdapter}
                  inputComponent={Input}
                  name="user.email"
                  help=""
                  placeholder="Email"
                  type="email"
                  label="Email"
                  validate={composeValidators(validateRequired(), validateEmail())}
                />
                <FieldsCollection>
                  <Field
                    component={FormGroupAdapter}
                    inputComponent={Input}
                    name="user.firstName"
                    help=""
                    placeholder="First name"
                    type="input"
                    label="FIrst Name"
                    validate={validateRequired()}
                  />

                  <Field
                    component={FormGroupAdapter}
                    inputComponent={Input}
                    name="user.lastName"
                    help=""
                    placeholder="Last name"
                    type="input"
                    label="Last Name"
                    validate={validateRequired()}
                  />
                </FieldsCollection>

                <Switch
                  title="Gender"
                  options={[
                    { id: 1, title: 'Male', value: 'male', name: 'user.gender' },
                    { id: 2, title: 'Female', value: 'female', name: 'user.gender' },
                  ]}
                />

                <Field
                  component={FormGroupAdapter}
                  inputComponent={Input}
                  name="user.username"
                  help=""
                  placeholder="Username"
                  type="input"
                  label="Username"
                  validate={validateRequired()}
                />

                <FieldsCollection>
                  <Field
                    component={FormGroupAdapter}
                    inputComponent={InputMask}
                    name="user.phoneNumber"
                    placeholder="+1 (000) 000-0000"
                    typeOfMask="phone"
                    label="Phone Number"
                    validate={composeValidators(validateRequired(), validatePhone())}
                  />

                  <Field
                    component={FormGroupAdapter}
                    inputComponent={InputMask}
                    name="user.birthday"
                    placeholder=""
                    typeOfMask="date"
                    label="Birthday"
                    validate={composeValidators(validateRequired(), validateDate())}
                  />
                </FieldsCollection>

                <Field
                  component={FormGroupAdapter}
                  inputComponent={ReactSelect}
                  name="user.teamId"
                  placeholder="Select favorite team"
                  options={teams}
                  type="select"
                  label="Favorite team"
                  validate={validateRequired()}
                />

                <Field
                  component={FormGroupAdapter}
                  inputComponent={(props) => <Toogle {...props}>Receive notifications</Toogle>}
                  name="user.recieveNotifications"
                  label="notifications"
                  type="checkbox"
                />

                <ToogleCollection>
                  <Field
                    component={FormGroupAdapter}
                    inputComponent={(props) => <Toogle {...props}>My picks reminder</Toogle>}
                    name="user.myPicksReminder"
                    label="notifications"
                    type="checkbox"
                  />
                  <Field
                    component={FormGroupAdapter}
                    inputComponent={(props) => <Toogle {...props}>Upcoming games</Toogle>}
                    name="user.upcomingGamesReminder"
                    type="checkbox"
                  />
                  <Field
                    component={FormGroupAdapter}
                    inputComponent={(props) => <Toogle {...props}>Prizes</Toogle>}
                    name="user.prizesReminder"
                    type="checkbox"
                  />
                </ToogleCollection>
              </InviteFriendsFormGroupCollection>

              {hasSubmitErrors && submitError && <ErrorMessage>{submitError}</ErrorMessage>}

              <Button type="submit" disable={submitting}>
                {submitting ? 'Saving...' : 'Save changes'}
              </Button>
            </InviteFriendsFormContainer>
          )}
        />
      </div>
    </>
  );
}

const mapStateToProps = (store) => ({
  currentUser: camelizeKeys(getUser(store)),
  userFullName: getUserFullName(store),
});

const mapDispatchToProps = (dispatch) => ({
  updateUserData: (userData) => dispatch(actions.updateAccountData(userData)),
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(InviteFriendsForm);

// styles

const FieldsCollection = styled.div`
  display: flex;

  div {
    padding: 0 15px;
    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 25px;
    div {
      padding: 0;
    }
  }
`;

const InviteFriendsFormGroupCollection = styled.div`
  width: 100%;
`;

const InviteFriendsFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    width: auto;
  }
`;

const ToogleCollection = styled.div`
  div {
    margin: 2px;
  }

  margin-bottom: 25px;
`;
