import { getRequest, postRequest } from '../requests';
import fetch from '../fetch';
import { MAKE_PICKS_PATH, getPath } from '../../constants/router';

export default class MakePicksApiAdapter {
  static getMakePicks(params) {
    const url = getPath(MAKE_PICKS_PATH, params);

    return fetch(url, getRequest());
  }

  static submitMakePicks(body, id, weekOrder) {
    const url = getPath(MAKE_PICKS_PATH, { id: id, weekOrder: weekOrder });
    return fetch(url, postRequest({ body }));
  }
}
