// @flow

import React from 'react';
import styled from 'styled-components';
import { path, compose, equals, prop } from 'ramda';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import { getPrizesList } from 'v2/selectors/prizes';
import { HOW_TO_PLAY } from 'v2/constants/router';
import { getQuerystring } from 'v2/apiAdapters/fetch';

import LogoImage from 'v2/components/shared/Image';
import star from 'v2/components/shared/Image/star.svg';
import mysteryMemorabliaImg from 'v2/components/shared/Image/mysteryMemorablia.svg';
import avatarPickemIcon from 'v2/components/shared/Image/avatar3.png';
import avatarSurviveIcon from 'v2/components/shared/Image/avatar1.png';
import Progress from 'v2/components/shared/GameCard/Progress';

import ContestLogo from 'v2/components/blocks/IconGame/shared/ContestLogo';
import ContestIcon from 'v2/components/blocks/IconGame/shared/ContestIcon';

import Period from 'v2/components/blocks/Period';

import InfoIcon from './InfoIcon';

type MakePicksHelpProps = {
  kind: string,
  prizeList?: [any],
};

const defaultProps = {
  prizeList: [],
};

const getAvatarImg = (type) => {
  if (type === 'survive') return avatarSurviveIcon;
  return avatarPickemIcon;
};

function MakePicksHelp({ prizeList, contest, onClick }: MakePicksHelpProps) {
  const { currentWeek, startsAt, endsAt, mysteryMemorablia } = contest;
  const intervalEndAt = moment(endsAt).format('D');
  const intervalStartAt = moment(startsAt).format('MMMM D');
  const isSurvive = compose(equals('survive'), prop('kind'))(contest);
  const league = prop('league', contest);

  const avatarImg = getAvatarImg(prop('kind', contest));

  const getDate = () => {
    if (isSurvive) {
      if (league == 'nfl') return `WEEK ${currentWeek}`;
      const startInt = moment(startsAt)
        .add(currentWeek, 'week')
        .weekday(0)
        .format('MMM D');
      const endInt = moment(startsAt)
        .add(currentWeek, 'week')
        .weekday(6)
        .format('D');

      return ` ${startInt} - ${endInt}`;
    }

    if (
      moment(startsAt)
        .startOf('day')
        .isSame(moment(endsAt).startOf('day'))
    ) {
      return intervalStartAt;
    }

    return `${intervalStartAt} - ${intervalEndAt}`;
  };

  return (
    <Container>
      {/* <StyledSubBlock>
        <ContestIconWrapper>
          <ContestIcon league={league} />
        </ContestIconWrapper>
        {!isSurvive && <StyledText isSurvive={isSurvive}>{getDate()}</StyledText>}
        {isSurvive && <StyledText>{getDate()}</StyledText>}
      </StyledSubBlock> */}
      <Period {...contest} />
      <ContestLogoWrapper>
        <ContestLogo {...contest} />
      </ContestLogoWrapper>

      <HowToPlayText onClick={onClick} kind={prop('kind', contest)}>
        <InfoIcon />
        <span>How to play?</span>
      </HowToPlayText>

      <Prizes>
        {prizeList.map(({ description, id }) => (
          <StyledGrandPrize key={id}>
            <GrandPrizeAvatar>
              {mysteryMemorablia ? (
                <LogoImage style={{ height: '100%', borderRadius: '50px' }} img={mysteryMemorabliaImg} />
              ) : (
                <LogoImage style={{ height: '100%', borderRadius: '50px' }} img={avatarImg} />
              )}
            </GrandPrizeAvatar>

            <GrandPrizeInfo>
              <Status>
                <img src={star} alt="" />
                Grand Prize
              </Status>
              {description && <GrandPrizeDescription>{description}</GrandPrizeDescription>}
            </GrandPrizeInfo>
          </StyledGrandPrize>
        ))}

        <ProgressWrapper>
          <Progress {...contest} />
        </ProgressWrapper>
      </Prizes>
    </Container>
  );
}

const mapStateToProps = (state, ownProps) => ({
  prizeList: getPrizesList(state, { prizeIds: path(['contest', 'prizes'], ownProps) }),
});
// export default enhance(HomePage);
MakePicksHelp.defaultProps = defaultProps;
export default connect(mapStateToProps)(MakePicksHelp);

// styles
const ProgressWrapper = styled.div`
  width: 100%;
  margin-bottom: 12px;
`;

const GrandPrizeAvatar = styled.div`
  /* margin-left: -25px; */
  /* position: absolute;
  top: 50%;
  border-radius: 50%;
  top: -25%;
  left: 0%;
  transform: translate(25%, 50%); */

  height: 60px !important;
  width: 60px !important;

  margin-right: 15px;
`;

const Status = styled.span`
  display: flex;
  font-size: 12px;
`;

const StyledGrandPrize = styled.div`
  position: relative;
  width: 100%;
  display: inline-flex;
  align-items: center;
  align-items: stretch;
  border-radius: 8px;
  background-color: #26262e;

  margin-bottom: 12px;

  padding: 15px;
`;

const Prizes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  margin-top: 10px;
`;

const GrandPrizeInfo = styled.div`
  display: flex;
  flex-direction: column;
  /* padding-right: 10px; */
  justify-content: space-around;
  justify-content: flex-start;

  /* margin-left: 60px; */
  /* padding: 0.25rem; */

  /* padding: 10px 0; */
`;

const GrandPrizeDescription = styled.h3`
  font-size: ${({ children }) => {
    if (children.length < 24) return '17px';
    if (children.length < 34) return '14px';
    return '12px';
  }};
  margin: 0;

  line-height: 18px;
  padding: 5px 0;
  /* line-height: 1;
  margin-top: 0.1em; */
`;

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.lighGray};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  border-radius: 8px;
  padding: 10px 15px;

  max-width: 365px;
  margin: auto;
`;

const StyledText = styled.span`
  margin-left: 8px;
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;

  text-transform: ${({ isSurvive }) => (isSurvive ? 'uppercase' : 'capitalize')};
`;

const ContestLogoWrapper = styled.div`
  /* margin-bottom: 10px; */
  margin-top: 0.25rem;
`;

const HowToPlayText = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 0;
  text-decoration: none !important;

  path {
    fill: ${({ theme }) => theme.colors.white};
  }

  span {
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.white};
    margin-left: 0.4rem;
  }

  path {
    fill: ${({ theme, kind }) => {
      if (kind === 'survive') return theme.colors.purpleAccent;
      if (kind === 'weekly') return theme.colors.greenAccent;

      return theme.colors.white;
    }};
  }

  span {
    color: ${({ theme, kind }) => {
      if (kind === 'survive') return theme.colors.purpleAccent;
      if (kind === 'weekly') return theme.colors.greenAccent;

      return theme.colors.white;
    }};
  }
`;

const StyledSubBlock = styled.div`
  display: flex;
  align-items: center;

  padding: 8px;
  border: 1px solid #26262e;
  border-radius: 15px;
`;

const ContestIconWrapper = styled.div`
  width: 16px;
  display: flex;
`;
