// @flow

import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { getInOr } from 'v2/helpers/ramda';

import ContestIcon from './shared/ContestIcon';

type PeriodProps = {};

function Period({ kind, league, currentWeek, startsAt, endsAt, ...rest }: PeriodProps) {
  const isSurvive = kind === 'survive';
  const intervalStartAt = moment(startsAt).format('MMMM D');
  const intervalEndAt = moment(endsAt).format('D');

  const contestWeekOrder = getInOr(currentWeek, 'urlContest.weekOrder', rest);

  const getDate = () => {
    if (league == 'nfl') return `WEEK ${contestWeekOrder}`;

    if (league == 'nba') {
      // if (isSurvive) return `WEEK ${contestWeekOrder}`;
      return intervalStartAt;
    }

    if (isSurvive) {
      if (league == 'nfl') return `WEEK ${contestWeekOrder}`;

      const startInt = moment(startsAt)
        .add(contestWeekOrder, 'week')
        .weekday(0)
        .format('MMM D');
      const endInt = moment(startsAt)
        .add(contestWeekOrder, 'week')
        .weekday(6)
        .format('D');

      return ` ${startInt} - ${endInt}`;
    }

    if (
      moment(startsAt)
        .startOf('day')
        .isSame(moment(endsAt).startOf('day'))
    ) {
      return intervalStartAt;
    }

    return `${intervalStartAt} - ${intervalEndAt}`;
  };

  return (
    <StyledSubBlock>
      <ContestIconWrapper>
        <ContestIcon league={league} />
      </ContestIconWrapper>
      {!isSurvive && <StyledText isSurvive={isSurvive}>{getDate()}</StyledText>}
      {isSurvive && (
        <StyledText>
          {/* <StyledText isSurvive>Week:</StyledText> */}
          {getDate()}
        </StyledText>
      )}
    </StyledSubBlock>
  );
}

export default Period;

// styles
const ContestIconWrapper = styled.div`
  width: 16px;
  display: flex;
`;

const StyledSubBlock = styled.div`
  display: flex;
  align-items: center;
  min-height: 30px;

  padding: 5px 8px;
  border: 1px solid #26262e;
  border-radius: 15px;
`;

const StyledText = styled.span`
  margin-left: 8px;
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;

  text-transform: ${({ isSurvive }) => (isSurvive ? 'uppercase' : 'capitalize')};
`;
