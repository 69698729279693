// @flow

import React from 'react';
import styled from 'styled-components';
import { times, pipe, addIndex, map, compose, join, split } from 'ramda';

import getIcon from 'v2/components/Contests/shared/Category/getIcon';

import { statusStyles, prizeBlockStyles } from '../../../styles';

import PlayerHeadshot from './PlayerHeadshot';

const defaultProps = {
  selectedPlayers: [],
};

type SurviveStatCategoryProps = {
  selectedPlayers?: [],
};

const mapWithIndex = addIndex(map);

const getName = compose(join('\n'), split(' '));

function SurviveStatCategory({
  id,
  subject,
  amount,
  league,
  hideName,
  selectedPlayers,
  selectionsRequired,
  showHeadshots,
}: SurviveStatCategoryProps) {
  const playersCollection = selectedPlayers.map((player, index) => (
    <PlayerHeadshot
      {...player}
      league={league}
      isCenter={index == 2}
      large={selectionsRequired < 3}
      hideName={hideName}
    />
  ));

  return (
    <Container>
      <StyledPrize key={id} marginRight>
        <StyledStatus>{getName(subject)}</StyledStatus>
        <StyledPrizeStat>
          <StyledPrizeText>{amount}</StyledPrizeText>
          <StyledCategoryIcon
            src={getIcon({ subject, league })}
            alt="americanFootballIcon"
            // small={statCategoriesList.length > 2}
          />
        </StyledPrizeStat>
      </StyledPrize>
      {showHeadshots && (
        <PlayersContainer>
          {pipe(
            times((index) => (
              <PlayerHeadshot
                key={index}
                league={league}
                isCenter={index == 2}
                large={selectionsRequired < 3}
                hideName={hideName}
              />
            )),
            mapWithIndex((defaultPlayer, index) => playersCollection[index] || defaultPlayer),
          )(selectionsRequired)}
        </PlayersContainer>
      )}
    </Container>
  );
}

SurviveStatCategory.defaultProps = defaultProps;
export default SurviveStatCategory;

const StyledPrizeText = styled.span`
  font-size: 20px;
  line-height: 18px;
  color: #fff;
  letter-spacing: 0.5px;
  display: block;
`;

const StyledCategoryIcon = styled.img`
  /* height: 36px; */
  width: ${({ small }) => (small ? '28px' : '36px')};
  position: absolute;
  right: 0;
`;

const StyledPrizeStat = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
`;

const StyledStatus = styled.span`
  ${statusStyles}
  white-space: break-spaces;
`;

const StyledPrize = styled.div`
  ${prizeBlockStyles}
  flex-direction: column;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const PlayersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  padding-top: 8px;
  margin: 8px 0;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 3px;
  max-width: 50%;
`;
