// @flow

import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import upIcon from 'v2/components/shared/Image/up_icon.svg';
import downIcon from 'v2/components/shared/Image/down_icon.svg';

type GamesTableProps = {
  games?: Array<{
    startsAt: string,
    homeTeam: string,
    awayTeam: string,
    started: boolean,
    missGame: boolean,
    hitInGame: boolean,
  }>,
};

const defaultProps = {
  games: [],
};

const formatGameDate = (date) => moment(date).format('MM/DD');
const formatAwayTeam = (awayTeam) => `@ ${awayTeam}`;

const getStatusGameImage = (isWin) => {
  if (isWin) return upIcon;

  return downIcon;
};
function GamesTable({ games }: GamesTableProps) {
  return (
    <Container>
      <Title>Last four games</Title>
      <Table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Game</th>
            <th>Result</th>
          </tr>
        </thead>
        <tbody>
          {games.map(({ startsAt, awayTeam, hitInGame }) => (
            <tr>
              <td>{formatGameDate(startsAt)}</td>
              <td>
                <AwayTeam>{formatAwayTeam(awayTeam)}</AwayTeam>
              </td>
              <td>
                <StatusImg src={getStatusGameImage(hitInGame)} alt="status" />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {games.length == 0 && <NoGamesBlock>No Games</NoGamesBlock>}
    </Container>
  );
}

GamesTable.defaultProps = defaultProps;
export default GamesTable;

// styles

const AwayTeam = styled.span`
  font-weight: bold;
  font-size: 16px;
`;

const StatusImg = styled.img`
  width: 20px;
  display: block;
  margin: auto;
`;

const Title = styled.span`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

const Table = styled.table`
  display: grid;
  padding: 10px 15px 0px;
  border-collapse: collapse;
  min-width: 100%;
  grid-template-columns:
    minmax(120px, 1fr)
    minmax(120px, 1fr)
    minmax(55px, 0.5fr);

  thead,
  tbody,
  tr {
    display: contents;
  }

  tr:nth-child(even) td {
    background-color: ${({ theme }) => theme.colors.lighGray};
  }

  tr td {
    background: #26262e;

    &:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  thead th {
    color: ${({ theme }) => theme.colors.lightenGray};

    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }

  thead th,
  tbody td {
    padding: 13px 15px;
    text-align: left;

    /* &:last-child {
      text-align: right;
    } */
  }
`;

const NoGamesBlock = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-weight: bold;
  font-size: 16px;
`;

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 40px 0 15px;
  background-color: ${({ theme }) => theme.colors.lighGray};
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`;
