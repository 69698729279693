// @flow

import React from 'react';
import styled from 'styled-components';

import DotsContainer from './Container';

type DotItemProps = {
  isActive?: boolean,
  isFilled?: boolean,
};

const defaultProps = {
  isActive: false,
  isFilled: false,
};

function DotItem({ isActive, isFilled }: DotItemProps) {
  return <Container isFilled={isFilled} isActive={isActive} />;
}

DotItem.Container = DotsContainer;

DotItem.defaultProps = defaultProps;
export default DotItem;

// styled

const Container = styled.div`
  height: 3px;
  width: 100%;
  border-radius: 6px;
  opacity: 0.5;
  transition: opacity 0.25s;
  opacity: ${({ isActive }) => isActive && '1'};

  background-color: ${({ theme }) => theme.colors.lightenGray};
  background-color: ${({ theme, isActive }) => isActive && theme.colors.white};
  background-color: ${({ theme, isFilled }) => isFilled && theme.colors.greenAccent};
`;
