import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { SIGN_UP_PATH, getPath } from 'v2/constants/router';
import arrow from 'v2/components/shared/Image/Icon/ico_arrow.svg';
import SignInForm from './shared/Form';

const propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      contest: PropTypes.shape({
        id: PropTypes.number.isRequired,
        urlContest: PropTypes.shape({
          categoryId: PropTypes.number.isRequired,
          weekOrder: PropTypes.number.isRequired,
        }),
      }),
    }),
  }).isRequired,
};

function SignIn({ location: { state } }) {
  const contest = state && state.contest;

  return (
    <SignInContainer>
      <SignInForm contest={contest} />
      <HaveAccountText>
        Don't have an account?
        <HaveAccountLink to={getPath(SIGN_UP_PATH, {})}>Sign Up</HaveAccountLink>
      </HaveAccountText>
    </SignInContainer>
  );
}

const HaveAccountText = styled.p`
  cursor: pointer;
`;

const HaveAccountLink = styled(NavLink)`
  text-transform: capitalize !important;
  margin-left: 5px;
  position: relative;
  text-decoration: none !important;

  &:after {
    background: url(${arrow}) no-repeat;
    content: '';
    display: inline-block;
    position: absolute;
    right: -75%;
    top: 50%;
    transform: translate(0, -20%);
    width: 20px;
    height: 20px;
  }
  /*
  @keyframes pulse {
    0% {
      right: -70%;
    }
    25% {
      right: -75%;
    }
    100% {
      right: -70%;
    }
  } */

  &:hover {
    filter: brightness(75%);
    &:after {
      right: -80%;
      transition: right 0.2s linear;
    }
  }
`;

const SignInContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

SignIn.propTypes = propTypes;

export default SignIn;
