import { MY_GAMES_RECENT } from 'v2/constants/router';

import { getRequest } from '../requests';
import fetch from '../fetch';

export default class MyGamesRecentApiAdapter {
  static getMyGamesRecentApiAdapter() {
    return fetch(MY_GAMES_RECENT, getRequest());
  }
}
