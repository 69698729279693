import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import * as actions from 'v2/actions';
import { SessionApiAdapter } from 'v2/apiAdapters';
import FormGroupAdapter from 'v2/components/shared/formDefault/FormGroup/Adapter';
import Input from 'v2/components/shared/formDefault/Input';
import InputMask from 'v2/components/shared/formDefault/Mask';
import Button from 'v2/components/shared/formDefault/Button';
import Checkbox from 'v2/components/shared/formDefault/Checkbox';
import ReactSelect from 'v2/components/shared/formDefault/ReactSelect';
import ErrorMessage from 'v2/components/shared/formDefault/ErrorMessage';
import {
  composeValidators,
  validateRequired,
  validatePhone,
  validateDate,
  validateCheckbox,
} from 'v2/components/shared/formDefault/validations';

const defaultProps = {
  contest: null,
};

const propTypes = {
  contest: PropTypes.shape({
    id: PropTypes.number.isRequired,
    urlContest: PropTypes.shape({
      categoryId: PropTypes.number.isRequired,
      weekOrder: PropTypes.number.isRequired,
    }),
  }),
  setupAccount: PropTypes.func.isRequired,
};

function PersonalDetailsForm({ setupAccount, contest }) {
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    async function fetchTeams() {
      const res = await SessionApiAdapter.fetchFavoriteTeams();
      setTeams(res);
    }

    fetchTeams();
  }, []);

  return (
    <PersonalDetailsFormContainer>
      <FormTitle>Please Fill In Personal Details</FormTitle>
      <Form
        initialValues={{ contest }}
        onSubmit={(props) => setupAccount(props)}
        validate={({ user }) => {
          const errors = {};

          if (user) {
            if (!user.acceptTermsOfService) {
              errors.user = { acceptTermsOfService: 'Checkbox must be selected' };
            }
          }

          return errors;
        }}
        render={({ handleSubmit, hasSubmitErrors, submitError, submitting }) => (
          <StyledForm onSubmit={handleSubmit}>
            <FieldsCollection>
              <FieldsGroup>
                <FieldWrapper>
                  <Field
                    component={FormGroupAdapter}
                    inputComponent={Input}
                    name="user.firstName"
                    help=""
                    placeholder="Enter first name"
                    type="input"
                    label="FIrst Name"
                    validate={validateRequired()}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <Field
                    component={FormGroupAdapter}
                    inputComponent={Input}
                    name="user.lastName"
                    help=""
                    placeholder="Enter last name"
                    type="input"
                    label="Last Name"
                    validate={validateRequired()}
                  />
                </FieldWrapper>
              </FieldsGroup>

              <Field
                component={FormGroupAdapter}
                inputComponent={InputMask}
                name="user.phoneNumber"
                placeholder="+1 (000) 000-0000"
                typeOfMask="phone"
                label="Phone Number"
                validate={composeValidators(validateRequired(), validatePhone())}
              />

              <FieldsGroup>
                <FieldWrapper>
                  <Field
                    component={FormGroupAdapter}
                    inputComponent={Input}
                    name="user.username"
                    placeholder="Enter your username"
                    type="input"
                    label="Username"
                    validate={validateRequired()}
                  />
                </FieldWrapper>

                <FieldWrapper>
                  <Field
                    component={FormGroupAdapter}
                    inputComponent={InputMask}
                    name="user.birthday"
                    placeholder=""
                    typeOfMask="date"
                    label="Birthday"
                    validate={composeValidators(validateRequired(), validateDate())}
                  />
                </FieldWrapper>
              </FieldsGroup>

              <Field
                component={FormGroupAdapter}
                inputComponent={ReactSelect}
                name="user.teamId"
                placeholder="Select favorite team"
                options={teams}
                type="select"
                label="Favorite team"
                validate={validateRequired()}
              />

              <Field
                component={FormGroupAdapter}
                inputComponent={Input}
                name="user.referalCode"
                placeholder="Enter your code"
                type="input"
                label="Referal Code"
              />

              <Field
                component={FormGroupAdapter}
                inputComponent={(props) => (
                  <Checkbox {...props}>
                    <TermsOsUse />
                  </Checkbox>
                )}
                name="user.acceptTermsOfService"
                type="checkbox"
                validate={validateCheckbox()}
                // label="Referal Code"
              />
              {hasSubmitErrors && submitError && (
                <ErrorMessageWrapper>
                  <ErrorMessage>{submitError}</ErrorMessage>
                </ErrorMessageWrapper>
              )}
            </FieldsCollection>

            <div>
              {/* <Button type="submit" disable={false}>
                Create account
              </Button> */}

              <Button type="submit" disable={submitting}>
                {submitting ? 'Loading...' : 'Create account'}
              </Button>
            </div>
          </StyledForm>
        )}
      />
    </PersonalDetailsFormContainer>
  );
}

const TermsOsUse = () => (
  <StyledText>
    I agree to the
    {' '}
    <StyledLink>Terms of Use</StyledLink>
    {' '}
    and the
    {' '}
    <StyledLink>Privacy Policy</StyledLink>
    {' '}
    and confirm
    that I am at least 18 years of age.Must be 19 years of age in Alabama and Nebraska. Must be 21 years of age in
    Massachusetts.
  </StyledText>
);

const FormTitle = styled.h2`
  @media (max-width: 468px) {
    font-size: 25px;
  }
`;

const ErrorMessageWrapper = styled.div`
  /* margin-bottom: 25px; */
  text-align: center;
`;

const StyledLink = styled.a`
  font-size: 11px !important;
  cursor: pointer;
  margin: 0;
`;

const StyledText = styled.p`
  padding: 0 25px;
  font-size: 14px;
  margin: 0;

  @media (max-width: 475px) {
    font-size: 11px;
    ${StyledLink} {
      font-size: 9px !important;
    }
  }
`;

const FieldsCollection = styled.div`
  margin-bottom: 50px;
`;

const FieldWrapper = styled.div`
  flex: 0 0 47%;

  @media (max-width: 768px) {
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const FieldsGroup = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  margin-bottom: 25px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const StyledForm = styled.form`
  width: 100%;
  margin-bottom: 25px;
  margin-top: 15px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PersonalDetailsFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 40px 90px;

  @media (max-width: 768px) {
    padding: 20px 45px;
  }

  @media (max-width: 660px) {
  }

  @media (max-width: 475px) {
    padding: 10px 30px;
  }
`;

const mapDispatchToProps = (dispatch) => ({
  setupAccount: (userData) => dispatch(actions.setupAccount(userData)),
});

const enhance = compose(connect(null, mapDispatchToProps));

PersonalDetailsForm.defaultProps = defaultProps;
PersonalDetailsForm.propTypes = propTypes;
export default enhance(PersonalDetailsForm);
