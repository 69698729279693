import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

// import { notificationTypes } from 'v2/types';
import Notification from '../../Notification';

// type ListOfNotificationsProps = {
//   notifications: Array<{ ...notificationTypes }>,
// };

// const defaultProps = {
//   notifications: [],
// };

function ListOfNotifications({ notifications }: ListOfNotificationsProps) {
  if (notifications.length === 0) {
    return null;
  }

  return (
    <NotificationsContainer>
      <NotificationsList>
        {notifications.map((notification) => (
          <NotificationWrapper key={notification.id}>
            <Notification createdAt={notification.created_at} {...notification} />
          </NotificationWrapper>
        ))}
      </NotificationsList>
      {/* <SeeAllNotifications to="/">See all incoming activity</SeeAllNotifications> */}
    </NotificationsContainer>
  );
}

// ListOfNotifications.defaultProps = defaultProps;
export default ListOfNotifications;

// styles

const SeeAllNotifications = styled(NavLink)`
  position: relative;
  color: #656579;
  display: inline-block;
  width: 100%;
  padding: 20px;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;

  &::before {
    content: '';
    display: inline-block;
    height: 1px;
    width: 90%;
    background: #26262e;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
`;

const NotificationWrapper = styled.li`
  padding: 25px;
  cursor: pointer;
  transition: filter ease-in 0.2s;
  background-color: #1b1b21;
  position: relative;
  /* filter: brightness(75%); */

  &::after {
    content: '';
    display: inline-block;
    height: 1px;
    width: 90%;
    background: #26262e;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }

  &:hover {
    filter: brightness(110%);
  }

  &:first-child {
    /* padding-top: 30px; */
    /* padding-top: 20px; */
  }

  &:last-child {
    /* padding-bottom: 30px; */
    &::after {
      display: none;
    }
  }
`;

const NotificationsList = styled.ul`
  margin: 0;
  position: relative;
  background-color: #1b1b21;
  /* padding: 15px 0; */
  max-width: 365px;
  max-height: 410px;
  overflow-y: scroll;
  /* box-shadow: 0px 20px 20px 0px rgba(11, 11, 13, 0.4); */
  scrollbar-width: none; /* Firefox 64 */
  -ms-overflow-style: none; /* Internet Explorer 11 */
  &::-webkit-scrollbar {
    /** WebKit */
    display: none;
  }

  @media screen and (max-width: 485px) {
    max-height: 250px;
    max-width: 100%;
    width: 100%;
  }
`;

const NotificationsContainer = styled.div`
  z-index: 9;
  background-color: #1b1b21;

  @media screen and (min-width: 485px) {
    border-radius: 8px;
    padding-top: 8px;
    &:before {
      z-index: 9;
      position: absolute;
      content: '';
      width: 24px;
      height: 4px;
      background: #e80c51;
      bottom: 45px;
      right: 48px;
      top: 0;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
`;
