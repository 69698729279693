import { createSelector } from 'reselect';
import { always, identity, propOr, find, propEq, pick, filter } from 'ramda';
// import { getIn } from 'v2/utils/ramda';
import { getEntities } from '../entities';
import { entityDetailGetter, entityListGetter } from '../../utils/entities';

export const initialState = {};

const prizeIdSelector = (state, { prizeId }) => prizeId;

const prizeIdsSelector = (state, { prizeIds }) => prizeIds;

function prizesSelector(state) {
  return state.prizes || initialState;
}

export const getPrizes = createSelector(
  prizesSelector,
  identity,
);

export const getPrizesList = createSelector(
  [always('prizes'), prizeIdsSelector, getEntities],
  entityListGetter,
);

// export const getResourceContestDetail = createSelector(
//   [always('contests'), getResourceContestId, getEntities],
//   entityDetailGetter,
// );
