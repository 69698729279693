import target from './target.svg';
import avatarPlayer from './avatarPlayer.svg';
import arrow from './arrow.svg';
import check from './check.svg';
import defaultAvatar from './defaultAvatar.png';
import shape from './shape.svg';
import star from './star.svg';
import bitmap from './bitmap.png';
import targetSuccses from './targetSuccses.svg';
import search from './search.svg';
import arrowPrevious from './arrowPrevious.svg';
import arrowNext from './arrowNext.svg';
import icoGroup from './icoGroup.svg';
import likeLeaderBoard from './likeLeaderBoard.svg';
import pendingLeaderBoard from './pendingLeaderBoard.svg';
import dislikeLeaderBoard from './dislikeLeaderBoard.svg';
import dislike from './dislike.svg';
import pending from './pending.svg';
import like from './like.svg';
import icoGroupGreen from './icoGroupGreen.svg';
import correctPick from './correctPick.svg';
import incorrectPick from './incorrectPick.svg';
import pendingPick from './pending_arrows.svg';

export {
  target,
  avatarPlayer,
  arrow,
  check,
  defaultAvatar,
  shape,
  star,
  bitmap,
  targetSuccses,
  search,
  arrowPrevious,
  arrowNext,
  icoGroup,
  likeLeaderBoard,
  pendingLeaderBoard,
  dislikeLeaderBoard,
  dislike,
  pending,
  like,
  icoGroupGreen,
  correctPick,
  incorrectPick,
  pendingPick,
};
