// @flow
import pickem from 'v2/components/shared/Image/pickem.svg';
import survive from 'v2/components/shared/Image/survive.svg';
import { isBasketball, isFootball } from 'v2/services/defineSport';

const defaultProps = {
  isSurvive: false,
};

type getLogoProps = {
  league: string,
  isSurvive?: boolean,
};

function ContestLogo({ league, isSurvive }: getLogoProps) {
  const getFootballLogo = (): string => (isSurvive ? survive : pickem);

  const getBasketballLogo = (): string => (isSurvive ? survive : pickem);

  const getLogo = (): string | null => {
    if (isBasketball(league)) {
      return getBasketballLogo();
    }

    if (isFootball(league)) {
      return getFootballLogo();
    }

    return null;
  };

  return getLogo();
}

ContestLogo.defaultProps = defaultProps;

export default ContestLogo;
