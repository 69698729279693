// @flow

import React from 'react';
import styled from 'styled-components';
import { prop, last, path, find, compose, equals, head, when } from 'ramda';
import Downshift from 'downshift';

import { isNotDefined, isDefined } from 'v2/helpers/ramda';

type AdapterProps = {
  items: Array<[String, { label: String, img?: String }]>,
  legend?: String,
  defaultKey?: String,
  onStateChange?: () => void,
  selectedImgOnly?: boolean,
  showLegend?: boolean,
};

const defaultProps = {
  legend: null,
  defaultKey: null,
  onStateChange: () => {},
  selectedImgOnly: false,
  showLegend: true,
};

function Adapter({
  items,
  legend,
  defaultKey,
  activeKey,
  onChange,
  onStateChange,
  onlyImgForSelect,
  hideImg,
  showLegend,
  isCenter,
  dropDownStyles,
  selectedImgOnly,
}: AdapterProps) {
  const getValue = ([value]) => value;
  const getLabel = ([value, { label }]) => label;
  const getImg = ([value, { img }]) => img;
  const getDefaultItem = () => find(compose(equals(defaultKey), head))(items);
  const getActive = () => compose(when(isNotDefined, getDefaultItem), find(compose(equals(activeKey), head)))(items);

  // const getActive = (v) => compose(when(isNotDefined, getDefaultItem), find(compose(equals(v), head)))(items);

  return (
    <Container onlyImgForSelect={selectedImgOnly && compose(when(isDefined, getImg), getActive)()}>
      {legend && showLegend && <Legend>{`${legend}:`} </Legend>}
      <Downshift
        onChange={({ value }) => onChange(value)}
        itemToString={(item) => (item ? item.value : '')}
        onStateChange={onStateChange}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getToggleButtonProps,
          getMenuProps,
          isOpen,
          inputValue,
          highlightedIndex,
          selectedItem,
          getRootProps,
        }) => (
          <div style={{ display: 'inline-flex', flexDirection: 'column', position: 'relative' }}>
            <DropDownLabel {...getToggleButtonProps()} isOpen={isOpen}>
              {selectedImgOnly && compose(when(isDefined, getImg), getActive)() ? (
                <DropDownImg src={compose(when(isDefined, getImg), getActive)()} alt="img" />
              ) : (
                compose(when(isDefined, getLabel), getActive)() || <Placeholder>select...</Placeholder>
              )}
            </DropDownLabel>
            {isOpen && (
              <DropDownList {...getMenuProps()} style={dropDownStyles}>
                {items.map((item, index) => (
                  <DropDownItem
                    {...getItemProps({
                      key: getValue(item),
                      index,
                      item: { value: getValue(item) },
                    })}
                  >
                    {onlyImgForSelect ? (
                      <OnlyImgForSelect src={getImg(item)} />
                    ) : (
                      getLabel(item) && <DropDownTitle isCenter={isCenter}>{getLabel(item)}</DropDownTitle>
                    )}

                    {getImg(item) && !hideImg && (
                      <DropDownImgContainer>
                        <DropDownImg src={getImg(item)} alt="drop down img" />
                      </DropDownImgContainer>
                    )}
                  </DropDownItem>
                ))}
              </DropDownList>
            )}
          </div>
        )}
      </Downshift>
    </Container>
  );
}

Adapter.defaultProps = defaultProps;
export default Adapter;

// styles

const OnlyImgForSelect = styled.img`
  width: 115px;
  max-height: 55px;
  padding: 6px;
`;

const DropDownImg = styled.img`
  width: 23px;
`;

const DropDownImgContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  padding-left: 0.5rem;
`;

const Legend = styled.span`
  font-size: 11px;
`;

const Placeholder = styled.span``;

const Container = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  margin: 0.5em 0;
  align-items: baseline;
  align-items: ${({ onlyImgForSelect }) => {
    /* console.log(onlyImgForSelect); */
    return onlyImgForSelect && 'center';
  }};

  ${Legend} {
    margin-bottom: ${({ onlyImgForSelect }) => onlyImgForSelect && '-3px'};
  }
`;

const DropDownLabel = styled.span`
  font-size: ${({ theme: { dropDown } }) => path(['sizes', 'normal', 'font'], dropDown)};
  color: ${({ theme: { dropDown } }) => path(['colors', 'label'], dropDown)};
  padding: 0em 1em;
  padding-left: 5px;
  padding-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  -webkit-appearance: none !important;

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    filter: brightness(1.8);
    border-top: 7px solid ${({ theme }) => theme.colors.gray};
    transition: all 0.25s;
    margin-left: 6px;
    margin-top: -3px;


    transform: ${({ isOpen }) => isOpen && 'rotate(180deg)'};

    /* position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) ${({ isActive }) => isActive && 'rotate(-180deg)'}; */
  }
`;

const DropDownList = styled.ul`
  margin: 0;
  background-color: ${({ theme }) => theme.colors.lighGray};
  display: inline-flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  z-index: 99;
  box-shadow: 0px 3px 20px -6px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
`;

const DropDownItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0.75rem 0.25rem 0.25rem;

  span {
    font-size: ${({ theme: { dropDown } }) => path(['sizes', 'normal', 'font'], dropDown)};
    color: ${({ theme: { dropDown } }) => path(['colors', 'item'], dropDown)};
    padding: 1em;
    padding-left: 7px;
    white-space: nowrap;
  }
`;

const DropDownTitle = styled.span`
  margin-right: ${({ isCenter }) => 'isCenter' && '-20px'};
`;
