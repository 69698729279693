// @flow

import React, { useContext } from 'react';
import styled from 'styled-components';
import { propOr, compose, or, not } from 'ramda';
import moment from 'moment';

import { isDefined } from 'v2/helpers/ramda';
import { parseVideoUrl } from 'v2/decorators/refs';
import { toHHMMSS } from 'v2/helpers/common';

import play from '../img/play.svg';
import BlogVideoContext from '../helpers/context';

import PreviewCardContainer from './Container';

type PreviewCardProps = {};
const convertDurationToHHMMSS = toHHMMSS({ isAmply: true, hideSeconds: true });
const dateFormat = (d) => moment(d).format('D MMM, YYYY');

function PreviewCard({
  duration,
  author,
  date,
  videoUrl,
  playVideo,
  isCentered,
  hideDescription,
  title,
  photo,
  previewPhotoUrl,
  ...rest
} = {}) {
  const photoToShow = or(photo, previewPhotoUrl);
  const isVideoMaterial = isDefined(videoUrl);
  const BlogVideoContextValues = useContext(BlogVideoContext);
  const openVideoModal = propOr(null, 'openVideoModal', BlogVideoContextValues);

  return (
    <Container isCentered={isCentered} hideDescription={hideDescription}>
      {isVideoMaterial && photoToShow && (
        <ImgContainer
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            openVideoModal(videoUrl);
          }}
        >
          <Img src={photoToShow} alt="video img" />
          <PlayVideIcon>
            <img src={play} alt="play" />
          </PlayVideIcon>
          <VideoDuration>5:03</VideoDuration>
        </ImgContainer>
      )}

      {not(isVideoMaterial) && <ImgContainer>{photoToShow && <Img src={photoToShow} alt="img" />}</ImgContainer>}

      <Description>
        <DescriptionBlock>{author && <Subtitle>{author}</Subtitle>}</DescriptionBlock>

        <DescriptionBlock>
          <Title>{title}</Title>
        </DescriptionBlock>

        <DescriptionBlock>
          <AdditionalInfo>
            <Subtitle>{dateFormat(date)}</Subtitle>
            {duration && <Subtitle>{`${duration} read`}</Subtitle>}
          </AdditionalInfo>
        </DescriptionBlock>
      </Description>
    </Container>
  );
}

PreviewCard.Container = PreviewCardContainer;
export default PreviewCard;

// styles

const ImgContainer = styled.div`
  width: 100%;
  position: relative;
`;

const VideoDuration = styled.span`
  padding: 5px;
  background: #000;
  color: ${({ theme }) => theme.colors.white};
  opacity: 0.5;
  position: absolute;
  bottom: 20px;
  left: 20px;
`;

const PlayVideIcon = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: red;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  img {
    width: 45%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -50%);
  }
`;

const AdditionalInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Subtitle = styled.span`
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.lightenGray};
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:last-child) {
    &::after {
      content: '';
      display: block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: ${({ theme }) => theme.colors.lightenGray};
      margin: 5px;
    }
  }
`;

const Title = styled.h3`
  margin: 0;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0px;
  text-transform: uppercase;
  line-height: 1.3;
`;

const Img = styled.img`
  width: 100%;
`;

const DescriptionBlock = styled.div`
  &:not(:last-child) {
    margin-bottom: 7px;
  }
`;

const Description = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
  transition: opacity 0.5s;
  z-index: 1;
`;

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  transition: filter 1s;

  ${Description} {
    transition: opacity 0.5s;
    position: ${({ isCentered }) => isCentered && 'absolute'};
    bottom: ${({ isCentered }) => isCentered && '10px'};
    opacity: ${({ hideDescription }) => {
      if (hideDescription) return '0';
    }};

    &:after {
      content: '';
      height: 150%;
      bottom: -10px;
      position: absolute;
      transition: opacity 0.4s ease;
      background: linear-gradient(
        -180deg,
        rgba(19, 19, 23, 0) 0%,
        rgba(19, 19, 23, 0.69) 34%,
        rgba(19, 19, 23, 0.97) 100%
      );
      opacity: 0.8;
      width: 100%;
      z-index: -1;
      display: block;
      left: 0;

      display: ${({ isCentered }) => (isCentered ? 'block' : 'none')};
    }
  }

  filter: ${({ hideDescription }) => {
    if (hideDescription) return 'brightness(0.65)';
  }};

  &:hover {
    ${Description} {
      opacity: ${({ hideDescription }) => {
        if (hideDescription) return '0.4';
      }};
    }
  }
`;
