import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { SIGN_UP_PATH, AUTH_VIA_FACEBOOK } from 'v2/constants/router';

function SocialProposal({ location: { pathname } }) {
  return (
    <SocialProposalContainer>
      <SocialProposalTutle>
        {`Sign ${pathname == SIGN_UP_PATH ? 'up' : 'in'} using social media account`}
      </SocialProposalTutle>
      <FaceBookButton href={AUTH_VIA_FACEBOOK}>CONTINUE WITH FACEBOOK</FaceBookButton>
      <LabelOr>Or</LabelOr>
    </SocialProposalContainer>
  );
}

const SocialProposalTutle = styled.span`
  text-transform: capitalize !important;
  font-size: 17px;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 25px;

  @media (max-width: 475px) {
    line-height: 1.4em;
  }
`;

const FaceBookButton = styled.a`
  position: relative;
  background: #3b5998;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0.5px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  transition: filter 0.2s linear;
  width: 100%;

  font-weight: bold;
  padding: 1em;
  border-radius: 4px;

  &:hover {
    filter: brightness(105%);
  }
`;

const LabelOr = styled.span`
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
`;

const SocialProposalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default withRouter(SocialProposal);
