// @flow

import React from 'react';
import styled from 'styled-components';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { isAuthorized, isUserStatusAcceptedTermsOfService } from 'v2/selectors/session';

import {
  ROOT_PATH,
  AUTH_PATH,
  SIGN_UP_PATH,
  SIGN_IN_PATH,
  SIGN_UP_REFERAL_PATH,
  RESTORE_PASSWORD_PATH,
  RESTORE_PASSWORD_FROM_EMAIL_PATH,
  SETUP_ACCOUNT_PATH,
} from 'v2/constants/router';
import { SocialProposal, SignUp, SignIn, Tabs, RestorePassword, NewPasswordForm, PersonalDetailsForm } from './shared';

type AuthPageProps = {
  isAuthorized: boolean,
  isUserStatusAcceptedTermsOfService: boolean,
};

function AuthPage({ isAuthorized, isUserStatusAcceptedTermsOfService }: AuthPageProps) {
  return (
    <ModalLayout>
      <Switch>
        <Route
          path={SETUP_ACCOUNT_PATH}
          render={(routerProps) =>
            isUserStatusAcceptedTermsOfService ? (
              <Redirect
                to={{
                  pathname: ROOT_PATH,
                  state: { from: routerProps.location },
                }}
              />
            ) : (
              <PersonalDetailsForm {...routerProps} />
            )
          }
          exact
        />

        <Route
          path={RESTORE_PASSWORD_FROM_EMAIL_PATH}
          render={(routerProps) => <NewPasswordForm {...routerProps} />}
          exact
        />

        <Route path={RESTORE_PASSWORD_PATH} render={(routerProps) => <RestorePassword {...routerProps} />} exact />

        <Route
          path={AUTH_PATH}
          render={({ location }) => (
            <Redirect
              to={{
                pathname: SIGN_IN_PATH,
                state: { from: location },
              }}
            />
          )}
          exact
        />

        <Route
          exact
          path={[SIGN_UP_PATH, SIGN_UP_REFERAL_PATH, SIGN_IN_PATH]}
          render={({ location }) =>
            isAuthorized ? (
              <Redirect
                to={{
                  pathname: SIGN_IN_PATH,
                  state: { from: location },
                }}
              />
            ) : (
              <>
                <Tabs />
                <ModalContent>
                  <SocialProposal />

                  <Switch>
                    <Route path={[SIGN_UP_PATH, SIGN_UP_REFERAL_PATH]} render={(routerProps) => <SignUp {...routerProps} />} exact />

                    <Route path={SIGN_IN_PATH} render={(routerProps) => <SignIn {...routerProps} />} exact />
                  </Switch>
                </ModalContent>
              </>
            )
          }
        />
      </Switch>
    </ModalLayout>
  );
}

const ModalLayout = styled.div`
  background: #1b1b21;
  max-width: 760px;
  margin: 0 auto;

  @media (max-width: 768px) {
    max-width: 620px;
  }

  @media (max-width: 660px) {
    max-width: 520px;
  }

  @media (max-width: 475px) {
    max-width: 320px;
    min-width: 290px;
    width: 100%;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 150px;

  @media (max-width: 768px) {
    padding: 50px 50px;
  }

  @media (max-width: 475px) {
    padding: 25px;
  }
`;

// const AuthPageContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

const mapStateToProps = (state) => ({
  isAuthorized: isAuthorized(state),
  isUserStatusAcceptedTermsOfService: isUserStatusAcceptedTermsOfService(state),
});

const enhance = compose(withRouter, connect(mapStateToProps));
export default enhance(AuthPage);
