// @flow

import React from 'react';
import Section from 'v2/components/PromoPages/2/shared/Section';
import PromoPageWrapper from 'v2/components/PromoPages/2/shared/PromoPageWrapper';
import {
  PhotoBlock1,
  PhotoBlock2,
  PhotoBlock3,
  PhotoBlock4,
  PhotoBlock5,
  PhotoBlock6,
  PhotoBlock7,
  PhotoBlock8,
  PhotoBlock9,
  VideoPreview,
  NewEraBlock,
} from 'v2/components/PromoPages/2/blocks';

function DesktopVersion() {
  return (
    <PromoPageWrapper>
      <VideoPreview />
      <Section>
        <PhotoBlock1 />
        <PhotoBlock2 />
        <PhotoBlock3 />
      </Section>

      <Section style={{ maxWidth: '725px' }}>
        <Section isRow noMarginRight>
          <Section>
            <PhotoBlock4 />
            <PhotoBlock5 />
            <PhotoBlock6 />
            <PhotoBlock7 />
          </Section>

          <Section>
            <PhotoBlock8 />
            <PhotoBlock9 />
          </Section>
        </Section>
        <NewEraBlock />
      </Section>
    </PromoPageWrapper>
  );
}

export default DesktopVersion;
