import React, { useState, useEffect } from 'react';
import { animateScroll } from 'react-scroll';
import styled from 'styled-components';

import DiagramStats from './shared/DiagramStats';
import Participations from './shared/Participations';

const propTypes = {};

const defaultProps = {
  usersLeaderborad: {
    currentUser: [],
    players: [],
  },
};

function Leaderboard(props) {
  const { contest } = props;

  useEffect(() => {
    animateScroll.scrollToTop();
  }, []);

  // const [isQuery, setIsQuery] = useState('');

  // const searchPlayers = players.filter((player) => R.toLower(player.name).includes(R.toLower(isQuery)));

  return (
    <LeaderboardStyled>
      {contest.kind === 'survive' ? <DiagramStats {...props} /> : <Participations {...props} />}
    </LeaderboardStyled>
  );
}

const LeaderboardStyled = styled.div`
  /* padding-top: 20px; */
`;

// const SearchUserName = styled.div`
//   padding: 20px;
//   background: #26262e;
// `;

// const UserList = styled.ul`
//   margin: 0;
// `;

// const User = styled.li`
//   position: relative;
//   display: flex;
//   justify-content: space-between;
//   text-transform: uppercase;
//   font-weight: 700;
//   padding: 15px 20px;
//   background: #1b1b21;
//   padding-right: 35px;
//   &:nth-child(2n) {
//     background: #26262e;
//   }
// `;

// const UserName = styled.div`
//   font-size: 14px;
//   line-height: 18px;
//   margin-right: 5px;
// `;

// const UserStatCount = styled.div``;

// const Count = styled.span`
//   position: relative;
//   margin-left: 44px;
//   font-size: 14px;
//   line-height: 18px;
//   ${'' /* &:before {
//     position: absolute;
//     content: url(${like});
//   } */}
// `;

// const CurrentWeek = styled.div`
//   background: #26262e;
//   padding: 20px;
//   display: flex;
//   flex-wrap: wrap;
// `;

// const Statistic = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   margin-top: 20px;
// `;

// const Item = styled.div`
//   width: 100%;
//   text-align: center;
//   display: flex;
//   flex-direction: column;
// `;

// const Indicator = styled.div`
//   width: 100%;
//   height: 8px;
//   margin-bottom: 10px;
//   border-radius: 8px;
//   &.advanced {
//     background: #0bd855;
//   }
//   &.pending {
//     width: calc(100% - 10px);
//     background: #ffb236;
//     margin: 0 auto 10px;
//   }
//   &.eliminated {
//     background: #e80c51;
//   }
// `;

// const ItemName = styled.h4`
//   text-transform: uppercase;
//   font-size: 12px;
//   margin: 0;
//   margin-top: 5px;
// `;

// const ItemCount = styled.span`
//   font-size: 18px;
//   line-height: 24px;
//   color: #fff;
// `;

// const NumberWeek = styled.h4`
//   width: 100%;
// `;

// const PastWeeks = styled.div`
//   padding: 0 20px;
// `;

// const PastWeek = styled.div`
//   margin-top: 20px;
// `;

// const ProgressBar = styled.div`
//   position: relative;
//   width: 80px;
//   height: 80px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-radius: 40px;
//   background: red;
//   &:before {
//     position: absolute;
//     content: '';
//     width: 100%;
//     height: 100%;
//     background: green;
//   }
//   &:after {
//     position: absolute;
//     content: '';
//     width: 100%;
//     height: 100%;
//     background: blue;
//   }
// `;

// const StatisticWeekWrapper = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   border-radius: 8px;
//   margin-top: 25px;
//   padding: 10px 45px;
//   background: #26262e;
//   @media (max-width: 768px) {
//     padding: 10px 15px;
//   }
// `;

// const StatisticWeek = styled.div`
//   text-align: center;
// `;

// const InputForm = styled.div`
//   position: relative;
// `;

// const SearchIcon = styled.img`
//   position: absolute;
//   top: 12px;
//   left: 10px;
// `;

// const SearchPlayer = styled.input`
//   display: block;
//   width: 100%;
//   padding: 20px;
//   background: #26262e;
//   color: #fff;
//   outline: none;
//   border: none;
//   box-sizing: border-box;
//   &::placeholder {
//     fonst-size: 12px;
//     line-height: 14px;
//     font-weight: 700;
//     color: #656579;
//   }
// `;

// const HeaderList = styled.div`
//   display: flex;
//   justify-content: space-between;
//   background: #26262e;
// `;

// const Icons = styled.div`
//   display: flex;
//   img {
//     margin-right: 37px;
//   }
// `;

// const UserRank = styled.span`
//   color: #fff;
//   font-size: 14px;
//   line-height: 18px;
//   margin-right: 10px;
//   &.top_place {
//     color: rgb(11, 216, 85);
//     &:before {
//       content: '';
//       position: absolute;
//       width: 3px;
//       height: 98%;
//       background: rgb(11, 216, 85);
//       top: 0;
//       left: 0;
//       border-top-right-radius: 8px;
//       border-bottom-right-radius: 8px;
//     }
//   }
// `;

Leaderboard.propTypes = propTypes;
Leaderboard.defaultProps = defaultProps;

export default Leaderboard;
