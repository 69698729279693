import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import { SIGN_UP_PATH, SIGN_IN_PATH } from 'v2/constants/router';

const defaultProps = {};

const propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

const authTabs = [
  {
    id: 0,
    title: 'Sign Up',
    pathname: SIGN_UP_PATH,
  },
  {
    id: 1,
    title: 'Sign In',
    pathname: SIGN_IN_PATH,
  },
];

function AuthTabs({ location, location: { state } }) {
  const contest = state && state.contest;

  return (
    <Tabs>
      {authTabs.map(({ id, pathname, title }) => (
        <Tab
          key={id}
          to={{
            pathname,
            state: { contest },
          }}
          active={pathname === location.pathname}
        >
          {title}
        </Tab>
      ))}
    </Tabs>
  );
}

const Tab = styled(NavLink)`
  flex: 0 0 50%;
  position: relative;
  font-size: 21px;
  text-transform: uppercase;
  padding: 1em 0;
  font-weight: bold;
  border-bottom: 1px solid #26262e;
  cursor: pointer;
  background: #1b1b21;
  text-decoration: none !important;
  transition: filter 0.2s linear;
  display: flex;
  justify-content: center;
  pointer-events: ${({ active }) => (active ? 'none' : 'auto')};
  filter: ${({ active }) => (active ? 'brightness(110%)' : 'none')};

  &:before {
    content: '';
    display: ${({ active }) => (active ? 'block' : 'none')};
    position: absolute;
    width: 100%;
    height: 4px;
    bottom: 0;
    left: 0;
    background-color: red;
    background: #5d5ce2;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:hover {
    filter: brightness(115%);
  }

  @media (max-width: 768px) {
    font-size: 19px;
  }

  @media (max-width: 558px) {
    font-size: 17px;
  }

  @media (max-width: 475px) {
    font-size: 15px;
    padding: 20px 20px;
  }
`;

const Tabs = styled.div`
  display: flex;
  flex-flow: wrap;
  /* margin-bottom: 50px; */
`;
AuthTabs.defaultProps = defaultProps;
AuthTabs.propTypes = propTypes;

export default withRouter(AuthTabs);
