// @flow

import React from 'react';
import styled from 'styled-components';

import List from './FAQsList';
import arrow from './icons/ico_arrow_right.svg';

type FAQItemProps = {};

function FAQItem({ question, answer, number, isOpen, onClick }: FAQItemProps) {
  return (
    <Container onClick={onClick} isOpen={isOpen}>
      <QuestionBlock>
        <Question dangerouslySetInnerHTML={{ __html: question }} />
        <OpenButton />
      </QuestionBlock>
      <AnswerBlock show={isOpen}>
        <Answer dangerouslySetInnerHTML={{ __html: answer }} />
      </AnswerBlock>
    </Container>
  );
}

FAQItem.List = List;
export default FAQItem;

// styles

const Answer = styled.div`
  font-size: 17px;
  line-height: 1.2;
  padding: 10px 1px 0;
  color: #656579;
  max-width: 750px;

  p {
    font-size: 17px;
  }
`;

const QuestionBlock = styled.div`
  font-weight: bold;
  letter-spacing: 0px;
  text-transform: uppercase;
  position: relative;
  display: flex;
  align-items: flex-start;
  /* display: flex;
  justify-content: space-between; */
`;

// const Number = styled.span`
//   color: #fff;
//   font-size: 20px;
//   line-height: 1.2;
// `;

const Question = styled.span`
  color: #fff;
  font-size: 20px;
  margin-left: 5px;
  margin-right: 40px;
  line-height: 1.2;

  @media (max-width: 425px) {
    font-size: 17px;
  }
`;

const OpenButton = styled.div`
  height: 36px;
  width: 36px;
  background: #26262e;
  border-radius: 50%;
  position: absolute;
  top: -12px;
  right: 0;
  opacity: 0.35;

  background-image: url(${arrow});
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: 14px;

  transition: opacity 0.25s, transform 0.25s;
`;

const AnswerBlock = styled.div`
  max-height: ${({ show }) => (show ? '1000px' : '0')};
  overflow: hidden;
  transition: max-height 0.5s ease;

  ${Answer} {
    opacity: ${({ show }) => (show ? '1' : '0')};
    transition: opacity 0.25s ease-out;
  }
`;

const Container = styled.div`
  width: 100%;
  background: #1b1b21;
  max-width: 1060px;
  padding: 35px 40px 30px;
  margin-bottom: 10px;
  border-radius: 8px;
  cursor: pointer;

  ${OpenButton} {
    opacity: ${({ isOpen }) => isOpen && '1'};
    transform: ${({ isOpen }) => isOpen && 'rotate(180deg)'};
  }

  &:hover {
    ${OpenButton} {
      opacity: 1;
    }
  }

  @media (max-width: 425px) {
    padding: 20px;

    ${OpenButton} {
      top: 0;
    }
  }
`;
