import React from 'react';
import Downshift from 'downshift';
import styled from 'styled-components';
import { withRouter } from 'react-router';

import { filterBySport } from 'v2/constants/UI/filterBySport';
import SportItems from './SportItems';
import SportItem from './SportItem';

// type FilterProps = {};

function Filter({ match: { params } }) {
  const currentSport = params.league
    ? filterBySport.find((sport) => sport.league === params.league)
    : filterBySport.find((sport) => sport.league.toLowerCase() === 'nfl');

  return (
    <FilterContainer>
      <Downshift itemToString={(option) => (option ? option.name : '')}>
        {({ isOpen, getToggleButtonProps, getItemProps, highlightedIndex }) => (
          <div>
            <ItemForOpen {...getToggleButtonProps()} isOpen={isOpen} {...currentSport}>
              {currentSport && currentSport.title}
            </ItemForOpen>
            <div>
              {isOpen ? (
                <SportItemsWrapper {...getToggleButtonProps()}>
                  <SportItems sportsCollections={filterBySport} currentSport={currentSport} />
                </SportItemsWrapper>
              ) : null}
            </div>
          </div>
        )}
      </Downshift>
    </FilterContainer>
  );
}

const ItemForOpen = styled(SportItem)`
  border-radius: 4px;
  height: 100%;

  border-bottom-left-radius: ${({ isOpen }) => (isOpen ? '0' : '4px')};
  border-bottom-right-radius: ${({ isOpen }) => (isOpen ? '0' : '4px')};

  @media (min-width: 680px) {
    padding-top: 0;
    padding-bottom: 0;
  }

  &:after {
    transition: transform 0.4s ease-in-out;
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: ${({ isOpen }) => (isOpen ? 'translate(0, -50%) rotate(180deg)' : 'translate(0, -50%)')};
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-top: 5px solid #656579;
  }
`;

const SportItemsWrapper = styled.div`
  position: absolute;
  width: 100%;
  z-index: 1;
  -webkit-appearance: none !important;
`;

const FilterContainer = styled.div`
  position: relative;
  width: 35%;

  div {
    height: 100%;
  }

  @media (max-width: 680px) {
    width: 100%;
    margin-bottom: 15px;
  }
`;

export default withRouter(Filter);
