import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// import useClassNames from 'hooks/useClassNames';

const propTypes = {
  className: PropTypes.string,
  component: PropTypes.elementType.isRequired,
  error: PropTypes.node,
  help: PropTypes.node,
  inputProps: PropTypes.shape({}),
  invalid: PropTypes.bool,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

const defaultProps = {
  className: undefined,
  error: undefined,
  help: undefined,
  inputProps: {},
  invalid: false,
  label: undefined,
  required: false,
};

function FormGroup({ error, help, component: Component, inputProps, invalid, label, name, required, ...props }) {
  // const groupClass = useClassNames(['form__group', className, invalid && 'form__group-errored']);

  return (
    <StyledFormGroup>
      {label && (
        <StyledLabel htmlFor={name}>
          {required && (
            <Fragment>
              <abbr title="All red asterisks are required in order to provide you with an accurate rate quote.">*</abbr>{' '}
            </Fragment>
          )}
          {label}
        </StyledLabel>
      )}
      <Component {...inputProps} isError={invalid} />
      {invalid && error && <FormError>{error}</FormError>}
      {/* {help && <p className="form__help">{help}</p>} */}
    </StyledFormGroup>
  );
}

const FormError = styled.span`
  color: #e80c51;
  margin: 10px 0 0 12px;
`;

const StyledLabel = styled.label`
  display: inline-block;
  color: #656579;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 18px;
  text-transform: uppercase;
  margin: 0 0 8px 12px;
  cursor: pointer;
`;

const StyledFormGroup = styled.div`
  margin-bottom: 25px;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
`;

FormGroup.propTypes = propTypes;
FormGroup.defaultProps = defaultProps;

export default FormGroup;
