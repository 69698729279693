// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose, type HOC } from 'recompose';

import { BlogApiAdapter } from 'v2/apiAdapters';

type PhotoType = { id: number, src: string };
type WrappedTypes = {
  fetchBlogCategories: Function,
};

const BlogController = (WrappedComponent) => {
  return (props: WrappedTypes) => {
    return <WrappedComponent {...props} />;
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchBlogCategories: BlogApiAdapter.getBlogCategories,
  fetchBlogCategory: BlogApiAdapter.getBlogCategory,
  fetchBlogArticle: BlogApiAdapter.getArticle,
});

// const mapStateToProps = (state) => ({
//   photos: [],
// });

const enhance = compose(connect(null, mapDispatchToProps), BlogController);

export default enhance;
