import React from 'react';
import PhotoBlock from 'v2/components/PromoPages/shared/PhotoBlock';
import img from 'v2/components/shared/Image/promo/4.png';

function block() {
  return (
    <PhotoBlock.Wrapper>
      <PhotoBlock src={img} />
    </PhotoBlock.Wrapper>
  );
}

export default block;
