import React from 'react';
import { SessionApiAdapter } from 'v2/apiAdapters';
// import { createBrowserHistory } from 'history';
import { push } from 'react-router-redux';
import { throwSubmissionError } from 'v2/utils/form';
import {
  ACCOUNT_SETTINGS_PATH,
  getPath,
  CONTEST_CATEGORY_PATH,
  ROOT_PATH,
  SETUP_ACCOUNT_PATH,
  SIGN_IN_PATH,
} from 'v2/constants/router';
import toast from 'v2/components/PushToasts/toast';
import { history } from 'v2/stores/configureStore';

import * as types from '../../constants/actionTypes';
import * as requestTypes from '../../constants/requestTypes';
import setRequestInProcess from '../request';

export const signIn = ({ contest, ...body }) => async (dispatch) => {
  const requestType = requestTypes.USER_SIGN_IN_POST;
  dispatch(setRequestInProcess(true, requestType));

  try {
    await SessionApiAdapter.postSignInCredentials(body);
    if (contest) {
      return navigateToContestAfterAuth(contest);
    }

    window.location.href = ROOT_PATH;
  } catch (err) {
    return throwSubmissionError(prettierErrors(err, 'user_session'));
  }
};

export const signUp = ({ contest, ...body }) => async (dispatch) => {
  const requestType = requestTypes.USER_SIGN_UP_POST;
  dispatch(setRequestInProcess(true, requestType));

  try {
    await SessionApiAdapter.postSignUpCredentials(body);

    // FIX ME
    if (contest) {
      dispatch(
        push({
          pathname: SETUP_ACCOUNT_PATH,
          state: { contest },
        }),
      );
    }

    dispatch(
      push({
        pathname: SETUP_ACCOUNT_PATH,
      }),
    );
  } catch (err) {
    return throwSubmissionError(prettierErrors(err, 'user_session'));
  }
};

export const setupAccount = ({ contest, ...body }) => async (dispatch) => {
  const requestType = requestTypes.USER_UPDATE;
  dispatch(setRequestInProcess(true, requestType));

  try {
    await SessionApiAdapter.setupAccount(body);

    // facebook pixels track event
    if (process.env.NODE_ENV === 'production') {
      window.fbq && window.fbq('track', 'CompleteRegistration');
    }

    if (contest) {
      return navigateToContestAfterAuth(contest);
    }

    window.location.href = `${ROOT_PATH}#AvailableGames`;
  } catch (err) {
    return throwSubmissionError(prettierErrors(err, 'user'));
  }
};

export const sendEmailForRestorePassword = (body, setMessageStatus) => async (dispatch) => {
  const requestType = requestTypes.SEND_EMAIL_RESTORE_PASSWORD;
  dispatch(setRequestInProcess(true, requestType));

  try {
    const { email } = await SessionApiAdapter.sendEmailForRestorePassword(body);

    setMessageStatus({ instructionSent: true, email });

    // window.location.href = ROOT_PATH;
  } catch (err) {
    console.log(err);
    return throwSubmissionError(prettierErrors(err, 'user'));
  }
};

export const changePasswordViaEmail = (body, id) => async (dispatch) => {
  const requestType = requestTypes.SEND_RESTORED_PASSWORD;
  dispatch(setRequestInProcess(true, requestType));

  try {
    await SessionApiAdapter.changePasswordViaEmail(body, id);

    window.location.href = SIGN_IN_PATH;
  } catch (err) {
    console.log(err);
    return throwSubmissionError(prettierErrors(err, 'user'));
  }
};

export const fetchNotifications = () => async (dispatch) => {
  const requestType = requestTypes.NOTIFICATIONS_FETCH_FETCH;
  dispatch(setRequestInProcess(true, requestType));

  try {
    const { notifications } = await SessionApiAdapter.fetchNotifications();

    if (notifications.length === 0) {
      setTimeout(() => dispatch(fetchNotifications()), 60000);
    } else {
      dispatch(fetchNotificationsSuccess(notifications));
    }
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setRequestInProcess(false, requestType));
  }
};

export const fetchMyAccount = () => async (dispatch) => {
  const requestType = requestTypes.MY_ACCOUNT_FETCH;
  dispatch(setRequestInProcess(true, requestType));

  try {
    const { user } = await SessionApiAdapter.fetchMyAccount();
    dispatch(fetchMyAccountSuccess(user));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setRequestInProcess(false, requestType));
  }
};

export const updateAccountData = (data) => async (dispatch) => {
  const requestType = requestTypes.USER_UPDATE;
  dispatch(setRequestInProcess(true, requestType));

  try {
    const { msg } = await SessionApiAdapter.updateAccountData(data);

    dispatch(fetchMyAccount());
    window.scrollTo(0, 0);
    toast({ title: <span>Success</span>, body: msg, kind: 'success' });
  } catch ({ errors }) {
    console.log(errors);
    toast({ title: null, body: errors, kind: 'error' });
  } finally {
    dispatch(setRequestInProcess(false, requestType));
  }
};

export const updateAccountEmail = (data) => async (dispatch) => {
  const requestType = requestTypes.USER_UPDATE;
  dispatch(setRequestInProcess(true, requestType));

  try {
    const { msg } = await SessionApiAdapter.updateAccountEmail(data);

    dispatch(fetchMyAccount());
    history.push(ACCOUNT_SETTINGS_PATH);
    // window.scrollTo(0, 0);
    toast({ title: <span>Success</span>, body: msg, kind: 'success' });
  } catch ({ errors }) {
    console.log(errors);
    toast({ title: null, body: errors, kind: 'error' });
  } finally {
    dispatch(setRequestInProcess(false, requestType));
  }
};

export const deleteNotification = (id) => async (dispatch) => {
  const requestType = requestTypes.UPDATE_NOTIFICATION;
  dispatch(setRequestInProcess(true, requestType));

  try {
    await SessionApiAdapter.deleteNotification(id);
    // dispatch(deleteNotificationSuccess(id));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setRequestInProcess(false, requestType));
  }
};

// export const deleteNotification = (id) => async (dispatch) => {
//   const requestType = requestTypes.UPDATE_NOTIFICATION;
//   dispatch(setRequestInProcess(true, requestType));

//   try {
//     // const { notifications } = await SessionApiAdapter.fetchNotifications();

//     dispatch(deleteNotificationSuccess(id));
//   } catch (err) {
//     console.log(err);
//   } finally {
//     dispatch(setRequestInProcess(false, requestType));
//   }
// };

const prettierErrors = (data, name) => {
  console.log('data :', data);

  const { errors } = data;

  if (errors) {
    const { source, message } = errors;

    if (source) {
      return { errors: { message, source: { [name]: { ...source.fields } } } };
    }

    if (message) {
      return { errors: { message } };
    }
  }

  return { errors: {} };
};

const fetchMyAccountSuccess = (payload) => ({
  type: types.MY_ACCOUNT_FETCH_SUCCESS,
  payload,
});

const fetchNotificationsSuccess = (payload) => ({
  type: types.NOTIFICATIONS_FETCH_SUCCESS,
  payload,
});

// const deleteNotificationSuccess = (payload) => ({
//   type: types.UPDATE_NOTIFICATION_ACTIVE_STATUS,
//   payload,
// });

const navigateToContestAfterAuth = (contest) => {
  const { urlContest, id } = contest;
  const makePicksPathname = getPath(CONTEST_CATEGORY_PATH, { id, ...urlContest });

  window.location.href = makePicksPathname;
};
