// @flow
import React, { useEffect, useState } from 'react';
import { identity, tap, compose, map, pick, prop } from 'ramda';
import styled from 'styled-components';

import DotItem from './DotItem';

type WrappedTypes = {
  goToNext?: () => any,
  goToPrev?: () => any,
  activeIndex: number,
  children: Node,
  showDots?: boolean,
};

const widthDots = (WrappedComponent) => {
  return (props: WrappedTypes) => {
    const showDots = prop('showDots', props);

    const dots = compose(
      map(pick(['id', 'isFilled', 'isActive'])),
      map(prop('props')),
      React.Children.toArray,
    )(prop('children', props));

    return (
      <Container>
        <WrappedComponent {...props} />

        {showDots && (
          <DotItem.Container>
            {map(
              ({ id, ...rest }) => (
                <DotItem key={id} {...rest} />
              ),
              dots,
            )}
          </DotItem.Container>
        )}
      </Container>
    );
  };
};

export default widthDots;

// styles
const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  min-width: 125px;
  max-width: 100%;

  @media (min-width: 425px) {
    width: 100%;
  }
`;
