import React, { useState, forwardRef } from 'react';
import { not, prop, or } from 'ramda';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { DATA_ITEMS } from '../../HowToPlay';
import AccordItem from '../../HowToPlay/AccordItem';
import closeIcon from 'v2/components/Contests/Header/icons/close.svg';
import HOCWrapper from './Wrapper';

type FAQModalProps = {
  kind?: 'weekly' | 'survive' | 'all',
};

const defaultProps = {
  kind: 'all',
};

const FAQModal = forwardRef(({ kind, onClose, onClick }: FAQModalProps, ref) => {
  console.log('onClick :>> ', onClick);
  const [contestKind, setContestKind] = useState(null);

  return (
    <Container ref={ref}>
      <Header>
        <Title>HOW TO PLAY</Title>
        {onClose && <CloseIcon onClick={onClose} src={closeIcon} />}
      </Header>

      {prop(kind, DATA_ITEMS).map((item, i) => (
        <AccordItem number={i + 1} {...item}>
          {item.content && item.content()}
        </AccordItem>
      ))}

      {/* {link && (
        <ButtonLink onClick={onClose} to={link}>
          GOT IT!
        </ButtonLink>
      )} */}

      {onClick && <Button onClick={onClick}>GOT IT!</Button>}
    </Container>
  );
});

FAQModal.defaultProps = defaultProps;

FAQModal.HOCWrapper = HOCWrapper;
export default FAQModal;

// styled
const Button = styled.div`
  background: ${({ theme }) => theme.colors.purpleAccent};
  border-radius: 6px;
  padding: 11px;
  width: 100%;
  max-width: 360px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
`;

const CloseIcon = styled.img`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-100%, -75%);
`;

const Container = styled.div`
  position: relative;
`;
