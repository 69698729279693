import React, { Fragment } from 'react';
import { compose, equals, prop } from 'ramda';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { history } from 'v2/stores/configureStore';

import {
  prizeBlockStyles,
  prizesBlockStyles,
  prizeStyles,
  progressInfoStyles,
  progressTextStyles,
  statusStyles,
} from '../styles';
// import peopleIcon from '../../Image/people_icon.svg';
// import upIcon from '../../Image/up_icon.svg';
// import downIcon from '../../Image/down_icon.svg';
// import refreshIcon from '../../Image/refresh_icon.svg';

import IconItems from './IconItems';

const propTypes = {
  kind: PropTypes.string,
};

const defaultProps = {
  kind: '',
};

function ProgressInGame(props) {
  const isSurvive = compose(equals('survive'), prop('kind'))(props);
  const isFootbal = compose(equals('nfl'), prop('league'))(props);

  const getColorByKind = (kind) => {
    if (kind === 'survive') {
      return '#5D5CE2';
    }

    if (kind === 'weekly') {
      return '#0bd855';
    }

    return '#0bd855';
  };

  const getText = () => {
    if (isFootbal) {
      if (isSurvive) {
        return <StyledProgressText>Advance to NEXT WEEK {'\n'}IF ALL PICKS ARE CORRECT!</StyledProgressText>;
      }
    }

    if (!isSurvive) {
      return (
        <StyledProgressText>
          MOST CORRECT PICKS WINS! {'\n'}
          <AccentText color="#0bd855">CHECK OUT THE LEADERBOARD</AccentText>
        </StyledProgressText>
      );
    }

    if (isSurvive) {
      return <StyledProgressText>Advance to the NEXT DAY {'\n'}IF ALL PICKS ARE CORRECT!</StyledProgressText>;
    }
  };

  return (
    <Fragment>
      <StyledPrizes>
        <StyledPrize borderColor={getColorByKind(props.kind)}>
          <StyledProgressInfo>
            <StyledStatus isGray>In Progress</StyledStatus>

            {/* {!isSurvive && <StyledProgressText>You're in the game! Check out what's going on</StyledProgressText>} */}
            {getText()}
          </StyledProgressInfo>

          <IconItems {...props} />
        </StyledPrize>
      </StyledPrizes>
    </Fragment>
  );
}

const AccentText = styled.span`
  color: ${({ color }) => color};
  font-size: 14px;
`;

const StyledStatus = styled.span`
  ${statusStyles}
`;

const StyledPrizes = styled.div`
  ${prizesBlockStyles} /* margin-top: 0.75em; */
  height: 100%;
`;

const StyledPrize = styled.div`
  ${prizeBlockStyles}
  ${prizeStyles}
  height: 100%;
  min-height: 90px;
`;

const StyledProgressText = styled.span`
  ${progressTextStyles}
  white-space: pre;
`;

const StyledProgressInfo = styled.div`
  ${progressInfoStyles}
  width: 100%;
`;

ProgressInGame.propTypes = propTypes;
ProgressInGame.defaultProps = defaultProps;

export default ProgressInGame;
