import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const propTypes = {
  kind: PropTypes.string,
};

const defaultProps = {
  kind: '',
};

const getDescriptionText = (val) => {
  if (val === 'survive') return 'Last one remaining wins the game';
  return 'The most correct picks wins the game';
};

function Description(props) {
  const { kind } = props;
  const descriptionText = getDescriptionText(kind);

  return <StyledDescription>{descriptionText}</StyledDescription>;
}

const StyledDescription = styled.p`
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  text-align: center;
  margin-top: 5px;
`;

Description.propTypes = propTypes;
Description.defaultProps = defaultProps;

export default Description;
