import { createSelector } from 'reselect';
import { when, compose, join, props, ascend, always, identity, prop, sortWith, head } from 'ramda';
import { isDefined } from 'v2/helpers/ramda';
import { getEntities } from '../entities';
import { entityListGetter } from '../../utils/entities';

export const initialState = {};

const getProps = (state, props) => props;

const statCategoryIdsSelector = (state, { statCategoryIds }) => statCategoryIds;

function statCategoriesSelector(state) {
  return state.statCategories || initialState;
}

export const StatCategories = createSelector(statCategoriesSelector, identity);

export const getStatCategoriesList = createSelector(
  [always('statCategories'), statCategoryIdsSelector, getEntities],
  entityListGetter,
);

export const getStatCategory = createSelector(getStatCategoriesList, (categories) => {
  return head(categories);
});

export const getStatCategoryFullName = createSelector(
  getStatCategory,
  when(isDefined, compose(join('  '), props(['amount', 'subject']))),
);

export const getOrderedStatCategoriesList = createSelector(getStatCategoriesList, sortWith([ascend(prop('order'))]));

// export const getResourceContestDetail = createSelector(
//   [always('contests'), getResourceContestId, getEntities],
//   entityDetailGetter,
// );
