import Cookies from 'js-cookie';

function setCookieByName(name, data) {
  if (data) return Cookies.set(name, data);
  return data;
}

function getCookieByName(name) {
  return Cookies.get(name);
}

function removeCookieByName(name) {
  return Cookies.remove(name);
}

export { setCookieByName, getCookieByName, removeCookieByName };
