// @flow

import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import Slider from 'react-slick';

import Category from './Category';

type NewCategoriesProps = {};

function NewCategories(props: NewCategoriesProps) {
  const { id, kind, currentWeek, league, selectionsRequired, statCategories } = props;

  const settings = {
    infinite: false,
    slidesToShow: 3,
    rows: 1,
    dots: false,
    arrows: true,
    slidesToScroll: 3,
    swipeToSlide: false,
    draggable: false,
    swipe: false,
    nextArrow: <Arrow next />,
    prevArrow: <Arrow prev />,
  };

  if (kind == 'weekly') {
    return (
      <Container>
        <SliderContainer>
          <Slider {...settings}>
            {statCategories.map((statCategory) => (
              <SlideContainer key={statCategory.id}>
                <Category
                  {...statCategory}
                  {...props}
                  hideNames
                  categoriesCount={statCategories.length}
                  showThumbs={false}
                />
              </SlideContainer>
            ))}
          </Slider>
        </SliderContainer>
      </Container>
    );
  }

  return (
    <Container>
      {statCategories.map((statCategory) => (
        <Category key={statCategory.id} {...statCategory} {...props} categoriesCount={statCategories.length} />
      ))}
    </Container>
  );
}

function areEqual(prevProps, nextProps) {
  return R.equals(prevProps, nextProps);
}

export default React.memo(NewCategories, areEqual);

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  justify-content: center;
  align-items: flex-start;

  padding: 0 5px;
`;

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
`;

const SlideContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 5px;
`;

const Arrow = styled.div`
  position: absolute;
  height: 100%;
  left: ${({ prev }) => prev && '0'};
  right: ${({ next }) => next && '0'};
  z-index: 9;
  /* transform: translate(0, -90%); */

  &:before {
    opacity: 1;
    line-height: 1;
    z-index: 9;
    display: flex;
    width: 75px;
    height: 75px;
    align-items: center;
    justify-content: flex-start;
  }
`;
