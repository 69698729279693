import React, { Component } from 'react';
import { createPortal } from 'react-dom';

import styled from 'styled-components';

import Loader from './shared/Loader';

// type ModalLoaderProps = {
// }

const defaultProps = {
  isLoading: true,
  isShowSpinner: true,
  text: null,
};

class ModalLoader extends React.Component {
  componentWillMount() {
    this.root = document.createElement('div');
    document.body.appendChild(this.root);
  }

  componentWillUnmount() {
    document.body.removeChild(this.root);
  }

  render() {
    const { isLoading, isShowSpinner, text } = this.props;

    if (isLoading) {
      return createPortal(
        <ModalContainer>
          {text && (
            <ModalTitleWrapper>
              <ModalTitle>{text}</ModalTitle>
              <Spinner>
                <Bounce1 />
                <Bounce2 />
                <Bounce3 />
              </Spinner>
            </ModalTitleWrapper>
          )}
          {isShowSpinner && <Loader />}
        </ModalContainer>,
        this.root,
      );
    }

    return null;
  }
}

// function ModalLoader({ isLoading }) {
//   if (isLoading) {
//     const root = document.createElement('div');

//     useEffect(() => {
//       document.body.appendChild(root);
//     });

//     useEffect(
//       () => () => {
//         document.body.removeChild(root);
//       },
//       [],
//     );

//   }

//   return null;
// }

const Bounce1 = styled.div``;
const Bounce2 = styled.div``;
const Bounce3 = styled.div``;

const Spinner = styled.div`
  width: 70px;
  text-align: center;
  margin-left: 10px;

  div {
    width: 14px;
    height: 14px;
    background-color: #fff;
    margin-right: 8px;

    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  ${Bounce1} {
    animation-delay: -0.2s;
  }

  ${Bounce2} {
    animation-delay: -0.1s;
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`;

const ModalTitle = styled.h1`
  font-size: 45px;
  margin: 0;
`;

const ModalTitleWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  @media (max-width: 468px) {
    flex-direction: column;
    align-items: center;

    ${Spinner} {
      margin: 35px 0;
    }

    ${ModalTitle} {
      font-size: 38px;
      margin: 0 25px;
      text-align: center;
      line-height: 1.01em;
    }
  }
`;

const ModalContainer = styled.div`
  z-index: 9999;
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  backdrop-filter: brightness(90%) blur(2px);
  /* backdrop-filter: brightness(60%); */
`;

ModalLoader.defaultProps = defaultProps;

export default ModalLoader;
