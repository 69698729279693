// @flow

import React from 'react';
import styled from 'styled-components';
import { not } from 'ramda';

type ArrowsProps = {};

function Arrows({ nextAction, prevAction }: ArrowsProps) {
  return (
    <Container>
      <PrevArrow onClick={prevAction} isDisabled={not(prevAction)} />
      <NextArrow onClick={nextAction} isDisabled={not(nextAction)} />
    </Container>
  );
}

export default Arrows;

// styles
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Arrow = styled.div`
  display: block;
  position: relative;
  background: ${({ theme }) => theme.colors.gray};
  height: 32px;
  width: 38px;
  margin-right: 1px;
  cursor: pointer;
  transition: all 0.15s;
  filter: ${({ isDisabled }) => isDisabled && 'brightness(0.75)'};

  &::after {
    content: '';
    display: inline-block;
    height: 6px;
    width: 6px;
    border-bottom: 2px solid ${({ theme }) => theme.colors.white};
    border-right: 2px solid ${({ theme }) => theme.colors.white};
    position: absolute;
    top: 50%;
  }

  &:hover {
    filter: brightness(1.5);
    filter: ${({ isDisabled }) => isDisabled && 'brightness(0.75)'};
  }
`;

const PrevArrow = styled(Arrow)`
  border-radius: 16px 0px 0px 16px;

  &::after {
    transform: rotate(-45deg);
    left: 60%;
    transform: translate(-75%, -50%) rotate(140deg);
  }
`;

const NextArrow = styled(Arrow)`
  border-radius: 0px 16px 16px 0px;

  &::after {
    transform: rotate(-45deg);
    right: 60%;
    transform: translate(75%, -50%) rotate(-45deg);
  }
`;
