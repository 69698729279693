import styled from 'styled-components';
import startQuote from 'v2/components/shared/Image/start_quote.png';
import endQuote from 'v2/components/shared/Image/end_quote.png';
import startBlackQuote from 'v2/components/shared/Image/promo/2/black-start-quote.png';
import endBlackQuote from 'v2/components/shared/Image/promo/2/black-end-quote.png';

const BlockDescription = styled.span`
  color: #fff;
  font-size: ${({ size }) => {
    if (size === 'large') return '32px';

    return '20px';
  }};
  font-weight: bold;
  letter-spacing: 0px;
  line-height: ${({ size }) => {
    if (size === 'large') return '38px';

    return '28px';
  }};
  text-align: center;
  white-space: pre-wrap;
  text-transform: uppercase;
  position: relative;
  display: inline;
  font-style: ${({ isItalic }) => isItalic && 'italic'};

  &:after,
  &:before {
    content: '';
    display: ${({ quotes, blackQuotes }) => (quotes || blackQuotes ? 'block' : 'none')};
    background-repeat: no-repeat;
    height: 30px;
    width: 35px;
    position: absolute;
    background-size: contain;
  }

  &:after {
    background-image: url(${({ blackQuotes }) => (blackQuotes ? endBlackQuote : endQuote)});
    bottom: 5px;
    right: 0;
    transform: translate(20%, 40%);
  }

  &:before {
    background-image: url(${({ blackQuotes }) => (blackQuotes ? startBlackQuote : startQuote)});
    position: absolute;
    top: 3px;
    left: 0px;
    transform: translate(-20%, -40%);
  }
`;

export const BlockDescriptionaAccent = styled(BlockDescription)`
  font-size: 18px;
  line-height: 24px;
`;

export const BlockDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;

  ${BlockDescription} {
    margin-bottom: 10px;
  }

  ${BlockDescriptionaAccent} {
    margin-bottom: 0;
  }
`;

export default BlockDescription;
