// @flow

import React from 'react';
import styled from 'styled-components';
import closeIcon from 'v2/components/shared/Image/Icon/cross.svg';

type CloseButtonTypes = {
  closeToast: () => any,
};

function CloseButton({ closeToast }: CloseButtonTypes) {
  return (
    // <StyledCLoseButton onClick={() => closeToast()}>
    <StyledCLoseButton
      onClick={(e) => {
        e.stopPropagation();
        closeToast(e);
      }}
    >
      <StyledCloseIcon src={closeIcon} alt="✕" />
    </StyledCLoseButton>
  );
}

const StyledCloseIcon = styled.img`
  width: 11px;
`;

const StyledCLoseButton = styled.button`
  position: relative;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  display: inline-block;
  position: absolute;
  top: 8px;
  right: 10px;
  cursor: pointer;
  filter: brightness(25%);
  /* transition: filter ease-in 0.2s; */

  &:after {
    content: '';
    display: block;
    width: 30px;
    height: 30px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
  }

  &:hover {
    filter: brightness(100%);
  }
`;

export default CloseButton;
