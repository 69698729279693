// @flow

import React from 'react';
import styled from 'styled-components';

type PriceTagProps = {
  price: string,
};

function PriceTag({ price }: PriceTagProps) {
  if (price) return <StyledPriceTag>{`${price}$`}</StyledPriceTag>;

  return <StyledPriceTag>free</StyledPriceTag>;
}

const StyledPriceTag = styled.span`
  display: inline-flex;
  background-color: #e1073a;
  color: #fff;
  padding: 0.4em 2em;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
  position: absolute;
  right: -42%;
  top: 3%;
  transform: rotate(45deg);
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export default PriceTag;
