// @flow

import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';

import { getIn } from 'v2/helpers/ramda';

import Avatar from 'v2/components/Contests/shared/Avatar';
import upIcon from 'v2/components/shared/Image/up_icon.svg';
import downIcon from 'v2/components/shared/Image/down_icon.svg';

import PlayerHeadshots from './Container';

const defaultProps = {
  photo: null,
  name: null,
  hideName: false,
  size: 'small',
  showThumbs: true,
  cutName: true,
};
type PlayerHeadshotProps = {
  photo?: string,
  name?: string,
  league: string,
  hideName?: boolean,
  showThumbs?: boolean,
  cutName?: boolean,

  size?: 'small' | 'big' | 'large' | 'extra-large',
};

const getPlayerName = (name) => {
  const firstNamePart = R.compose(R.head, R.split(' '))(name) || name;
  const secondNamePart = R.compose(R.join(' '), R.tail, R.split(' '))(name);

  return R.join('\n', [firstNamePart, secondNamePart]);
};

const getThumbIcon = (status) => {
  if (status === 'success') return upIcon;
  if (status === 'fail') return downIcon;
};

function PlayerHeadshot({
  photo,
  name,
  league,
  isCenter,
  hideName,
  statusPick,
  showThumbs,
  isLoading,
  cutName,
  isContestActive,
  size,
  existStatuspcik,
}: PlayerHeadshotProps) {
  // const size = 'big';
  const progressIcon = getThumbIcon(statusPick);

  if (isLoading) {
    return (
      <Container center={isCenter} size={size}>
        <AvatarContainer>
          <AvatarSkeleton />
        </AvatarContainer>
      </Container>
    );
  }

  return (
    <Container center={isCenter} size={size}>
      <AvatarContainer>
        {/* fixme ln */}
        <Avatar league={league} source={photo} league={league} />
      </AvatarContainer>

      <PlayerNameContainer>
        {!hideName
          && (name ? (
            <PlayerName cutName={cutName}>{getPlayerName(name)}</PlayerName>
          ) : isContestActive ? (
            <PlayerName>Pick A Player</PlayerName>
          ) : (
            <PlayerName>
              Did Not
              {' '}
              {'\n'}
              Make Pick
            </PlayerName>
          ))}
      </PlayerNameContainer>

      {!isContestActive && !(progressIcon && showThumbs) && !hideName && !name && (
        <StatusImg src={getThumbIcon('fail')} alt="fail" center={isCenter} />
      )}

      {progressIcon && showThumbs && <StatusImg src={progressIcon} alt={statusPick} center={isCenter} />}
    </Container>
  );
}

PlayerHeadshot.defaultProps = defaultProps;

export { PlayerHeadshots };
export default React.memo(PlayerHeadshot, R.equals);

// styles

const PlayerNameContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  max-width: 100%;
`;

const AvatarSkeleton = styled.div`
  width: 100%;
  border-radius: 50%;
  align-self: flex-start;
  background-color: #26262e;
`;

const StatusImg = styled.img`
  padding-top: 5px;
  position: absolute;
  bottom: ${({ center }) =>
    /* if (center) return '-1px'; */
    '-17px'
};
`;

const PlayerName = styled.span`
  color: #fff;
  font-size: 7px;
  text-align: center;
  line-height: 9px;
  white-space: pre;
  margin-top: 5px;

  /* max-width: ${({ cutName }) => cutName && '55px'}; */
  /* overflow: hidden;
  text-overflow: ellipsis; */
`;

const AvatarContainer = styled.div`
  display: flex;
  width: 100%;
  /* height: 60px; */
  z-index: 1;
  border-radius: 50%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ size, theme }) => {
    const { normal, small, large, extraLarge } = getIn('gameCard.headshot.avatarSize', theme);

    if (size == 'extra-large') return extraLarge;
    if (size == 'large') return large;
    if (size == 'normal') return normal;
    if (size == 'big') return large;
    return small;
  }};

  position: ${({ center }) => center && 'absolute'};
  position: ${({ center }) => center && 'absolute'};
  transform: ${({ center }) => center && 'translate(-50%, 0)'};
  z-index: ${({ center }) => center && '2'};
  /* top: 0; */
  left: 50%;
  top: 0;

  ${PlayerName} {
    font-size: ${({ size, theme }) => {
    if (size == 'extra-large') return '11px';
    if (size == 'large') return '10px';
    return '8px';
    /* if (size == 'big') return '8px';

    return '6px'; */
  }};
    line-height: 1.2;
  }
`;
