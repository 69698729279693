// @flow

import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

type NotificationAboutTimeProps = {
  createdAt: string,
  className?: string,
};

function NotificationAboutTime({ createdAt, className }: NotificationAboutTimeProps) {
  const getAboutTime = (date) => {
    return moment(date, 'YYYY-MM-DD, kk:mm:ss').fromNow();
  };

  return (
    <StyledNotificationAboutTime className={className}>
      {`about ${getAboutTime(createdAt)}`}
    </StyledNotificationAboutTime>
  );
}

const StyledNotificationAboutTime = styled.span`
  color: #656579;
  font-size: 13px;
  font-weight: normal;
  text-transform: lowercase;
`;

export default NotificationAboutTime;
