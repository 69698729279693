import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import * as actions from 'v2/actions';
import FormGroupAdapter from 'v2/components/shared/formDefault/FormGroup/Adapter';
import Input from 'v2/components/shared/formDefault/Input';
import Button from 'v2/components/shared/formDefault/Button';
import ErrorMessage from 'v2/components/shared/formDefault/ErrorMessage';
import { composeValidators, validateRequired, validateEmail } from 'v2/components/shared/formDefault/validations';

import SuccessMessage from './shared/SuccessMessage';

function PersonalDetailsForm({ sendEmailForRestorePassword }) {
  const [messageStatus, setMessageStatus] = useState({ instructionSent: false, email: '' });

  const getComponent = () => {
    const { instructionSent, email } = messageStatus;

    if (instructionSent) {
      return <SuccessMessage email={email} />;
    }

    return (
      <>
        <FormTitle>Restore Password</FormTitle>
        <Form
          onSubmit={(props) => sendEmailForRestorePassword(props, setMessageStatus)}
          render={({ handleSubmit, hasSubmitErrors, submitError, submitting }) => (
            <StyledForm onSubmit={handleSubmit}>
              <FieldsCollection isErrors={hasSubmitErrors || submitError}>
                <Field
                  component={FormGroupAdapter}
                  inputComponent={Input}
                  name="restoreData.email"
                  help=""
                  placeholder="Enter your email"
                  type="email"
                  label="Email"
                  validate={composeValidators(validateRequired(), validateEmail())}
                />
                {hasSubmitErrors && submitError && (
                  <ErrorMessageWrapper>
                    <ErrorMessage>{submitError}</ErrorMessage>
                  </ErrorMessageWrapper>
                )}
              </FieldsCollection>

              <Button type="submit" disable={submitting}>
                {submitting ? 'Submitting...' : 'Submit'}
              </Button>
            </StyledForm>
          )}
        />
      </>
    );
  };

  return <RestorePasswordFormContainer>{getComponent()}</RestorePasswordFormContainer>;
}

// const FieldsCollection = styled.div`
//   margin-bottom: 50px;
// `;

// const FieldsGroup = styled.div`
//   display: flex;
//   flex-flow: wrap;
//   justify-content: space-between;
//   margin-bottom: 25px;

//   @media (max-width: 768px) {
//     display: flex;
//     flex-direction: column;
//   }
// `;

const FormTitle = styled.h2`
  @media (max-width: 468px) {
    font-size: 25px;
  }
`;

const ErrorMessageWrapper = styled.div`
  /* margin-bottom: 25px; */
  text-align: center;
`;

// const FieldWrapper = styled.div`
//   margin-bottom: 25px;
// `;

const FieldsCollection = styled.div`
  margin-bottom: ${({ isErrors }) => (isErrors ? '25px' : '50px')};

  @media (max-width: 475px) {
    margin-bottom: 25px;
  }
`;

const StyledForm = styled.form`
  width: 100%;
  margin-top: 15px;
  margin-bottom: 25px;
`;

const RestorePasswordFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 100px 50px 100px;

  @media (max-width: 660px) {
    padding: 25px 50px;
  }

  @media (max-width: 475px) {
    padding: 10px 25px;
  }
  /* max-width: 650px;
  margin: 0 auto;
  background: #1b1b21; */
`;

const mapDispatchToProps = (dispatch) => ({
  sendEmailForRestorePassword: (restoreData, cb) => dispatch(actions.sendEmailForRestorePassword(restoreData, cb)),
});

const enhance = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
);

export default enhance(PersonalDetailsForm);
