// @flow

import React, { useState, useEffect } from 'react';
import { propOr } from 'ramda';
import styled from 'styled-components';

import leagueIcons from 'v2/constants/UI/leagueIcons';
import { getKindText } from 'v2/helpers/contest';

type Props = {
  league: String,
  kind: String,
  size?: 'small' | 'normal' | 'large',
};

const defaultProps = {
  size: 'normal',
};

const getLeagueIcon = (league) => propOr({}, league, leagueIcons);

function NoGames({ league, kind, size }: Props) {
  return (
    <MainText size={size}>
      <MainSubText>LAUNCHING NEW</MainSubText>
      <MainSubText>
        <LeagueIconWrapper>
          <LeagueIcon {...getLeagueIcon(league)} />
        </LeagueIconWrapper>{' '}
        <KindText kind={kind}>{getKindText(kind)}</KindText> GAMES SOON!
      </MainSubText>
    </MainText>
  );
}
NoGames.defaultProps = defaultProps;

function JoinedAllAvailableGamesText({ league, kind, size }: Props) {
  const [showUnderline, setShowUnderline] = useState(false);

  useEffect(() => {
    setShowUnderline(true);
  }, []);

  return (
    <MainText size={size}>
      <MainSubText>YOU’VE JOINED ALL AVAILABLE</MainSubText>
      <MainSubText>
        <LeagueIconWrapper>
          <LeagueIcon {...getLeagueIcon(league)} />
        </LeagueIconWrapper>{' '}
        <KindText kind={kind}>{getKindText(kind)}</KindText> GAMES!
      </MainSubText>
      {/* <div style={{ padding: '5px 0' }} /> */}
      <MainSubText style={{ marginTop: '1px' }}>CHECK OUT MY GAMES!</MainSubText>
    </MainText>
  );
}
JoinedAllAvailableGamesText.defaultProps = defaultProps;

function NoGamesJoined({ league, kind, size }: Props) {
  const [showUnderline, setShowUnderline] = useState(false);

  useEffect(() => {
    setShowUnderline(true);
  }, []);
  return (
    <MainText size={size}>
      <MainSubText>NO GAMES JOINED YET!</MainSubText>
      <MainSubText>
        CHECK OUT <UnderlineText showUnderline={showUnderline}>AVAILABLE GAMES!</UnderlineText>
      </MainSubText>
    </MainText>
  );
}
NoGamesJoined.defaultProps = defaultProps;

export { JoinedAllAvailableGamesText, NoGames, NoGamesJoined };

// styles

const MainSubText = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-weight: bolder;
  text-align: center;
  line-height: 1.5em;
`;

const MainText = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;

  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ size, theme }) => theme.gameCard.noGames.textSize[size]};
  font-weight: bolder;
  text-align: center;
  line-height: 1.5em;

  ${MainSubText} {
    font-size: ${({ size, theme }) => theme.gameCard.noGames.textSize[size]};
  }
`;

const LeagueIconWrapper = styled.span`
  font-size: 1em;
  position: relative;
  width: 1.5em;
  height: 1.25em;
  padding: 0 0.75em;
  margin-right: 5px;
`;

const LeagueIcon = styled.img`
  width: 1.5em;
  position: absolute;
  transform: translate(0, -35%);
  top: 50%;
  left: 0;
`;

const KindText = styled.span`
  font-size: 1em;
  color: ${({
    kind,
    theme: {
      colors: { getColorByKind },
    },
  }) => getColorByKind(kind)};
`;

const UnderlineText = styled.span`
  font-size: 1em;
  position: relative;
  color: ${({ theme }) => theme.colors.white};
  border-bottom: ${({ showUnderline }) => showUnderline && '1px solid #fff'};
  padding-bottom: 5px;

  /* &::after {
    content: '';
    display: block;
    bottom: -7px;
    width: 0;
    position: absolute;
    width: 0;
    height: 2px;
    background: ${({ theme }) => theme.colors.white};
    transition: width 0.75s;
    width: ${({ showUnderline }) => showUnderline && '100%'};
  } */

  &:hover::after {
    width: 100%;
  }
`;
