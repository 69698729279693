import React from 'react';
import PropTypes from 'prop-types';

// import useClassNames from 'hooks/useClassNames';

const propTypes = {
  baseClass: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
};

const defaultProps = {
  baseClass: undefined,
  className: undefined,
  type: 'text',
};

function Input({ type, onChange, name, className, baseClass, ...props }) {
  return <input name={name} onChange={onChange} type={type} {...props} className={className} />;
}

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
