import { GamesCountApiAdapter } from 'v2/apiAdapters';

import * as requestTypes from '../../constants/requestTypes';
import * as types from '../../constants/actionTypes';

import setRequestInProcess from '../request';

export function fetchGamesCount() {
  const requestType = requestTypes.GAMES_COUNT_FETCH;

  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));

    GamesCountApiAdapter.getGamesCountApiAdapter().then((res) => {
      dispatch(fetchGamesCountApiAdapterSuccess(res.activeCount));
      dispatch(setRequestInProcess(false, requestType));
    });
    // .catch((error) => {
    //   if (error.response) {
    //     openNotification('error', error.response.data.message);
    //   }
    // });
  };
}

function fetchGamesCountApiAdapterSuccess(payload) {
  return {
    type: types.GAMES_COUNT_FETCH_SUCCESS,
    payload,
  };
}

export default fetchGamesCount;
