// @flow

import React from 'react';
import styled from 'styled-components';

import CarouselItemsContainer from './Container';

type CarouselItemProps = {
  src: string,
  alt?: string,
};

const defaultProps = {
  alt: '',
};

function CarouselItem({ src, alt }: CarouselItemProps) {
  return (
    <CarouselItemContainer>
      <CarouselItemImage src={src} alt={alt} />
    </CarouselItemContainer>
  );
}

CarouselItem.Container = CarouselItemsContainer;
CarouselItem.defaultProps = defaultProps;
export default CarouselItem;

// styles
const CarouselItemImage = styled.img``;

const CarouselItemContainer = styled.div`
  border-radius: 8px;
  height: 175px;
  width: 175px;
  margin-left: 15px;
  flex: 1;
`;
