// @flow

import React from 'react';
import styled from 'styled-components';
import PromoPageWrapper from 'v2/components/PromoPages/1/shared/PromoPageWrapper';
import videoPreviewImg from 'v2/components/shared/Image/promo/mobile-vide-preview.jpg';
import newEraImg from 'v2/components/shared/Image/promo/new_era_mobile.png';
import { InstagramCarousel } from 'v2/components/shared';

import {
  PhotoBlock1,
  PhotoBlock2,
  PhotoBlock3,
  PhotoBlock4,
  PhotoBlock5,
  PhotoBlock6,
  PhotoBlock8,
  PhotoBlock7,
  PhotoBlock9,
  PhotoBlock10,
  VideoPreview,
  NewEraBlock,
} from 'v2/components/PromoPages/1/blocks';

function MobileVersion() {
  return (
    <PromoPageWrapper>
      <VideoPreview showText={false} pict={videoPreviewImg} />
      <PhotoBlock7 />
      <PhotoBlock1 />
      <PhotoBlock4 />
      <PhotoBlock5 />
      <PhotoBlock8 />
      <PhotoBlock2 />
      <PhotoBlock6 />
      <PhotoBlock9 />
      <PhotoBlock10 />
      <PhotoBlock3 />

      <NewEraWrapper>
        <NewEraBlock pict={newEraImg} />
      </NewEraWrapper>

      <InstagramCarousel />
    </PromoPageWrapper>
  );
}

export default MobileVersion;

// styles

const NewEraWrapper = styled.div`
  padding: 0 5px;
  border-radius: 8px;
  overflow: hidden;
`;
