// @flow

import React from 'react';
import moment from 'moment';
import { partial, not } from 'ramda';
import styled from 'styled-components';

import { arrowPrevious, arrowNext } from '../../../shared/Image';

const getPeriod = (league, startsAt, periodName, currentPeriod) => {
  if (league === 'nfl') return `${periodName} ${currentPeriod}`;

  return moment(startsAt)
    .add(currentPeriod - 1, 'days')
    .format('MMMM D');
};

function WeekSwitcher({
  isLoading,
  handler,
  startsAt,
  periodName,
  league,
  previousWeek,
  nextWeek,
  currentWeek,
  eliminationWeek,
  ...contestProps
}) {
  const currentPeriod = partial(getPeriod, [league, startsAt, periodName]);
  const isNextBtnShow = nextWeek && (not(eliminationWeek) || eliminationWeek >= nextWeek);
  const isPrevBtnShow = previousWeek && currentWeek > previousWeek && !(previousWeek == currentWeek);

  const renderTitle = () => {
    const currentPeriodByCurrentWeek = currentPeriod(currentWeek);

    if (currentPeriodByCurrentWeek) return <Title>{currentPeriodByCurrentWeek}</Title>;

    return null;
  };

  return (
    <Container isLoading={isLoading}>
      <Section left>
        {isPrevBtnShow && (
          <Switch onClick={() => handler(previousWeek)}>
            <SwitchArrow>
              <img src={arrowPrevious} alt="arrow" />
            </SwitchArrow>
            <SwitchDesc>Previous</SwitchDesc>
          </Switch>
        )}
      </Section>

      <Section>
        {/* <Title>{isNFL ? currentPeriod(currentWeek + displayCurrentPeriod - 1) : currentPeriod(currentWeek)}</Title> */}
        {renderTitle()}
      </Section>

      <Section right>
        {isNextBtnShow && (
          <Switch onClick={() => handler(nextWeek)}>
            <SwitchDesc>Next</SwitchDesc>
            <SwitchArrow>
              <img src={arrowNext} alt="arrow" />
            </SwitchArrow>
          </Switch>
        )}
      </Section>
    </Container>
  );
}

export default WeekSwitcher;

// styles
const Title = styled.span`
  color: ${({ theme }) => theme.colors.white};
`;

const SwitchDesc = styled.span``;

const SwitchArrow = styled.div`
  border-radius: 6px;
  display: flex;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: #26262e;
  padding: 0;
  margin: 0;
  margin: 0 10px;
  &:focus {
    background: #131317;
  }
`;

const Switch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Section = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: ${({ left }) => left && 'flex-start'};
  justify-content: ${({ right }) => right && 'flex-end'};
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-bottom: 5px; */
  min-height: 32px;
  background-color: ${({ theme }) => theme.colors.dark};
  filter: ${({ isLoading }) => isLoading && 'brightness(0.75) opacity(0.75)'};
`;
