import React from 'react';

import useFormGroupAdapter from 'v2/hooks/useFormGroupAdapter';

import FormGroup from './index';

function Adapter(props) {
  const [groupProps, inputProps] = useFormGroupAdapter(props);
  return <FormGroup {...groupProps} inputProps={inputProps} />;
}

export default Adapter;
