// @flow

import React from 'react';
import styled from 'styled-components';

import Avatar from 'v2/components/Contests/shared/Avatar';

type PlayerInfoProps = {
  name: string,
  avatar?: string,
  teamName: string,
  position: string,
};

const defaultProps = {
  avatar: null,
};

function PlayerInfo({ position, name, avatar, teamName }: PlayerInfoProps) {
  return (
    <Container>
      <AvatarWrapper>
        <Avatar source={avatar} alt={name} />
        <PositionContainer>
          <Position>{position}</Position>
        </PositionContainer>
      </AvatarWrapper>
      <Name>{name}</Name>
      <TeamName>{teamName}</TeamName>
    </Container>
  );
}

PlayerInfo.defaultProps = defaultProps;
export default PlayerInfo;

// styles

const AvatarWrapper = styled.div`
  width: 105px;
  margin-bottom: 25px;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Position = styled.span`
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

const PositionContainer = styled.div`
  position: absolute;
  bottom: -40%;
  left: 50%;

  transform: translate(-50%, -100%);
  background: red;
  /* padding: 0.2rem 0.3rem; */
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.lighGray};
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Name = styled.span`
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;

  margin-top: 8px;
`;

const TeamName = styled.span`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-top: 8px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 20px 0 40px;
  background-color: ${({ theme }) => theme.colors.lighGray};
`;
