/* eslint-disable react/prop-types */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import { parseISO, differenceInHours } from 'date-fns/fp';
import { connect } from 'react-redux';
import { compose as recompose } from 'recompose';

import { getAvailableContestsList } from 'v2/selectors/contests';
import { getMyGamesActiveList } from 'v2/selectors/myGamesActive';
import { isDefined } from 'v2/helpers/ramda';

import Games from './Games';
import NavigationBlock from './NavigationBlock';
import * as paramsHelpers from './NavigationBlock/helpers/params';

const propTypes = {};

const defaultProps = {};

const filterBySubmitPicks = (contests) =>
  contests.filter(({ totalSelectionsRequired, submitPicks }) => totalSelectionsRequired !== submitPicks);

const filterByLeague = (league) => (contests) =>
  contests.filter((contest) => {
    if (league) {
      return contest.league.toLowerCase() === league.toLowerCase();
    }

    return true;
  });

const filterByKind = (kind) => (contests) =>
  contests.filter((contest) => {
    if (kind) {
      return contest.kind.toLowerCase() === kind.toLowerCase();
    }

    return true;
  });

const selectNotLiveContests = R.filter(R.compose(R.not, R.prop('isContestLive')));
// const selectWhereNotLastDay = R.filter(
//   R.ifElse(
//     R.compose(R.equals('weekly'), R.prop('kind')),
//     R.compose(R.lt(24), differenceInHours(new Date()), parseISO, R.prop('endsAt')),
//     R.always(true),
//   ),
// );

const { kindKey, leagueKey, activeIdKey, defaultLeagueKey, defaultKindKey } = paramsHelpers;

const getLens = (key) => R.lens(R.prop(key), R.assoc(key));
const setParam = R.curry((params, lensKey, key) => R.set(getLens(lensKey), key, params));
const getParam = R.curry((params, lensKey) => R.view(getLens(lensKey), params));

const filterContests = (params) =>
  R.compose(
    filterByKind(R.propOr(defaultKindKey, kindKey, params)),
    filterByLeague(R.propOr(defaultLeagueKey, leagueKey, params)),
  );

function AvailableGames({ availableContests, myGamesActive, session }) {
  const [params, setParams] = useState({});

  const filterByParams = filterContests(params);

  function onParamsChange(input) {
    paramsHelpers.stringifyParams(input);
  }

  const setFilterParam = R.compose(setParams, R.tap(onParamsChange), setParam(params));

  const onChange = R.curry((lensKey, key) => {
    setFilterParam(lensKey, key);
  });

  useEffect(() => {
    R.compose(
      R.when(isDefined, R.compose(setParams, R.tap(onParamsChange), R.pick([kindKey, leagueKey]))),
      R.head,
      R.sortWith([
        R.descend(R.propEq('kind', 'survive')),
      ]),
    )(availableContests);
  }, [availableContests]);

  return (
    <StyledAvailableGames>
      <NavigationBlock onChange={onChange} getStatus={getParam(params)} />
      <Games
        session={session}
        gamesCollection={R.compose(selectNotLiveContests, filterByParams)(availableContests)}
        myGamesCollection={myGamesActive}
        kind={R.propOr(defaultKindKey, kindKey, params)}
        league={R.propOr(defaultLeagueKey, leagueKey, params)}
        showHeadshots={false}
        showFAQ
      />
    </StyledAvailableGames>
  );
}

const StyledAvailableGames = styled.div``;

AvailableGames.propTypes = propTypes;
AvailableGames.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  availableContests: getAvailableContestsList(state),
  myGamesActive: getMyGamesActiveList(state),
});

const enhance = recompose(React.memo, connect(mapStateToProps));

export default enhance(AvailableGames);
