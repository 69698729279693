// @flow

import React from 'react';
import styled from 'styled-components';
import { and, not } from 'ramda';

import play from '../img/play.svg';

import SlideContainer from './SlideContainer';

type ArticlePreviewProps = {};

function ArticlePreview({
  videoUrl,
  playVideo,
  isCentered,
  isActive,
  title,
  previewPhotoUrl,
  id: articleId,
  ...rest
}: ArticlePreviewProps) {
  const isVideoMaterial = false;

  return (
    <Container isCentered={isCentered} isActive={isActive}>
      {isVideoMaterial && (
        <ImgContainer onClick={() => playVideo('274739963')}>
          <Img src={previewPhotoUrl} alt="testArticleImg" />
          <PlayVideIcon>
            <img src={play} alt="play" />
          </PlayVideIcon>
          <VideoDuration>5:03</VideoDuration>
        </ImgContainer>
      )}

      {not(isVideoMaterial) && previewPhotoUrl && <Img src={previewPhotoUrl} alt="testArticleImg" />}

      <Description>
        <DescriptionBlock>
          <Subtitle>John Johnson</Subtitle>
        </DescriptionBlock>

        <DescriptionBlock>
          <Title>{title}</Title>
        </DescriptionBlock>

        <DescriptionBlock>
          <AdditionalInfo>
            <Subtitle>3 Jul, 2020</Subtitle>
            <Subtitle>5 min read</Subtitle>
          </AdditionalInfo>
        </DescriptionBlock>
      </Description>
    </Container>
  );
}

export { SlideContainer };
export default ArticlePreview;

// styles

const VideoDuration = styled.span`
  padding: 5px;
  background: #000;
  color: ${({ theme }) => theme.colors.white};
  opacity: 0.5;
  position: absolute;
  bottom: 20px;
  left: 20px;
`;

const PlayVideIcon = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: red;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  img {
    width: 45%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -50%);
  }
`;

const ImgContainer = styled.div`
  position: relative;
`;

const AdditionalInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Subtitle = styled.span`
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.lightenGray};
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:last-child) {
    &::after {
      content: '';
      display: block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: ${({ theme }) => theme.colors.lightenGray};
      margin: 5px;
    }
  }
`;

const Title = styled.h3`
  margin: 0;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0px;
  text-transform: uppercase;
  line-height: 1.3;
`;

const Img = styled.img`
  width: 100%;
`;

const DescriptionBlock = styled.div`
  &:not(:last-child) {
    margin-bottom: 7px;
  }
`;

const Description = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
  transition: opacity 0.5s;

  position: ${({ isCentered }) => isCentered && 'absolute'};
  bottom: ${({ isCentered }) => isCentered && '10px'};
  display: ${({ isCentered, isActive }) => {
    if (isCentered) {
      if (not(isActive)) return 'none';
    }
  }};
  opacity: ${({ isCentered, isActive }) => {
    if (isCentered) {
      if (not(isActive)) return '0';
    }
  }};
`;

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  text-decoration: none !important;

  ${Description} {
    position: ${({ isCentered }) => isCentered && 'absolute'};
    bottom: ${({ isCentered }) => isCentered && '10px'};
    display: ${({ isCentered, isActive }) => {
      if (and(isCentered, not(isActive))) return 'none';
    }};
    opacity: ${({ isCentered, isActive }) => {
      if (and(isCentered, not(isActive))) return '0';
    }};
  }

  filter: ${({ isCentered, isActive }) => {
    if (and(isCentered, not(isActive))) return '';
  }};
`;
