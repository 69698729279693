// @flow

import React, { type Node } from 'react';
import styled from 'styled-components';
import Wrapper from './Wrapper';

type PhotoBlcokProps = {
  children?: Node,
  src: string,
  alt?: string,
  contentDirection?: 'top' | 'bottom' | 'center',
  isShadow?: boolean,
  showBorderRadius?: boolean,
};

const defaultProps = {
  alt: 'Promo image',
  children: null,
  contentDirection: 'center',
  isShadow: false,
  showBorderRadius: true,
};

function PhotoBlcok({ noBorder, contentDirection, isShadow, children, ...rest }: PhotoBlcokProps) {
  return (
    <Container>
      <Image {...rest} disableBorder={noBorder} />
      <Content isShadow={isShadow} direction={contentDirection}>
        {children}
      </Content>
    </Container>
  );
}

PhotoBlcok.defaultProps = defaultProps;
PhotoBlcok.Wrapper = Wrapper;
export default PhotoBlcok;

// styles

const Image = styled.img`
  border-radius: ${({ disableBorder }) => (disableBorder ? 0 : '8px')};
  width: 100%;
  align-self: flex-start;
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  padding: 20px 10px;
  z-index: 2;

  align-items: ${({ direction }) => {
    if (direction === 'bottom') return 'flex-end';
    if (direction === 'top') return 'flex-start';
    return 'center';
  }};

  &:after {
    content: '';
    display: ${({ isShadow }) => (isShadow ? 'block' : 'none')};
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: linear-gradient(-180deg, rgba(27, 27, 33, 0) 0%, rgb(27, 27, 33) 100%);
    border-radius: 8px;
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  border-radius: ${({ showBorderRadius }) => showBorderRadius && '8px'};
  /* min-width: 350px; */
  width: 100%;

  height: 100%;
  overflow: hidden;
`;
