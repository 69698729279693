import React from 'react';
import PhotoBlock from 'v2/components/PromoPages/shared/PhotoBlock';
import img from 'v2/components/shared/Image/promo/5.png';
import BlockDescription, { BlockDescriptionContainer } from '../../shared/DescriptionBlock';

function block() {
  return (
    <PhotoBlock.Wrapper>
      <PhotoBlock src={img} contentDirection="bottom">
        <BlockDescriptionContainer noPadding>
          <BlockDescription size="large" style={{ fontSize: '33px' }} italic>
            GO BEYOND THE GAME,{'\n'}
            BEHIND THE SCENES.
          </BlockDescription>
        </BlockDescriptionContainer>
      </PhotoBlock>
    </PhotoBlock.Wrapper>
  );
}

export default block;
