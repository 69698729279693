/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import './style.scss';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  href: PropTypes.string,
};

const defaultProps = {
  className: undefined,
  children: undefined,
  href: undefined,
};

function Button({ className, children, href }) {
  return (
    <StyledButton className={className} href={href}>
      {children}
    </StyledButton>
  );
}

const StyledButton = styled.a``;

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

// export default enhance(HomePage);
export default Button;
