// @flow

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';

import { ContestsApiAdapter } from 'v2/apiAdapters';

import Loader from 'v2/components/shared/Loader';

import PlayerHeadshot from '..';

type PlayerHeadshotsCOntainerProps = {};

const getHeadshotSize = (selectionsRequired, categoriesCount, isCenter = false) => {
  // if (selectionsRequired == 1) return 'large';

  // if (categoriesCount == 1) {
  //   if (selectionsRequired == 3 && isCenter) return 'large';

  //   if (selectionsRequired == 3) return 'big';
  // }

  // if (categoriesCount == 2) {
  //   if (selectionsRequired == 3 && isCenter) return 'large';

  //   if (selectionsRequired == 3) return 'big';
  // }

  // if (selectionsRequired == 2 && isCenter) return 'large';

  // if (selectionsRequired == 2) return 'big';

  // if (isCenter) return 'big';

  if (categoriesCount > 3 && selectionsRequired > 1) return 'large';
  if (categoriesCount == 3 && selectionsRequired == 2) return 'normal';
  if (selectionsRequired < 3) return 'extra-large';

  return 'big';
};

const mapWithIndex = R.addIndex(R.map);

function PlayerHeadshotsCOntainer({
  selectionsRequired,
  league,
  contestId,
  currentWeek,
  id,
  hideNames,
  categoriesCount,
  showThumbs,
  isContestActive,
  kind,
  players,
}: PlayerHeadshotsCOntainerProps) {
  const isWeekly = kind == 'weekly';

  const existStatuspcik = R.compose(
    (statusArr) => R.contains('fail', statusArr) || R.contains('success', statusArr),
    R.uniq,
    R.map(R.prop('statusPick')),
    R.or,
  )(players, []);

  const selectedPlayers = R.or(players, []).map((player, index) => (
    <PlayerHeadshot
      existStatuspcik={existStatuspcik}
      selectionsRequired={selectionsRequired}
      key={player.id}
      isLoading={false}
      league={league}
      isCenter={categoriesCount > 1 && index == 1 && R.length(R.or(players, [])) == 3}
        // hideName={selectionsRequired > 2 || isWeekly}
      hideName={isWeekly}
      size={getHeadshotSize(selectionsRequired, categoriesCount, index == 2)}
      showThumbs={showThumbs}
      cutName={selectionsRequired > 1}
      {...player}
    />
  ));

  // if (isLoading) return <Loader minHeight="0" />;

  return (
    <PlayerHeadshots hasPlayers={R.length(selectedPlayers) > 0}>
      {R.pipe(
        R.times((index) => (
          <PlayerHeadshot
            existStatuspcik={existStatuspcik}
            isLoading={false}
            key={index}
            league={league}
            isCenter={categoriesCount > 1 && index == 2}
            // hideName={selectionsRequired > 2 || isWeekly}
            hideName={isWeekly}
            size={getHeadshotSize(selectionsRequired, categoriesCount, index == 2)}
            isContestActive={isContestActive}
          />
        )),
        mapWithIndex((defaultPlayer, index) => selectedPlayers[index] || defaultPlayer),
      )(selectionsRequired)}
    </PlayerHeadshots>
  );
}

export default React.memo(PlayerHeadshotsCOntainer, R.equals);

const PlayerHeadshots = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  margin: 5px auto;
  position: relative;
  min-height: ${({ hasPlayers }) => '71px'};
`;
