import { useCallback } from 'react';
import { find, propEq, prop } from 'ramda';

function useSimpleValueSelect({ onChange: originOnChange, value: originValue, options }) {
  const onChange = useCallback((option) => originOnChange(prop('value', option)), [originOnChange]);
  const value = find(propEq('value', originValue), options);

  return [value, onChange];
}

export default useSimpleValueSelect;
