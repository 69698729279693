import allSports from '../../components/shared/Image/Sports/all_sports.svg';
import football from '../../components/shared/Image/Sports/football.svg';
import basketball from '../../components/shared/Image/Sports/basketball.svg';
import golf from '../../components/shared/Image/Sports/golf.svg';
import hockey from '../../components/shared/Image/Sports/hockey.svg';
import soccer from '../../components/shared/Image/Sports/soccer.svg';
import baseball from '../../components/shared/Image/Sports/baseball.svg';

const filterBySport = [
  {
    id: 0,
    title: 'all sports',
    league: 'all',
    available: true,
    logo: {
      src: allSports,
      alt: 'all sports',
    },
  },
  {
    id: 1,
    title: 'football',
    league: 'NFL',
    available: true,
    logo: {
      src: football,
      alt: 'football',
    },
  },
  {
    id: 2,
    title: 'basketball',
    league: 'NBA',
    available: true,
    logo: {
      src: basketball,
      alt: 'basketball',
    },
  },
  {
    id: 3,
    title: 'hockey',
    league: 'NHL',
    available: false,
    logo: {
      src: hockey,
      alt: 'hockey',
    },
  },
  {
    id: 4,
    title: 'baseball',
    league: 'MLB',
    available: false,
    logo: {
      src: baseball,
      alt: 'baseball',
    },
  },
  {
    id: 5,
    title: 'soccer',
    league: 'NSL',
    available: false,
    logo: {
      src: soccer,
      alt: 'soccer',
    },
  },
  {
    id: 6,
    title: 'golf',
    league: 'NCCGA',
    available: false,
    logo: {
      src: golf,
      alt: 'golf',
    },
  },
];

export { filterBySport };
