/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { prop, andThen, length, slice, propOr, isEmpty, compose, when } from 'ramda';

import background from 'v2/components/shared/Image/bg.png';
import { isDefined } from 'v2/helpers/ramda';

import { BlogApiAdapter } from 'v2/apiAdapters';
import DefaultSlider from 'v2/components/Blog/shared/Sliders/Default';
import PreviewCard from 'v2/components/Blog/shared/PreviewCardNew';

import Title from '../../../shared/Title';
import Paragraph from '../../../shared/Paragraph';
import PreviewSlider from './OffersSlider';

const propTypes = {};

const defaultProps = {};

function Offers({ offers }) {
  // const [data, setData] = useState([]);

  // const getData = async () => {
  //   try {
  //     compose(andThen(when(isDefined, setData)), andThen(prop('data')), BlogApiAdapter.getArticles)({ filter: 'main' });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   getData();
  // }, []);

  if (isEmpty(offers)) return null;

  return (
    <StyledOffers>
      <OffersTitle>
        <Title text={'Beyond The Game'} />
        {/* <Paragraph text={'News around the leagues and much more'} /> */}
      </OffersTitle>

      <PreviewSlider data={offers} />

      {/* {length(data) >= 2 && (
        <DefaultSlider>
          {data.map((post) => (
            <PreviewCard.Container {...post}>
              <PreviewCard />
            </PreviewCard.Container>
          ))}
        </DefaultSlider>
      )}

      {length(data) < 2 && (
        <Container>
          {data.map((post) => (
            <PreviewCard.Container {...post}>
              <PreviewCard />
            </PreviewCard.Container>
          ))}
        </Container>
      )} */}
    </StyledOffers>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledOffers = styled.div`
  position: relative;
  .slick-list {
    padding-bottom: 6px !important;
  }

  &:after {
    content: '';
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }

  @media (min-width: 768px) {
    .slider_wrap {
      padding-bottom: 30px;
    }
  }
`;

const OffersTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
  @media (min-width: 768px) {
    align-items: flex-start;
    padding: 0 15px;
  }
`;

Offers.propTypes = propTypes;
Offers.defaultProps = defaultProps;

// export default enhance(HomePage);
export default Offers;
