// @flow

import React from 'react';
import styled from 'styled-components';
import PromoPageWrapper from 'v2/components/PromoPages/3/shared/PromoPageWrapper';
import { VideoPreview, PhotoBlock1, PhotoBlock2, PhotoBlock3, PhotoBlock4 } from 'v2/components/PromoPages/3/blocks';
import { PlayerQuote } from 'v2/components/shared';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import robIcon from 'v2/components/shared/Image/playerQuotes/rob_red.svg';
import jamalIcon from 'v2/components/shared/Image/playerQuotes/jamal_green.svg';

const settings = {
  className: '',
  infinite: true,
  centerMode: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  rows: 1,
  centerPadding: '0',
  arrows: false,
  dots: true,
};

const dataQuotes = [
  {
    key: 1,
    text: `GOOD LUCK STREAKIN’.\n GO PLAY & HAVE SOME FUN…\n LET’S GO!`,
    name: 'Rob Gronkowski',
    color: '#E1073A',
    photo: { src: robIcon, lat: 'rob' },
  },
  {
    key: 2,
    text: `Here’s your opportunity to\n win big! Win some gear and\n meet athletes like me.`,
    name: 'Jamal Adams',
    color: '#0BD855',
    photo: { src: jamalIcon, lat: 'jamal' },
  },
];

function MobileVersion() {
  return (
    <PromoPageWrapper>
      <VideoPreview />
      <PhotoBlock1 />
      <PhotoBlocks>
        <PhotoBlock2 size="smaller" />
        <PhotoBlock3 size="smaller" />
        <PhotoBlock4 size="smaller" />
        <PhotoBlock4 size="smaller" />
      </PhotoBlocks>

      <SliderContaoner>
        <Slider {...settings}>
          {dataQuotes.map(({ ...rest }) => (
            <PlayerQuote {...rest} />
          ))}
        </Slider>
      </SliderContaoner>
    </PromoPageWrapper>
  );
}

export default MobileVersion;

// styles

const PhotoBlocks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 12px;
  margin: 0 5px;
`;

const SliderContaoner = styled.div`
  overflow: hidden;
`;
