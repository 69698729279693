/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { not } from 'ramda';
import { Link as ScrollLink } from 'react-scroll';
import { isMobileDevice } from 'v2/utils/detectPlatform';
import Avatar from 'v2/components/Contests/shared/Avatar';

import { defaultAvatar, incorrectPick } from '../../../Image';

const propTypes = {};

const defaultProps = {};

function DefaultPlayers({ isContestActive, playerKey, league, isLarge, ...rest }) {
  const isContestEnded = not(isContestActive);

  return (
    <Player key={playerKey} large={isLarge}>
      <StyledScrollLink to="searchPlayers" offset={-80} spy smooth hashSpy duration={500} delay={100} isDynamic>
        <PlayerNumber>{playerKey + 1}</PlayerNumber>
        <AvatarContainer>
          <Avatar defaultAvatar isDefault league={league} />
        </AvatarContainer>
        {isContestEnded && <PlayerName>{`Did Not \nMake Pick`}</PlayerName>}

        {not(isContestEnded) && (
          <Fragment>
            <PlayerName>{`Pick \na player`}</PlayerName>
            {isMobileDevice() ? null : <span>CLICK HERE TO MAKE PICKS</span>}
          </Fragment>
        )}
      </StyledScrollLink>

      {isContestEnded && <PlayerIndicator src={incorrectPick} />}
    </Player>
  );
}

DefaultPlayers.propTypes = propTypes;
DefaultPlayers.defaultProps = defaultProps;

// export default enhance(HomePage);
export default DefaultPlayers;

// styles
const PlayerIndicator = styled.img`
  width: 30px;
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translate(-50%, -10%);
  /* @media (max-width: 901px) {
  } */
`;

const StyledScrollLink = styled(ScrollLink)`
  text-decoration: none !important;
`;

const AvatarContainer = styled.div`
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: block;
  width: 75px;
  border-radius: 50%;
  display: flex;
  overflow: hidden;
  @media (max-width: 480px) {
    width: 75px;
    /* top: -20px; */
  }
`;

const PlayerName = styled.h3`
  /* margin: 14px 0 0; */
  white-space: nowrap;
  white-space: break-spaces;
  line-height: 1.5;
  font-size: 15px;

  @media (max-width: 480px) {
    /* margin-top: 10px; */
    /* line-height: 14px;
    margin-bottom: 10px; */
  }
`;

const PlayerNumber = styled.span`
  width: 24px;
  height: 24px;
  background: #656579;
  border-radius: 24px;
  display: block;
  position: absolute;
  top: -40px;
  left: 0;
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  @media (max-width: 480px) {
    width: 18px;
    height: 18px;
    font-size: 12px;
    line-height: 18px;
  }
`;

const Player = styled.div`
  position: relative;
  width: 100%;
  max-width: 103px;
  background: #26262e;
  box-sizing: border-box;
  margin: 75px 0 30px;
  padding: 60px 10px 45px;
  text-align: center;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:not(:first-child) {
    margin-left: 20px;
  }
  &.selected {
    background: #28302e;
  }
  @media (max-width: 480px) {
    padding: 45px 5px 25px;
    &:not(:first-child) {
      margin-left: 10px;
    }
    margin: 55px 0 30px;
    margin-bottom: 0;

    /* max-width: ${({ large }) => large && '190px'}; */
    /* padding: ${({ large }) => large && '50px 5px 15px'}; */

    ${AvatarContainer} {
      width: ${({ large }) => large && '75px'};
    }
  }
`;
