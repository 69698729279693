import { createSelector } from 'reselect';
import { always, identity, propOr, find, propEq, pick, filter } from 'ramda';
import { entityDetailGetter, entityListGetter } from '../../utils/entities';
import { getEntities } from '../entities';

export const initialState = {};

// const myGamesActiveIdsSelector = (state, { myGamesActiveIds }) => myGamesActiveIds;

function myGamesSelector(state) {
  return state.myGamesActive || initialState;
}

export const getMyGames = createSelector(
  myGamesSelector,
  identity,
);

export const getMyGamesActive = createSelector(
  getMyGames,
  (myGamesActive) => myGamesActive.active,
);

export const getMyGamesActiveList = createSelector(
  [always('contests'), getMyGamesActive, getEntities],
  entityListGetter,
);
