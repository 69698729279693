export const REFS_HOME_PAGE_FETCH = 'REFS_HOME_PAGE_FETCH';
export const AVALAIBLE_CONTESTS_FETCH = 'AVALAIBLE_CONTESTS_FETCH';

export const USER_SIGN_IN_POST = 'USER_SIGN_IN_POST';
export const USER_SIGN_UP_POST = 'USER_SIGN_UP_POST';
export const USER_UPDATE = 'USER_UPDATE';

export const SEND_EMAIL_RESTORE_PASSWORD = 'SEND_EMAIL_RESTORE_PASSWORD';
export const SEND_RESTORED_PASSWORD = 'SEND_RESTORED_PASSWORD';

export const CONTEST_FETCH = 'CONTEST_FETCH';
export const CONTEST_MAKE_PICKS_FETCH = 'CONTEST_MAKE_PICKS_FETCH';
export const CONTEST_SELECTED_PLAYER_FETCH = 'CONTEST_SELECTED_PLAYER_FETCH';
export const CONTEST_LEADER_PARTICIPATIONS_FETCH = 'CONTEST_LEADER_PARTICIPATIONS_FETCH';
export const CONTEST_LEADER_DIAGRAM_STATS_FETCH = 'CONTEST_LEADER_DIAGRAM_STATS_FETCH';
export const CONTEST_BREACKDOWN_OVERALL_FETCH = 'CONTEST_BREACKDOWN_OVERALL_FETCH';
export const CONTEST_BREAKDOWN_WEEK_PICK_FETCH = 'CONTEST_BREAKDOWN_WEEK_PICK_FETCH';

export const SIGN_OUT_FETCH = 'SIGN_OUT_FETCH';
export const DISMISS_NOTIFICATION_FETCH = 'DISMISS_NOTIFICATION_FETCH';
export const MY_GAMES_ACTIVE_FETCH = 'MY_GAMES_ACTIVE_FETCH';
export const MY_GAMES_RECENT_FETCH = 'MY_GAMES_RECENT_FETCH';
export const MY_ACCOUNT_FETCH = 'MY_ACCOUNT_FETCH';

export const SUCCESS_PLAYER_INFO_FETCH = 'SUCCESS_PLAYER_INFO_FETCH';

export const NOTIFICATIONS_FETCH_FETCH = 'NOTIFICATIONS_FETCH_FETCH';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';

export const SUBMIT = 'SUBMIT';

export const GAMES_COUNT_FETCH = 'GAMES_COUNT_FETCH';
