import React from 'react';
import styled from 'styled-components';

const propTypes = {};

function SuccessMessage({ email }) {
  return <StyledSuccessMessage>{`Instruction letter was sent to ${email}`}</StyledSuccessMessage>;
}

const StyledSuccessMessage = styled.span`
  font-size: 14px;

  @media (max-width: 658px) {
    padding: 15px 25px;
    line-height: 1.3em;
  }
`;
SuccessMessage.propTypes = propTypes;
export default SuccessMessage;
