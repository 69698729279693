import PhotoBlock1 from './PhotoBlock1';
import PhotoBlock2 from './PhotoBlock2';
import PhotoBlock3 from './PhotoBlock3';
import PhotoBlock4 from './PhotoBlock4';
import PhotoBlock5 from './PhotoBlock5';
import PhotoBlock6 from './PhotoBlock6';
import PhotoBlock7 from './PhotoBlock7';
import PhotoBlock8 from './PhotoBlock8';
import PhotoBlock9 from './PhotoBlock9';
import PhotoBlock10 from './PhotoBlock10';
import VideoPreview from './VideoPreview';
import NewEraBlock from './NewEraBlock';

export {
  PhotoBlock1,
  PhotoBlock2,
  PhotoBlock3,
  PhotoBlock4,
  PhotoBlock5,
  PhotoBlock6,
  PhotoBlock7,
  PhotoBlock8,
  PhotoBlock9,
  PhotoBlock10,
  VideoPreview,
  NewEraBlock,
};
