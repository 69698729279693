// @flow

import React from 'react';
import useWindowSize from 'v2/services/useWindowSize';
import DesktopVersion from './versions/Desktop';
import MobileVersion from './versions/Mobile';

function PromoPage() {
  const [documentWidth] = useWindowSize();
  const isMobile = documentWidth < 450;

  if (isMobile) return <MobileVersion />;
  return <DesktopVersion />;
}

export default PromoPage;
