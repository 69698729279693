import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { history } from 'v2/stores/configureStore';

import {
  prizeBlockStyles,
  prizesBlockStyles,
  prizeStyles,
  progressInfoStyles,
  progressTextStyles,
  statusStyles,
} from '../styles';
import Avatars from './Avatars';

import IconItems from './IconItems';

const propTypes = {
  kind: PropTypes.string,
};

const defaultProps = {
  kind: '',
};

function ProgressSubmit(props) {
  const { kind, league, submitPicks, totalSelectionsRequired } = props;

  const isSurvive = kind === 'survive';
  const isFootbal = league === 'nfl';
  const isSubmitAll = submitPicks === totalSelectionsRequired;

  const getAccentColor = () => {
    if (isUserWin) return '#0bd855';
    if (isUserAdvanced) return '#0bd855';

    if (isUserEliminated) return '#E80C51';
    if (!isUserWin) return '#E80C51';

    return '#FFB236';
  };
  const getPreGameText = () => {
    if (submitPicks !== totalSelectionsRequired) return 'Continue making all picks \nbefore the game starts!';

    // if (isSurvive) return 'SURVIVE & ADVANCE \nWITH  ALL CORRECT PICKS!';

    if (isFootbal) {
      if (isSurvive)
        return (
          <>
            Advance to NEXT WEEK {'\n'}IF <AccentText color={isSurvive ? '#5D5CE2' : '#0bd855'}>ALL PICKS</AccentText>{' '}
            ARE CORRECT!{' '}
          </>
        );
    }

    if (isSurvive)
      return (
        <>
          Advance to the NEXT DAY {'\n'}IF <AccentText color={isSurvive ? '#5D5CE2' : '#0bd855'}>ALL PICKS</AccentText>{' '}
          ARE CORRECT!
        </>
      );

    return 'MOST CORRECT PICKS WINS! \nVIEW YOUR PICKS';
  };

  return (
    <Fragment>
      <StyledPrizes>
        <StyledPrize borderColor={isSurvive ? '#5D5CE2' : '#0bd855'}>
          <StyledProgressInfo>
            <StyledStatus isGray>PREGAME</StyledStatus>

            <StyledProgressText>{getPreGameText()}</StyledProgressText>
          </StyledProgressInfo>

          <IconItems {...props} />
          {/* {isSurvive && (
            <StyledProgressImages isSurvive={isSurvive}>
              <Avatars kind={kind} isSubmitAll={isSubmitAll} />
            </StyledProgressImages>
          )} */}
        </StyledPrize>
      </StyledPrizes>
    </Fragment>
  );
}

const StyledStatus = styled.span`
  ${statusStyles}
`;

const StyledPrizes = styled.div`
  ${prizesBlockStyles}
  height: 100%;
`;

const StyledPrize = styled.div`
  ${prizeBlockStyles}
  ${prizeStyles}
  min-height: 90px;
`;

const StyledProgressText = styled.span`
  ${progressTextStyles}
  white-space: pre;
`;

const StyledProgressInfo = styled.div`
  ${progressInfoStyles}
`;

const StyledProgressImages = styled.div`
  position: relative;
  width: 100%;
  max-width: ${(props) => (props.isSurvive ? '70px' : '90px')};
  height: 50px;
`;

const AccentText = styled.span`
  color: ${({ color }) => color};
  font-size: 14px;
`;

ProgressSubmit.propTypes = propTypes;
ProgressSubmit.defaultProps = defaultProps;

export default ProgressSubmit;
