import React, { Fragment, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { not, length, head, map, prop, compose, find, propEq, findIndex } from 'ramda';
import styled from 'styled-components';
import Slider from 'react-slick';
import useWindowSize from 'v2/services/useWindowSize';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Dots, Arrow } from './shared';

import GameCard from '../GameCard';

const propTypes = {
  gamesCollection: PropTypes.arrayOf(PropTypes.shape({})),
  session: PropTypes.shape({}).isRequired,
};

const defaultProps = {
  gamesCollection: [],
};

const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  rows: 1,
  centerPadding: '0',
  dots: true,
  draggable: true,
  swipeToSlide: true,
  swipe: true,
  arrows: true,
  nextArrow: <Arrow />,
  prevArrow: <Arrow />,
  appendDots: (dots) => <Dots dots={dots} />,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        variableWidth: false,
        centerPadding: '0px',
        dots: true,
        rows: 1,
        swipeToSlide: true,
        draggable: true,
        swipe: true,
        appendDots: (dots) => <Dots dots={dots} />,
      },
    },
  ],
};

function SliderGameCard(props) {
  const { gamesCollection, session, activeId, showHeadshots } = props;
  const sliderRef = useRef();

  const [documentWidth] = useWindowSize();
  const isMobile = documentWidth < 480;

  useEffect(() => {
    if (activeId && gamesCollection && gamesCollection.length > 0) {
      const activeIndex = findIndex(propEq('id', Number(activeId)))(gamesCollection);
      if (sliderRef && sliderRef.current && activeIndex && activeIndex !== -1) {
        sliderRef.current.slickGoTo(activeIndex);
      }
    }
  }, [activeId, gamesCollection]);

  useEffect(() => {
    if (sliderRef && sliderRef.current) {
      sliderRef.current.slickGoTo(0);
    }
  }, [gamesCollection]);

  useEffect(() => {}, [sliderRef]);

  function addclass(el) {
    el.className += ' ' + 'slick-extra-wrapper';
  }

  useEffect(() => {
    try {
      map(compose(addclass, head, prop('childNodes')))(document.getElementsByClassName('slick-slide'));
    } catch (error) {
      console.log(error);
    }
  }, [gamesCollection]);

  return (
    <Fragment>
      {isMobile && (
        <SliderWrapGameCard>
          <Slider {...settings} ref={sliderRef}>
            {gamesCollection.map((game, index) => (
              <WrapCard
                data-index={index}
                key={index}
                className="slider_wrap slider_wrap__card"
                style={{ height: '100%' }}
              >
                <GameCard session={session} {...game} showHeadshots={showHeadshots} showFAQ={prop('showFAQ', props)} />
              </WrapCard>
            ))}
          </Slider>
          {/* {gamesCollection && gamesCollection.length > 1 && <GameCardDotstext>Swipe for more games</GameCardDotstext>} */}
        </SliderWrapGameCard>
      )}

      {!isMobile && (
        <GameCollectionDesctop style={not(isMobile) && length(gamesCollection) < 3 ? { justifyContent: 'center' } : {}}>
          {gamesCollection.map((game) => (
            <GameCard key={game.id} session={session} {...game} showHeadshots={showHeadshots} />
          ))}
        </GameCollectionDesctop>
      )}
    </Fragment>
  );
}

const SliderWrapGameCard = styled.div`
  /* padding-bottom: 25px; */
  .slick-track {
    display: flex;

    .slick-slide {
      height: auto;
    }
  }

  padding-bottom: 10px;
  background-image: linear-gradient(
    to bottom,
    #131317,
    #131317,
    #131317,
    #131317,
    #131317,
    #141418,
    #141418,
    #151519,
    #17171b,
    #18181d,
    #191a1f,
    #1b1b21
  );
  /* padding-bottom: 20px; */
  border-radius: 8px;
  @media (min-width: 768px) {
    display: none;
  }
`;

const GameCollectionDesctop = styled.div`
  display: none;
  max-width: 1110px;
  margin: auto;
  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;

    ${'' /* justify-content: space-between; */}
  }
`;

const GameCardDotstext = styled.div`
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #656579;
  margin-top: 15px;
  @media (min-width: 768px) {
    display: none;
  }
`;

const WrapCard = styled.div`
  height: 100% !important;
`;

SliderGameCard.propTypes = propTypes;
SliderGameCard.defaultProps = defaultProps;

export default React.memo(SliderGameCard);
