// @flow

import React, { Fragment, useState, useMemo } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { getMyGamesRecentList } from 'v2/selectors/myGamesRecent';
import { getMyGamesActiveList } from 'v2/selectors/myGamesActive';

import { Counter } from 'v2/components/shared';

import GameCard from '../../../shared/GameCard';

type GamesFilterProps = {
  myGamesRecentList?: Array<any>,
  myGamesActiveList?: Array<any>,
};
// const propTypes = {};

const defaultProps = {
  myGamesRecentList: [],
  myGamesActiveList: [],
};

function GamesFilter({ myGamesLink, session, myGamesRecentList, myGamesActiveList }: GamesFilterProps) {
  const [isActive, setIsActive] = useState(true);
  const [isLive, setIsLive] = useState(false);

  const toogleCheckBox = () => {
    isLive ? setIsLive(false) : setIsLive(true);
  };

  // const [gamesActiveLive, gamesActiveAll] = R.partition(R.where({ live: R.equals(false) }), myGamesActive);
  // const currentGamesActiveCollection = useMemo(() => (isLive ? gamesActiveAll : gamesActiveLive), [
  //   isLive,
  //   gamesActiveLive,
  //   gamesActiveAll,
  // ]);

  return (
    <Fragment>
      <StyledGamesFilter>
        <GamesStatus>
          <Status
            className={isActive ? 'active' : ''}
            onClick={() => {
              setIsActive(true);
            }}
          >
            <span>active</span>
            <Counter isUnActive={!isActive}>{myGamesActiveList.length}</Counter>
          </Status>
          <Status
            className={!isActive ? 'active' : ''}
            onClick={() => {
              setIsActive(false);
            }}
          >
            <span>recent</span>
            <Counter isUnActive={isActive}>{myGamesRecentList.length}</Counter>
          </Status>
        </GamesStatus>
        {/* {isActive ? (
          <FilterCheckBox>
            <CheckBoxText>Live</CheckBoxText>
            <input id="toggle" type="checkbox" defaultChecked={!isLive} onChange={toogleCheckBox} />
            <label className="toogle--off" for="toggle" />
          </FilterCheckBox>
        ) : (
          ''
        )} */}
      </StyledGamesFilter>
      <GamesColletion>
        {isActive
          ? myGamesActiveList &&
            myGamesActiveList.map((game) => (
              <GameCard key={game.id} {...game} session={session} gameState="active" myGamesLink />
            ))
          : myGamesRecentList &&
            myGamesRecentList.map((game) => (
              <GameCard key={game.id} {...game} session={session} gameState="recent" myGamesLink />
            ))}
      </GamesColletion>
    </Fragment>
  );
}

const StyledGamesFilter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  padding-right: 15px;
  border-bottom: 1px solid #26262e;
  @media (max-width: 768px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    background: #131317;
    z-index: 100;
    border-top: 1px solid #26262e;
  }
`;

const GamesStatus = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    background: #131317;
    z-index: 100;
    border-top: 1px solid #26262e;
  }
`;

const FilterCheckBox = styled.div`
  display: flex;
  padding: 0;
  @media (max-width: 768px) {
    justify-content: space-between;
    width: 100%;
    padding: 0 15px;
  }
`;

const CheckBoxText = styled.div`
  margin-right: 30px;
  text-transform: uppercase;
  font-weight: 700;
`;

const Status = styled.div`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-weight: 700;
  color: #656579;
  margin-right: 20px;
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    color: #fff;
  }
  &.active {
    span {
      color: #fff;
    }
    position: relative;
    color: #fff;
    &:before {
      position: absolute;
      content: '';
      height: 4px;
      width: 100%;
      bottom: -15px;
      background: #5d5ce2;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      @media (max-width: 768px) {
        bottom: 0;
        left: 0;
      }
    }
  }
  @media (max-width: 768px) {
    display: flex;
    justify-content: flex-start;
    width: 50%;
    padding: 20px;
    text-align: center;
    margin: 0;
    span {
      margin-right: 10px;
    }
    ${'' /* &:hover {
      background: #1b1b21;
    } */}
  }
`;

const GamesColletion = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

// GamesFilter.propTypes = propTypes;
GamesFilter.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  myGamesRecentList: getMyGamesRecentList(state),
  myGamesActiveList: getMyGamesActiveList(state),
});

export default connect(mapStateToProps)(GamesFilter);
