import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { RESTORE_PASSWORD_PATH } from 'v2/constants/router';
function ForgotLink() {
  return <StyledForgotLink to={RESTORE_PASSWORD_PATH}>Forgot?</StyledForgotLink>;
}

const StyledForgotLink = styled(NavLink)`
  color: #0bd855;
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  cursor: pointer;
  text-decoration: none !important;
  transition: filter 0.2s linear;
  filter: brightness(95%);

  &:hover {
    filter: brightness(105%);
  }
`;

export default ForgotLink;
