import React from 'react';
import styled from 'styled-components';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import * as actions from 'v2/actions';
import FormGroupAdapter from 'v2/components/shared/formDefault/FormGroup/Adapter';
import Input from 'v2/components/shared/formDefault/Input';
import Button from 'v2/components/shared/formDefault/Button';
import ErrorMessage from 'v2/components/shared/formDefault/ErrorMessage';
import { composeValidators, validateRequired, validateEmail } from 'v2/components/shared/formDefault/validations';

const defaultProps = {
  contest: null,
};

const propTypes = {
  contest: PropTypes.shape({
    id: PropTypes.number.isRequired,
    urlContest: PropTypes.shape({
      categoryId: PropTypes.number.isRequired,
      weekOrder: PropTypes.number.isRequired,
    }),
  }),
  postSignUpCredentials: PropTypes.func.isRequired,
};

function FirstStep({ postSignUpCredentials, contest }) {
  return (
    <Form
      initialValues={{ contest }}
      onSubmit={(props) => postSignUpCredentials(props)}
      validate={({ userSession }) => {
        const errors = {};

        if (userSession) {
          if (userSession.passwordConfirmation !== userSession.password) {
            errors.userSession = { passwordConfirmation: 'Must match' };
          }
        }

        return errors;
      }}
      render={({ handleSubmit, submitError, hasSubmitErrors, submitting }) => (
        <StyledForm onSubmit={handleSubmit}>
          <FieldsCollection>
            <Field
              component={FormGroupAdapter}
              inputComponent={Input}
              name="userSession.email"
              help=""
              placeholder="Email"
              type="email"
              label="Email"
              validate={composeValidators(validateRequired(), validateEmail())}
            />

            <Field
              component={FormGroupAdapter}
              inputComponent={Input}
              name="userSession.password"
              placeholder="Your password"
              type="password"
              label="Password"
              validate={validateRequired()}
            />

            <Field
              component={FormGroupAdapter}
              inputComponent={Input}
              name="userSession.passwordConfirmation"
              placeholder="Retype password"
              type="password"
              label="Confirm password"
              validate={validateRequired()}
            />
            {hasSubmitErrors && submitError && (
              <ErrorMessageWrapper>
                <ErrorMessage>{submitError}</ErrorMessage>
              </ErrorMessageWrapper>
            )}
          </FieldsCollection>

          <Button type="submit" disable={submitting}>
            {submitting ? 'Loading...' : 'Next Step'}
          </Button>
        </StyledForm>
      )}
    />
  );
}

const ErrorMessageWrapper = styled.div`
  /* margin-bottom: 25px; */
  text-align: center;
`;

const FieldsCollection = styled.div`
  margin-bottom: 50px;
`;

const StyledForm = styled.form`
  width: 100%;
  margin-bottom: 25px;
`;

const mapDispatchToProps = (dispatch) => ({
  postSignUpCredentials: (userData) => dispatch(actions.signUp(userData)),
});

const enhance = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
);
FirstStep.defaultProps = defaultProps;
FirstStep.propTypes = propTypes;
export default enhance(FirstStep);
