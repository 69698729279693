import { MY_GAMES_ACTIVE } from 'v2/constants/router';

import { getRequest } from '../requests';
import fetch from '../fetch';

export default class MyGamesActiveApiAdapter {
  static getMyGamesActiveApiAdapter() {
    return fetch(MY_GAMES_ACTIVE, getRequest());
  }
}
