// ------------------------- nfl start ---------------------------
import nflPassingTds from 'v2/components/shared/Image/categoryIcons/nfl/icons_passing_tds_orange.svg';

// import passingYdsBlue from 'v2/components/shared/Image/categoryIcons/nfl/icons_passing_yds/blue.svg';
// import passingYdsGreen from 'v2/components/shared/Image/categoryIcons/nfl/icons_passing_yds/green.svg';
import nflPassingYdsOrange from 'v2/components/shared/Image/categoryIcons/nfl/icons_passing_yds/orange.svg';

// import receivingTdsBlue from 'v2/components/shared/Image/categoryIcons/nfl/icons_receiving_tds/blue.svg';
import nflReceivingTdsGreen from 'v2/components/shared/Image/categoryIcons/nfl/icons_receiving_tds/green.svg';

// import receivingYdsBlue from 'v2/components/shared/Image/categoryIcons/nfl/icons_receiving_yds/blue.svg';
import nflReceivingYdsGreen from 'v2/components/shared/Image/categoryIcons/nfl/icons_receiving_yds/green.svg';

import nflRushingTdsBlue from 'v2/components/shared/Image/categoryIcons/nfl/icons_rushing_tds/blue.svg';
// import rushingTdsGreen from 'v2/components/shared/Image/categoryIcons/nfl/icons_rushing_tds/green.svg';

import nflRushingYdsBlue from 'v2/components/shared/Image/categoryIcons/nfl/icons_rushing_yds/blue.svg';
// import rushingYdsGreen from 'v2/components/shared/Image/categoryIcons/nfl/icons_rushing_yds/green.svg';

// ------------------------- nfl end ---------------------------

// ------------------------- nba start ---------------------------
import nbaDoubleDouble from 'v2/components/shared/Image/categoryIcons/nba/double-double/basketball_double_double.svg';
import nbaPoints from 'v2/components/shared/Image/categoryIcons/nba/points/basketball_points.svg';
import nbaRebounds from 'v2/components/shared/Image/categoryIcons/nba/rebounds/basketball_rebounds.svg';
import nbaAssists from 'v2/components/shared/Image/categoryIcons/nba/assists/basketball_assists.svg';
import nba3PointersMade from 'v2/components/shared/Image/categoryIcons/nba/3-pointer/basketball_3pointer.svg';
import nba3Steals from 'v2/components/shared/Image/categoryIcons/nba/steals/basketball_steals.svg';
// ------------------------- nba end ---------------------------

// ------------------------- mlb start ---------------------------
import mlbHits from 'v2/components/shared/Image/categoryIcons/mlb/hits.svg';
import mlbHomerun from 'v2/components/shared/Image/categoryIcons/mlb/homerun.svg';
// import mlbRbis from 'v2/components/shared/Image/categoryIcons/mlb/rbis.svg';
// import mlbRuns from 'v2/components/shared/Image/categoryIcons/mlb/runs.svg';
import mlbSaves from 'v2/components/shared/Image/categoryIcons/mlb/saves.svg';
import mlbStolenBase from 'v2/components/shared/Image/categoryIcons/mlb/stolen-base.svg';
import mlbStrikeout2 from 'v2/components/shared/Image/categoryIcons/mlb/strikeout_2.svg';
import mlbWins from 'v2/components/shared/Image/categoryIcons/mlb/wins.svg';
// ------------------------- nba end ---------------------------

import { target, targetSuccses } from '../Image';

const categoryIcons = {
  nfl: [
    {
      id: 0,
      subject: 'PASSING TDS',
      icons: {
        primary: nflPassingTds,
        active: nflPassingTds,
      },
    },
    {
      id: 1,
      subject: 'PASSING YARDS',
      icons: {
        primary: nflPassingYdsOrange,
        // active: passingYdsGreen,
      },
    },
    {
      id: 2,
      subject: 'RECEIVING TDS',
      icons: {
        primary: nflReceivingTdsGreen,
        // active: nflReceivingTdsGreen,
      },
    },
    {
      id: 3,
      subject: 'RECEIVING YARDS',
      icons: {
        primary: nflReceivingYdsGreen,
        // active: nflReceivingYdsGreen,
      },
    },
    {
      id: 4,
      subject: 'RUSHING TDS',
      icons: {
        primary: nflRushingTdsBlue,
        // active: rushingTdsGreen,
      },
    },
    {
      id: 5,
      subject: 'RUSHING YARDS',
      icons: {
        primary: nflRushingYdsBlue,
        // active: rushingYdsGreen,
      },
    },
  ],
  nba: [
    {
      id: 0,
      subject: 'DOUBLE-DOUBLE',
      icons: {
        primary: nbaDoubleDouble,
      },
    },
    {
      id: 1,
      subject: 'POINTS',
      icons: {
        primary: nbaPoints,
      },
    },
    {
      id: 2,
      subject: 'REBOUNDS',
      icons: {
        primary: nbaRebounds,
      },
    },
    {
      id: 3,
      subject: 'ASSISTS',
      icons: {
        primary: nbaAssists,
      },
    },
    {
      id: 4,
      subject: 'THREE-POINTERS',
      icons: {
        primary: nba3PointersMade,
      },
    },
    {
      id: 5,
      subject: 'STEALS',
      icons: {
        primary: nba3Steals,
      },
    },
  ],
  mlb: [
    {
      id: 0,
      subject: 'HITS',
      icons: {
        primary: mlbHits,
      },
    },
    {
      id: 1,
      subject: 'HOMERUNS',
      icons: {
        primary: mlbHomerun,
      },
    },
    {
      id: 2,
      subject: 'STOLEN BASES',
      icons: {
        primary: mlbStolenBase,
      },
    },
    {
      id: 3,
      subject: 'WINS',
      icons: {
        primary: mlbWins,
      },
    },
    {
      id: 4,
      subject: 'SAVES',
      icons: {
        primary: mlbSaves,
      },
    },
    {
      id: 5,
      subject: 'STRIKEOUTS',
      icons: {
        primary: mlbStrikeout2,
      },
    },
  ],
};

function getIcon({ subject, isFill, league }) {
  const findCategory = categoryIcons[league] && categoryIcons[league].find((category) => category.subject === subject);

  if (findCategory) {
    const {
      icons: { primary },
    } = findCategory;

    // return isFill ? active : primary;
    return primary;
  }

  return isFill ? targetSuccses : target;
}

export default getIcon;
