import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

// type TabItemProps = {
//   url: string,
//   title: string,
//   active: boolean,
// };

function TabItem({ url, title, active }) {
  return (
    <StyledTabItem to={url} active={active}>
      {title}
    </StyledTabItem>
  );
}

const StyledTabItem = styled(NavLink)`
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding: 0 0 15px;
  position: relative;
  text-decoration: none !important;
  transition: filter 0.35s ease-out;
  filter: brightness(65%);
  filter: ${({ active }) => active && 'brightness(110%) !important'};

  &:before {
    content: '';
    display: none;
    display: ${({ active }) => active && 'block'};
    background: #5d5ce2;
    height: 3px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 100px 100px 0 0;
  }

  &:hover {
    filter: brightness(85%);
  }

  @media (max-width: 475px) {
    font-size: 14px;
  }
`;

export default TabItem;
