// @flow

import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { getContestDetailLeague } from 'v2/selectors/contests';
import { connect } from 'react-redux';
import PlayerHeadshots from 'v2/constants/UI/playerHeadshots';

// import { defaultAvatar } from '../Image';

const defaultProps = {
  league: null,
  alt: 'avatar',
};

type AvatarProps = {
  league?: string,
  source: string,
  alt?: string,
};
const getDefaultHeadshot = (league) => R.prop(league)(PlayerHeadshots) || R.prop('default')(PlayerHeadshots);
function Avatar({ league, source, alt }: AvatarProps) {
  const [userAvatar, setUserAvatar] = useState(source || getDefaultHeadshot(league));

  useEffect(() => {
    setUserAvatar(source || getDefaultHeadshot(league));
  }, [source]);

  const getDeaultUserAvatarByLeague = (): void => {
    setUserAvatar(() => getDefaultHeadshot(league));
  };

  return <AvatarImage src={userAvatar} alt={alt} onError={() => getDeaultUserAvatarByLeague()} />;
}

const AvatarImage = styled.img`
  width: 100%;
  border-radius: 50%;
  align-self: flex-start;
`;

Avatar.defaultProps = defaultProps;

const mapStateToProps = (state, props) => ({
  league: props.league || getContestDetailLeague(state),
});

export default connect(mapStateToProps)(Avatar);
