import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useLocation, withRouter } from 'react-router';

const tabs = [
  {
    id: 0,
    title: 'AVAILABLE GAMES',
    type: 'all',
  },
  {
    id: 1,
    title: 'my games',
    type: 'my_games',
  },
  // {
  //   id: 2,
  //   title: 'Available games',
  //   type: 'available_games',
  // },
];

// type MainTabsProps = {
// }

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function MainTabs() {
  const query = useQuery();
  const currentType = query.get('type');

  return (
    <TabsCollection>
      {tabs.map((tab) => (
        <Tab to={`?type=${tab.type}`} active={currentType ? currentType === tab.type : tab.type === 'all'} key={tab.id}>
          {tab.title}
        </Tab>
      ))}
    </TabsCollection>
  );
}

const Tab = styled(NavLink)`
  font-size: 18px;
  font-weight: bold;
  text-decoration: none !important;
  margin-left: 25px;
  padding-bottom: 15px;
  transition: filter 0.2s ease-out;
  position: relative;

  &:hover {
    filter: brightness(65%);
  }

  /* &:last-child {
    margin-right: 0;
  } */

  &:before {
    content: '';
    display: block;
    position: absolute;
    transform: translate(-10%, -20%);
    width: 150%;
    height: 150%;
  }

  &:after {
    content: ${({ active }) => active && "''"};
    display: block;
    position: absolute;
    bottom: 0;
    width: 80%;
    left: 50%;
    transform: translate(-50%, 0);
    height: 5px;
    background: #5d5ce2;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
  }
`;

const TabsCollection = styled.div`
  display: inline-flex;
  transition: filter 0.2s ease-out;

  /* &:hover {
    filter: brightness(75%);
  } */
`;

export default withRouter(MainTabs);
