import React from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation, withRouter } from 'react-router';
import styled from 'styled-components';

const TABS = [
  {
    key: 0,
    title: 'Active',
    status: 'active',
    activeBydefault: true,
  },
  {
    key: 1,
    title: 'Recent',
    status: 'recent',
  },
];

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function StatusGameNavigation({ location: { search } }) {
  const query = useQuery();
  const currentStatus = query.get('status');
  const currentkind = query.get('kind');
  const htefTo = currentkind ? `?kind=${currentkind}&` : '?';

  return (
    <Tabs>
      {TABS.map(({ title, status, activeBydefault, ...recent }) => (
        <Tab
          to={`${htefTo}status=${status}`}
          active={currentStatus === status || (activeBydefault && !currentStatus)}
          {...recent}
        >
          {title}
        </Tab>
      ))}
    </Tabs>
  );
}

const Tab = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 50px;
  border-radius: 19px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none !important;

  background: ${({ active }) => active && '#5d5ce2'};
`;

const Tabs = styled.div`
  display: flex;

  @media (max-width: 425px) {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #131317;
    z-index: 9;
    padding: 8px 15px;
    width: 100%;
    box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.25);

    ${Tab} {
      padding: 12px 50px;
      width: 100%;
    }
  }
`;

export default withRouter(StatusGameNavigation);
