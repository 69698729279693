import React from 'react';
import styled from 'styled-components';

import Title from 'v2/components/shared/Title';
import StatusGameNavigation from '../StatusGameNavigation';

function DesktopDescription() {
  return (
    <DesktopDescriptionContainer>
      <DesktopText>
        <Title text="My Games" />
        {/* <p>All available contests</p> */}
        <StatusGameNavigationWrapper>
          <StatusGameNavigation />
        </StatusGameNavigationWrapper>
      </DesktopText>
      <Separator />
    </DesktopDescriptionContainer>
  );
}

export default DesktopDescription;

const StatusGameNavigationWrapper = styled.div`
  margin-top: 25px;
`;

const DesktopText = styled.div`
  padding: 0 25px;
`;

const Separator = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  background-color: #26262e;
  bottom: 0;
  margin-top: 20px;
  margin-bottom: 15px;
`;

const DesktopDescriptionContainer = styled.div`
  padding: 0 25px;

  @media (max-width: 425px) {
    display: none;
  }
`;
