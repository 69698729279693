import React from 'react';
import PhotoBlock from 'v2/components/PromoPages/shared/PhotoBlock';
import PlayNowButton from 'v2/components/PromoPages/shared/PlayVideoButton';
import BlockDescription from 'v2/components/PromoPages/2/shared/DescriptionBlock';
import videoPreviewImg from '../../img/main_video@2x.png';

const defaultProps = {
  showText: true,
};

function block({ showText }) {
  return (
    <PhotoBlock.Wrapper style={{ maxWidth: '100%', position: 'relative' }}>
      <PlayNowButton videoId="274739963" />
      <PhotoBlock src={videoPreviewImg} contentDirection="bottom">
        {showText && (
          <BlockDescription size="large" style={{ marginBottom: '20px', textShadow: '-2px 1px 4px rgba(0,0,0,0.7)' }}>
            WIN VIP EXPERIENCES,{'\n'}
            AUTOGRAPHED MEMORABILIA,{'\n'}
            CASH PRIZES & MORE!
          </BlockDescription>
        )}
      </PhotoBlock>
    </PhotoBlock.Wrapper>
  );
}

block.defaultProps = defaultProps;
export default block;
