import React from 'react';

import defaultImg from 'v2/components/shared/Image/noGamesIcons/no_games_americanfootball.svg';
import nonoBaseballGames from 'v2/components/shared/Image/noGamesIcons/no_games_baseball.svg';

import noGamesNbaSurvive from 'v2/components/shared/Image/noGamesIcons/noGames/nba/survive_wt.svg';
import noGamesNbaWeekly from 'v2/components/shared/Image/noGamesIcons/noGames/nba/weekly_wt.svg';
import noGamesNflSurvive from 'v2/components/shared/Image/noGamesIcons/noGames/nfl/survive_wt.svg';
import noGamesNflWeekly from 'v2/components/shared/Image/noGamesIcons/noGames/nfl/weekly_wt.svg';

import noGamesMlbSurvive from 'v2/components/shared/Image/noGamesIcons/noGames/mlb/survive_wt.svg';
import noGamesMlbWeekly from 'v2/components/shared/Image/noGamesIcons/noGames/mlb/weekly_wt.svg';

import joinedAllAvailableGamesNflSurvive from 'v2/components/shared/Image/noGamesIcons/joinedAllAvailableGames/nfl/new_survive.svg';
import joinedAllAvailableGamesNflWeekly from 'v2/components/shared/Image/noGamesIcons/joinedAllAvailableGames/nfl/new_survive.svg';

import joinedAllAvailableGamesNbaSurvive from 'v2/components/shared/Image/noGamesIcons/joinedAllAvailableGames/nba/new_survive.svg';
import joinedAllAvailableGamesNbaWeekly from 'v2/components/shared/Image/noGamesIcons/joinedAllAvailableGames/nba/new_survive.svg';

import joinedAllAvailableGamesMlbSurvive from 'v2/components/shared/Image/noGamesIcons/joinedAllAvailableGames/mlb/survive_wt.svg';
import joinedAllAvailableGamesMlbWeekly from 'v2/components/shared/Image/noGamesIcons/joinedAllAvailableGames/mlb/weekly_wt.svg';

import noGamesJoinedNflSurvive from 'v2/components/shared/Image/noGamesIcons/noGamesJoined/nfl/survive_wt.svg';
import noGamesJoinedNbaSurvive from 'v2/components/shared/Image/noGamesIcons/noGamesJoined/nba/new_survive.svg';
import noGamesJoinedMlbSurvive from 'v2/components/shared/Image/noGamesIcons/noGamesJoined/mlb/new_survive.svg';

import checkBackNflSurvive from 'v2/components/shared/Image/noGamesIcons/checkBack/nfl/survive.svg';
import checkBackNbaSurvive from 'v2/components/shared/Image/noGamesIcons/checkBack/nba/sirvive.svg';

import { JoinedAllAvailableGamesText, NoGames, NoGamesJoined } from 'v2/components/noGamesTexts';

const noGamesIcons = {
  noGames: {
    all: {
      survive: {
        logo: { src: noGamesNflSurvive, alt: 'no games' },
        link: '#',
        text: () => <NoGames league="nfl" kind="survive" size="large" />,
      },
      weekly: {
        logo: { src: noGamesNflWeekly, alt: 'no games' },
        link: '#',
        text: () => <NoGames league="nfl" kind="weekly" size="large" />,
      },
      all: {
        logo: { src: noGamesNflWeekly, alt: 'no games' },
        link: '#',
        text: () => <NoGames league="nfl" kind="weekly" size="large" />,
      },
    },
    nfl: {
      survive: {
        logo: { src: noGamesNflSurvive, alt: 'no games' },
        link: '#',
        text: () => <NoGames league="nfl" kind="survive" size="large" />,
      },
      weekly: {
        logo: { src: noGamesNflWeekly, alt: 'no games' },
        link: '#',
        text: () => <NoGames league="nfl" kind="weekly" size="large" />,
      },
      all: {
        logo: { src: noGamesNflWeekly, alt: 'no games' },
        link: '#',
        text: () => <NoGames league="nfl" kind="weekly" size="large" />,
      },
    },
    nba: {
      survive: {
        logo: { src: noGamesNbaSurvive, alt: 'no games' },
        link: '#',
        text: () => <NoGames league="nba" kind="survive" size="large" />,
      },
      weekly: {
        logo: { src: noGamesNbaWeekly, alt: 'no games' },
        link: '#',
        text: () => <NoGames league="nba" kind="weekly" size="large" />,
      },
      all: {
        logo: { src: noGamesNbaWeekly, alt: 'no games' },
        link: '#',
        text: () => <NoGames league="nba" kind="weekly" size="large" />,
      },
    },
    mlb: {
      survive: {
        logo: { src: noGamesMlbSurvive, alt: 'no games' },
        link: '#',
        text: () => <NoGames league="mlb" kind="survive" size="large" />,
      },
      weekly: {
        logo: { src: noGamesMlbWeekly, alt: 'no games' },
        link: '#',
        text: () => <NoGames league="mlb" kind="weekly" size="large" />,
      },
      all: {
        logo: { src: noGamesMlbWeekly, alt: 'no games' },
        link: '#',
        text: () => <NoGames league="mlb" kind="weekly" size="large" />,
      },
    },
  },
  joinedAllAvailableGames: {
    all: {
      survive: {
        logo: { src: joinedAllAvailableGamesNflSurvive, alt: 'no games' },
        link: '/my_games?kind=survive&league=nfl',
        text: () => <JoinedAllAvailableGamesText league="nfl" kind="survive" />,
      },
      weekly: {
        logo: { src: joinedAllAvailableGamesNflWeekly, alt: 'no games' },
        link: '/my_games?kind=weekly&league=nfl',
        text: () => <JoinedAllAvailableGamesText league="nfl" kind="weekly" />,
      },
      all: {
        logo: { src: joinedAllAvailableGamesNflWeekly, alt: 'no games' },
        link: '/my_games?kind=weekly&league=nfl',
        text: () => <JoinedAllAvailableGamesText league="nfl" kind="weekly" />,
      },
    },
    nfl: {
      survive: {
        logo: { src: joinedAllAvailableGamesNflSurvive, alt: 'no games' },
        link: '/my_games?kind=survive&league=nfl',
        text: () => <JoinedAllAvailableGamesText league="nfl" kind="survive" />,
      },
      weekly: {
        logo: { src: joinedAllAvailableGamesNflWeekly, alt: 'no games' },
        link: '/my_games?kind=weekly&league=nfl',
        text: () => <JoinedAllAvailableGamesText league="nfl" kind="weekly" />,
      },
      all: {
        logo: { src: joinedAllAvailableGamesNflWeekly, alt: 'no games' },
        link: '/my_games?kind=weekly&league=nfl',
        text: () => <JoinedAllAvailableGamesText league="nfl" kind="weekly" />,
      },
    },
    nba: {
      survive: {
        logo: { src: joinedAllAvailableGamesNbaSurvive, alt: 'no games' },
        link: '/my_games?kind=survive&league=nba',
        text: () => <JoinedAllAvailableGamesText league="nba" kind="survive" />,
      },
      weekly: {
        logo: { src: joinedAllAvailableGamesNbaWeekly, alt: 'no games' },
        link: '/my_games?kind=weekly&league=nba',
        text: () => <JoinedAllAvailableGamesText league="nba" kind="weekly" />,
      },
      all: {
        logo: { src: joinedAllAvailableGamesNbaWeekly, alt: 'no games' },
        link: '/my_games?kind=weekly&league=nba',
        text: () => <JoinedAllAvailableGamesText league="nba" kind="weekly" />,
      },
    },
    mlb: {
      survive: {
        logo: { src: joinedAllAvailableGamesMlbSurvive, alt: 'no games' },
        link: '/my_games?kind=survive&league=mlb',
        text: () => <JoinedAllAvailableGamesText league="mlb" kind="survive" />,
      },
      weekly: {
        logo: { src: joinedAllAvailableGamesMlbWeekly, alt: 'no games' },
        link: '/my_games/?kind=survive&league=mlb',
        text: () => <JoinedAllAvailableGamesText league="mlbl" kind="weekly" />,
      },
      all: {
        logo: { src: joinedAllAvailableGamesMlbWeekly, alt: 'no games' },
        link: '/my_games/?kind=survive&league=mlb',
        text: () => <JoinedAllAvailableGamesText league="mlbl" kind="weekly" />,
      },
    },
  },

  // my games
  noGamesJoined: {
    all: {
      survive: {
        logo: { src: noGamesJoinedNflSurvive, alt: 'no games' },
        link: '/?kind=survive&league=nfl',
        text: () => <NoGamesJoined league="nfl" kind="survive" />,
      },
      weekly: {
        logo: { src: noGamesJoinedNflSurvive, alt: 'no games' },
        link: '/?kind=weekly&league=nfl',
        text: () => <NoGamesJoined league="nfl" kind="weekly" />,
      },
      all: {
        logo: { src: noGamesJoinedNflSurvive, alt: 'no games' },
        link: '/?kind=weekly&league=nfl',
        text: () => <NoGamesJoined league="nfl" kind="weekly" />,
      },
    },
    nfl: {
      survive: {
        logo: { src: noGamesJoinedNflSurvive, alt: 'no games' },
        link: '/?kind=survive&league=nfl',
        text: () => <NoGamesJoined league="nfl" kind="survive" />,
      },
      weekly: {
        logo: { src: noGamesJoinedNflSurvive, alt: 'no games' },
        link: '/?kind=weekly&league=nfl',
        text: () => <NoGamesJoined league="nfl" kind="weekly" />,
      },
      all: {
        logo: { src: noGamesJoinedNflSurvive, alt: 'no games' },
        link: '/?kind=weekly&league=nfl',
        text: () => <NoGamesJoined league="nfl" kind="weekly" />,
      },
    },
    nba: {
      survive: {
        logo: { src: noGamesJoinedNbaSurvive, alt: 'no games' },
        link: '/?kind=survive&league=nba',
        text: () => <NoGamesJoined league="nba" kind="survive" />,
      },
      weekly: {
        logo: { src: noGamesJoinedNbaSurvive, alt: 'no games' },
        link: '/?kind=weekly&league=nba',
        text: () => <NoGamesJoined league="nba" kind="weekly" />,
      },
      all: {
        logo: { src: noGamesJoinedNbaSurvive, alt: 'no games' },
        link: '/?kind=weekly&league=nba',
        text: () => <NoGamesJoined league="nba" kind="weekly" />,
      },
    },
    mlb: {
      survive: {
        logo: { src: noGamesJoinedMlbSurvive, alt: 'no games' },
        link: '/?kind=survive&league=mlb',
        text: () => <NoGamesJoined league="mlb" kind="survive" />,
      },
      weekly: {
        logo: { src: noGamesJoinedMlbSurvive, alt: 'no games' },
        link: '/?kind=weekly&league=mlb',
        text: () => <NoGamesJoined league="mlb" kind="weekly" />,
      },
      all: {
        logo: { src: noGamesJoinedMlbSurvive, alt: 'no games' },
        link: '/?kind=weekly&league=mlb',
        text: () => <NoGamesJoined league="mlb" kind="weekly" />,
      },
    },
  },

  // breakdown
  checkBack: {
    all: {
      survive: {
        logo: { src: checkBackNflSurvive, alt: 'check back when game starts' },
        link: '/?kind=survive&league=nfl',
      },
      weekly: {
        logo: { src: checkBackNflSurvive, alt: 'check back when game starts' },
        link: '/?kind=weekly&league=nfl',
      },
    },
    nfl: {
      survive: {
        logo: { src: checkBackNflSurvive, alt: 'check back when game starts' },
        link: '/?kind=survive&league=nfl',
      },
      weekly: {
        logo: { src: checkBackNflSurvive, alt: 'check back when game starts' },
        link: '/?kind=weekly&league=nfl',
      },
    },
    nba: {
      survive: {
        logo: { src: checkBackNbaSurvive, alt: 'check back when game starts' },
        link: '/?kind=survive&league=nba',
      },
      weekly: {
        logo: { src: checkBackNbaSurvive, alt: 'check back when game starts' },
        link: '/?kind=weekly&league=nba',
      },
    },
    mlb: {
      survive: {
        logo: { src: noGamesJoinedNflSurvive, alt: 'check back when game starts' },
        link: '/?kind=survive&league=mlb',
      },
      weekly: {
        logo: { src: noGamesJoinedNflSurvive, alt: 'check back when game starts' },
        link: '/?kind=weekly&league=mlb',
      },
    },
  },

  default: { logo: { src: defaultImg, alt: 'no games' }, link: '/' },
};

// export default noGamesIconBylegue;
export { noGamesIcons };
