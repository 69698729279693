import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { propOr, not, and } from 'ramda';
import { getUserStatusAcceptedTermsOfService } from 'v2/selectors/session';

import linkStyles from 'v2/components/blocks/LinkGame/linkStyles';
import getLinkColors from 'v2/components/blocks/LinkGame/getLinkColors';
import { SIGN_IN_PATH, SETUP_ACCOUNT_PATH } from 'v2/constants/router';
import { LinkGame } from '../../blocks';

const propTypes = {
  id: PropTypes.number.isRequired,
  isContestAvailiable: PropTypes.bool,
  isProgressResult: PropTypes.bool,
  kind: PropTypes.string,
  session: PropTypes.shape({}),
  submitPicks: PropTypes.number,
  totalSelectionsRequired: PropTypes.number,
  urlContest: PropTypes.shape({}),
  isUserAcceptedTermsOfService: PropTypes.bool,
};

const defaultProps = {
  isContestAvailiable: false,
  isProgressResult: false,
  kind: '',
  session: {},
  submitPicks: 0,
  totalSelectionsRequired: 0,
  urlContest: {},
  isUserAcceptedTermsOfService: false,
};

function Button(props) {
  const {
    session,
    myGamesLink,
    isContestLive,
    kind,
    isUserAdvanced,
    isContestActive,
    status,
    urlContest,
    id,
    isContestAvailiable,
    submitPicks,
    totalSelectionsRequired,
    isProgressResult,
    currentWeek,
    isUserAcceptedTermsOfService,
    isUserWin,
    isJoin,
    isUserEliminated,
  } = props;

  const getButtonText = () => {
    if (not(isJoin)) return 'Join Game';

    if (isUserWin) return 'VIEW RESULTS!';

    if (kind == 'survive') {
      if (isUserEliminated) return 'View Results';
      if (isUserAdvanced) return 'Make Picks';
    }

    if (kind == 'weekly') {
      if (isUserEliminated) return 'VIEW RESULTS';
    }

    if (isContestLive) return 'Game Dashboard';
    if (isProgressResult) return 'View Results';
    if (propOr(false, 'isAuthorized', session) && isContestAvailiable && submitPicks === 0) return 'Make Picks';

    if (isContestAvailiable && submitPicks === 0) return 'Join Game';

    if (
      (submitPicks > 0 && submitPicks !== totalSelectionsRequired) ||
      (currentWeek > 1 && submitPicks !== totalSelectionsRequired)
    ) {
      return `Submit Picks ${submitPicks}/${totalSelectionsRequired}`;
    }
    if (submitPicks === totalSelectionsRequired) return 'My Picks';
    return 'View picks';
  };

  const isStartSubmitPicks =
    (submitPicks > 0 && submitPicks !== totalSelectionsRequired) ||
    (currentWeek > 1 && submitPicks !== totalSelectionsRequired);

  return (
    <Fragment>
      {session.isAuthorized && isUserAcceptedTermsOfService ? (
        <LinkGame
          {...props}
          isEliminated={
            (not(isUserWin) && isUserEliminated) ||
            (not(isUserWin) && isJoin && and(not(isUserAdvanced), and(not(isContestActive), not(isUserWin))))
          }
          myGamesLink={myGamesLink ? myGamesLink : ''}
          kind={kind}
          urlContest={urlContest}
          id={id}
          buttonText={getButtonText()}
          isStartSubmitPicks={isStartSubmitPicks}
          isProgressResult={isProgressResult}
        />
      ) : (
        <div style={{ textAlign: 'center' }}>
          <StyledNavLink
            color={getLinkColors(kind, !urlContest)}
            // className={urlContest ? 'link link__pick link__weekly' : 'link link__pick link__dashboard'}
            to={{
              pathname: session.isAuthorized ? SETUP_ACCOUNT_PATH : SIGN_IN_PATH,
              state: { contest: { id, urlContest } },
            }}
            // href="/auth/sign_up"
          >
            {urlContest ? 'Join Game' : 'dashboard'}
          </StyledNavLink>
        </div>
      )}
    </Fragment>
  );
}

// const StyledLink = styled.a`
//   ${linkStyles}
// `;

const StyledNavLink = styled(NavLink)`
  ${linkStyles};
  color: 'blue';
`;

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  isUserAcceptedTermsOfService: getUserStatusAcceptedTermsOfService(state),
});

const enhance = compose(connect(mapStateToProps));

export default enhance(Button);
