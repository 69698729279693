export const pagination = (c, m) => {
  const current = c;
  const last = m;
  const delta = 2;
  const left = current - delta;
  const right = current + delta + 1;
  const range = [];
  const rangeWithDots = [];
  let l;

  for (let i = 1; i <= last; i++) {
    if (i == 1 || i == last || (i >= left && i < right)) {
      range.push(i);
    }
  }

  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push({ value: l + 1, label: l + 1 });
      } else if (i - l !== 1) {
        rangeWithDots.push({ label: '...' });
      }
    }
    rangeWithDots.push({ value: i, label: i });
    l = i;
  }

  return rangeWithDots;
};
