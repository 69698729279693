// @flow

import React from 'react';
import styled from 'styled-components';
import { equals } from 'ramda';

type propTypes = {
  onChange: (key: string) => void,
  activeKey?: string,
};

const defaultProps = {
  activeKey: 'active',
};

const tabs = [
  ['active', { label: 'Active' }],
  ['recent', { label: 'Recent' }],
];

function StatusGameTabs({ onChange, activeKey }: propTypes) {
  return (
    <Container>
      {tabs.map(([key, { label }]) => (
        <TabItem key={key} onClick={() => onChange(key)} isActive={equals(activeKey, key)}>
          {label}
        </TabItem>
      ))}
    </Container>
  );
}

StatusGameTabs.defaultProps = defaultProps;
export default React.memo(StatusGameTabs);

// style

const TabItem = styled.div`
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.dark};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s linear;
  font-size: 17px;
  padding: 11px 50px;
  border-radius: 19px;
  text-transform: uppercase;
  font-weight: bold;
  flex: 1;

  background: ${({ theme, isActive }) => isActive && theme.colors.purpleAccent};
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.theme.colors.dark};
`;
