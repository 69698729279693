// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose, type HOC } from 'recompose';

type PhotoType = { id: number, src: string };
type WrappedTypes = {
  testFetchFunction: Function,
};

const InstagramCarouselController = (WrappedComponent) => {
  return ({ testFetchFunction, ...rest }: WrappedTypes) => {
    useEffect(() => {
      const fetch = async () => {
        testFetchFunction();
      };

      fetch();
    }, []);

    return <WrappedComponent {...rest} />;
  };
};

const mapDispatchToProps = (dispatch) => ({
  testFetchFunction: (props) => console.log('dispatch from props ', props),
});

const mapStateToProps = (state) => ({
  photos: [],
});

const enhance: HOC<*, { photos: PhotoType[] }> = compose(
  connect(mapStateToProps, mapDispatchToProps),
  InstagramCarouselController,
);

export default enhance;
