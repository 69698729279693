// @flow

import React from 'react';
import styled from 'styled-components';

type CounterProps = {
  children: number,
  isUnActive: boolean,
  className?: string,
};

const defaultProps = {
  className: null,
};

function Counter({ children, isUnActive, className }: CounterProps) {
  return (
    <StyledCounter isBigNumber={children > 10} isUnActive={isUnActive} className={className}>
      {children}
    </StyledCounter>
  );
}

const StyledCounter = styled.span`
  margin-left: 5px;
  padding: 3px 7px;
  border: 1px solid #e80c51;
  border-radius: 20px;
  letter-spacing: 0px;
  color: ${({ isUnActive }) => (isUnActive ? '#656579' : '#fff')};
  &:hover {
    text-decoration: none;
  }
  @media (min-width: 480px) {
    padding: 2px 6px;
  }
  border: 1px solid ${({ isUnActive }) => (isUnActive ? '#656579' : 'red')};
  font-size: ${({ isBigNumber }) => isBigNumber && '9px'};
`;
Counter.defaultProps = defaultProps;
export default Counter;
