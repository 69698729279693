// @flow

import React from 'react';
import styled from 'styled-components';
import { curry, not, equals, prop } from 'ramda';

import { pagination } from './helpers';

import arrowTop from './img/arrow_top.svg';

type PaginationProps = {
  totalPages: Number,
  currentPage: Number,
  nextPage?: Number,
  prevPage?: Number,
  handler?: (v: Number) => void,
};

const defaultProps = {
  nextPage: null,
  prevPage: null,
  handler: () => null,
};

function Pagination({ totalPages, currentPage, nextPage, prevPage, handler }: PaginationProps) {
  const checkCurrentPage = curry((fn, page) => page && not(equals(page, currentPage)) && fn(page));
  const handlerWithCheck = checkCurrentPage(handler);

  if (not(totalPages)) return null;

  return (
    <Container>
      {prevPage && (
        <PrevNavigationButton onClick={handlerWithCheck(prevPage)}>
          <img src={arrowTop} alt="prev" />
        </PrevNavigationButton>
      )}

      <PageNumbers>
        {pagination(currentPage, totalPages).map((paginationProp) => (
          <PageNumber
            isActive={equals(prop('value', paginationProp), currentPage)}
            onClick={() => handlerWithCheck(prop('value', paginationProp))}
          >
            {prop('label', paginationProp)}
          </PageNumber>
        ))}
      </PageNumbers>

      {nextPage && (
        <NextNavigationButton onClick={() => handlerWithCheck(nextPage)}>
          <img src={arrowTop} alt="next" />
        </NextNavigationButton>
      )}
    </Container>
  );
}

Pagination.defaultProps = defaultProps;
export default Pagination;

// styles

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 0;

  border-top: 1px solid ${({ theme }) => theme.colors.gray};
  min-height: 53px;
`;

const PageNumbers = styled.ul`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;

  margin: 0 10px;
`;

const PageNumber = styled.li`
  position: relative;
  color: ${({ theme }) => theme.colors.lightenGray};
  padding: 0 8px;
  transition: all 0.5s;
  cursor: pointer;
  font-size: 19px;

  color: ${({ theme, isActive }) => isActive && theme.colors.purpleAccent};

  &:after {
    content: '';
    width: 100%;
    height: 1px;
    background: ${({ theme, isActive }) => isActive && theme.colors.purpleAccent};

    position: absolute;
    top: -17px;
    left: 0;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.purpleAccent};
    filter: brightness(0.8);
  }
`;

const NavigationButton = styled.div`
  background: ${({ theme }) => theme.colors.gray};
  display: inline-flex;
  padding: 12px 11px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    filter: brightness(1.2);
  }
`;

const PrevNavigationButton = styled(NavigationButton)`
  img {
    transform: rotate(-90deg);
    margin-left: -2px;
  }
`;

const NextNavigationButton = styled(NavigationButton)`
  img {
    transform: rotate(90deg);
    margin-right: -2px;
  }
`;
