import React, { useState } from 'react';
import styled from 'styled-components';
import { contains, without, prepend, slice } from 'ramda';

import FAQItem from './shared/FAQItem';
import faqs from './shared/data';

function FAQsPage() {
  const [activeIds, setActiveIds] = useState([]);

  const toogleItem = (id) => {
    setActiveIds((prev) => {
      if (getActiveStatus(id)) {
        return without([id], prev);
      }

      return [id];
      // return prepend(id, prev);
    });
  };

  const getActiveStatus = (id) => contains(id, activeIds);

  return (
    <Container>
      <FAQItem.List {...faqs[0]}>
        {slice(1, Infinity, faqs).map((item, i) => (
          <FAQItem
            onClick={() => toogleItem(item.id)}
            isOpen={getActiveStatus(item.id)}
            number={i + 1}
            key={item}
            {...item}
          />
        ))}
      </FAQItem.List>
    </Container>
  );
}

export default FAQsPage;

// styles

const Container = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 12px;
`;
