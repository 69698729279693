import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { progressIconItemStyles, progressIconValStyles } from '../styles';
// import successIcon from '../../Image/result_success.svg';
import successIcon from '../../Image/champion.svg';
import medalIcon from '../../Image/medal.svg';
import unSuccessIcon from '../../Image/result_unsuccess.svg';
import upIcon from '../../Image/up_icon.svg';
import downIcon from '../../Image/down_icon.svg';
import refreshIcon from '../../Image/refresh_icon.svg';

const propTypes = {
  kind: PropTypes.string,
  isResultSuccess: PropTypes.bool,
};

const defaultProps = {
  kind: '',
  isResultSuccess: false,
};

const getSurviveResultIcon = (isSuccess, rank) => {
  if (rank == 1) return <img src={successIcon} alt="successIcon" height="39px" />;

  if (isSuccess) return <img src={medalIcon} alt="successIcon" height="39px" />;
  return <img src={unSuccessIcon} alt="unSuccessIcon" height="39px" />;
};

function ResultIconItems({ kind, isResultSuccess, rank, correctPicks, uncorrectPicks, pendingPicks, ...rest }) {
  // console.log('rest :>> ', rest);
  if (isResultSuccess) {
    return <StyledProgressIconItemResult>{getSurviveResultIcon(isResultSuccess, rank)}</StyledProgressIconItemResult>;
  }

  return (
    <Fragment>
      <StyledProgressIconItem>
        <img src={upIcon} alt="downIcon" />
        <StyledProgressIconVal>{correctPicks}</StyledProgressIconVal>
      </StyledProgressIconItem>

      <StyledProgressIconItem>
        <img src={refreshIcon} alt="refreshIcon" />
        <StyledProgressIconVal>{pendingPicks}</StyledProgressIconVal>
      </StyledProgressIconItem>

      <StyledProgressIconItem>
        <img src={downIcon} alt="upIcon" />
        <StyledProgressIconVal>{uncorrectPicks}</StyledProgressIconVal>
      </StyledProgressIconItem>
    </Fragment>
  );
}

const StyledProgressIconItem = styled.div`
  ${progressIconItemStyles}
`;

const StyledProgressIconItemResult = styled(StyledProgressIconItem)`
  justify-content: center;
  img {
    height: 60px;
  }
`;

const StyledProgressIconVal = styled.div`
  ${progressIconValStyles}
`;

ResultIconItems.propTypes = propTypes;
ResultIconItems.defaultProps = defaultProps;

export default ResultIconItems;
