// @flow

import React from 'react';

import Adapter from './Adapter';

function DropDown(props) {
  return <Adapter {...props} />;
}

export default DropDown;
