// @flow

import React from 'react';
import PromoPageWrapper from 'v2/components/PromoPages/3/shared/PromoPageWrapper';
import Section from 'v2/components/PromoPages/3/shared/Section';
import { VideoPreview, PhotoBlock2, PhotoBlock3, PhotoBlock4 } from 'v2/components/PromoPages/3/blocks';

function DesktopVersion() {
  return (
    <PromoPageWrapper>
      <VideoPreview />

      <Section>
        <PhotoBlock2 />
      </Section>

      <Section>
        <PhotoBlock3 />
      </Section>

      <Section>
        <PhotoBlock4 />
      </Section>
    </PromoPageWrapper>
  );
}

export default DesktopVersion;
