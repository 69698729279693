/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import Title from '../../../shared/Title';
import Paragraph from '../../../shared/Paragraph';

import LogoImage from '../../../shared/Image';

import cashMoney from './cash_money.svg';
import footwear from './footwear.svg';
import memorabilia from './memorabilia.svg';
import merchandise from './merchandise.svg';

import team from '../../../shared/Image/team.png';

const propTypes = {};

const defaultProps = {};

function Prizes(props) {
  return (
    <StyledPrizes>
      <Title style={{ textAlign: 'center' }} text={'Prizes'}></Title>
      <Paragraph text={'Win once in a lifetime vip experiences'}></Paragraph>
      <PrizesItems>
        <Item className="item_top_left">
          <LogoImage width="40" img={cashMoney} />
          <ItemText>CASH MONEY</ItemText>
        </Item>
        <Item className="item_top_right">
          <LogoImage width="40" img={memorabilia} />
          <ItemText>AUTOGRAPHED MEMORABILIA</ItemText>
        </Item>
        <Item className="item_bottom_left">
          <LogoImage width="40" img={footwear} />
          <ItemText>EXCLUSIVE FOOTWEAR</ItemText>
        </Item>
        <Item className="item_bottom_right">
          <LogoImage width="40" img={merchandise} />
          <ItemText>GONE STREAKIN’ MERCHANDISE</ItemText>
        </Item>
      </PrizesItems>
      <Bitmap>
        <LogoImage style={{ width: '100%' }} img={team} />
      </Bitmap>
    </StyledPrizes>
  );
}

const StyledPrizes = styled.div`
  position: relative;
  padding: 20px 0 0;
  background: #1b1b21;
  border-radius: 8px;
  overflow: hidden;
  @media (min-width: 768px) {
    background: #17171c;
    padding: 40px 290px 185px;
  }
  @media (max-width: 425px) {
    margin: 15px 0;
  }
`;

const PrizesItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;

  @media (max-width: 425px) {
    margin-top: 0;
  }
`;

const Item = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #26262e;
  @media (min-width: 768px) {
    background: #1b1b21;
    position: absolute;
    width: 255px;
    height: 50%;
    justify-content: center;
    border-color: #17171c;
  }
`;

const ItemText = styled.h4`
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
  font-weight: 700;
  padding: 20px 0;
  padding-bottom: 0;
`;

const Bitmap = styled.div`
  /* margin-top: 20px; */
  margin-top: 0;
  @media (min-width: 768px) {
    max-width: 350px;
    max-height: 315px;
    margin: 20px auto -100px;
  }
`;

Prizes.propTypes = propTypes;
Prizes.defaultProps = defaultProps;

// export default enhance(HomePage);
export default Prizes;
