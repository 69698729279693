import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SIGN_IN, SIGN_UP, ROOT_PATH } from 'v2/constants/router';
import closeIcon from 'v2/components/shared/Image/Icon/ico_close.svg';
import Button from '../../../shared/Button';

const defaultProps = {};

const propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

function SignIn({ location }) {
  if ([SIGN_IN, SIGN_UP].indexOf(location.pathname) !== -1) {
    return (
      <CloseIcon to={ROOT_PATH}>
        <img src={closeIcon} alt="close" />
      </CloseIcon>
    );
  }

  return (
    <StyledSignIn>
      <Button className="button button--dark" href={SIGN_IN}>
        sign in
      </Button>
      <Button className="button" href={SIGN_UP}>
        sign up
      </Button>
      <Button className="button button--mobile button--sm" href={SIGN_IN}>
        login
      </Button>
    </StyledSignIn>
  );
}

const StyledSignIn = styled.div`
  display: flex;
  align-items: center;
`;

const CloseIcon = styled(NavLink)`
  cursor: pointer;
  position: relative

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
  }
`;

SignIn.propTypes = propTypes;
SignIn.defaultProps = defaultProps;

// export default enhance(HomePage);
export default withRouter(SignIn);
