// @flow

import React from 'react';
import Section from 'v2/components/PromoPages/2/shared/Section';
import PromoPageWrapper from 'v2/components/PromoPages/2/shared/PromoPageWrapper';
import VideoPreview from '../../blocks/VideoPreview';

import PhotoBlcok1 from '../../blocks/1';
import PhotoBlcok2 from '../../blocks/2';
import PhotoBlcok3 from '../../blocks/3';
import PhotoBlcok4 from '../../blocks/4';
import PhotoBlcok5 from '../../blocks/5';

function DesktopVersion() {
  return (
    <PromoPageWrapper>
      <VideoPreview />
      <Section>
        <PhotoBlcok1 />
        <PhotoBlcok2 />
      </Section>

      <Section>
        <PhotoBlcok3 />
      </Section>

      <Section>
        <PhotoBlcok4 />
        <PhotoBlcok5 />
      </Section>

      {/* <Section style={{ maxWidth: '725px' }}> */}
      {/* <Section isRow noMarginRight></Section> */}
      {/* </Section> */}
    </PromoPageWrapper>
  );
}

export default DesktopVersion;
