// @flow

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as Scroll from 'react-scroll';

import topArraowLogo from 'v2/components/shared/Image/ico_arrow_top.svg';
import PlayButtonButton from '../PlayNowButton';

// type PromoFooterProps = {
//   showUp?: boolean,
// };

// const defaultProps = {
//   showUp: false,
// };

function PromoFooter() {
  const [isButton, setIsButton] = useState(false);
  const scroll = Scroll.animateScroll;

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
  }, []);

  const getEndPositionStatus = () => {
    return (
      document.documentElement.scrollHeight - document.documentElement.scrollTop <=
      document.documentElement.clientHeight + 350
    );
  };

  const handleScroll = () => {
    setIsButton(getEndPositionStatus());
  };

  return (
    <PromoFooterContainer>
      <PlayButtonButton to="/">Play Now</PlayButtonButton>
      {isButton && <UpButton onClick={() => scroll.scrollToTop()} />}
    </PromoFooterContainer>
  );
}

// PromoFooter.defaultProps = defaultProps;
export default PromoFooter;

// styles

const UpButton = styled.div`
  border: 1px solid rgb(38, 38, 46);
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  border-radius: 6px;

  &:before {
    content: '';
    display: block;
    background-image: url(${topArraowLogo});
    background-position: center;
    background-repeat: no-repeat;
    width: 16px;
    height: 9px;
  }
`;

const PromoFooterContainer = styled.div`
  width: 100%;
  background-color: #131317;
  border-top: 1px solid #26262e;
  padding: 8px 5px;
  display: flex;
  max-width: 1100px;
`;
