import React from 'react';

const propTypes = {};

const defaultProps = {};

function LogoImage(props) {
  const { img } = props;
  return <img alt="" {...props} src={img} />;
}

// const StyledMenu = styled.ul`
//   display: inline-block;
//   transition: all 0.3s ease-in-out;
//   cursor: pointer;
//   padding: 0px;
//   margin-right: 10px;
// `;

LogoImage.propTypes = propTypes;
LogoImage.defaultProps = defaultProps;

// export default enhance(HomePage);
export default LogoImage;
