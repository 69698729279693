import React from 'react';
import styled from 'styled-components';

import Mask from '../../formBase/Mask';

function ClassicMask(props) {
  return <StyledMask {...props} />;
}

const StyledMask = styled(Mask)`
  width: 100%;
  background: #26262e;
  font-size: 14px;
  color: #fff;
  letter-spacing: 0.5px;
  border: 0;
  padding: 12px;
  border-radius: 4px;
  transition: filter 0.2s linear;
  /* transition: border 0.2s linear; */
  border: ${({ isError }) => isError && '1px solid #e80c51'};
  padding: ${({ isError }) => (isError ? '11px' : '12px')};
  cursor: pointer;

  &:focus {
    border: 1px solid #5d5ce2;
    padding: 11px;
    cursor: auto;
  }

  &::placeholder {
    color: #656579;
  }

  &:hover {
    filter: brightness(105%);
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #26262e inset !important;
    -webkit-text-fill-color: #fff !important;
    background-clip: content-box !important;
  }
`;

export default ClassicMask;
