import React from 'react';
import PhotoBlock from 'v2/components/PromoPages/shared/PhotoBlock';
import GsLogo from 'v2/components/shared/Image/promo/GS_logo.svg';

import BlockDescription from 'v2/components/PromoPages/2/shared/DescriptionBlock';

function block() {
  return (
    <PhotoBlock.Wrapper style={{ height: '126px' }}>
      <PhotoBlock src={GsLogo} fill>
        <BlockDescription>
          WIN PRIZES AND EXPERIENCES,{'\n'}
          YOU’VE ONLY EVER DREAMED OF!
        </BlockDescription>
      </PhotoBlock>
    </PhotoBlock.Wrapper>
  );
}

export default block;
