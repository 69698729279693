// @flow

import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import { ACCOUNT_SETTINGS_PATH, ACCOUNT_INVITE_FRIENDS_PATH } from 'v2/constants/router';
import TabItem from './TabItem';

const tabsOptions = [
  {
    id: 1,
    title: 'Invite Friends',
    url: ACCOUNT_INVITE_FRIENDS_PATH,
  },
  {
    id: 2,
    title: 'Settings',
    url: ACCOUNT_SETTINGS_PATH,
  },
];

function Tabs({ location: { pathname } }) {
  return (
    <TabItemsContainer>
      {tabsOptions.map((tabsOption) => (
        <TabItem key={tabsOption.id} {...tabsOption} active={pathname === tabsOption.url} />
      ))}
    </TabItemsContainer>
  );
}

const TabItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export default withRouter(Tabs);
