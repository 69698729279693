import { RefsApiAdapter } from 'v2/apiAdapters';

import * as requestTypes from '../../constants/requestTypes';
import * as types from '../../constants/actionTypes';

import setRequestInProcess from '../request';

export function fetchHomePageRefs() {
  const requestType = requestTypes.REFS_HOME_PAGE_FETCH;

  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));

    RefsApiAdapter.getRefs().then((res) => {
      dispatch(fetchHomePageRefsSuccess(res));
      dispatch(setRequestInProcess(false, requestType));
    });
    // .catch((error) => {
    //   if (error.response) {
    //     openNotification('error', error.response.data.message);
    //   }
    // });
  };
}

function fetchHomePageRefsSuccess(payload) {
  return {
    type: types.REFS_MERGE,
    payload,
  };
}

export default fetchHomePageRefs;
