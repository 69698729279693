import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';

import { getUserStatusAcceptedTermsOfService } from 'v2/selectors/session';
import * as Router from 'v2/constants/router';

// HOW_TO_PLAY

const propTypes = {};

const defaultProps = {};

function Drawer(props) {
  const { isOpen, isUserAcceptedTermsOfService } = props;

  return (
    <StyledDrawer className={isOpen ? 'show_drawer' : ''}>
      <Nav>
        <NavItem>
          {/* <a href={isUserAcceptedTermsOfService ? Router.HOW_TO_PLAY : Router.SETUP_ACCOUNT_PATH}>How to play</a> */}
          <NavLink to={isUserAcceptedTermsOfService ? Router.HOW_TO_PLAY : Router.SETUP_ACCOUNT_PATH}>
            How to play
          </NavLink>
        </NavItem>
        <NavItem>
          <a href={isUserAcceptedTermsOfService ? Router.PRIZES : Router.SETUP_ACCOUNT_PATH}>Prizes</a>
        </NavItem>
        <NavItem>
          <a href={isUserAcceptedTermsOfService ? Router.FAQ : Router.SETUP_ACCOUNT_PATH}>Faq</a>
        </NavItem>
        <NavItem>
          <a href={isUserAcceptedTermsOfService ? Router.CONTACT_US : Router.SETUP_ACCOUNT_PATH}>CONTACT us</a>
        </NavItem>
      </Nav>
    </StyledDrawer>
  );
}

const mapStateToProps = (state) => ({
  isUserAcceptedTermsOfService: getUserStatusAcceptedTermsOfService(state),
});

const StyledDrawer = styled.nav`
  position: absolute;
  width: 100%;
  background: #1b1b21;
  left: 0;
  right: 0;
  display: none;
  transition: opacity 0.15s ease-in-out;
  border-radius: 8px;
  z-index: -1;
  @media (min-width: 768px) {
    width: auto;
    height: auto;
    margin-top: 15px;
    left: auto;
    right: auto;
  }
`;

const Nav = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  background: #1b1b21;
  margin-top: 0px;
  margin-bottom: 0;
  @media (min-width: 768px) {
    padding: 20px 40px 20px 20px;
    align-items: flex-start;
    &:before {
      position: absolute;
      content: '';
      width: 24px;
      height: 4px;
      background: #5d5ce2;
      bottom: 45px;
      left: 20px;
      top: 0;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
`;

const NavItem = styled.li`
  margin: 10px 0;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5;
  font-weight: 700;
  text-transform: uppercase;
`;

Drawer.propTypes = propTypes;
Drawer.defaultProps = defaultProps;

// export default enhance(HomePage);
export default connect(mapStateToProps)(Drawer);
