import { createSelector } from 'reselect';
import { identity } from 'ramda';

export const initialState = {};

function gamesCountSelector(state) {
  return state.gamesCount || initialState;
}

export const getGamesCount = createSelector(
  gamesCountSelector,
  identity,
);

export const getGamesCountActive = createSelector(
  getGamesCount,
  (gamesCount) => gamesCount.activeCount,
);
