// @flow

import React from 'react';
import styled from 'styled-components';

import Form from './shared/Form';

type ContactUsPageProps = {};

function ContactUsPage({}: ContactUsPageProps) {
  return (
    <Container>
      <Form />
    </Container>
  );
}

export default ContactUsPage;

// styles

const Container = styled.div``;
