// @flow

import React from 'react';
import styled from 'styled-components';

import plusIcon from 'v2/components/shared/Image/plus_icon.svg';

type FooterProps = {
  categoryName?: String,
  handleClick: () => void,
  actionName?: String,
  kind: String,
};

const defaultProps = {
  categoryName: 'N/A',
  actionName: 'Add this player',
};

function Footer({ categoryName, handleClick, actionName, kind, ...rest }: FooterProps) {
  return (
    <Container onClick={handleClick} kind={kind}>
      <img src={plusIcon} alt="add" onClick={handleClick} />
      <ActionName>{actionName}</ActionName>
    </Container>
  );
}

Footer.defaultProps = defaultProps;
export default Footer;

// styles

const ActionName = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  padding-left: 10px;
`;

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.gray};
  padding: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  /* background-color: ${({ theme, kind }) => theme.colors.getColorByKind(kind)}; */
  background-color: transparent;

  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;
