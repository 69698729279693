import { DISMISS_ALL_ACTIVE } from 'v2/constants/router';

import { postRequest } from '../requests';
import fetch from '../fetch';

export default class DismissNotificationApiAdapter {
  static getDismissNotification() {
    return fetch(DISMISS_ALL_ACTIVE, postRequest({}));
  }
}
