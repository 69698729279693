import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import useClassNames from 'hooks/useClassNames';

import Checkbox from '../../formBase/Checkbox';

const propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  isError: PropTypes.boolean,
};

const defaultProps = {
  className: undefined,
  children: undefined,
  isError: false,
};

function ClassicCheckbox({ className, children, name, isError, ...props }) {
  // const checkboxClass = useClassNames(['form__checkbox', className]);

  return (
    <LabelForm htmlFor={name} isError={isError}>
      <CheckboxContainer className={className}>
        <HiddenCheckbox name={name} {...props} />
        <StyledCheckbox checked={props.checked} isError={isError}>
          <Icon viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </Icon>
        </StyledCheckbox>
      </CheckboxContainer>
      {children}
    </LabelForm>
  );
}

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  position: relative;
  cursor: pointer;

  &:after {
    content: '';
    display: block;
    position: absolute;
    height: 25px;
    width: 25px;
    top: 0;
  }
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

const HiddenCheckbox = styled(Checkbox)`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: #26262e;
  border-radius: 3px;
  transition: all 150ms;
  /* transition: border 0.2s linear; */
  border: ${({ isError }) => isError && '1px solid #e80c51'};
  filter: brightness(150%);

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;

const LabelForm = styled.label`
  margin-top: 50px;
  display: flex;
  cursor: pointer;
  padding-bottom: ${({ isError }) => isError && '25px'};
  border-bottom: ${({ isError }) => isError && '1px solid #e80c51'};
  transition: filter 0.2s linear;

  &:hover {
    filter: brightness(105%);
  }
`;

ClassicCheckbox.propTypes = propTypes;
ClassicCheckbox.defaultProps = defaultProps;

export default ClassicCheckbox;
