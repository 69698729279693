// @flow

import React, { useEffect, useState } from 'react';
import {
  when,
  tap,
  compose,
  join,
  props,
  ascend,
  always,
  identity,
  prop,
  append,
  sortWith,
  prepend,
  map,
  mergeDeepLeft,
  find,
  propEq,
} from 'ramda';
import styled from 'styled-components';

import { ContestsApiAdapter } from 'v2/apiAdapters';

import StatCategory from './SurviveStatCategory';

const defaultProps = {
  empltyCategories: [],
};

type SurviveStatCategoriesProps = {
  empltyCategories?: Array<any>,
};

function SurviveStatCategories({
  empltyCategories,
  hideName,
  contestId,
  showHeadshots,
  ...rest
}: SurviveStatCategoriesProps) {
  const [categories, setCategories] = useState([]);

  const getSelectedPlayers = async (categoryId) => {
    try {
      const res = await ContestsApiAdapter.getContestPicks({ id: contestId, weekOrder: 1, categoryId });
      setCategories(prepend(mergeDeepLeft(find(propEq('id', categoryId), empltyCategories), res)));
    } catch (e) {
      console.log('e :>> ', e);
    }
  };

  useEffect(() => {
    compose(
      map((categoryId) => getSelectedPlayers(categoryId)),
      tap(() => setCategories([])),
      map(prop('id')),
    )(empltyCategories);
  }, []);

  if (categories.length > 0) {
    return sortWith([ascend(prop('order'))], categories).map((stat) => (
      <>
        <StatCategory hideName={hideName} {...stat} {...rest} key={stat.id} showHeadshots={showHeadshots} />
      </>
    ));
  }

  if (empltyCategories.length > 0) {
    return empltyCategories.map((stat) => <StatCategory {...stat} {...rest} key={stat.id} />);
  }

  return null;
}

SurviveStatCategories.defaultProps = defaultProps;
export default React.memo(SurviveStatCategories);
