// import allSports from 'v2/components/shared/Image/Sports/all_sports.svg';
import basketball from 'v2/components/shared/Image/Sports/color/new_basketball.svg';
import baseball from 'v2/components/shared/Image/Sports/color/baseball.svg';
import football from 'v2/components/shared/Image/Sports/color/football.svg';
import golf from 'v2/components/shared/Image/Sports/color/golf.svg';
import hockey from 'v2/components/shared/Image/Sports/color/hockey.svg';
import soccer from 'v2/components/shared/Image/Sports/color/soccer.svg';

export default [
  ['all', { label: 'All' }],
  ['nfl', { label: 'footbal', img: football }],
  ['nba', { img: basketball }],
  ['mlb', { img: baseball }],
  // ['nhl', { label: 'hockey', img: hockey }],
  // ['nsl', { label: 'soccer', img: soccer }],
  // ['nccga', { label: 'baseball', img: golf }],
];

export const LEAGUE_TABS_WHITHOUT_LABEL = [
  ['all', { label: 'All' }],
  ['nfl', { img: football }],
  ['nba', { img: basketball }],
  ['mlb', { img: baseball }],
  // ['nhl', { label: 'hockey', img: hockey }],
  // ['nsl', { label: 'soccer', img: soccer }],
  // ['nccga', { label: 'baseball', img: golf }],
];
