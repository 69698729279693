// @flow

import React, { useState } from 'react';
import styled from 'styled-components';
import defaultIcon from 'v2/components/shared/Image/logo.svg';
import survive from 'v2/components/shared/Image/survive.svg';
import getContestLogo from 'v2/services/getContestLogo';

const defaultProps = {
  logo: defaultIcon,
  alt: 'push icon',
  className: null,
  contestKind: null,
  league: null,
};

type NotificationLogoProps = {
  logo?: string,
  alt?: string,
  className?: string,
  contestKind?: string,
  league?: string,
};

function NotificationLogo({ league, contestKind, logo, alt, className }: NotificationLogoProps) {
  const [notificationLogo, setNotificationLogo] = useState(
    (logo && (league && getContestLogo({ league, isSurvive: contestKind === 'survive' }))) || defaultIcon,
  );

  return (
    <img src={notificationLogo} alt={alt} onError={() => setNotificationLogo(defaultIcon)} className={className} />
  );
}

// const NotificationIcon = styled.div`
//   height: 33px;
//   width: 33px;
// `;

NotificationLogo.defaultProps = defaultProps;
export default NotificationLogo;
