import { mergeDeepRight } from 'ramda';

import * as actionTypes from '../../constants/actionTypes';
import { initialState } from '../../selectors/refs';

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REFS_MERGE:
      return mergeDeepRight(state, action.payload);
    default:
      return state;
  }
};
