import * as actionTypes from '../../constants/actionTypes';
import { initialState } from '../../selectors/myGamesRecent';

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MY_GAMES_RECENT_FETCH_SUCCESS:
      return {
        ...state,
        recent: action.payload,
      };
    default:
      return state;
  }
};
