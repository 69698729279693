// @flow

import React from 'react';
import styled from 'styled-components';

type CocialIconsContainerProps = {};

function CocialIconsContainer({ children, isVertical }: CocialIconsContainerProps) {
  return (
    <Container isVertical={isVertical}>
      {React.Children.map(children, (child) => React.cloneElement(child, { isVertical }))}
    </Container>
  );
}

export default CocialIconsContainer;

// styles
const Container = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;

  flex-direction: ${({ isVertical }) => isVertical && 'column'};
`;
