import React from 'react';
import styled from 'styled-components';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';

import * as actions from 'v2/actions';
import FormGroupAdapter from 'v2/components/shared/formDefault/FormGroup/Adapter';
import Input from 'v2/components/shared/formDefault/Input';
import { composeValidators, validateRequired, validateEmail } from 'v2/components/shared/formDefault/validations';
import ErrorMessage from 'v2/components/shared/formDefault/ErrorMessage';
import Title from 'v2/components/AccountPage/shared/Title';
import Button from 'v2/components/shared/formDefault/Button';
import { getUserEmail } from 'v2/selectors/session';

type ChangeEmailFormProps = {
  updateAccountEmail: () => void,
  email: string,
};

function ChangeEmailForm({ updateAccountEmail, email }: ChangeEmailFormProps) {
  return (
    <ChangeEmailFormContainer onClick={(e) => e.stopPropagation()}>
      <Title>Change your email address</Title>
      <Form
        initialValues={{ user: { email } }}
        onSubmit={(props) => updateAccountEmail(props)}
        render={({ handleSubmit, hasSubmitErrors, submitError, submitting }) => (
          <ChangeEmailFormWrapper onSubmit={handleSubmit}>
            <ChangeEmailFormGroupCollection>
              <Field
                component={FormGroupAdapter}
                inputComponent={Input}
                name="user.email"
                help=""
                placeholder="Email"
                type="email"
                label="Email"
                validate={composeValidators(validateRequired(), validateEmail())}
              />
              <Field
                component={FormGroupAdapter}
                inputComponent={Input}
                name="user.password"
                placeholder="Your password"
                type="password"
                label="Password"
                validate={validateRequired()}
              />
            </ChangeEmailFormGroupCollection>

            {hasSubmitErrors && submitError && <ErrorMessage>{submitError}</ErrorMessage>}

            <Button type="submit" disable={submitting}>
              {submitting ? 'Saving...' : 'Save changes'}
            </Button>
          </ChangeEmailFormWrapper>
        )}
      />
    </ChangeEmailFormContainer>
  );
}

const ChangeEmailFormGroupCollection = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 20px;

  div {
    padding: 0 15px;
    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 40px;
    div {
      padding: 0;
    }
  }
`;

const ChangeEmailFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    width: auto;
  }
`;

const ChangeEmailFormContainer = styled.div`
  background: #1b1b21;
  min-width: 780px;
  padding: 25px 60px 50px;
  border-radius: 4px;
  z-index: 9;

  @media (max-width: 768px) {
    min-width: auto;
    width: 100%;
    padding: 20px 40px;
  }

  @media (max-width: 475px) {
    min-width: auto;
    width: 100%;
    padding: 25px 25px 45px;
  }
`;

const mapStateToProps = (store) => ({
  email: getUserEmail(store),
});

const mapDispatchToProps = (dispatch) => ({
  updateAccountEmail: (userData) => dispatch(actions.updateAccountEmail(userData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeEmailForm);
