import { MyGamesRecentApiAdapter } from 'v2/apiAdapters';

import { contestsNormalizr, contestNormalizr, statCategoriesNormalizr } from '../../normalizrs';
import { mergeEntities } from '../entities';
import * as requestTypes from '../../constants/requestTypes';
import * as types from '../../constants/actionTypes';

import setRequestInProcess from '../request';

export function fetchMyGamesRecent() {
  const requestType = requestTypes.MY_GAMES_RECENT_FETCH;

  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));

    MyGamesRecentApiAdapter.getMyGamesRecentApiAdapter().then(({ recentContest }) => {
      const { entities, result } = contestsNormalizr(recentContest);
      dispatch(mergeEntities(entities));

      dispatch(fetchMyGamesRecentApiAdapterSuccess(result));
      dispatch(setRequestInProcess(false, requestType));
    });
    // .catch((error) => {
    //   if (error.response) {
    //     openNotification('error', error.response.data.message);
    //   }
    // });
  };
}

function fetchMyGamesRecentApiAdapterSuccess(payload) {
  return {
    type: types.MY_GAMES_RECENT_FETCH_SUCCESS,
    payload,
  };
}

export default fetchMyGamesRecent;
