// @flow

import React from 'react';
import styled from 'styled-components';

import Checkbox from '../../formBase/Checkbox';

type ToggleProps = {
  children: string,
  className: string,
  name: string,
  isError: boolean,
};

function Toggle({ children, className, name, isError, ...props }: ToggleProps) {
  if (children) {
    return (
      <ToggleWrapper htmlFor={name} isError={isError}>
        <ToogleTitle>{children}</ToogleTitle>
        <ToggleContainer htmlFor={name} isError={isError}>
          <CheckboxHidden name={name} {...props} />
          <StyledToogle active={props.checked} isError={isError} />
        </ToggleContainer>
      </ToggleWrapper>
    );
  }

  return (
    <ToggleWrapper htmlFor={name} isError={isError}>
      <CheckboxHidden name={name} {...props} />
      <StyledToogle active={props.checked} isError={isError} />
    </ToggleWrapper>
  );
}

const ToogleTitle = styled.span`
  font-size: 14px;
  text-transform: capitalize;
  color: #fff;
  font-weight: 500;
`;

const StyledToogle = styled.div`
  background: ${({ active }) => (active ? '#5d5ce2' : '#1b1b21')};
  border-radius: 12px;
  padding: 3px !important;
  height: 20px;
  width: 36px;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.25s linear;
  /* justify-content: ${({ active }) => (active ? 'flex-end' : 'flex-start')}; */

  &:before {
    content: '';
    display: block;
    border-radius: 12px;
    height: 16px;
    width: 16px;
    background: #fff;
    position: absolute;
    left: ${({ active }) => (active ? '50%' : '3px')};
    transition: all 0.25s linear;
  }
`;

const CheckboxHidden = styled(Checkbox)`
  position: absolute;
  appearance: none;
  cursor: pointer;
`;

const ToggleContainer = styled.label`
  position: relative;
  appearance: none;
  cursor: pointer;
`;

const ToggleWrapper = styled.label`
  background: #26262e;
  padding: 12px;
  border-radius: 4px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  /* &:before {
    content: '';
    display: block;
    height: 120%;
    width: 120%;
    position: absolute;
  } */
`;

export default Toggle;
