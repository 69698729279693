// @flow

import React from 'react';
import styled from 'styled-components';
import { not, and, pick } from 'ramda';

import { NavLink } from 'react-router-dom';
import { BLOG_ARTICLE_PATH, getPath } from 'v2/constants/router';

type ArticlePreviewContainerProps = {};

function ArticlePreviewContainer({ children, articleId, ...rest }: ArticlePreviewContainerProps) {
  console.log(pick(['isCentered', 'isActive'], rest));

  return <Container to={getPath(BLOG_ARTICLE_PATH, { articleId })}>{React.cloneElement(children, rest)}</Container>;
}

export default ArticlePreviewContainer;

// styles
const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.lighGray};
  transition: all 0.25s;
  cursor: pointer;
  border-radius: 8px;
  max-width: 350px;
  width: 100%;
  text-decoration: none !important;

  &:after {
    content: '';
    display: block;
    position: absolute;
    transition: opacity 0.4s ease;
    background: rgba(19, 19, 23, 0.8);
    opacity: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: ${({ isCentered, isActive }) => {
      if (and(isCentered, not(isActive))) return '1';
    }};
  }

  &:hover {
    filter: brightness(0.85);
  }
`;
