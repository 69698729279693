// @flow

import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { noGamesIcons } from 'v2/constants/UI/noGamesIcons';
import * as R from 'ramda';

// type NoAvailableGamesLogoProps = {
//   match: any,
// };

function NoAvailableGamesLogo({ logo }) {
  const defaultLogo = R.path(['default', 'logo'], noGamesIcons);

  if (logo) {
    return <StyledSportIcon src={logo.src || defaultLogo.src} alt={logo.alt || defaultLogo.alt} />;
  }

  return <StyledSportIcon src={defaultLogo.src} alt={defaultLogo.alt} />;
}

const StyledSportIcon = styled.img`
  width: 60%;

  @media (max-width: 625px) {
    width: 85%;
  }
`;

// const StyledSportIconWrapper = styled.div`
//   width: 75px;
//   height: 75px;
//   background: #26262e;
//   border-radius: 50%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-bottom: 20px;

//   &:before {
//     content: '';
//     display: block;
//     position: absolute;
//     width: 60px;
//     height: 60px;

//     border-radius: 50%;
//     border: 4px solid #131317;
//   }
// `;

export default withRouter(NoAvailableGamesLogo);
