export default [
  {
    id: 0,
    question: 'What is&nbsp;Gone Streakin&rsquo;?',
    answer:
      'Gone Streakin’ features new fantasy sports games with prizes you’ve only ever dreamed of, including exclusive memorabilia, class act VIP fan experiences, player meet and greets, cash prizes and much more!',
  },
  {
    id: 1,
    question: '1) Are there different types of games?',
    answer:
      '<p>Yes, Gone Streakin’ has 4 different types of games.</p> <br/> <p>The 4 game types are as follows:</p> <ul><li>- Survivor Games</li><li>- Pick’em Games</li><li>- Bracket Games</li><li>- Streak Games</li>',
  },
  {
    id: 2,
    question: '2) What sports do you have on Gone Streakin’?',
    answer:
      'We currently feature games for football, basketball, and     baseball.  We will be launching other sports such as hockey, soccer, golf, and MMA over the next year.',
  },
  {
    id: 3,
    question: '3) Can I enter multiple game types at once?',
    answer: 'Yes, you can enter multiple games simultaneously.',
  },
  {
    id: 4,
    question: '4) How many entries can a user make per contest?',
    answer: 'One entry per user, per contest.',
  },

  {
    id: 5,
    question: '5) How old do you have to be to participate in FREE contests?',
    answer: 'To participate in free contests, you must be at least 13 years of age.',
  },

  {
    id: 6,
    question: '6) How old do you have to be to participate in PAID contests?',
    answer:
      'To participate in paid contests, you must be at least 18 years of age. Must be 19 years of age in Alabama and Nebraska. Must be 21 years of age in Massachusetts.',
  },

  {
    id: 7,
    question: '7) How long will the Survivor games last?',
    answer: 'The contest will last until one user is remaining or until the end of the individual sports season.',
  },

  {
    id: 8,
    question: '8) Can I make picks for future weeks in Survivor games?',
    answer: 'Yes. You can make your picks for future weeks in the ‘MY PICKS’ section of the game.',
  },

  {
    id: 9,
    question: '9) How long will the Pick’em games last?',
    answer:
      'Football contests will last one week or sometimes one day. Be sure to look at the date range on the gamecard when signing up for the contest. All sports will have the duration listed on the game card.',
  },

  {
    id: 10,
    question: '10) When is the deadline to enter new contests?',
    answer: 'The start time for each game will be listed on the game card.',
  },

  {
    id: 11,
    question: '11) When is the deadline time to make picks for each contest?',
    answer: 'You can make or change picks up until 1 minute before each game starts.',
  },

  {
    id: 12,
    question: '12) What happens if there is a tie?',
    answer: 'Tie breakers for: Football – Basketball  Baseball-',
  },

  {
    id: 13,
    question: '13) When and how do I redeem my prizes?',
    answer:
      'Winners are contacted immediately via email to discuss logistics for prize delivery and athlete experience dates. Cash and all other memorabilia prizes will be disbursed at the conclusion of the contest upon user identity verification.',
  },

  {
    id: 14,
    question: '14) How can I earn free vouchers to redeem for entry into a paid contest?',
    answer: 'You can earn free vouchers through our referral program.',
  },

  // {
  //   id: 15,
  //   question: '15) How can&nbsp;I earn free vouchers to&nbsp;redeem for entry into a&nbsp;paid contest?',
  //   answer:
  //     'You can earn free vouchers through our referral program, and also by&nbsp;placing in&nbsp;the rope perfect if&nbsp;each games leaderboard.',
  // },
];
