/* eslint-disable react/prop-types */
import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';
import Oval from '../../../shared/Image/oval.svg';
import rectanglePlay from '../../../shared/Image/rectangle_play.png';

const propTypes = {};

const defaultProps = {};

const settings = {
  className: 'preview__img',
  infinite: true,
  centerMode: true,
  draggable: true,
  centerPadding: '0',
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  arrows: false,
  settings: 'unslick',
  responsive: [
    {
      breakpoint: 768,
      settings: {
        autoplay: false,
        slidesToShow: 1,
        arrows: false,
        dots: true,
      },
    },
  ],
};

function Preview(props) {
  const { title } = props;

  const [isOpen, setIsOpen] = useState(undefined);
  return (
    <Fragment>
      <StyledPreview>
        <PreviewTitle>
          <Icon>
            <img alt="icon" src={Oval} />
          </Icon>
          {title}
        </PreviewTitle>
        <div className="preview_wrap">
          <PreviewImg>
            <img alt="" style={{ width: '100%' }} src={rectanglePlay} />
            <StyledButton
              onClick={() => {
                setIsOpen(true);
              }}
            />
          </PreviewImg>
          <PreviewText>SURVIVE & ADVANCE IS A SURVIVOR STYLE CONTEST WITH PLAYERS INSTEAD OF TEAMS.</PreviewText>
        </div>
        {/* <Slider {...settings}>
          <div className="slider_wrap">
            <img alt="" style={{ width: '100%' }} src={rectanglePlay} />
            <PreviewText>SURVIVE & ADVANCE IS A SURVIVOR STYLE CONTEST WITH PLAYERS INSTEAD OF TEAMS.</PreviewText>
            <StyledButton
              onClick={() => {
                setIsOpen(true);
              }}
            />
          </div>
        </Slider> */}
        <ModalVideo
          autoplay={1}
          channel="youtube"
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          videoId="18H5kr60JsI"
          className="modalVideo"
        />
      </StyledPreview>
    </Fragment>
  );
}

const StyledPreview = styled.div`
  background: #1b1b21;
  padding: 20px 10px;
  margin: 30px 0;
  border-radius: 8px;
  @media (min-width: 768px) {
    background: transparent;
  }
`;

const PreviewTitle = styled.h2`
  position: relative;
  font-size: 22px;
  line-height: 28px;
  text-transform: uppercase;
  margin-top: 0;
  padding-left: 5px;
  @media (min-width: 768px) {
    padding-left: 0px;
  }
`;

const PreviewText = styled.h3`
  margin-top: 10px;
  /* margin-bottom: 30px; */
  margin-bottom: 0;
  @media (min-width: 768px) {
    padding: 0 15px;
  }
`;

const Icon = styled.span`
  display: inline-block;
  margin-right: 15px;
`;

const StyledButton = styled.button`
  background: #ce3c4d;
  padding: 24px;
  border-radius: 50px;
  border: 0;
  box-shadow: 0px 2px 4px 0px;
  position: absolute;
  top: calc(50% - 24px);
  left: 0;
  right: 0;
  margin: 0 auto;
  cursor: pointer;
  bottom: 0;
  margin: 0 auto;
  cursor: pointer;
  height: 24px;
  width: 24px;
  &:hover {
    background: #e8495b;
    color: #fff;
    &:after {
      border-color: #e8495b #e8495b #e8495b #fff;
    }
  }
  &:after {
    width: 0px;
    height: 0px;
    background: white;
    position: absolute;
    top: calc(50% - -5px);
    left: calc(50% - 4px);
    margin-top: -12px;
    content: ' ';
    border-style: solid;
    border-width: 7px 0 7px 12px;
    border-color: #ce3c4d #ce3c4d #ce3c4d #fff;
  }
`;

const PreviewImg = styled.div`
  position: relative;
`;

Preview.propTypes = propTypes;
Preview.defaultProps = defaultProps;

// export default enhance(HomePage);
export default Preview;
