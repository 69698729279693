import React from 'react';
import styled from 'styled-components';
import target from './targetGray.svg';

const propTypes = {};

const defaultProps = {};

function PlayerPickemContest() {
  return (
    <PlayerPickemContestStyled>
      <ContestText>
        <TitleContest>Player Pick`em</TitleContest>
        <DescriptionContest>
          Player Pick`em is a weekly contest with weekly winners. To get started, user is shown 6 stat objectives and
          asked for 3 player for each stat for a total of 18 picks.
        </DescriptionContest>
      </ContestText>
      <StatCategoryExample>
        <h4 style={{ textAlign: 'center' }}>The stat categories offered in the Player Pick`em contest are:</h4>
        <StatcategoryExampleList>
          <StatCategoryExampleItem>
            <CategoryName>Passing TDs</CategoryName>
            <CategoryText>
              2+
              <img src={target} alt="" />
            </CategoryText>
          </StatCategoryExampleItem>
          <StatCategoryExampleItem>
            <CategoryName>Rushing TD</CategoryName>
            <CategoryText>
              1+
              <img src={target} alt="" />
            </CategoryText>
          </StatCategoryExampleItem>
          <StatCategoryExampleItem>
            <CategoryName>Receiving TD</CategoryName>
            <CategoryText>
              1+
              <img src={target} alt="" />
            </CategoryText>
          </StatCategoryExampleItem>
          <StatCategoryExampleItem>
            <CategoryName>Passing Yards</CategoryName>
            <CategoryText>
              300+
              <img src={target} alt="" />
            </CategoryText>
          </StatCategoryExampleItem>
          <StatCategoryExampleItem>
            <CategoryName>Rushing Yards</CategoryName>
            <CategoryText>
              100+
              <img src={target} alt="" />
            </CategoryText>
          </StatCategoryExampleItem>
          <StatCategoryExampleItem>
            <CategoryName>Rushing Yards</CategoryName>
            <CategoryText>
              300+
              <img src={target} alt="" />
            </CategoryText>
          </StatCategoryExampleItem>
        </StatcategoryExampleList>
        <h4>
          For example, 2 or more Passing Touchdowns category, select (3) different quarterbacks to throw for at least 2
          or more touchdowns.
        </h4>
        <h4>
          For example, 300 or more Passing Yards category, select (3) different quarterbacks to throw for 300 or more
          yards.
        </h4>
      </StatCategoryExample>
      <List>
        <Item>Each user can select a player once per stat category.</Item>
        <Item>The goal is to be the user with the most correct picks to win the weekly contest.</Item>
      </List>
    </PlayerPickemContestStyled>
  );
}

const PlayerPickemContestStyled = styled.div`
  max-width: 700px;
  margin: 0 auto;
`;

const ContestText = styled.div`
  text-align: center;
  margin-bottom: 30px;
`;

const TitleContest = styled.h2`
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  @media (max-width: 768px) {
  }
`;

const DescriptionContest = styled.p`
  font-size: 17px;
  line-height: 25px;
`;

const List = styled.ol`
  list-style: none;
  padding: 0;
  margin-bottom: 40px;
`;

const Item = styled.li`
  counter-increment: my-awesome-counter;
  position: relative;
  padding: 20px 30px;
  background: #26262e;
  border-radius: 4px;
  padding-left: 70px;
  &:nth-child(2n) {
    background: transparent;
  }
  &:before {
    content: counter(my-awesome-counter);
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    position: absolute;
    background: #5d5ce2;
    top: 15px;
    left: 30px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
  }
  @media (max-width: 768px) {
    padding: 20px;
    margin: 0 -20px;
    padding-left: 70px;
  }
`;

const StatcategoryExampleList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0 -50px;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    margin: 0 -15px;
  }
`;

const StatCategoryExampleItem = styled.li`
  position: relative;
  width: 100%;
  font-weight: 700;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #26262e;
  width: 31%;
  margin: 1%;
`;

const CategoryName = styled.div`
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 14px;
  margin-bottom: 15px;
`;

const CategoryText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 22px;
  line-height: 28px;
  text-transform: uppercase;
  font-weight: 700;
  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const StatCategoryExample = styled.div``;

PlayerPickemContest.propTypes = propTypes;
PlayerPickemContest.defaultProps = defaultProps;

// export default HomePage;
export default PlayerPickemContest;
