import mlbSurviveHeadshot from 'v2/components/shared/Image/playerHeadshots/mlb/survive.svg';
import nbaSurviveHeadshot from 'v2/components/shared/Image/playerHeadshots/nba/survive.svg';
import nflSurviveHeadshot from 'v2/components/shared/Image/playerHeadshots/nfl/updated_survive.svg';
import defaultAvatar from 'v2/components/Contests/shared/Image/defaultAvatar.png';

export default {
  nfl: nflSurviveHeadshot,
  nba: nbaSurviveHeadshot,
  mlb: mlbSurviveHeadshot,
  default: defaultAvatar,
};
