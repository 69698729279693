import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { progressIconItemStyles, progressIconsStyles, progressIconValStyles } from '../styles';
import peopleIcon from '../../Image/people_icon.svg';
import upIcon from '../../Image/up_icon.svg';

const propTypes = {};

const defaultProps = {};

function SurviveIconItems(props) {
  const { correctPicks, submitPicks } = props;
  return (
    <Fragment>
      <StyledProgressIcons>
        <StyledProgressIconItem>
          <img src={upIcon} alt="upIcon" />
          <StyledProgressIconVal>{`${correctPicks}/${submitPicks}`}</StyledProgressIconVal>
        </StyledProgressIconItem>
        {/* <StyledProgressIconItem>
          <img src={peopleIcon} alt="peopleIcon" />
          <StyledProgressIconVal>50</StyledProgressIconVal>
        </StyledProgressIconItem> */}
      </StyledProgressIcons>
    </Fragment>
  );
}

const StyledProgressIcons = styled.div`
  ${progressIconsStyles}
`;

const StyledProgressIconItem = styled.div`
  ${progressIconItemStyles}
`;

const StyledProgressIconVal = styled.div`
  ${progressIconValStyles}
`;

SurviveIconItems.propTypes = propTypes;
SurviveIconItems.defaultProps = defaultProps;

export default SurviveIconItems;
