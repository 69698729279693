// @flow

import React from 'react';
import useWindowSize from 'v2/services/useWindowSize';

import DesktopVersion from './versions/Desktop';
import MobileVersion from './versions/Mobile';

function PromoPage() {
  // useEffect(() => {
  //   const getRefs = async () => {
  //     fetchHomePageRefs();
  //   };

  //   getRefs();
  // }, []);

  const [documentWidth] = useWindowSize();
  const isMobile = documentWidth < 450;

  if (isMobile) return <MobileVersion />;
  return <DesktopVersion />;
}

// const mapStateToProps = (state) => ({
//   specialOffers: getRefsOffers(state),
// });

// const mapDispatchToProps = (dispatch) => ({
//   fetchHomePageRefs: () => dispatch(actions.fetchHomePageRefs()),
// });

// const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default PromoPage;
