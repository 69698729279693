// @flow

import React from 'react';
import styled from 'styled-components';

type SocialIconProps = {
  src: String,
  alt: String,
};

function SocialIcon(params: SocialIconProps) {
  return (
    <div>
      <img {...params} />
    </div>
  );
}

export default SocialIcon;
