import React from 'react';
import styled from 'styled-components';

const propTypes = {};

const defaultProps = {};

function SurviveAdvanceContest() {
  return (
    <SurviveAdvanceContestStyled>
      <ContestText>
        <TitleContest>Survive & Advance</TitleContest>
        <DescriptionContest>Survive & Advance is a survivor style contest offered weekly.</DescriptionContest>
      </ContestText>
      <List>
        <Item>Each contest lasts until all users have been eliminated.</Item>
        <Item>Make picks for players to achieve specified stat objectives.</Item>
        <Item>Advance to the next week with correct picks.</Item>
        <Item>
          All users can only select each player once for each stat category throughout the duration of the contest.
        </Item>
        <Item>Any incorrect pick, results in elimination from the contest.</Item>
        <Item>Survive & Advance with the most correct picks and be the last one remaining to win.</Item>
      </List>
    </SurviveAdvanceContestStyled>
  );
}

const SurviveAdvanceContestStyled = styled.div`
  max-width: 700px;
  margin: 0 auto;
`;

const ContestText = styled.div`
  text-align: center;
  margin-bottom: 30px;
`;

const TitleContest = styled.h2`
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  @media (max-width: 768px) {
  }
`;

const DescriptionContest = styled.p`
  font-size: 17px;
  line-height: 25px;
`;

const List = styled.ol`
  list-style: none;
  padding: 0;
  margin-bottom: 40px;
`;

const Item = styled.li`
  counter-increment: my-awesome-counter;
  position: relative;
  padding: 20px 30px;
  background: #26262e;
  border-radius: 4px;
  padding-left: 70px;
  &:nth-child(2n) {
    background: transparent;
  }
  &:before {
    content: counter(my-awesome-counter);
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    position: absolute;
    background: #5d5ce2;
    top: 15px;
    left: 30px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
  }
  @media (max-width: 768px) {
    padding: 20px;
    margin: 0 -20px;
    padding-left: 70px;
  }
`;

SurviveAdvanceContest.propTypes = propTypes;
SurviveAdvanceContest.defaultProps = defaultProps;

// export default HomePage;
export default SurviveAdvanceContest;
