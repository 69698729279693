// @flow
import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import LogoImage from 'v2/components/shared/Image';
import { getContestLogo } from './helpers';

const defaultProps = {
  statCategoriesList: [],
};

type getLogoProps = {
  league: string,
  kind: string,
  statCategoriesList?: string[],
};

const ContestLogo = ({ statCategoriesList, kind, league }: getLogoProps) => {
  return <StyledLogoImage img={getContestLogo(league, kind, R.map(R.prop('subject'), statCategoriesList))} />;
};

const StyledLogoImage = styled(LogoImage)`
  width: 13.5em;
  padding: 0.5em 0;
`;

ContestLogo.defaultProps = defaultProps;
export default ContestLogo;
