import React from 'react';
import PhotoBlock from 'v2/components/PromoPages/shared/PhotoBlock';
import promoImg5 from 'v2/components/shared/Image/promo/2/5.png';

function block() {
  return (
    <PhotoBlock.Wrapper>
      <PhotoBlock src={promoImg5} />
    </PhotoBlock.Wrapper>
  );
}

export default block;
