// @flow

import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { noGamesIcons } from 'v2/constants/UI/noGamesIcons';

import { JoinedAllAvailableGamesText } from 'v2/components/noGamesTexts';
import Logo from './Logo';

const filterByLeague = (league) => R.filter(R.propEq('league', R.toLower(league)));
const filterByKind = (kind) => R.filter(R.propEq('kind', R.toLower(kind)));

const getName = (isMyGames, isEmptyCollection) => {
  if (isMyGames) return 'noGamesJoined';
  if (isEmptyCollection) return 'noGames';
  return 'joinedAllAvailableGames';
};

const getAvailableGamesLogo = R.curry((league, kind, isMyGames, myCollection) => {
  if ((league, kind)) {
    return (
      R.path([getName(isMyGames, R.isEmpty(myCollection)), R.toLower(league), R.toLower(kind)], noGamesIcons) ||
      R.path(['default'], noGamesIcons)
    );
  }

  return R.path(['default'], noGamesIcons);
});

const defaultProps = {
  league: 'nfl',
};

function NoAvailableGames({ myGamesCollection, league, isMyGames, kind, ...rest }) {
  const { logo, link, text: TextComponent } = R.compose(
    getAvailableGamesLogo(league, kind, isMyGames),
    R.when(() => R.not(isMyGames), R.compose(filterByLeague(league), filterByKind(kind))),
  )(myGamesCollection);

  return (
    <NoAvailableGamesContainer to={link}>
      <Logo logo={logo} />
      {TextComponent && <TextComponent />}
    </NoAvailableGamesContainer>
  );
}

const NoAvailableGamesContainer = styled(Link)`
  padding: 75px;
  display: flex;
  flex-direction: column;
  min-height: 465px;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
  text-decoration: none !important;
  /* background: #26262e; */
  /* border: 1px dashed #26262e; */
  border-radius: 8px;

  @media (max-width: 625px) {
    padding: 0;
  }

  @media (max-width: 425px) {
    min-height: 265px;
  }
`;

const NoAvailableGamesTitle = styled.span`
  font-size: 29px;
  text-transform: capitalize;
  line-height: 1.5em;
  text-align: center;
  max-width: 525px;
  font-weight: ${({ isMyGames }) => isMyGames && 'bold'};
  padding: 0 25px;
  @media (max-width: 468px) {
    font-size: 15px;
    max-width: 262px;
  }
`;
NoAvailableGames.defaultProps = defaultProps;
export default NoAvailableGames;
