import React from 'react';
import styled from 'styled-components';
import { withRouter, NavLink, useLocation } from 'react-router-dom';
import { getPath, ROOT_PATH, LEAGUE_PATH } from 'v2/constants/router';

// type SportItemProps = {};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function SportItem({ available, children, className, logo, isLink, location, league, isCurrentSport, ...props }) {
  const { search } = location;
  const query = useQuery();
  const currentType = query.get('type');

  const url = currentType
    ? `${league ? getPath(LEAGUE_PATH, { league }) : ROOT_PATH}${`?type=${currentType}`}`
    : league
    ? getPath(LEAGUE_PATH, { league })
    : ROOT_PATH;

  return (
    <StyledSportItem {...props} available={available} className={className} active={isCurrentSport}>
      {isLink && available && <Tab to={url} />}
      {logo && <StyledSportLogo {...logo} />}
      <StyledSportTitle>{children}</StyledSportTitle>
    </StyledSportItem>
  );
}

const StyledSportLogo = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 10px;
  opacity: 0.5;
`;

const StyledSportTitle = styled.span`
  color: #fff;
  text-transform: uppercase;

  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: bold;
`;

const Tab = styled(NavLink)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const StyledSportItem = styled.li`
  /* height: 100%; */
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  background: #26262e;
  padding: 15px 25px;
  cursor: pointer;
  transition: filter 0.2s ease-out;
  -webkit-appearance: none !important;

  ${StyledSportTitle} {
    color: ${({ available }) => !available && '#656579'};
  }

  cursor: ${({ available }) => !available && 'not-allowed'};
  /* pointer-events: ${({ disabled }) => disabled && 'none'}; */
  ${StyledSportLogo} {
    opacity: ${({ available }) => !available && '0.2'};
  }

  &:hover {
    filter: brightness(125%);
  }

  filter: ${({ active }) => active && 'brightness(125%)'};
`;

export default withRouter(SportItem);
