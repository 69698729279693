import pickem from 'v2/components/shared/Image/pickem.svg';
import survive from 'v2/components/shared/Image/survive.svg';

import basketball from '../../components/shared/Image/Sports/color/new_basketball.svg';
import golf from '../../components/shared/Image/Sports/color/golf.svg';
import hockey from '../../components/shared/Image/Sports/color/hockey.svg';
import soccer from '../../components/shared/Image/Sports/color/soccer.svg';
import baseball from '../../components/shared/Image/Sports/color/baseball.svg';
import football from '../../components/shared/Image/Sports/color/football.svg';

// import football from '../../components/shared/Image/Sports/BW/football.svg';
// import basketball from '../../components/shared/Image/Sports/BW/basketball.svg';
// import golf from '../../components/shared/Image/Sports/golf.svg';
// import hockey from '../../components/shared/Image/Sports/hockey.svg';
// import soccer from '../../components/shared/Image/Sports/soccer.svg';
// import baseball from '../../components/shared/Image/Sports/baseball.svg';

const ButtonsData = [
  // {
  //   id: 1,
  //   title: 'all',
  //   activeByDefault: true,
  // },
  {
    id: 2,
    league: 'NFL',
    title: 'football',
    alt: 'football',
    logo: football,
    activeByDefault: true,
  },
  {
    id: 3,
    league: 'NBA',
    title: 'basketball',
    alt: 'basketball',
    logo: basketball,
    activeByDefault: false,
  },
  {
    id: 5,
    title: 'baseball',
    league: 'MLB',
    alt: 'baseball',
    logo: baseball,
    activeByDefault: false,
  },
  // {
  //   id: 4,
  //   title: 'hockey',
  //   league: 'await',
  //   logo: hockey,
  //   activeByDefault: false,
  //   inactive: true,
  // },
  // {
  //   id: 6,
  //   title: 'soccer',
  //   league: 'await',
  //   alt: 'soccer',
  //   logo: soccer,
  //   activeByDefault: false,
  //   inactive: true,
  // },
  // {
  //   id: 7,
  //   title: 'golf',
  //   league: 'await',
  //   alt: 'golf',
  //   logo: golf,
  //   activeByDefault: false,
  //   inactive: true,
  // },
];

const ButtonsContestKind = [
  {
    id: 1,
    kind: 'survive',
    title: 'Survive & Advance',
    inactive: false,
    logo: {
      alt: 'Survive & Advance logo',
      src: survive,
    },
  },
  {
    id: 0,
    kind: 'weekly',
    title: 'Player Pick’em',
    inactive: false,
    logo: {
      alt: 'Player Pick’em logo',
      src: pickem,
    },
  },
  {
    id: 3,
    kind: '_survive',
    title: 'Survive & Advance',
    inactive: true,
    logo: {
      alt: 'Survive & Advance logo',
      src: survive,
    },
  },
  {
    id: 2,
    kind: '_weekly',
    title: 'Player Pick’em',
    inactive: true,
    logo: {
      alt: 'Player Pick’em logo',
      src: pickem,
    },
  },
];

export { ButtonsContestKind, ButtonsData };
