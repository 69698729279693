// @flow

import React from 'react';
import PromoPageWrapper from 'v2/components/PromoPages/2/shared/PromoPageWrapper';

import VideoPreview from '../../blocks/VideoPreview';
import PhotoBlcok1 from '../../blocks/1';
import PhotoBlcok2 from '../../blocks/2';
import PhotoBlcok3 from '../../blocks/3';
import PhotoBlcok4 from '../../blocks/4';
import PhotoBlcok5 from '../../blocks/5';
import PhotoBlcok6 from '../../blocks/6';
import PhotoBlcok7 from '../../blocks/7';

function MobileVersion() {
  return (
    <div>
      <VideoPreview showText={false} />
      <PromoPageWrapper>
        <PhotoBlcok7 />
        <PhotoBlcok1 />
        <PhotoBlcok3 />
        <PhotoBlcok4 />
        <PhotoBlcok2 />
        <PhotoBlcok5 />
        <PhotoBlcok6 />
      </PromoPageWrapper>
    </div>
  );
}

export default MobileVersion;
