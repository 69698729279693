// @flow

import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import useWindowSize from 'v2/services/useWindowSize';

import { SIGN_UP_REFERAL_PATH, getPath } from 'v2/constants/router';
import { getUserReferalCode } from 'v2/selectors/session';

const defaultProps = {
  color: '#5d5ce2',
  icon: null,
};

type ReferalButtonProps = {
  referalCode: string,
  title: string,
  icon?: string,
  color?: string,
  Adapter: any,
};

function ReferalButton({ Adapter, title, icon, color, referalCode }: ReferalButtonProps) {
  const { origin } = window.location;
  const [documentWidth] = useWindowSize();
  const isMobile = documentWidth < 660;

  return (
    <Adapter
      subject="subject"
      body="body"
      separator="separator"
      url={`${origin}${getPath(SIGN_UP_REFERAL_PATH, { referalCode })}`}
    >
      <ReferalButtonContainer color={color} icon={icon}>
        {!isMobile && title}
      </ReferalButtonContainer>
    </Adapter>
  );
}

const ReferalButtonContainer = styled.span`
  width: 100%;
  color: #fff;
  cursor: pointer;
  background: ${({ color }) => color};
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 18px;
  text-transform: uppercase;
  padding: 11px 17px 9px;
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  transition: filter 0.5s linear;
  text-decoration: none !important;

  &:before {
    content: url(${({ icon }) => icon});
    margin-right: 15px;
    margin-top: 1px;
  }

  &:hover {
    filter: brightness(115%);
  }

  @media (max-width: 660px) {
    justify-content: center;

    &:before {
      margin-right: 0;
    }
  }
`;

const mapStateToProps = (store) => ({
  referalCode: getUserReferalCode(store),
});

ReferalButton.defaultProps = defaultProps;

export default connect(mapStateToProps)(ReferalButton);
