import React from 'react';
import PhotoBlock from 'v2/components/PromoPages/shared/PhotoBlock';
import promoImg6 from 'v2/components/shared/Image/promo/2/6.png';

function block() {
  return (
    <PhotoBlock.Wrapper>
      <PhotoBlock src={promoImg6} />
    </PhotoBlock.Wrapper>
  );
}

export default block;
