// @flow

import React from 'react';
import styled from 'styled-components';

import Logo from './Logo';
import NotificationAboutTime from './AboutTime';

type NotificationProps = {
  title?: string,
  body: string,
  // logo?: string,
  createdAt?: string,
  showLogo?: boolean,
  toast?: boolean,
  contestId?: number,
  contestKind?: string,
  kind?: string,
  league?: string,
  kind?: string,
};

const defaultProps = {
  title: "You've been eliminated.",
  // logo: null,
  createdAt: null,
  showLogo: true,
  toast: false,
  contestId: null,
  contestKind: null,
  kind: null,
  league: null,
};

const notificationTexts = [
  { id: 1, contestKind: 'survive', body: 'Survive & Advance' },
  { id: 2, contestKind: 'weekly', body: 'Player Pick’em' },
];

function Notification({ toast, showLogo, title, body, createdAt, contestKind, league, kind }: NotificationProps) {
  // const getNotificationText = (text) => text && text.replace(/(?:\r\n|\r|\n)/g, '<br />');
  const specialText =
    kind === 'new_contest' &&
    notificationTexts.find((notificationText) => notificationText.contestKind === contestKind);

  return (
    <NotificationWrapper toast={toast}>
      {showLogo && <StyledLogo contestKind={contestKind} league={league} />}
      {/* <img src={survive} style={{ width: 45 }} /> */}
      <NotificationDescription>
        <NotificationText>
          {title && <NotificationTitle>{title}</NotificationTitle>}
          {body && (
            <NotificationBody>
              {specialText && (
                <NotificationContestName contestKind={contestKind}>{specialText.body} </NotificationContestName>
              )}{' '}
              {body}
            </NotificationBody>
          )}
          {/* {title && <NotificationTitle dangerouslySetInnerHTML={{ __html: getNotificationText(title) }} />} */}
          {/* {kind === 'new_contest' && (
            <NotificationBody contestKind={contestKind}>
              {notificationTexts.find((notificationText) => notificationText.contestKind == contestKind).body} -
            </NotificationBody>
          )}
          {body && <NotificationBody dangerouslySetInnerHTML={{ __html: getNotificationText(body) }} />} */}
        </NotificationText>
        {createdAt && <StyledAboutTime createdAt={createdAt} />}
      </NotificationDescription>
    </NotificationWrapper>
  );
}

const StyledLogo = styled(Logo)`
  margin-top: 5px;
`;

const StyledAboutTime = styled(NotificationAboutTime)``;

const NotificationTitle = styled.span`
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0px;
  text-transform: capitalize;
  line-height: 1.5em;
  margin-bottom: 5px;
  display: block;
`;

const NotificationBody = styled(NotificationTitle)`
  color: #656579;
  font-size: 13px;
  font-weight: normal;
  letter-spacing: 0px;
  text-transform: capitalize;
  line-height: 1.5em;
  margin-bottom: 10px;
`;

const NotificationContestName = styled(NotificationBody)`
  display: inline-block;
  color: ${({ contestKind }) => contestKind === 'survive' && '#5D5CE2'} !important;
  color: ${({ contestKind }) => contestKind === 'weekly' && '#0BD855'} !important;
  &:after {
    content: '';
    width: 0px;
    height: 1px;
    display: block;
    background: ${({ contestKind }) => contestKind === 'survive' && '#5D5CE2'} !important;
    background: ${({ contestKind }) => contestKind === 'weekly' && '#0BD855'} !important;
    transition: 300ms;
  }
`;

const NotificationText = styled.div``;

const NotificationDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  min-width: 250px;
`;

const NotificationWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  ${StyledLogo} {
    /* height: ${({ toast }) => toast && '45px'}; */
    width: ${({ toast }) => toast && '45px'};
  }

  ${NotificationTitle} {
    font-size: ${({ toast }) => toast && '14px'};
    margin-bottom: ${({ toast }) => toast && '5px'};
  }

  ${NotificationBody} {
    color: ${({ toast }) => toast && '#fff'};
  }

  ${NotificationText} {
    margin-bottom: ${({ toast }) => toast && '10px'};
  }

  ${StyledAboutTime} {
    font-size: ${({ toast }) => toast && '13px'};
  }

  &:hover {
    ${NotificationContestName} {
      &:after {
        width: 100%;
      }
    }
  }
`;

Notification.defaultProps = defaultProps;
export default Notification;
