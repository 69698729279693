import styled, { css } from 'styled-components';

import ReactSelect from '../../formBase/ReactSelect';

export const style = css`
  font-size: 14px;
  line-height: 1.42857;
  & .react-select__control {
    cursor: pointer;
    min-height: 32px;
    font-weight: normal;
    background-color: #26262e;
    border: none;
    border-radius: 2px;
    box-shadow: none;
    transition: filter 0.2s linear;
    border: ${({ isError }) => isError && '1px solid #e80c51'};

    &:hover {
      filter: brightness(105%);
    }

    ${({ disabled }) =>
      disabled &&
      css`
        cursor: not-allowed;
        ${'' /* background-color: #dadbdb; */}
        background-color: #26262e;
        opacity: 1;
      `}
  }

  .react-select__single-value {
    color: #fff;
  }

  .react-select__placeholder {
    color: #656579;
  }

  .has-error & .react-select__control,
  .form__group-errored & .react-select__control {
    border-color: red;
  }

  & .react-select__control--is-focused {
    ${'' /* border: 1px solid #479ccf; */}
    box-shadow: none;
    box-shadow: none;
    outline: 0;
    transition: filter 0.2s linear;
    border: 1px solid #5d5ce2;

    &:hover {
      filter: brightness(115%);
      border-color: #5d5ce2;
      box-shadow: none;
    }
  }

  .has-error & .react-select__control--is-focused,
  .form__group-errored & .react-select__control--is-focused {
    ${'' /* border-color: #a94442; */}
    border: none;
  }

  .react-select__option {
    background-color: #26262e;
    transition: filter 0.2s linear;
    padding-top: 12px;
    padding-bottom: 12px;
    cursor: pointer;
    &:hover {
      filter: brightness(150%);
    }
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__value-container {
    ${'' /* padding-top: 0;
    padding-bottom: 0; */}
    color: #fff;
    padding: 8px;
  }

  & .react-select__menu {
    margin: 3px 0 0;
    font-weight: normal;
    font-size: 14px;
    background-color: #26262e;
  }

  & .react-select__indicator {
    padding: 10px;
    background-color: #26262e;
  }
`;

const ClassicSelect = styled(ReactSelect)`
  ${style}
`;

export default ClassicSelect;
