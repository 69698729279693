// nfl
import doubleDouble from 'v2/components/shared/Image/contest_stats/nba/survive/1_double-double.svg';
import pointsPointers from 'v2/components/shared/Image/contest_stats/nba/survive/2_points&3pointers.svg';
import pointsAssists from 'v2/components/shared/Image/contest_stats/nba/survive/2_points&assists.svg';
import pointsRebounds from 'v2/components/shared/Image/contest_stats/nba/survive/2_points&rebounds.svg';
import pointsSteals from 'v2/components/shared/Image/contest_stats/nba/survive/2_points&steals.svg';
import reboundsPointers from 'v2/components/shared/Image/contest_stats/nba/survive/2_rebounds&3pointers.svg';
import reboundsAssists from 'v2/components/shared/Image/contest_stats/nba/survive/2_rebounds&assists.svg';
import pointsReboundsAssists from 'v2/components/shared/Image/contest_stats/nba/survive/3_points&rebounds&assists.svg';
import defaulSurviveNba from 'v2/components/shared/Image/contest_stats/nba/survive/default.svg';
import defaulWeeklyNba from 'v2/components/shared/Image/contest_stats/nba/weekly/default.svg';

// nba
import touchdown from 'v2/components/shared/Image/contest_stats/nfl/survive/touchdawn.svg';
import touchdownAndYards from 'v2/components/shared/Image/contest_stats/nfl/survive/tds_yards.svg';
import yardage from 'v2/components/shared/Image/contest_stats/nfl/survive/yardage.svg';

// mlb
// import hitsRbis from 'v2/components/shared/Image/contest_stats/mlb/survive/hits-rbis-1.svg';
// import homerunsRbis from 'v2/components/shared/Image/contest_stats/mlb/survive/homeruns-rbis-1.svg';
// import savesStrikeouts from 'v2/components/shared/Image/contest_stats/mlb/survive/saves-strikeouts-1.svg';
import winsSaves from 'v2/components/shared/Image/contest_stats/mlb/survive/wins-saves-1.svg';
import winsStrikeouts from 'v2/components/shared/Image/contest_stats/mlb/survive/wins-strikeouts-1.svg';
import winsStrikeoutsSaves from 'v2/components/shared/Image/contest_stats/mlb/survive/wins-strikeouts-saves-1.svg';
import hitHomerunsWins from 'v2/components/shared/Image/contest_stats/mlb/survive/hit-homeruns-wins-1.svg';
import hitsStolenBases from 'v2/components/shared/Image/contest_stats/mlb/survive/hits-stolen-bases-1.svg';
import homerunsWins from 'v2/components/shared/Image/contest_stats/mlb/survive/homeruns-wins-1.svg';
import defaulWeeklyMlb from 'v2/components/shared/Image/contest_stats/mlb/survive/homeruns-wins-1.svg';
import hitHomeruns from 'v2/components/shared/Image/contest_stats/mlb/survive/baseball_hits-runs-2.svg';

import {
  DOUBLE_DOUBLE,
  STEALS,
  POINTERS_MADE,
  ASSISTS,
  REBOUNDS,
  POINTS,
  HITS,
  HOMERUNS,
  STOLEN_BASES,
  WINS,
  SAVES,
  STRIKEOUTS,
} from './statCategories';

export default {
  nfl: {
    survive: {
      default: defaulSurviveNba,
      scopes: [],
      overlaps: [
        [['TDS', 'YARDS'], touchdownAndYards],
        [['TDS'], touchdown],
        [['YARDS'], yardage],
      ],
    },
    weekly: {
      default: defaulWeeklyNba,
      scopes: [],
      overlaps: [],
    },
  },
  nba: {
    survive: {
      default: defaulSurviveNba,
      scopes: [
        [[DOUBLE_DOUBLE], doubleDouble],
        [[POINTS, POINTERS_MADE], pointsPointers],
        [[POINTS, ASSISTS], pointsAssists],
        [[POINTS, REBOUNDS, ASSISTS], pointsReboundsAssists],
        [[POINTS, STEALS], pointsSteals],
        [[POINTS, REBOUNDS], pointsRebounds],
        [[REBOUNDS, POINTERS_MADE], reboundsPointers],
        [[ASSISTS, REBOUNDS], reboundsAssists],
      ],
      overlaps: [],
    },
    weekly: {
      default: defaulWeeklyNba,
      scopes: [],
      overlaps: [],
    },
  },
  mlb: {
    survive: {
      default: defaulWeeklyMlb,
      scopes: [
        [[HITS, HOMERUNS, WINS], hitHomerunsWins],
        [[HITS, HOMERUNS], hitHomeruns],
        [[HITS, STOLEN_BASES], hitsStolenBases],
        [[WINS, SAVES], winsSaves],
        [[WINS, STRIKEOUTS, SAVES], winsStrikeoutsSaves],
        [[WINS, STRIKEOUTS], winsStrikeouts],
        [[HOMERUNS, WINS], homerunsWins],
      ],
      overlaps: [],
    },
    weekly: {
      default: defaulWeeklyNba,
      scopes: [],
      overlaps: [],
    },
  },
};
