import { css } from 'styled-components';

export const statusStyles = css`
  display: flex;
  ${'' /* margin-bottom: 8px; */}
  color: ${(props) => (props.isGray ? '#656579' : '#fff')};
  margin-bottom: 1em;
`;

export const prizesBlockStyles = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const prizeBlockStyles = css`
  display: flex;
  ${'' /* flex-direction: ${(props) => (props.isRow ? 'row' : 'column')}; */}
  justify-content: space-between;
  width: 100%;
  ${'' /* min-height: 78px; */}
  padding: 10px;
  box-shadow: 0px 0px 0px 1px #26262e;

  border-radius: 8px;
  box-sizing: border-box;
  ${'' /* &:last-child {
    margin-left: ${(props) => props.marginRight && '8px'};
  } */}
`;

export const avatarBlockStyles = css`
  position: absolute;
  top: 50%;
  right: 0;
  z-index: ${(props) => (props.main ? '2' : '1')};
  margin-top: ${(props) => (props.main ? '-25px' : '-20px')};
  width: ${(props) => (props.main ? '50px' : '40px')};
  height: ${(props) => (props.main ? '50px' : '40px')};
  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: contain;
`;

export const avatarBlockLeftStyles = css`
  right: auto;
  left: 0;
`;

export const progressInfoStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  ${'' /* width: 60%; */}
`;

export const progressTextStyles = css`
  display: block;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  letter-spacing: 0.5px;
`;

export const prizeStyles = css`
  position: relative;
  padding: 10px 15px 10px 13px;
  background-color: #26262e;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background-color: ${(props) => props.borderColor};
    border-radius: 8px 0 0 8px;
  }
`;

export const progressIconsStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -4px;
`;

export const progressIconItemStyles = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 12px;
  img {
    height: 17px;
    margin-bottom: 5px;
  }
`;

export const progressIconValStyles = css`
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
`;
