import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { history } from 'v2/stores/configureStore';
import { ROOT_PATH } from 'v2/constants/router';

// import StepBackIcon from 'v2/components/shared/Image/Icon/step_back_icon.svg';
import homeIcon from 'v2/components/shared/Image/Icon/home.svg';

const isAvailableToBack = (stackLength, key) => stackLength > 2 && key;

function HeadrBackButton() {
  const [currentPathname, setCurrentPathname] = useState(history.location.pathname);
  const [isShow, setIsShow] = useState(isAvailableToBack(history.length, null));
  history.listen(({ key, pathname }) => {
    if (pathname !== currentPathname) {
      setIsShow(isAvailableToBack(history.length, key));
      setCurrentPathname(pathname);
    }
  });

  useEffect(() => {
    const { length, location } = history;
    setIsShow(isAvailableToBack(length, location.key));
  }, []);

  return (
    <BackButton onClick={() => history.push(ROOT_PATH)} type="button">
      <BackButtonImg src={homeIcon} alt="back" />
    </BackButton>
  );
}

export default HeadrBackButton;

// styles

const BackButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  &:after {
    content: '';
    display: block;
    width: 50px;
    height: 50px;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
  }
`;

const BackButtonImg = styled.img`
  dispatch: flex;
  margin-top: -2px;
  width: 23px;
  filter: brightness(1.8);
`;
