// @flow

import { type Node } from 'react';

type RandomPlayerQuoteProps = {
  children: Node[],
};

function RandomPlayerQuote({ children }: RandomPlayerQuoteProps) {
  if (children.length > 1) {
    return children[Math.floor(Math.random() * children.length)];
  }

  return children;
}

export default RandomPlayerQuote;
