/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import styled from 'styled-components';
import Avatar from 'v2/components/Contests/shared/Avatar';
import { correctPick, pendingPick, incorrectPick } from '../../../shared/Image';

const propTypes = {};

const defaultProps = {
  player: {
    statusPick: '',
  },
};

const getColor = (statusPick) => {
  if (statusPick === 'success') return '#0bd855';
  if (statusPick === 'pending') return '#FFB236';
  if (statusPick === 'fail') return '#E80C51';
};

const getBackground = (val, isOverAll) => {
  const numberVal = Number(val);

  if (isOverAll) return '#26262e';
  if (numberVal > 33 && numberVal <= 66) return '#302f28';
  if (numberVal > 66) return '#28302e';
  return '#302828';
};

const getStatusImg = (val) => {
  const { statusPick } = val;

  if (statusPick === 'success') return correctPick;
  if (statusPick === 'pending') return pendingPick;
  if (statusPick === 'fail') return incorrectPick;
  return '';
};

function Player(props) {
  const {
    position,
    name,
    photo,
    percent,
    teamName,
    statusPick,
    iconStatus,
    relPercent,
    game = {},
    teamId,
    isOverAll,
  } = props;

  const { awayTeam, homeTeam, awayTeamId, homeTeamId, startsAt } = game;

  const colorBar = getColor(statusPick);

  const colorBackground = getBackground(relPercent, isOverAll);

  const gameStartAt = `${moment(startsAt).format('ddd ha')} etc`;

  const getNameSize = (name) => {
    if (R.length(name) > 14) return '16px';
    return '18px';
  };

  return (
    <StyledPlayer backgroundPlayer={colorBackground} className={statusPick}>
      <PlayerButton>
        <AvatarWrapper>
          <Avatar source={photo} alt="avatar" />
        </AvatarWrapper>
        <Wrapper>
          <PlayerInfo>
            {!isOverAll && (
              <span>
                <PlayerTeam>
                  <PlayerPosition color={getColor(statusPick)}>{position}</PlayerPosition>
                  <PlayerName size={getNameSize(name)}>{name}</PlayerName>
                </PlayerTeam>
              </span>
            )}
            <AdditionalInfo isOverAll={isOverAll}>
              <AdditionalInfoItem>
                <PlayerDescription>
                  {isOverAll && (
                    <>
                      <PlayerPosition isOverAll={isOverAll} color={getColor(statusPick)}>
                        {position}
                      </PlayerPosition>
                      <PlayerDescriptionTeam isActive>{teamName}</PlayerDescriptionTeam>
                    </>
                  )}

                  {homeTeam && awayTeam && (
                    <>
                      <PlayerDescriptionTeam isActive={teamId == homeTeamId}>{homeTeam}</PlayerDescriptionTeam> @{' '}
                      <PlayerDescriptionTeam isActive={teamId == awayTeamId}>{awayTeam}</PlayerDescriptionTeam>
                    </>
                  )}
                </PlayerDescription>
              </AdditionalInfoItem>

              {/* <AdditionalInfoItem>
                <DesctopText>{gameStartAt}</DesctopText>
                <MobileText>{gameStartAt}</MobileText>
              </AdditionalInfoItem> */}
            </AdditionalInfo>

            {isOverAll && (
              <span>
                <PlayerTeam>
                  <PlayerName isOverAll={isOverAll} size={getNameSize(name)}>
                    {name}
                  </PlayerName>
                </PlayerTeam>
              </span>
            )}
          </PlayerInfo>

          <PlayerStatistic isOverAll={isOverAll}>
            {percent > 0 && <span>{`${percent}%`}</span>}
            {relPercent > 0 && <ProgressBar percent={relPercent} backgroundBar={colorBar} />}
            {iconStatus && <IconStatus src={getStatusImg({ statusPick })} />}
          </PlayerStatistic>
        </Wrapper>
      </PlayerButton>
    </StyledPlayer>
  );
}

const AdditionalInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
  margin-top: ${({ isOverAll }) => isOverAll && 0};
`;

const AdditionalInfoItem = styled.div`
  margin: 0 5px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;

const PlayerName = styled.span`
  color: #fff;
  font-size: 19px;
  white-space: nowrap;
  margin: ${({ isOverAll }) => isOverAll && '8px 0'};

  @media (max-width: 450px) {
    font-size: ${({ size }) => size};
    max-width: 155px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const PlayerPosition = styled.span`
  color: ${({ color }) => color};
  margin-right: 5px;
  font-size: 18px;
  font-size: ${({ isOverAll }) => isOverAll && '14px'};
`;

const PlayerTeam = styled.span`
  color: #fff;
  display: flex;
  align-items: center;
`;

const PlayerDescription = styled.div`
  color: #656579;
  font-size: 14px;
`;

const PlayerDescriptionTeam = styled.span`
  color: ${({ isActive }) => isActive && '#fff'};
  font-size: 14px;
  font-weight: ${({ isActive }) => isActive && 'bold'};
`;

const StyledPlayer = styled.li`
  list-style: none;
  position: relative;
  background: ${(props) => props.backgroundPlayer};
  border-radius: 8px;
  padding: 0px 0px;
  margin-left: 40px;
  margin-bottom: 5px;
  &.success {
    /* background: #28302e; */
    background: #2a4843;
  }
  &.pending {
    /* background: #302f28; */
    background: #5e4520;
  }
  &.fail {
    /* background: #302828; */
    background: #57162d;
  }
  &:not(:first-child) {
    margin-top: 10px;
  }
  &.selected {
    /* background: #28302e; */
    background: #2a4843;
  }
`;

const PlayerButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  outline: none;
  border: navajowhite;
  padding: 0;
  text-align: left;
  width: 100%;
  cursor: pointer;
  // padding: 0 0px 20px;
  // padding-left: 60px;
  padding-right: 0;
`;

const AvatarWrapper = styled.div`
  position: absolute;
  width: 70px;
  border-radius: 40px;
  top: calc(50% - 35px);
  left: -40px;

  img {
    width: 100%;
  }
`;

const PlayerIndicator = styled.img`
  position: absolute;
  right: 25px;
  top: calc(50% - 15px);
  @media (max-width: 901px) {
    right: -10px;
  }
`;

const MobileText = styled.span`
  display: none;
  font-size: 14px;
  @media (max-width: 768px) {
    display: block;
  }
`;

const DesctopText = styled.span`
  /* margin-left: 5px; */
  display: inline-block;
  @media (max-width: 768px) {
    display: none;
  }
`;

const ProgressBar = styled.div`
  position: relative;
  max-width: 205px;
  width: ${(props) => `${props.percent}%` || '0'};
  height: 8px;
  background: ${(props) => props.backgroundBar};
  /* background: #1b1b21; */
  border-radius: 8px;
  margin-right: 10px;
  margin-left: 8px;
  /* &:before {
    content: '';
    width: ${(props) => `${props.percent}%` || '0'};
    height: 100%;
    border-radius: 8px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: ${(props) => props.backgroundBar};
  } */
`;

const PlayerStatistic = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-top: ${({ isOverAll }) => isOverAll && 0};
  span {
    color: #fff;
  }

  ${ProgressBar} {
    background ${({ isOverAll, theme }) => isOverAll && theme.colors.purpleAccent};
  }
      /* margin-top: 8px;
  /* isOverAll */
  /* @media (max-width: 768px) {
    margin-top: 0px;
  } */
`;

const Wrapper = styled.div`
  width: 100%;
  min-height: 68px;
  display: flex;
  padding: 13px 8px;
  padding-left: 40px;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 12px 35px 6px 45px;
    display: flex;
    justify-content: center;
  }
`;

const IconStatus = styled.img`
  /* margin-right: 25px; */

  position: absolute;
  right: -10px;
  top: 50%;

  transform: translate(0, -50%);
`;

const PlayerInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

Player.propTypes = propTypes;
Player.defaultProps = defaultProps;

// export default enhance(HomePage);
export default Player;
