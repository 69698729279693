import styled from 'styled-components';

export default styled.div`
  max-width: 350px;
  margin-bottom: 20px;

  @media (max-width: 425px) {
    max-width: 365px;
    margin-bottom: 10px;
  }
`;
