// @flow

import React from 'react';
import { connect } from 'react-redux';
import { compose, type HOC } from 'recompose';
import { getRefsPromoPrizes } from 'v2/selectors/refs';

type PrizeType = { id: number, primaryPhotoUrl: string, secondaryPhotoUrl: string, url?: string, title?: string };

const SliderController = (WrappedComponent) => ({ promoPrizes }) => <WrappedComponent prizes={promoPrizes} />;

const mapStateToProps = (state) => ({
  promoPrizes: getRefsPromoPrizes(state),
});

const enhance: HOC<*, { promoPrizes: Array<PrizeType> }> = compose(connect(mapStateToProps), SliderController);

export default enhance;
