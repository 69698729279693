import React, { useContext, useState, useEffect } from 'react';
import { compose, not, equals, prop, contains, map } from 'ramda';
import { toast as toastify } from 'react-toastify';
import styled from 'styled-components';
import { Switch, Route, NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { lifecycle } from 'recompose';
import useWindowSize from 'v2/services/useWindowSize';
import {
  CONTEST_CATEGORY_PATH,
  CONTEST_BREAKDOWN_CATEGORY_PATH,
  CONTEST_LEADERBOARD_PATH,
  CONTEST_PAYMENT_SUCCESS_PATH,
  getPath,
} from 'v2/constants/router';
import { getIn } from 'v2/utils/ramda';
import howToPlayModalContext from 'v2/contexts/FAQModal';
import { ModalLoader } from 'v2/components/shared';
import * as actions from 'v2/actions';
import { requestInProcess } from 'v2/selectors/request';
import * as requestTypes from 'v2/constants/requestTypes';

import Title from 'v2/components/shared/Title';
import PlayerSuccessModal from 'v2/components/Contests/shared/PlayerSuccessModal';
import { ContestProvider } from 'v2/contexts/contest';
import { getResourceContestDetail, getResourceContestWeeks } from '../../selectors/contests';

import MakePicks from '../Contests/MakePicks';
import PickBreakdown from '../Contests/PickBreakdown';
import Leaderboard from '../Contests/Leaderboard';
import MakePicksContest from '../Contests/MakePicksContest';
import MakePicksHelp from '../Contests/MakePicksHelp';
import Header from '../Contests/Header';

const propTypes = {};

const defaultProps = {
  contest: {
    id: null,
    totalSelectionsRequired: 0,
    urlContest: {
      weekOrder: null,
    },
  },
  statCategories: [],
  contestPlayers: [],
  contestPrizes: [],
  contestCategory: [],
  weeks: {
    nextWeek: null,
    previousWeek: null,
  },
};

function ContestRoutes(props) {
  const {
    resetResourceContest,
    match,
    contest,
    contestPrizes,
    categoryId,
    onCurrentCategoryId,
    weeks,
    isLoading,
    resetContest,
  } = props;
  const { id, isContestActive, urlContest, isContestLive, isContestEnded, statCategories } = contest;
  const { currentWeek } = weeks;

  const getContestUrl = (url) =>
    getPath(url, {
      id: getIn('params.id', match),
      weekOrder: prop('weekOrder', urlContest),
      categoryId: prop('categoryId', urlContest),
    });

  const [isShowContest, setIsShowContest] = useState(false);
  const [width] = useWindowSize();
  const [successPlayerInfo, setSuccessPlayerInfo] = useState({
    show: false,
    id: null,
    action: null,
    categoryId: null,
    reset: () => setSuccessPlayerInfo(({ reset, ...rest }) => ({ ...map(() => null, rest), reset })),
  });

  const isMobile = width < 425;

  useEffect(() => {
    return () => {
      resetResourceContest();
      resetContest();
    };
  }, []);

  // const showContest = () => {
  //   isShowContest ? setIsShowContest(false) : setIsShowContest(true);
  // };

  const handleTabClick = (e) => {
    if (compose(not, prop('weekOrder'))(urlContest)) {
      e.preventDefault();
    }

    if (isShowContest) {
      toogleShowContest();
    }
  };

  useContext(howToPlayModalContext);
  const { showHowToPlayModal, closeHowToPlayModal } = useContext(howToPlayModalContext);

  const toogleShowContest = () => setIsShowContest(!isShowContest);

  const isSurvivor = compose(equals('survive'), prop('kind'))(contest);
  return (
    <ContestProvider
      value={{
        setSuccessPlayerInfo,
      }}
    >
      {/* <ModalLoader isLoading={isLoading} /> */}
      <PlayerSuccessModal
        contestId={id}
        playerId={prop('id', successPlayerInfo)}
        categoryId={prop('categoryId', successPlayerInfo)}
        show={prop('show', successPlayerInfo)}
        handleClose={prop('reset', successPlayerInfo)}
        handleClick={prop('action', successPlayerInfo)}
      />

      <Container>
        {isMobile && (
          <HeaderContainer>
            <Header
              handleClose={isShowContest && toogleShowContest}
              handleInfo={() => toogleShowContest()}
              {...contest}
            />
          </HeaderContainer>
        )}
        {isShowContest && <Blur onClick={() => toogleShowContest()} />}
        {width > 425 && (!isContestLive || !isContestEnded) && (
          <MakePicksText>
            <Title text="Make Picks to Achieve Stat Objectives" />
            {isSurvivor ? <p>Advance If All Picks Are Correct</p> : <p>Most Correct Picks Wins</p>}
          </MakePicksText>
        )}
        {/* <MakePicksContestDrawer onClick={() => toogleShowContest()} className={isShowContest ? 'arrow_open' : ''}>
        {contest.name}
        <svg class="rotate" width="8px" height="4px" viewBox="0 0 8 4" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="ico_dropdown" transform="translate(-4.000000, -6.000000)" fill="#656579" fill-rule="nonzero">
              <path
                d="M4.68284271,6.68284271 L7.71715729,9.71715729 C7.873367,9.873367 8.126633,9.873367 8.28284271,9.71715729 L11.3171573,6.68284271 C11.473367,6.526633 11.473367,6.273367 11.3171573,6.11715729 C11.2421427,6.04214274 11.1404012,6 11.0343146,6 L4.96568542,6 C4.74477153,6 4.56568542,6.1790861 4.56568542,6.4 C4.56568542,6.5060866 4.60782816,6.60782816 4.68284271,6.68284271 Z"
                id="arrow"
              ></path>
            </g>
          </g>
        </svg>
      </MakePicksContestDrawer> */}
        {/* <ModalLoader isLoading={isLoading} /> */}
        <MakePicksWraper>
          <RightSection>
            {getIn('params.id', match) && (
              <Tabs>
                <Tab
                  onClick={handleTabClick}
                  to={getContestUrl(CONTEST_CATEGORY_PATH)}
                  isActive={(match, location) => !!location.pathname.match(`contests/${id}/make_picks`)}
                  activeClassName="active"
                >
                  {isContestLive || not(isContestActive) ? 'My Picks' : 'Make Picks'}
                </Tab>
                <Tab
                  onClick={handleTabClick}
                  to={getContestUrl(CONTEST_BREAKDOWN_CATEGORY_PATH)}
                  isActive={(match, location) => contains('pick_breakdown', location.pathname.split('/'))}
                  activeClassName="active"
                >
                  % SELECTED
                </Tab>
                <Tab
                  onClick={handleTabClick}
                  to={getPath(CONTEST_LEADERBOARD_PATH, {
                    id: getIn('params.id', match),
                  })}
                  activeClassName="active"
                >
                  Leaderboard
                </Tab>
              </Tabs>
            )}
            <Switch>
              <Route
                path={CONTEST_CATEGORY_PATH}
                render={(routerProps) => (
                  <MakePicks {...props} {...routerProps} onCurrentCategoryId={onCurrentCategoryId} />
                )}
              />
              <Route
                path={CONTEST_BREAKDOWN_CATEGORY_PATH}
                render={(routerProps) => (
                  <PickBreakdown
                    {...props}
                    categoryId={urlContest.categoryId}
                    onCurrentCategoryId={onCurrentCategoryId}
                    {...routerProps}
                  />
                )}
              />
              <Route
                path={CONTEST_LEADERBOARD_PATH}
                render={(routerProps) => <Leaderboard {...props} {...routerProps} />}
              />
            </Switch>
          </RightSection>
          {/* <MakePicksContest contest={contest} isShowContest={isShowContest} contestPrizes={contest.prizes} /> */}
        </MakePicksWraper>

        {isShowContest && (
          <MakePicksHelpWrapper>
            <MakePicksHelp
              contest={contest}
              onClick={() =>
                showHowToPlayModal({
                  kind: prop('kind', contest),
                  onClickHandler: compose(toogleShowContest, closeHowToPlayModal),
                })
              }
            />
          </MakePicksHelpWrapper>
        )}
      </Container>
    </ContestProvider>
  );
}

const MakePicksHelpWrapper = styled.div`
  position: absolute;
  backdrop-filter: blur(3px);
  top: 45px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
`;

const Blur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  backdrop-filter: blur(3px);
`;

const Container = styled.div`
  width: 100%;
  position: relative;
  max-width: 1140px;
  padding: 0px 5px;
  margin: 0 auto;
  overflow: visible;
  flex: 1 0 auto;
  box-sizing: border-box;
  overflow-x: inherit;
  overflow-y: visible;
  /* margin-bottom: 100px; */

  /* padding-bottom: 250px; */

  @media (max-width: 468px) {
    margin-top: -60px;
  }
`;

const MakePicksText = styled.div`
  margin-bottom: 0;
  text-align: center;
  @media (min-width: 768px) {
    padding: 0 20px;
    text-align: left;
    margin-bottom: 40px;
  }
`;

// const MakePicksContestDrawer = styled.div`
//   z-index: 2;
//   position: relative;
//   padding: 15px;
//   box-sizing: border-box;
//   background: #26262e;
//   /* border-radius: 8px; */
//   border-top-left-radius: 8px;
//   border-top-right-radius: 8px;
//   text-align: center;
//   text-transform: uppercase;
//   font-size: 14px;
//   font-weight: 700;
//   line-height: 18px;
//   /* margin: 10px 0; */
//   cursor: pointer;
//   display: none;
//   @media (max-width: 901px) {
//     display: block;
//   }
//   svg {
//     position: absolute;
//     right: 15px;
//     top: 21px;
//   }
// `;

const MakePicksWraper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
`;

const Tab = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 50px;
  border-radius: 19px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none !important;
  flex: 1;
  font-size: 16px;
  white-space: nowrap;
  transition: background-color 0.25s ease;
  text-transform: uppercase;

  &.active {
    background-color: #5d5ce2;
  }

  /* &:hover {
    background-color: #5d5ce2;
  } */
`;

const Tabs = styled.div`
  display: flex;
  width: 100%;
  background: #1b1b21;
  justify-content: space-between;

  @media (max-width: 425px) {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #131317;
    z-index: 2;
    /* padding: 8px 15px; */
    width: 100%;
    box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.25);

    ${Tab} {
      padding: 14px 0;
      font-size: 16px;
      width: 100%;
    }
  }
`;

const RightSection = styled.div`
  width: 100%;
  margin-right: 30px;
  border-radius: 8px;
  /* background: #1b1b21; */
  @media (max-width: 901px) {
    margin-right: 0;
  }
`;

const HeaderContainer = styled.div`
  position: sticky;
  z-index: 99;
  top: 0;
`;

const LeftSection = styled.div``;

const mapStateToProps = (state, props) => {
  const categoryId = getIn('match.params.categoryId', props);
  const weekOrder = getIn('match.params.weekOrder', props);

  return {
    weekOrder,
    categoryId,
    contest: getResourceContestDetail(state),
    weeks: getResourceContestWeeks(state),
    isLoading: requestInProcess(state, { requestType: requestTypes.CONTEST_FETCH }),
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetResourceContest: () => dispatch(actions.resetResourceContest()),
  fetchContest: (id) => dispatch(actions.fetchContest(id), dispatch),
  onCurrentCategoryId: (contest, categoryId) => dispatch(actions.currentContestCategoryId(contest, categoryId)),
  resetContest: () => dispatch(actions.resetContest(), dispatch),
});

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { fetchContest, match } = this.props;
      const { id } = match.params;

      fetchContest(id);
    },
  }),
);

ContestRoutes.propTypes = propTypes;
ContestRoutes.defaultProps = defaultProps;

export default enhance(ContestRoutes);
