import React from 'react';
import styled from 'styled-components';
import instagramLogo from 'v1/images/icons/socials/instagram.svg';

function Description({ children, name, instagram }) {
  return (
    <>
      <ChildrenContainer>{children}</ChildrenContainer>
      <DescriptionContainer>
        <InstagramTextContainer>
          <InstagramText>{`from ${instagram}`}</InstagramText>
          <InstagramIcon src={instagramLogo} alt="instagram" />
        </InstagramTextContainer>
        <NameText>{name}</NameText>
      </DescriptionContainer>
    </>
  );
}

export default Description;

// styles

const InstagramText = styled.span`
  font-weight: bold;
  letter-spacing: 0px;
  text-transform: uppercase;
`;

const InstagramTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InstagramIcon = styled.img`
  width: 12px;
  filter: brightness(0.5);
  margin-left: 5px;
`;

const NameText = styled.span`
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: #fff;
  padding-top: 8px;
`;

const ChildrenContainer = styled.div`
  position: relative;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 10px;
`;
