import React, { useEffect } from 'react';
import { Switch, Redirect, Route, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
  ACCOUNT_PATH,
  ACCOUNT_SETTINGS_PATH,
  ACCOUNT_MY_PROGRESS_PATH,
  ACCOUNT_INVITE_FRIENDS_PATH,
} from 'v2/constants/router';

import SettingsForm from './shared/SettingsForm';
import InviteFriendsForm from './shared/InviteFriendsForm';
import Tabs from './shared/Tabs';

const propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      contest: PropTypes.shape({
        id: PropTypes.number.isRequired,
        urlContest: PropTypes.shape({
          categoryId: PropTypes.number.isRequired,
          weekOrder: PropTypes.number.isRequired,
        }),
      }),
    }),
  }).isRequired,
};

function Profile({ location: { state } }) {
  const contest = state && state.contest;

  useEffect(() => {
    console.warn('state :', state);
  }, []);

  return (
    <ProfilePageContainer>
      <ProfileContentContainer>
        <Tabs />
        <ProfileMainContent>
          <Switch>
            <Route path={ACCOUNT_SETTINGS_PATH} component={SettingsForm} exact />
            <Route path={ACCOUNT_INVITE_FRIENDS_PATH} component={InviteFriendsForm} exact />

            <Route
              path={ACCOUNT_PATH}
              render={({ location }) => (
                <Redirect
                  to={{
                    pathname: ACCOUNT_SETTINGS_PATH,
                    state: { from: location },
                  }}
                />
              )}
            />
          </Switch>
        </ProfileMainContent>
      </ProfileContentContainer>
    </ProfilePageContainer>
  );
}

Profile.propTypes = propTypes;
export default withRouter(Profile);

// styles

const ProfilePageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-bottom: 150px; */
`;

const ProfileContentContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  min-width: 780px;

  @media (max-width: 768px) {
    min-width: auto;
    width: 100%;
    padding: 20px 40px;
  }

  @media (max-width: 475px) {
    min-width: auto;
    width: 100%;
    padding: 10px;
  }
`;

const ProfileMainContent = styled.div`
  padding: 50px 90px;
  background: #1b1b21;
  width: 100%;

  @media (max-width: 768px) {
    padding: 20px 40px;
  }

  @media (max-width: 660px) {
  }

  @media (max-width: 475px) {
    padding: 50px 25px;
  }
`;
