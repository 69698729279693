// @flow
import React from 'react';
import styled from 'styled-components';
import {
  last,
  ifElse,
  when,
  head,
  nth,
  add,
  findIndex,
  propEq,
  map,
  prop,
  compose,
  equals,
  propOr,
  length,
  gt,
  tap,
} from 'ramda';

import { isNotDefined } from 'v2/helpers/ramda';

import NewCategoryItem from '../../../shared/NewCategoryItem';

type StatCategoriesProps = {
  showDots?: boolean,
  currentCategoryId: number,
  selectionsRequired: number,
  league: 'nfl' | 'nba' | 'mlb',
  navigateToCategory: (id: number) => void,
  categories?: [{ subject: string, id: number, amount: string, order: number }],
};

const defaultProps = {
  categories: [],
  showDots: true,
};

function StatCategories({
  navigateToCategory,
  selectionsRequired,
  league,
  currentCategoryId,
  categories,
  showDots,
}: StatCategoriesProps) {
  const getActiveIndex = findIndex(propEq('id', currentCategoryId));
  const getActiveStatus = compose(equals(currentCategoryId), prop('id'));
  const getFilledStatus = compose(equals(selectionsRequired), length, propOr([], 'selectedPlayers'));

  const navToNextCategory = () =>
    compose(
      navigateToCategory,
      prop('id'),
      ifElse(
        gt(3),
        () => nth(3, categories),
        () => nth(0, categories),
      ),
      // when(isNotDefined, () => head(categories)),
      // (index) => nth(index, categories),
      // add(3),
      findIndex(propEq('id', currentCategoryId)),
    )(categories);

  const navToPrevCategory = () =>
    compose(
      navigateToCategory,
      prop('id'),
      ifElse(
        gt(3),
        () => nth(3, categories),
        () => nth(0, categories),
      ),
      // ifElse(
      //   equals(-1),
      //   () => last(categories),
      //   (index) => nth(index, categories),
      // ),
      // add(-1),
      findIndex(propEq('id', currentCategoryId)),
    )(categories);

  return (
    <Container>
      <NewCategoryItem.Container
        showDots={showDots}
        goToNext={navToNextCategory}
        goToPrev={navToPrevCategory}
        activeIndex={getActiveIndex(categories)}
      >
        {map(
          (statCategoryProps) => (
            <NewCategoryItem
              key={prop('id', statCategoryProps)}
              isFilled={getFilledStatus(statCategoryProps)}
              isActive={getActiveStatus(statCategoryProps)}
              onClick={compose(navigateToCategory, prop('id'))}
              league={league}
              {...statCategoryProps}
            />
          ),
          categories,
        )}
      </NewCategoryItem.Container>
    </Container>
  );
}

StatCategories.defaultProps = defaultProps;
export default StatCategories;

// styles
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 750px;
  margin: 0 auto;
`;
