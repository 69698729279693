/* eslint-disable react/prop-types */
import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as actions from 'v2/actions';
import { connect } from 'react-redux';
import { lifecycle } from 'recompose';
import {
  // assoc,
  sortWith,
  descend,
  // tap,
  not,
  equals,
  // has,
  compose,
  prop,
  // map,
  propOr,
  when,
  replace,
  toString,
  // sort,
  // uniq,
  // findIndex,
  // add,
  // addIndex,
  // ifElse,
  // indexBy,
  // values,
  // __,
} from 'ramda';

import { getResourceContestParticipations, getResourceContestDetail } from 'v2/selectors/contests';
import { requestInProcess } from 'v2/selectors/request';
import * as requestTypes from 'v2/constants/requestTypes';
import { isDefined, isNotDefined } from 'v2/helpers/ramda';
// import { placeSuffix } from 'v2/helpers/common';

import { Loader } from 'v2/components/shared';

import { like, dislike, pending } from '../../../shared/Image';

const propTypes = {};

const defaultProps = {
  usersLeaderborad: {
    currentUser: [],
    players: [],
  },
};

// const diff = function(a, b) {
//   return b - a;
// };

const sortPlayers = sortWith([descend(prop('correctPicks'))]);
// const mapIndexed = addIndex(map);

function Participations(props) {
  const { usersLeaderborad, isLoading, contest } = props;
  const { players, currentUser } = usersLeaderborad;

  // const sortedRanks = compose(sort(diff), map(prop('correctPicks')))(players);
  // const getRank = (vv) => compose((r) => r + 'st (T)', add(1), findIndex(equals(vv)))(sortedRanks);

  // const assignRanks = mapIndexed((player, index) => assoc('rank', `${placeSuffix(index + 1)} (T)`, player));

  const hasRank = compose(not, equals('N/A'), prop('rank'));

  const isContestLive = propOr(false, 'isContestLive', contest);

  if (isLoading) {
    return <Loader />;
  }

  const numberWithCommas = compose(replace(/\B(?=(\d{3})+(?!\d))/g, ','), toString);

  // const playersEntities = compose(indexBy(prop('id')), assignRanks, sortPlayers)(players);

  // const findRank = (player) => compose((id) => prop(id, playersEntities), prop('id'))(player);

  const getPlayerRank = (player) => compose(propOr('', 'rank'))(player);

  return (
    <StyledDiagramStats>
      <HeaderList>
        <p>
          <HeaderTitle>USERNAME</HeaderTitle>
          <EntriesCOunt>
            ({compose(when(isDefined, numberWithCommas), propOr(null, 'participantsMaxCount'))(contest || {})} entries)
          </EntriesCOunt>
        </p>
        <UserIcons>
          <img src={like} />
          <img src={pending} />
          <img src={dislike} />
        </UserIcons>
      </HeaderList>

      <UserList>
        {currentUser.name && (
          <User style={{ background: '#5d5ce2' }}>
            <UserName>
              {hasRank(currentUser) && isContestLive && (
                <UserRank className={(currentUser.rank === '1st' || currentUser.rank === '1st (T)') && 'top_place'}>
                  {getPlayerRank(currentUser)}
                </UserRank>
              )}
              {currentUser.name}
            </UserName>
            <UserStatCount>
              <Count style={{ color: '#0bd855' }}>{currentUser.correctPicks}</Count>
              <Count style={{ color: '#ffffff' }}>{currentUser.pendingPicksCount}</Count>
              <Count style={{ color: '#ffffff' }}>{currentUser.incorrectPicks}</Count>
            </UserStatCount>
          </User>
        )}
        {sortPlayers(players).map((player) => (
          <User key={player.id}>
            <UserName>
              {hasRank(player) && isContestLive && (
                <UserRank className={(player.rank === '1st' || player.rank === '1st (T)') && 'top_place'}>
                  {getPlayerRank(player)}
                </UserRank>
              )}

              {player.name}
            </UserName>
            <UserStatCount>
              <Count style={{ color: '#0bd855' }}>{player.correctPicks}</Count>

              <Count>{player.pendingPicksCount}</Count>
              <Count>{player.incorrectPicks}</Count>
            </UserStatCount>
          </User>
        ))}
      </UserList>
    </StyledDiagramStats>
  );
}

const HeaderTitle = styled.span`
  color: #fff;
  font-size: 14px;
`;

const StyledDiagramStats = styled.div``;

const EntriesCOunt = styled.span`
  font-size: 12px;
  line-height: 1.3;
  margin-left: 10px;
  vertical-align: middle;
`;

const UserList = styled.ul`
  margin: 0;
`;

const User = styled.li`
  position: relative;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  font-weight: 700;
  padding: 15px 20px;
  background: #1b1b21;
  /* padding-right: 0; */
  &:nth-child(2n) {
    background: #26262e;
  }
`;

const UserName = styled.div`
  font-size: 14px;
  line-height: 18px;
  margin-right: 5px;
`;

const UserStatCount = styled.div`
  display: flex;
  justify-content: space-between;
  width: 130px;
`;

const Count = styled.span`
  position: relative;
  display: flex;
  justify-content: center;
  width: 130px;
  font-size: 14px;
  line-height: 18px;
  ${'' /* &:before {
    position: absolute;
    content: url(${like});
  } */}
`;

const HeaderList = styled.div`
  display: flex;
  justify-content: space-between;
  background: #26262e;
  padding: 20px;
  align-items: center;
  padding-right: 31px;
`;

const UserRank = styled.span`
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  margin-right: 10px;
  &.top_place {
    color: rgb(11, 216, 85);
    &:before {
      content: '';
      position: absolute;
      width: 3px;
      height: 98%;
      background: rgb(11, 216, 85);
      top: 0;
      left: 0;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
`;

const UserIcons = styled.div`
  display: flex;
  width: 105px;
  justify-content: space-between;
`;

const mapStateToProps = (state) => {
  return {
    contest: getResourceContestDetail(state),
    usersLeaderborad: getResourceContestParticipations(state),
    isLoading: requestInProcess(state, { requestType: requestTypes.CONTEST_LEADER_PARTICIPATIONS_FETCH }),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchContestLeaderParticipations: (params) => dispatch(actions.fetchContestLeaderParticipations(params)),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { fetchContestLeaderParticipations, match } = this.props;
      const { id } = match.params;

      fetchContestLeaderParticipations(id);
    },
  }),
);

Participations.propTypes = propTypes;
Participations.defaultProps = defaultProps;

export default enhance(Participations);
