// @flow

import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { curry, compose, equals, indexOf, split, prop, not, without, join } from 'ramda';

import useOnClickOutside from 'v2/hooks/useOnClickOutside';
import { addclass, removeclass } from 'v2/helpers/common';

import warningSvg from './img/Warning.svg';
import closeSvg from './img/close.svg';
import failSvg from './img/Fail.svg';

const defaultProps = {
  type: 'warning',
  handleClose: null,
  handleClick: null,
};

type ModalProps = {
  type?: 'warning' | 'fail',
  handleClose?: () => void,
  handleClick?: () => void,
};

const weeklyMsg = 'You’ve already selected this player for another stat category';
const surviveMsg = 'You’ve already used this player in another week';

const getColor = (type) => {
  if (type === 'success') return '#0bd855';
  if (type === 'warning') return '#FFB236';
  if (type === 'fail') return '#E80C51';
};

const getIcon = (type) => {
  if (type === 'success') return warningSvg;
  if (type === 'warning') return warningSvg;
  if (type === 'fail') return failSvg;
};

function Modal({ type, handleClose, handleClick, kind }: ModalProps) {
  const ref = useRef();

  const isSurvive = kind == 'survive';

  useOnClickOutside(ref, handleClose || handleClick);

  useEffect(() => {
    addclass('inactive', document.body);

    // fixme
    return () => {
      setTimeout(() => {
        removeclass('inactive', document.body);
      }, 150);
    };
  }, []);

  return (
    <AbsoluteContainer ref={ref}>
      <ModalContainer color={getColor(type)} onClick={handleClick}>
        <ModalContent>
          <ModalImg alt={type} src={getIcon(type)} />
          <ModalMessage>{isSurvive ? surviveMsg : weeklyMsg}</ModalMessage>
        </ModalContent>

        {handleClose && (
          <CloseIcong
            onClick={(e) => {
              e.stopPropagation();
              handleClose();
            }}
            src={closeSvg}
            alt="close"
          />
        )}
      </ModalContainer>
    </AbsoluteContainer>
  );
}

Modal.defaultProps = defaultProps;
export default Modal;

// styles

const ModalMessage = styled.div`
  font-size: 17px;
  color: #fff;
  text-align: left;
`;

const CloseIcong = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(-10px, 10px);
`;

const ModalImg = styled.img`
  margin-right: 16px;
`;

const ModalContent = styled.div`
  display: flex;
  align-items: center;
  padding: 0 35px 0 16px;
`;

const ModalContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: #26262e;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 8px;

  &:before {
    background-color: ${({ color }) => color};
    content: '';
    display: block;
    width: 5px;
    height: 100%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`;

const AbsoluteContainer = styled.div`
  position: absolute;
  width: 120%;
  top: 0;
  right: 0;
  bottom: 0;
  left: -49px;
  z-index: 2;
`;
