import React from 'react';
import styled from 'styled-components';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router';

import * as actions from 'v2/actions';
import FormGroupAdapter from 'v2/components/shared/formDefault/FormGroup/Adapter';
import Input from 'v2/components/shared/formDefault/Input';
import Button from 'v2/components/shared/formDefault/Button';
import ErrorMessage from 'v2/components/shared/formDefault/ErrorMessage';
import { validateRequired } from 'v2/components/shared/formDefault/validations';

function PersonalDetailsForm({ changePasswordViaEmail, match: { params } }) {
  const { token, id } = params;

  return (
    <RestorePasswordFormContainer>
      <FormTitle>Enter your new password</FormTitle>
      <Form
        initialValues={{ email_token: token }}
        onSubmit={(props) => changePasswordViaEmail(props, id)}
        validate={({ userSession }) => {
          const errors = {};

          if (userSession) {
            if (userSession.passwordConfirmation !== userSession.password) {
              errors.userSession = { password: 'Must match', passwordConfirmation: 'Must match' };
            }
          }

          return errors;
        }}
        render={({ handleSubmit, hasSubmitErrors, submitError, submitting }) => (
          <StyledForm onSubmit={handleSubmit}>
            <FieldsCollection isErrors={hasSubmitErrors || submitError}>
              <FieldsGroup>
                <FieldWrapper>
                  <Field
                    component={FormGroupAdapter}
                    inputComponent={Input}
                    name="user.password"
                    help=""
                    placeholder="Enter new password"
                    type="password"
                    label="new password"
                    validate={validateRequired()}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <Field
                    component={FormGroupAdapter}
                    inputComponent={Input}
                    name="user.passwordConfirmation"
                    help=""
                    placeholder="Retype new password"
                    type="password"
                    label="confirm new password"
                    validate={validateRequired()}
                  />
                </FieldWrapper>
              </FieldsGroup>

              {hasSubmitErrors && submitError && (
                <ErrorMessageWrapper>
                  <ErrorMessage>{submitError}</ErrorMessage>
                </ErrorMessageWrapper>
              )}
            </FieldsCollection>

            <Button type="submit" disable={submitting}>
              {submitting ? 'Submitting...' : 'Submit'}
            </Button>
          </StyledForm>
        )}
      />
    </RestorePasswordFormContainer>
  );
}

// const FieldsCollection = styled.div`
//   margin-bottom: 50px;
// `;

// const FieldsGroup = styled.div`
//   display: flex;
//   flex-flow: wrap;
//   justify-content: space-between;
//   margin-bottom: 25px;

//   @media (max-width: 768px) {
//     display: flex;
//     flex-direction: column;
//   }
// `;

const FormTitle = styled.h2`
  @media (max-width: 468px) {
    font-size: 25px;
  }
`;

const ErrorMessageWrapper = styled.div`
  /* margin-bottom: 25px; */
  text-align: center;
`;

const FieldWrapper = styled.div`
  flex: 0 0 47%;

  @media (max-width: 768px) {
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const FieldsGroup = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  margin-bottom: 25px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const FieldsCollection = styled.div`
  margin-bottom: ${({ isErrors }) => (isErrors ? '25px' : '50px')};

  @media (max-width: 475px) {
    margin-bottom: 25px;
  }
`;

const StyledForm = styled.form`
  width: 100%;
  margin-bottom: 25px;
`;

const RestorePasswordFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 100px;

  @media (max-width: 660px) {
    padding: 25px 50px;
  }

  @media (max-width: 475px) {
    padding: 10px 25px;
  }
  /* max-width: 650px;
  margin: 0 auto;
  background: #1b1b21; */
`;

const mapDispatchToProps = (dispatch) => ({
  changePasswordViaEmail: (body, id) => dispatch(actions.changePasswordViaEmail(body, id)),
});

const enhance = compose(
  withRouter,
  connect(
    null,
    mapDispatchToProps,
  ),
);

export default enhance(PersonalDetailsForm);
