// ------------------------- nfl start ---------------------------
import nflPassingTds from 'v2/components/shared/Image/categoryIcons/nfl/icons_passing_tds_orange.svg';
import nflPassingYdsOrange from 'v2/components/shared/Image/categoryIcons/nfl/icons_passing_yds/orange.svg';
import nflReceivingTdsGreen from 'v2/components/shared/Image/categoryIcons/nfl/icons_receiving_tds/green.svg';
import nflReceivingYdsGreen from 'v2/components/shared/Image/categoryIcons/nfl/icons_receiving_yds/green.svg';
import nflRushingTdsBlue from 'v2/components/shared/Image/categoryIcons/nfl/icons_rushing_tds/blue.svg';
import nflRushingYdsBlue from 'v2/components/shared/Image/categoryIcons/nfl/icons_rushing_yds/blue.svg';

// ------------------------- nba start ---------------------------
import nbaDoubleDouble from 'v2/components/shared/Image/categoryIcons/nba/double-double/basketball_double_double.svg';
import nbaPoints from 'v2/components/shared/Image/categoryIcons/nba/points/basketball_points.svg';
import nbaRebounds from 'v2/components/shared/Image/categoryIcons/nba/rebounds/basketball_rebounds.svg';
import nbaAssists from 'v2/components/shared/Image/categoryIcons/nba/assists/basketball_assists.svg';
import nba3PointersMade from 'v2/components/shared/Image/categoryIcons/nba/3-pointer/basketball_3pointer.svg';
import nba3Steals from 'v2/components/shared/Image/categoryIcons/nba/steals/basketball_steals.svg';

// ------------------------- mlb start ---------------------------
import mlbHits from 'v2/components/shared/Image/categoryIcons/mlb/hits.svg';
import mlbHomerun from 'v2/components/shared/Image/categoryIcons/mlb/homerun.svg';
import mlbSaves from 'v2/components/shared/Image/categoryIcons/mlb/saves.svg';
import mlbStolenBase from 'v2/components/shared/Image/categoryIcons/mlb/stolen-base.svg';
import mlbStrikeout2 from 'v2/components/shared/Image/categoryIcons/mlb/strikeout_2.svg';
import mlbWins from 'v2/components/shared/Image/categoryIcons/mlb/wins.svg';

export const categoryIcons = {
  nfl: {
    'PASSING TDS': {
      primary: nflPassingTds,
      active: nflPassingTds,
    },
    'PASSING YARDS': {
      primary: nflPassingYdsOrange,
      // active: passingYdsGreen,
    },
    'RECEIVING TDS': {
      primary: nflReceivingTdsGreen,
      // active: nflReceivingTdsGreen,
    },
    'RECEIVING YARDS': {
      primary: nflReceivingYdsGreen,
      // active: nflReceivingYdsGreen,
    },
    'RUSHING TDS': {
      primary: nflRushingTdsBlue,
      // active: rushingTdsGreen,
    },
    'RUSHING YARDS': {
      primary: nflRushingYdsBlue,
      // active: rushingYdsGreen,c
    },
  },
  nba: {
    'DOUBLE-DOUBLE': {
      primary: nbaDoubleDouble,
    },
    POINTS: {
      primary: nbaPoints,
    },
    REBOUNDS: {
      primary: nbaRebounds,
    },
    ASSISTS: {
      primary: nbaAssists,
    },
    'THREE-POINTERS': {
      primary: nba3PointersMade,
    },
    STEALS: {
      primary: nba3Steals,
    },
  },
  mlb: {
    HITS: {
      primary: mlbHits,
    },
    HOMERUNS: {
      primary: mlbHomerun,
    },
    'STOLEN BASES': {
      primary: mlbStolenBase,
    },
    WINS: {
      primary: mlbWins,
    },
    SAVES: {
      primary: mlbSaves,
    },
    STRIKEOUTS: {
      primary: mlbStrikeout2,
    },
  },
};
