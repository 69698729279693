import * as actionTypes from '../../constants/actionTypes';
import { initialState } from '../../selectors/contests';

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CONTESTS_AVAILABLE_FETCH_SUCCESS:
      return {
        ...state,
        available: action.payload,
      };

    case actionTypes.CONTEST_RESET:
      return {
        ...state,
        resourceContest: { id: null },
        weeks: {},
      };

    case actionTypes.CONTEST_FETCH_SUCCESS:
      return {
        ...state,
        resourceContest: action.payload,
      };

    case actionTypes.CONTEST_MAKE_PICKS_FETCH_SUCCESS:
      return {
        ...state,
        weeks: action.payload,
      };

    case actionTypes.CONTEST_LEADER_PARTICIPATIONS_FETCH_SUCCESS:
      return {
        ...state,
        users: action.payload,
      };

    case actionTypes.CONTEST_LEADER_DIAGRAM_STATS_FETCH_SUCCESS:
      return {
        ...state,
        weeksCollection: action.payload,
      };

    case actionTypes.CONTEST_BREACKDOWN_OVERALL_FETCH_SUCCESS:
      return {
        ...state,
        breackdownOverallCollection: action.payload,
      };
    case actionTypes.CONTEST_BREAKDOWN_WEEK_PICK_FETCH_SUCCESS:
      return {
        ...state,
        breackdownWeekPickCollection: action.payload,
      };

    default:
      return state;
  }
};
