// @flow

import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { HOW_TO_PLAY_KIND, getPath } from 'v2/constants/router';
import infoIcon from 'v2/components/shared/Image/Icon/ico_info.svg';

type HowToPlayLinkProps = {
  kind: string,
};

function HowToPlayLink({ kind }: HowToPlayLinkProps) {
  return (
    <HowToPlayLinkWrapper>
      <StyledHowToPlayLink to={getPath(HOW_TO_PLAY_KIND, { kind })}>
        <HowToPlayLinkIcon src={infoIcon} alt="info" />
        <HowToPlayLinkText>How to pay</HowToPlayLinkText>
      </StyledHowToPlayLink>
    </HowToPlayLinkWrapper>
  );
}

const HowToPlayLinkIcon = styled.img`
  filter: grayscale(1);
  transition: filter 0.2s ease-out;
  margin-right: 5px;
`;

const HowToPlayLinkText = styled.span`
  text-transform: capitalize;
  transition: color 0.2s ease-out;
  font-size: 16px;
`;

const StyledHowToPlayLink = styled(NavLink)`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  text-decoration: none !important;

  &:hover {
    ${HowToPlayLinkIcon} {
      filter: grayscale(0);
    }

    ${HowToPlayLinkText} {
      color: #fff;
    }
  }

  &:before {
    content: '';
    display: block;
    width: 200%;
    height: 200%;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(65%, -50%);
  }
`;

const HowToPlayLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 35px;
`;

export default HowToPlayLink;
