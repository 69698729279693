// @flow

import React from 'react';
import styled from 'styled-components';

type PlayerQuoteImageProps = {
  src: string,
  alt: string,
};

function PlayerQuoteImage({ src, alt }: PlayerQuoteImageProps) {
  return <PlayerImage src={src} alt={alt} />;
}

export default PlayerQuoteImage;

// styles
const PlayerImage = styled.img`
  width: 102px;
`;
