// @flow
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React, { useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

import PrizeItem from './PrizeItem';
import Controller from './Controllers';

type PrizeType = { id: number, primaryPhotoUrl: string, secondaryPhotoUrl: string, url?: string, title?: string };
type prizeSliderProps = {
  prizes: Array<PrizeType>,
};
function PrizeSlider({ prizes }: prizeSliderProps) {
  const [activeIndex, setActiveIndex] = useState(0);
  const settings = {
    className: 'promo_slider',
    infinite: true,
    centerMode: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: '0',
    variableWidth: false,
    arrows: false,
    dots: true,
    focusOnSelect: true,
    autoplay: false,
    appendDots: (dots) => <DotsWrapper style={{ position: 'absolute' }}>{dots}</DotsWrapper>,
    beforeChange: (current, next) => setActiveIndex(next),
    // afterChange: (current) => setActiveIndex(current),
    responsive: [
      {
        breakpoint: 1200,
        settings: { autoplay: false, slidesToShow: 1, centerPadding: '320px', variableWidth: false },
      },
      {
        breakpoint: 980,
        settings: { autoplay: false, slidesToShow: 1, centerPadding: '0', variableWidth: false },
      },
      {
        breakpoint: 768,
        settings: { autoplay: true, slidesToShow: 1, arrows: false, centerPadding: '0', variableWidth: false },
      },
    ],
  };

  if (prizes.length > 2) {
    return (
      <StyledPreview>
        <Slider {...settings}>
          {prizes.map((testOption: PrizeType, index) => (
            <PrizeItemContainer key={testOption.id}>
              <PrizeItem {...testOption} isActive={activeIndex === index} />
            </PrizeItemContainer>
          ))}
        </Slider>
      </StyledPreview>
    );
  }

  return null;
}

const DotsWrapper = styled.div`
  width: 380px;
  position: absolute;
  left: 50%;
  bottom: 3%;
  transform: translate(-50%, 0);

  li {
    margin: 0 3px;
  }
`;

// export const PrizeItems = styled.div`
//   /* display: flex;
//   justify-content: space-between; */
// `;

const PrizeItemContainer = styled.div`
  /* width: 33.3%; */
  @media (max-width: 1200px) {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  /* @media (max-width: 980px) {
    justify-content: flex-start;
  } */
`;

export const StyledPreview = styled.div`
  width: 100%;
  position: relative;
  padding: 20px 0;
  border-radius: 8px;
  margin: 30px 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  max-width: 1110px;
  margin: auto;
  @media (min-width: 768px) {
    background: transparent;
  }
  @media (max-width: 425px) {
    margin: 0;
    padding: 10px 0 0;
    overflow: hidden;
  }

  .slick-current {
    /* margin: 0 30px; */
  }

  .slick-slide {
    overflow: hidden;
  }

  .slick-list {
    padding: 0 !important;
  }

  .slick-track {
    margin-left: 15px;

    @media (max-width: 1200px) {
      margin-left: 315px;
    }

    @media (max-width: 980px) {
      margin-left: 0;
    }
  }

  @media (min-width: 980px) {
    margin-top: 100px;
  }
`;
export default Controller(PrizeSlider);
