// @flow

import React, { type Node } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

type ButtonTabProps = {
  children: Node,
  to: string,
};

function ButtonTab({ children, to }: ButtonTabProps) {
  return <Tab to={to}>{children}</Tab>;
}

export default ButtonTab;

// styles
const Tab = styled(NavLink)`
  font-size: 14px;
  width: 100%;
  text-transform: uppercase;
  padding: 11px 0;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  background: #5d5ce2;
  text-decoration: none !important;
  transition: filter 0.2s linear;
  display: flex;
  justify-content: center;
  transition: all 0.25s linear;

  &:hover {
    filter: brightness(115%);
  }
`;
