// @flow

import React from 'react';
import styled from 'styled-components';
import { or, not, and, filter } from 'ramda';

import { getPath, BLOG_CATEGORY_PATH } from 'v2/constants/router';
import useWindowSize from 'v2/services/useWindowSize';

import Arrows from './Arrows';
import SeeMoreBtn from './SeeMoreBtn';

type CategoryHeadProps = {
  nextAction?: () => void,
  prevAction?: () => void,
  showMoreBtn?: Boolean,
};

const defaultProps = {
  nextAction: null,
  prevAction: null,
  showMoreBtn: false,
};

function CategoryHead({
  children,
  nextAction,
  prevAction,
  id: categoryId,
  title,
  subtitle,
  league,
  showMoreBtn,
  ...rest
}: CategoryHeadProps) {
  const [documentWidth] = useWindowSize();
  const isMobile = documentWidth < 660;

  const isShowArrows = and(or(nextAction, prevAction), not(isMobile));
  const seeMoreNavigateUrl = getPath(BLOG_CATEGORY_PATH, { categoryId });

  return (
    <Container>
      <Header>
        <Description>
          <Title>{title}</Title>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </Description>

        {isMobile && showMoreBtn && <SeeMoreBtn navigateUrl={seeMoreNavigateUrl} />}
        {isShowArrows && <Arrows nextAction={nextAction} prevAction={prevAction} />}
      </Header>

      <ContentContainer>{children}</ContentContainer>

      {not(isMobile) && showMoreBtn && <SeeMoreBtn navigateUrl={seeMoreNavigateUrl} />}
    </Container>
  );
}

CategoryHead.defaultProps = defaultProps;
export default CategoryHead;

// styles

const Subtitle = styled.span`
  padding: 0;
  margin: 0;
  line-height: 1;
  font-size: 17px;
  text-transform: capitalize;
  letter-spacing: 0px;
  font-weight: 500;
  margin-bottom: 4px;
`;

const Title = styled.h1`
  padding: 0;
  margin: 0;
  line-height: 1;
  font-size: 30px;
  margin-right: 10px;
  letter-spacing: 0px;
`;

const Description = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0 5px 0 5px;
  position: relative;
  margin-left: 10px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -16px;
    left: 0;
    width: 100%;
    height: 3px;
    background: ${({ theme }) => theme.colors.purpleAccent};
    z-index: 1;
    border-radius: 1.5px;
  }
`;

const ContentContainer = styled.div`
  margin: 25px 0;

  @media (max-width: 450px) {
    margin: 10px 0;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding-bottom: 15px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: ${({ theme }) => theme.colors.gray};
    z-index: 0;
    border-radius: 1.5px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 50px;
  /* .slick-list {
    width: 100vw;
  } */

  /* &:last-child {
    margin-bottom: 0;
  } */
`;
