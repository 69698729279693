import { SignOutApiAdapter } from 'v2/apiAdapters';

import { setCookieByName } from 'v2/utils/cookies';

import * as requestTypes from '../../constants/requestTypes';
import * as types from '../../constants/actionTypes';

import setRequestInProcess from '../request';

export function fetchSignOut() {
  const requestType = requestTypes.SIGN_OUT_FETCH;

  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));

    SignOutApiAdapter.getSignOut().then((res) => {
      window.location.reload();
      dispatch(fetchSignOutSuccess(res.signOut));
      dispatch(setRequestInProcess(false, requestType));
    });

    setCookieByName('isShowedTutorial', 'false');
    // .catch((error) => {
    //   if (error.response) {
    //     openNotification('error', error.response.data.message);
    //   }
    // });
  };
}

function fetchSignOutSuccess(payload) {
  return {
    type: types.SIGN_OUT_FETCH_SUCCESS,
    payload,
  };
}

export default fetchSignOut;
