// @flow

import React, { useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { KindTabs } from 'v2/components/Tabs';
import * as R from 'ramda';

import { KIND_TABS } from 'v2/constants/UI/tabs/kindTabs';

import * as paramsHelpers from './helpers/params';
import secondStepImg from './img/2_step.png';
import surviveExamplemg from './img/survive_example.png';

import AccordItem from './AccordItem';

export const DATA_ITEMS = {
  // all: [
  //   {
  //     title: 'PICK A GAME TYPE & JOIN A CONTEST.',
  //     content: () => (
  //       <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
  //         <img style={{ width: '135px', padding: '0 10px' }} src={pickemLogo} alt="Pickem Logo" />
  //         <img style={{ width: '135px', padding: '0 10px' }} src={survivorLogo} alt="Survive Logo" />
  //       </div>
  //     ),
  //   },
  //   {
  //     title: 'CHOOSE PLAYERS TO ACHIEVE THE STAT OBJECTIVES.',
  //     content: () => <img style={{ width: '100%', height: '100%' }} src={secondStepImg} />,
  //   },
  //   { title: 'SUBMIT PICKS FOR a CHANCE TO WIN!' },
  // ],
  survive: [
    {
      title: 'FEATURING SURVIVOR CONTESTS,\nUSING PLAYERS INSTEAD OF TEAMS.\n\nANY INCORRECT PICKS = ELIMINATION',
    },
    { title: 'PICK PLAYERS TO ACHIEVE EACH\nSTAT OBJECTIVE.', content: () => <img style={{ width: '100%', height: '100%' }} src={surviveExamplemg} /> },
    { title: 'SURVIVE & ADVANCE WEEKLY\nIF ALL PICKS ARE CORRECT.\n\nBE THE LAST ONE REMAINING TO WIN!' },
  ],
  weekly: [
    {
      title: 'PICK 3 PLAYERS TO ACHIEVE EACH STAT OBJECTIVE.',
      content: () => <img style={{ width: '100%', height: '100%' }} src={secondStepImg} />,
    },
    { title: 'PLAYERS CAN ONLY BE SELECTED ONCE REGARDLESS OF THE STAT OBJECTIVE.' },
    { title: 'WIN WITH THE MOST CORRECT PICKS!' },
  ],
};

const getLens = (key) => R.lens(R.prop(key), R.assoc(key));
const setParam = R.curry((params, lensKey, key) => R.set(getLens(lensKey), key, params));
const getParam = R.curry((params, lensKey) => R.view(getLens(lensKey), params));

const { kindKey, defaultKindKey } = paramsHelpers;

function HowToPlay() {
  const [params, setParams] = useState(paramsHelpers.parseParams());

  function onParamsChange(input) {
    paramsHelpers.stringifyParams(input);
  }

  const onChange = R.curry((lensKey, key) => {
    setParams((oldParams) => {
      onParamsChange({ ...oldParams, ...setParam(params, lensKey, key) });
      return { ...oldParams, ...setParam(params, lensKey, key) };
    });
  });

  const getStatus = getParam(params);

  return (
    <Container>
      <Title>HOW TO PLAY</Title>

      <TabsContainer>
        <TabsTitle>Game Type</TabsTitle>
        <KindTabs onChange={onChange(kindKey)} activeKey={R.or(getStatus(kindKey), defaultKindKey)} tabs={R.tail(KIND_TABS)} />
      </TabsContainer>

      {R.prop(R.or(getStatus(kindKey), defaultKindKey), DATA_ITEMS).map((item, i) => (
        <AccordItem number={i + 1} {...item}>
          {item.content && item.content()}
        </AccordItem>
      ))}

      <ButtonContainer>
        <ButtonLink to={`/?kind=${R.or(getStatus(kindKey), defaultKindKey)}`}>GOT IT!</ButtonLink>
      </ButtonContainer>
    </Container>
  );
}

export default HowToPlay;

// styles

const Title = styled.h1`
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
`;

const Container = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
  padding: 10px;

  max-width: 400px;
  margin: auto;
  padding-bottom: 80px;
`;

const ButtonLink = styled(NavLink)`
  background: ${({ theme }) => theme.colors.purpleAccent};
  border-radius: 6px;
  padding: 11px;
  width: 100%;
  max-width: 360px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase;
`;

const ButtonContainer = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: 10px 0;

  background: ${({ theme }) => theme.colors.dark};
`;

const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

const TabsTitle = styled.span`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 8px;
`;
