import React from 'react';
import PhotoBlock from 'v2/components/PromoPages/shared/PhotoBlock';
import videoPreviewImg from 'v2/components/shared/Image/promo/2/1.png';

import PlayNowButton from '../../../shared/PlayVideoButton';
import BlockDescription from '../../shared/DescriptionBlock';

const defaultProps = {
  showText: true,
};

function block({ showText }) {
  return (
    <PhotoBlock.Wrapper style={{ maxWidth: '100%', position: 'relative' }}>
      <PlayNowButton videoId="274739963" />
      <PhotoBlock src={videoPreviewImg} contentDirection="bottom" noBorder>
        {showText && (
          <BlockDescription size="large" style={{ marginBottom: '60px' }}>
            MAKE YOUR FANTASY{'\n'}A REALITY!
          </BlockDescription>
        )}
      </PhotoBlock>
    </PhotoBlock.Wrapper>
  );
}

block.defaultProps = defaultProps;
export default block;
