import activeLogo from 'v2/components/shared/Image/status_games_icons/active.svg';
import recentLogo from 'v2/components/shared/Image/status_games_icons/recent.svg';
// import bracketLogo from 'v2/components/shared/Image/kind_icons/bracket.svg';
// import streakLogo from 'v2/components/shared/Image/kind_icons/streak.svg';

export default [
  ['active', { label: 'active', img: activeLogo, activeColor: 'greenAccent' }],
  ['recent', { label: 'recent', img: recentLogo, activeColor: 'redAccent' }],
  // ['survive', { label: 'survivor', img: survivorLogo, activeColor: 'purpleAccent' }],
  // ['weekly', { label: 'pickem', img: pickemLogo, activeColor: 'greenAccent' }],
  // ['bracket', { label: 'bracket', img: bracketLogo, survivorLogo, activeColor: 'purpleAccent' }],
  // ['streakLogo', { label: 'streakLogo', img: streakLogo, survivorLogo, activeColor: 'purpleAccent' }],
];
