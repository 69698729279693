// @flow

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { propOr, propEq, filter, not, map, prop, path, replace, isEmpty, compose } from 'ramda';
import { FacebookShareButton, TwitterShareButton } from 'react-share';

import { history } from 'v2/stores/configureStore';
import { BLOG_ARTICLE_PATH, getPath } from 'v2/constants/router';
import useWindowSize from 'v2/services/useWindowSize';
import { copyToClipboard } from 'v2/helpers/common';
import twitterIcon from 'v2/components/shared/Image/Icon/social/new/twitter.svg';
import facebookIcon from 'v2/components/shared/Image/Icon/social/new/facebook.svg';
import linkIcon from 'v2/components/shared/Image/Icon/social/new/link.svg';
import StepBackIcon from 'v2/components/shared/Image/Icon/step_back_icon.svg';
import NoContent from '../NoContent';

import PreviewCard from '../shared/PreviewCardNew';
import SocialLink from '../shared/SocialLink';
import CategoryBlock from '../shared/CategoryBlock';

type ArticlePageProps = {};

function ArticlePage({
  fetchBlogArticle,
  fetchBlogCategory,
  match: {
    params: { articleId },
  },
  ...rest
}: ArticlePageProps) {
  const { origin } = window.location;

  const [data, setData] = useState({});
  const [categoryCompilation, setCategoryCompilation] = useState();
  const [documentWidth] = useWindowSize();
  const isMobile = documentWidth < 660;
  const imgToShow = prop('coverPhotoUrl', data);
  const isShowCategoryCompilation = compose(not, isEmpty, propOr([], 'posts'))(categoryCompilation);
  const textForClipboard = `${origin}${getPath(BLOG_ARTICLE_PATH, { articleId })}`;

  const shareButtons = [
    // { key: 0, icon: instagramIcon, url: '#', Adapter: FacebookShareButton },
    { key: 1, icon: StepBackIcon, url: '#', handler: history.goBack },
    { key: 2, icon: twitterIcon, url: '#', Adapter: TwitterShareButton },
    { key: 3, icon: facebookIcon, url: '#', Adapter: FacebookShareButton },
    {
      key: 4,
      icon: linkIcon,
      url: '#',
      handler: copyToClipboard(textForClipboard),
    },
  ];

  const getData = async () => {
    try {
      const res = await fetchBlogArticle(articleId);
      const catInfo = await fetchBlogCategory(path(['data', 'categoryId'], res), { limit: 3 });
      setData(res.data);
      setCategoryCompilation(prop('data', catInfo));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, [articleId]);

  const wrapImg = (attachment) => {
    return `<div class="img_wrapper">${attachment}</div>`;
  };

  return (
    <Container>
      {not(isMobile) && (
        <DesktopMenu>
          <SocialLink.Container isVertical>
            {shareButtons.map((shareButton) => (
              <SocialLink {...shareButton} />
            ))}
          </SocialLink.Container>
        </DesktopMenu>
      )}

      <PreviewCard photo={imgToShow} {...data} />

      {isMobile && (
        <MobileMenu>
          <SocialLink.Container>
            {compose(
              map((shareButton) => <SocialLink {...shareButton} />),
              filter(compose(not, propEq('key', 1))), // without back button
            )(shareButtons)}
          </SocialLink.Container>
        </MobileMenu>
      )}

      <ContentContainer
        dangerouslySetInnerHTML={{
          __html: compose(
            replace(/url=/g, 'src='),
            replace(/<\/action-text-attachment>/g, ''),
            replace(/<action-text-attachment/g, '<img class="article_img" '),
            replace(/(<action-text-attachment.+?<\/action-text-attachment>)/g, wrapImg),
            propOr('', 'content'),
          )(data),
        }}
      />

      {isShowCategoryCompilation && <CategoryBlock withSlider={false} {...categoryCompilation} />}
    </Container>
  );
}

export default ArticlePage;

// styles
const DesktopMenu = styled.div`
  position: absolute;
  left: -50px;
  top: 0;
`;

const MobileMenu = styled.div`
  padding: 0 20px;
`;

const ContentContainer = styled.div`
  width: 100%;
  position: relative;

  line-height: 1.4;
  font-weight: 400;
  padding: 15px;
  font-size: 18px;
  line-height: 1.6;
  letter-spacing: 0;

  .article_img {
    height: auto;
    max-height: 100%;
    max-width: 100%;
  }

  .img_wrapper {
    margin-left: -15px;
    margin-right: -15px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  a {
    color: ${({ theme }) => theme.colors.purpleAccent};
    font-size: 18px;
  }

  &:before {
    content: '';
    display: block;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.gray};
    padding: 0 20px;
    margin-bottom: 20px;
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 730px;
  margin: auto;
`;
