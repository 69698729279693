import React from 'react';
import styled from 'styled-components';

import Statistic from '../Statistics';
import LogoImage from '../../shared/Image';
import star from '../../shared/Image/star.svg';
import avatar from '../../shared/Image/avatar.svg';
import mysteryMemorabliaImg from '../../shared/Image/mysteryMemorablia.svg';
import avatarPickemIcon from '../../shared/Image/avatar3.png';
import avatarSurviveIcon from '../../shared/Image/avatar1.png';

const propTypes = {};

const defaultProps = {
  grandPrize: {
    description: '',
  },
};

const getAvatarImg = (type) => {
  if (type === 'survive') return avatarSurviveIcon;
  return avatarPickemIcon;
};

function GrandPrize(props) {
  const { grandPrize, mysteryMemorablia, kind } = props;
  const { description } = grandPrize;

  const avatarImg = getAvatarImg(kind);

  return (
    <StyledGrandPrize>
      <GrandPrizeAvatar>
        {mysteryMemorablia ? (
          <LogoImage style={{ height: '100%', borderRadius: '50px' }} img={mysteryMemorabliaImg} />
        ) : (
          <LogoImage style={{ height: '100%', borderRadius: '50px' }} img={avatarImg} />
        )}
      </GrandPrizeAvatar>
      <GrandPrizeInfo>
        <Status>
          {/* <img src={star} alt="" /> */}
          Grand Prize
        </Status>
        {description && <GrandPrizeDescription>{description}</GrandPrizeDescription>}
        <Statistic {...props} />
      </GrandPrizeInfo>
    </StyledGrandPrize>
  );
}

const GrandPrizeDescription = styled.h3`
  font-size: ${({ children }) => {
    if (children.length < 24) return '17px';
    if (children.length < 34) return '14px';
    return '12px';
  }};
  margin: 0;
  /* margin-top: -10px; */
  /* line-height: 1;
  margin-top: 0.1em; */
`;

const StyledGrandPrize = styled.div`
  position: relative;
  width: 110%;
  display: inline-flex;
  align-items: center;
  align-items: stretch;
  /* background: #26262e; */
  /* margin-top: 1em; */
  /* margin-top: 15px; */
  /* margin-left: 25px; */
  /* padding: 5px 0; */
  border-radius: 8px;
  margin-top: -5px;
  /* padding: 5px 0; */
`;

// :before {
//   content: '';
//   display: block;
//   width: 3px;
//   height: 100%;
//   position: absolute;
//   right: 0;
//   background: #ffb236;
//   border-radius: 0px 100px 100px 0px;
// }

const GrandPrizeAvatar = styled.div`
  /* margin-left: -25px; */
  margin-right: 10px;
  margin-top: 7px;
  // border: 1px dashed #ffb236;
  border-radius: 50%;

  width: 60px;
`;

const GrandPrizeInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  justify-content: space-around;
`;

const Status = styled.span`
  display: flex;
  margin-bottom: -4px;
  font-size: 12px;
`;

GrandPrize.propTypes = propTypes;
GrandPrize.defaultProps = defaultProps;

// export default enhance(HomePage);
export default GrandPrize;
