// @flow

import React from 'react';
import styled from 'styled-components';

type NoContentProps = {};

function NoContent({}: NoContentProps) {
  return (
    <Container>
      <Title>No content</Title>
    </Container>
  );
}

export default NoContent;

// styles

const Title = styled.h1`
  color: #656579;
  font-size: 38px;
  text-transform: capitalize;

  line-height: 2;
  text-align: center;
`;

const Container = styled.div``;
