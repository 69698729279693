import React, { useEffect, useState } from 'react';
import { getIn } from 'v2/helpers/ramda';
import { or, compose } from 'ramda';
import styled from 'styled-components';
import { history } from 'v2/stores/configureStore';
import { MY_GAMES } from 'v2/constants/router';
import { getQuerystring } from 'v2/apiAdapters/fetch';
import closeIcon from './icons/close.svg';
import infoIcon from './icons/info.svg';

function ContestHeader({ handleClose, handleInfo, id, kind, isContestActive, ...contest }) {
  // console.log('rest :>> ', rest);
  const [opacity, setOpacity] = useState(1);
  const calculateOpacity = () => (document.documentElement.scrollTop * 1.5) / 100;

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    setOpacity(calculateOpacity());
  }, []);

  const handleScroll = compose(setOpacity, calculateOpacity);

  // console.log('isContestActive :>> ', isContestActive);
  const navigateToMyGames = () =>
    history.push(
      `${MY_GAMES}${getQuerystring({
        activeId: id,
        status: isContestActive ? 'active' : 'recent',
      })}`,
    );

  const backAction = or(handleClose, navigateToMyGames);
  const name = getIn('name', contest);

  return (
    <Container style={{ backgroundColor: `rgba(19, 19, 23, ${opacity})` }}>
      <ContainerSection onClick={backAction}>
        <img src={closeIcon} alt="close" />
      </ContainerSection>
      {name && <ContainerSection main>{name}</ContainerSection>}
      <ContainerSection onClick={handleInfo}>
        <img src={infoIcon} alt="close" style={{ width: '20px' }} />
      </ContainerSection>
    </Container>
  );
}

export default ContestHeader;

// styles

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-weight: bold;
  font-size: 16px;
  padding: 0.9rem 0.75rem;
`;

const ContainerSection = styled.div`
  flex: ${({ main }) => (main ? 3 : 1)};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-child {
    justify-content: flex-start;
  }

  &:last-child {
    justify-content: flex-end;
  }
`;
