import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { progressIconsStyles, progressIconItemStyles, progressIconValStyles } from '../styles';
import peopleIcon from '../../Image/people_icon.svg';
import upIcon from '../../Image/up_icon.svg';
import downIcon from '../../Image/down_icon.svg';
import refreshIcon from '../../Image/refresh_icon.svg';

const propTypes = {};

const defaultProps = {};

function WeeklyIconItems(props) {
  const { correctPicks, pendingPicks, uncorrectPicks } = props;
  return (
    <Fragment>
      <StyledProgressIcons>
        <StyledProgressIconItem>
          <img src={upIcon} alt="upIcon" />
          <StyledProgressIconVal>{correctPicks}</StyledProgressIconVal>
        </StyledProgressIconItem>
        <StyledProgressIconItem>
          <img src={refreshIcon} alt="refreshIcon" />
          <StyledProgressIconVal>{pendingPicks}</StyledProgressIconVal>
        </StyledProgressIconItem>
        <StyledProgressIconItem>
          <img src={downIcon} alt="downIcon" />
          <StyledProgressIconVal>{uncorrectPicks}</StyledProgressIconVal>
        </StyledProgressIconItem>
      </StyledProgressIcons>
    </Fragment>
  );
}

const StyledProgressIcons = styled.div`
  ${progressIconsStyles}
`;

const StyledProgressIconItem = styled.div`
  ${progressIconItemStyles}
`;

const StyledProgressIconVal = styled.div`
  ${progressIconValStyles}
`;

WeeklyIconItems.propTypes = propTypes;
WeeklyIconItems.defaultProps = defaultProps;

export default WeeklyIconItems;
