import styled from 'styled-components';

export default styled.div`
  padding-bottom: 50px;
  max-width: 1100px;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  @media (max-width: 755px) {
    padding-bottom: 60px;
    justify-content: center;
  }
`;
