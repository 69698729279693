// @flow

import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import useWindowSize from 'v2/services/useWindowSize';

import { ButtonsData } from 'v2/constants/UI/buttons';
import { getPath, ROOT_PATH, LEAGUE_PATH } from 'v2/constants/router';
import Button from '../Button';
import LeagueTabs from './LeagueTabs/index';
import Tooltip from './Tooltip';

function NavigationPanel({ match: { params }, kind }) {
  const [documentWidth] = useWindowSize();

  const isMobile = documentWidth < 560;

  const getActiveStatusByLeague = (league, defaultActive = false) => {
    if (params.league) {
      return params.league === league;
    }

    return defaultActive;
  };

  return (
    <NavigationContainer>
      {/* <Title>Free To Join</Title> */}

      <MainButtonGroup>
        {ButtonsData.map(({ alt, id, title, logo, league, inactive, activeByDefault }) => (
          <ButtonContainer key={id}>
            {inactive ? (
              <Tooltip message="Coming soon">
                <Button
                  isLink
                  logo={logo}
                  alt={alt}
                  title={null}
                  activeStatus={getActiveStatusByLeague(league, activeByDefault)}
                  hrefTo={!inactive && (league ? getPath(LEAGUE_PATH, { league }) : ROOT_PATH)}
                  disabled={inactive}
                />
              </Tooltip>
            ) : (
              <Button
                isLink
                logo={logo}
                alt={alt}
                title={null}
                activeStatus={getActiveStatusByLeague(league, activeByDefault)}
                hrefTo={!inactive && (league ? getPath(LEAGUE_PATH, { league }) : ROOT_PATH)}
                disabled={inactive}
              />
            )}
          </ButtonContainer>
        ))}
      </MainButtonGroup>
      {/* <Separator /> */}
      <LeagueTabs kind={kind} />
    </NavigationContainer>
  );
}

const Separator = styled.div`
  display: block;
  width: 100vw;
  height: 1px;
  background-color: #26262e;
  bottom: 0;
  margin-top: 20px;
  margin-bottom: 15px;
`;

const Title = styled.h1`
  color: #fff;
  font-size: 44px;
  font-weight: bold;
  text-align: center;
  display: inline-flex;
  border-radius: 2px;
  position: relative;
  padding-bottom: 14px;
  text-transform: capitalize;
  margin-top: 0;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #5d5ce2;
    bottom: 0;
  }

  @media (max-width: 776px) {
    font-size: 35px;
  }

  @media (max-width: 576px) {
    font-size: 30px;
  }
`;

const MainButtonGroup = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  margin-left: 0.45em;

  &:first-child {
    margin-left: 0;
  }
`;

const NavigationContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  padding-bottom: 5px;

  @media (max-width: 476px) {
    padding-bottom: 10px;
  }
`;

export default withRouter(NavigationPanel);
