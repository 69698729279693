/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router';
import { animateScroll } from 'react-scroll';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import { history } from 'v2/stores/configureStore';
import { removeCookieByName } from 'v2/utils/cookies';
import { getOrderedStatCategoriesList } from 'v2/selectors/statCategories';
import useWindowSize from 'v2/services/useWindowSize';
import { getIn } from 'v2/utils/ramda';
import { requestInProcess } from 'v2/selectors/request';
import * as actions from 'v2/actions';
import * as requestTypes from 'v2/constants/requestTypes';

import Loader from 'v2/components/shared/Loader';

import { Players, CategoryPlayers, Category } from '../shared';
import { search, arrowPrevious, arrowNext } from '../shared/Image';
import { CONTEST_CATEGORY_PATH, getPath } from '../../../constants/router';
import closeIcon from '../Header/icons/close.svg';

import StatCategories from './shared/StatCategories';

import WeekSwitcher from './shared/WeekSwitcher';

import {
  getResourceContestWeeks,
  getResourceContestDetail,
  getResourceContestPrizes,
  getResourceContestStatCategories,
  getResourceContestPlayers,
  getResourceContestStatCategory,
  getResourceContestSelectedPlayers,
  getResourceContestSelectedPlayersIds,
  getCategoryWithSelectedPlayers,
  getSelectedPlayersCount,
  getIsFinalChoise,
} from '../../../selectors/contests';

const propTypes = {
  statCategories: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.string,
      id: PropTypes.number.isRequired,
      subject: PropTypes.string,
    }),
  ),
};

const defaultProps = {
  contest: {
    totalSelectionsRequired: 0,
    urlContest: {
      weekOrder: null,
    },
  },
  statCategories: [],
  contestPlayers: [],
  contestPrizes: [],
  contestCategory: [],
  weeks: {
    nextWeek: null,
    previousWeek: null,
  },
};

function MakePicks(props) {
  const [showSearch, setShowSearch] = useState(false);
  const inputRef = useRef();

  const { resetResourceContest, categories } = props;
  const {
    contest,
    statCategories,
    contestSelectedPlayers,
    contestPlayers,
    contestSelectedPlayersIds,
    onPlayerSelect,
    categoryId,
    contestCategory,
    onSubmitPicks,
    categoryWithSelectedPlayers,
    contestPrizes,
    selectedPlayersCount,
    weeks,
    onCurrentCategoryId,
    isLoading,
    isFinalChoise,
    joinToContest,
    statCategoriesList,
    match: {
      params: { id: contestId, weekOrder },
    },
    match: { params },
  } = props;

  const { previousWeek, nextWeek, currentWeek } = weeks;
  const {
    id,
    urlContest,
    selectionsRequired,
    totalSelectionsRequired,
    isUserEliminated,
    isContestEnded,
    contestLoading,
    live,
    eliminationWeek = 1000, // fixme,
  } = contest;

  const dispatch = useDispatch();

  const getDayOrWeek = R.ifElse(
    compose(R.equals('nfl'), R.curry(getIn)('league')),
    () => 'week',
    () => 'day',
  )(contest);

  const categoryIdInt = parseInt(categoryId, 10);

  const [isQuery, setIsQuery] = useState('');

  useEffect(
    () => () => {
      // resetResourceContest();
      removeCookieByName('selectedPlayers');
    },
    [],
  );

  const searchPlayers = contestPlayers.filter((player) => R.toLower(player.name).includes(R.toLower(isQuery)));

  const testCategory = (id, currentId) => {
    const sortedCategories = statCategoriesList;

    const slicedStatCategories = R.compose(
      R.slice(R.findIndex(R.propEq('id', id), sortedCategories), sortedCategories.length),
    )(sortedCategories);

    const findStat = slicedStatCategories.find(
      (statCategory) =>
        statCategory.id !== id
        && statCategory.selectedPlayers
        && statCategory.selectedPlayers.length !== selectionsRequired,
    );

    if (findStat) {
      return findStat.id;
    }
    const nexFindStat = sortedCategories.find(
      (statCategory) =>
        statCategory.id !== id
        && statCategory.selectedPlayers
        && statCategory.selectedPlayers.length !== selectionsRequired,
    );

    if (nexFindStat) {
      return nexFindStat.id;
    }

    return null;
  };
  // const notFilledCategoryId = testCategory();

  const [documentWidth] = useWindowSize();

  const isMobile = documentWidth < 480;

  const contestJoin = R.once(() => joinToContest(contestId));

  useEffect(() => {
    window.scrollTo(0, 0);
    if (typeof contest.isJoin !== 'undefined' && contest.isJoin != null && !contest.isJoin) {
      contestJoin();
    }
  }, [contest.isJoin]);

  useEffect(() => {
    if (id) {
      dispatch(actions.fetchContestPicks({ id, weekOrder, categoryId }));
    }
  }, [weekOrder, categoryId, id]);

  const onPlayerSelectModify = (category, playerId) => {
    const notFilledCategory = testCategory(category.id, contestId);
    onPlayerSelect(category, playerId, selectionsRequired, {
      categoryWithSelectedPlayers,
      contestId,
      currentWeek,
      notFilledCategoryId: notFilledCategory,
    });
  };

  if (selectedPlayersCount === totalSelectionsRequired) {
    animateScroll.scrollToTop();
  }

  // if (contestLoading) {
  //   return <Loader />;
  // }

  const hideSearchForm = () => {
    setIsQuery('');
    setShowSearch(false);
  };

  useEffect(() => {
    if (showSearch && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current, showSearch]);

  const resetSearchForm = () => {
    if (R.isEmpty(isQuery)) {
      return setShowSearch(false);
    }

    return setIsQuery('');
  };

  const navigateToCategory = (categoryId) =>
    history.push(getPath(CONTEST_CATEGORY_PATH, { id, weekOrder: R.or(currentWeek, weekOrder), categoryId }));

  const navigateToWeek = (weekOrder) => history.push(getPath(CONTEST_CATEGORY_PATH, { id, weekOrder, categoryId }));

  return (
    <>
      <MakePicksSection>
        <MakePicksSectionStick>
          {currentWeek && contest.kind === 'survive' && (
            <WeekSwitcher isLoading={isLoading} handler={navigateToWeek} {...contest} {...weeks} />
          )}

          <MakePicksHeader>
            <StatCategories
              navigateToCategory={navigateToCategory}
              selectionsRequired={selectionsRequired}
              league={contest.league}
              currentCategoryId={categoryIdInt}
              categories={statCategoriesList}
            />

            {/* {!isMobile && (
              <StatCategories
                navigateToCategory={navigateToCategory}
                selectionsRequired={selectionsRequired}
                league={contest.league}
                currentCategoryId={categoryIdInt}
                categories={R.slice(0, 3, statCategoriesList)}
                showDots={false}
              />
            )} */}

            <CategoryPlayers
              {...contest}
              league={contest.league}
              selectedPlayers={contestSelectedPlayers}
              selectionsRequired={selectionsRequired}
              onPlayerSelect={onPlayerSelect}
              contestCategory={contestCategory}
              isLoading={isLoading}
              isContestEnded={isContestEnded}
            />

            {/* <StatCategories
              navigateToCategory={navigateToCategory}
              selectionsRequired={selectionsRequired}
              league={contest.league}
              currentCategoryId={categoryIdInt}
              categories={R.slice(3, 6, statCategoriesList)}
              showDots={false}
            /> */}
          </MakePicksHeader>

          {isMobile && !isFinalChoise && R.length(R.pathOr([], ['players'], contestCategory)) > 0 && !isLoading && (
            <TitleContainer>
              <ChooseplayerTitle onClick={() => setShowSearch((old) => !old)}>
                <SearchButton src={search} alt="search" active={showSearch} />
                {`Choose ${Number(selectionsRequired) - Number(contestSelectedPlayers.length)} Player${
                  Number(selectionsRequired) - Number(contestSelectedPlayers.length) > 1 ? 's' : ''
                } to Achieve: `}

                <StatCategoryPrime isSurvive={contest.kind === 'survive'}>
                  {contestCategory && ` ${contestCategory.amount} ${contestCategory.subject} `}
                </StatCategoryPrime>
              </ChooseplayerTitle>
            </TitleContainer>
          )}
        </MakePicksSectionStick>
        {!isFinalChoise && R.length(R.pathOr([], ['players'], contestCategory)) > 0 && !isLoading && (
          <MakePicksBody>
            {R.not(isMobile) && (
              <div>
                <ChooseplayerTitle onClick={() => setShowSearch((old) => !old)}>
                  <SearchButton src={search} alt="search" active={showSearch} />
                  {`Choose ${Number(selectionsRequired) - Number(contestSelectedPlayers.length)} Player${
                    Number(selectionsRequired) - Number(contestSelectedPlayers.length) > 1 ? 's' : ''
                  } to Achieve: `}

                  <StatCategoryPrime isSurvive={contest.kind === 'survive'}>
                    {contestCategory && ` ${contestCategory.amount} ${contestCategory.subject} `}
                  </StatCategoryPrime>
                </ChooseplayerTitle>
              </div>
            )}
            <InputForm id="searchPlayers" active={showSearch}>
              <SearchIcon src={search} alt="search" onClick={hideSearchForm} />
              <SearchPlayer
                ref={inputRef}
                type="text"
                placeholder="Search player"
                value={isQuery}
                onChange={(e) => setIsQuery(e.target.value)}
              />
              <CloseIcon src={closeIcon} alt="closeIcon" onClick={resetSearchForm} active={showSearch} />
            </InputForm>
            <Players
              contestCategory={contestCategory}
              onPlayerSelect={onPlayerSelectModify}
              makePicksPlayers={searchPlayers}
              contestSelectedPlayersIds={contestSelectedPlayersIds}
              selectionsRequired={selectionsRequired}
              isUserEliminated={isUserEliminated}
              kind={contest.kind}
            />
          </MakePicksBody>
        )}
      </MakePicksSection>
    </>
  );
}

const mapStateToProps = (state, props) => {
  const categoryId = getIn('match.params.categoryId', props);
  const weekOrder = getIn('match.params.weekOrder', props);
  const selectionsRequired = getIn('contest.selectionsRequired', props);

  return {
    weekOrder,
    contest: getResourceContestDetail(state),
    contestLoading: requestInProcess(state, { requestType: requestTypes.CONTEST_FETCH }),
    weeks: getResourceContestWeeks(state),
    contestPrizes: getResourceContestPrizes(state),
    selectedPlayersCount: getSelectedPlayersCount(state),
    statCategories: getResourceContestStatCategories(state),
    statCategoriesList: getOrderedStatCategoriesList(state, { statCategoryIds: props.contest.statCategories }),
    categoryWithSelectedPlayers: getCategoryWithSelectedPlayers(state),
    contestSelectedPlayersIds: getResourceContestSelectedPlayersIds(state, { categoryId }),
    contestSelectedPlayers: getResourceContestSelectedPlayers(state, { categoryId }),
    contestPlayers: getResourceContestPlayers(state, { categoryId }),
    contestCategory: getResourceContestStatCategory(state, { categoryId }),
    isLoading:
      requestInProcess(state, { requestType: requestTypes.CONTEST_SELECTED_PLAYER_FETCH })
      || requestInProcess(state, { requestType: requestTypes.CONTEST_SELECTED_PLAYER_FETCH })
      || requestInProcess(state, { requestType: requestTypes.CONTEST_MAKE_PICKS_FETCH }),
    isFinalChoise: getIsFinalChoise(state, { categoryId, selectionsRequired }),
    categoryId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetResourceContest: () => dispatch(actions.resetResourceContest()),
  joinToContest: (contestId) => dispatch(actions.joinToContest(contestId)),
  fetchContestMakePicks: (params) => dispatch(actions.fetchContestMakePicks(params)),
  onPlayerSelect: (contestCategory, playerId, selectRequired, options) => {
    dispatch(actions.toggleStatCategoryPlayerId(contestCategory, playerId, selectRequired, options));
  },
  onSubmitPicks: (body, id, weekOrder, notFilledCategoryId) =>
    dispatch(actions.submitContestMakePicks(body, id, weekOrder, notFilledCategoryId)),
});

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidUpdate(nextProps) {
      if (this.props.weekOrder !== nextProps.weekOrder) {
        const { fetchContestMakePicks, match } = this.props;
        const { id, weekOrder } = match.params;

        fetchContestMakePicks({ id, weekOrder });
      }
    },
    componentDidMount() {
      const { fetchContestMakePicks, match } = this.props;
      const { id, weekOrder } = match.params;

      fetchContestMakePicks({ id, weekOrder });
    },
  }),
);

MakePicks.propTypes = propTypes;
MakePicks.defaultProps = defaultProps;

export default enhance(React.memo(MakePicks));

// styles
const ChooseplayerTitle = styled.span`
  white-space: nowrap;
  margin: 0;
  line-height: 1;
  font-size: 18px;
  color: #fff;
  margin-bottom: 10px;

  width: 100%;
  display: block;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 525px) {
    font-size: 12px;
  }
`;

const MakePicksSection = styled.div``;

const TitleContainer = styled.div`
  background-color: #131317;
  padding: 10px 0 5px 10px;
`;

const MakePicksHeader = styled.div`
  padding: 20px;
  background-color: #131317;
  @media (max-width: 480px) {
    overflow-x: hidden;
    /* display: flex;
    flex-direction: column; */
    padding: 12px 5px;
    /* background-color: rgb(19, 19, 23); */
  }
`;

const MakePicksSectionStick = styled.div`
  @media (max-width: 480px) {
    position: sticky;
    top: 49px;
    z-index: 9;
    /* box-shadow: 0px 25px 37px -4px rgba(0, 0, 0, 0.25); */
  }
`;

const MakePicksBody = styled.div`
  max-width: 750px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #1b1b21;
  @media (max-width: 480px) {
    padding: 10px;
    padding-bottom: 60px;
  }
`;

const InputForm = styled.div`
  position: relative;
  max-height: ${({ active }) => (active ? '100px' : 0)};
  transition: 0.15s;
  overflow: hidden;
`;

const SearchIcon = styled.img`
  position: absolute;
  top: 12px;
  left: 10px;
`;

const SearchButton = styled.img`
  padding: 0 5px 0 0;
  opacity: ${({ active }) => (active ? '0' : '1')};
  transition: 0.5s;
`;

const CloseIcon = styled.img`
  position: absolute;
  top: 37%;
  transform: translate(-50%, -50%);
  right: 5px;
  opacity: ${({ active }) => (active ? '1' : '0')};
  transition: 0.5s;
`;

const SearchPlayer = styled.input`
  display: block;
  width: 100%;
  padding: 10px;
  padding-left: 35px;
  /* margin-top: 15px; */
  margin: 0 0 10px;
  border-radius: 8px;
  background: #26262e;
  color: #fff;
  outline: none;
  border: none;
  box-sizing: border-box;
  &::placeholder {
    color: #656579;
  }
`;

const StatCategoryPrime = styled.span`
  /* color: ${({ isSurvive }) => (isSurvive ? '#5d5ce2' : '#0bd855')}; */
  color: #0bd855;
  font-size: 23px;
  padding-left: 4px;

  @media (max-width: 525px) {
    font-size: 16px;
  }
`;
