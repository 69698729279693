// @flow

import React, { useState, useEffect } from 'react';
import { not, isEmpty, compose, propOr } from 'ramda';
import styled from 'styled-components';

import CategoryBlock from '../shared/CategoryBlock';
import Pagination from '../shared/Pagination';

import NoContent from '../NoContent';

type BlogPageProps = {
  fetchBlogCategories: () => void,
};

function BlogPage({ fetchBlogCategories, openVideoModal, ...rest }: BlogPageProps) {
  const [categories, setCategories] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [meta, setMeta] = useState({});

  const getData = async (page) => {
    try {
      setLoading(true);
      const res = await fetchBlogCategories(page);
      setCategories(res.data);
      compose(setCategories, propOr([], 'data'))(res);
      compose(setMeta, propOr([], 'meta'))(res);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (not(isLoading) && isEmpty(categories)) {
    return <NoContent />;
  }

  if (categories) {
    return (
      <>
        {categories.map((categoryOption) => (
          <CategoryBlock openVideoModal={openVideoModal} {...categoryOption} />
        ))}

        <Pagination {...meta} handler={getData} />
      </>
    );
  }

  return null;
}

export default BlogPage;

// styles
const Container = styled.div`
  padding-bottom: 50px;
  max-width: 1100px;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  @media (max-width: 755px) {
    padding-bottom: 60px;
    justify-content: center;
  }

  .slick-list {
    min-height: fit-content;
    @media (min-width: 768px) {
      margin: 0;
    }
  }

  /* .slick-slide {
    width: calc(1100px / 3 + 25px) !important;
  } */

  /* .slick-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  } */
`;
