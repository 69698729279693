import React, { Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';

import PreviewImage from '../PreviewImg';

const propTypes = {
  mediaCollection: PropTypes.arrayOf(PropTypes.object),
};

const defaultProps = {
  mediaCollection: undefined,
};

function SlickCarousel(props) {
  const { mediaCollection, settings } = props;

  const [isOpen, setIsOpen] = useState(undefined);
  const [currentVideo, setCurrentVideo] = useState({});

  if (!mediaCollection) {
    return false;
  }

  return (
    <Fragment>
      <StyledSlider {...settings}>
        {mediaCollection.map((media) => (
          <div className="slider_wrap slider_wrap__promo" key={media.id}>
            <PreviewImage pictureUrl={media.pictureUrl} />
            <StyledButton
              onClick={() => {
                setIsOpen(true);
                setCurrentVideo({ id: media.videoId, channel: media.videoChannel });
              }}
            />
          </div>
        ))}
      </StyledSlider>

      <ModalVideo
        autoplay="true"
        channel={currentVideo.channel}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        videoId={currentVideo.id}
        className="modalVideo"
      />
    </Fragment>
  );
}

const StyledButton = styled.button`
  background: #ce3c4d;
  padding: 24px;
  border-radius: 50px;
  border: 0;
  position: absolute;
  top: calc(50% - 24px);
  left: 0;
  right: 0;
  margin: 0 auto;
  cursor: pointer;
  bottom: 0;
  margin: 0 auto;
  cursor: pointer;
  height: 24px;
  width: 24px;
  &:hover {
    background: #e8495b;
    color: #fff;
    box-shadow: 0px 0px 10px 0px;
    &:after {
      border-color: #e8495b #e8495b #e8495b #fff;
    }
  }
  &:before {
    content: '';
    display: block;
    width: 250%;
    height: 250%;
    position: absolute;
    top: 50%;
    left: -250%;
    transform: translate(70%, -50%);
  }
  &:after {
    width: 0px;
    height: 0px;
    background: white;
    position: absolute;
    top: calc(50% - -5px);
    left: calc(50% - 4px);
    margin-top: -12px;
    content: ' ';
    border-style: solid;
    border-width: 7px 0 7px 12px;
    border-color: #ce3c4d #ce3c4d #ce3c4d #fff;
  }
`;

const StyledSlider = styled(Slider)`
  .slick-slide {
    /* width: 100%; */
  }
`;
// const { isOpen } = isHook(false);

// function isHook(defaultIsOpen) {
//   const [isOpen, setIsOpen] = useState(false);
//   const toOpen = useCallback(() => setIsOpen(true), [setIsOpen]);
//   const toClose = useCallback(() => setIsOpen(true), [setIsOpen]);
//   const toToggle = useCallback(() => setIsOpen(true), [setIsOpen, isOpen]);
//   return { toOpen, toClose, isOpen, toToggle };
// }

// export function tClose(defaultIsOpen) {
//   const [isClose, setIsClose] = useState(false);
//   const toClose = useCallback(() => setIsClose(true), [setIsClose]);
//   return { toClose };
// }

SlickCarousel.propTypes = propTypes;
SlickCarousel.defaultProps = defaultProps;

export default SlickCarousel;
