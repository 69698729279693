import React from 'react';
import styled from 'styled-components';

function Arrow(props) {
  const { className, style, onClick } = props;

  return <ArrowContainer className={className} onClick={onClick} />;
}

const ArrowContainer = styled.div`
  display: inline-block;
  width: 35px;
  height: 53px;
  /* border-radius: 50%; */
  top: auto;
  bottom: -50px;
  position: absolute;
  top: 23%;
  z-index: 8;
  transform: translate(0, -100%);

  &:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border: 3px solid #fff;
    transform: rotate(135deg);
    z-index: 6;
    border-left-color: transparent;
    border-top-color: transparent;
    top: 50%;
  }

  &.slick-prev {
    left: 10px;
    /* background: rgba(38, 38, 46, 0.3); */
    left: 0;

    &:before {
      transform: rotate(135deg) translate(-20%, 55%);
      left: 50%;
    }

    &:hover,
    &:hover,
    &:focus {
      /* background: rgba(38, 38, 46, 0.3); */
    }
  }

  &.slick-next {
    right: 10px;
    /* background: rgba(38, 38, 46, 0.3); */
    right: 0;

    &:before {
      transform: rotate(-45deg) translate(65%, -10%);
      right: 50%;
    }

    &:hover,
    &:hover,
    &:focus {
      /* background: rgba(38, 38, 46, 0.3); */
    }
  }
`;

export default Arrow;
