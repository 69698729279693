require('react-app-polyfill/ie11');
// require('core-js/features/array/find');
// require('core-js/features/array/includes');
// require('core-js/features/number/is-nan');
require('react-app-polyfill/stable');
require('isomorphic-fetch');
require('promise-polyfill/src/polyfill');

const componentRequireContext = require.context('v2/components', true);
const ReactRailsUJS = require('react_ujs');

ReactRailsUJS.useContext(componentRequireContext);
