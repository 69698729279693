import React from 'react';
import styled from 'styled-components';
import {
  evolve,
  split,
  has,
  sortWith,
  ascend,
  prop,
  tap,
  always,
  nth,
  __,
  when,
  not,
  compose,
  assoc,
  times,
  map,
  tail,
  head,
} from 'ramda';

import { isNotDefined, isDefined } from 'v2/helpers/ramda';

import upIcon from 'v2/components/shared/Image/up_icon.svg';
import downIcon from 'v2/components/shared/Image/down_icon.svg';

const evolveName = (fullname) => {
  const firstpart = compose(head, split(' '))(fullname);
  const seconpart = compose(tail, split(' '))(fullname);

  return `${head(firstpart)}. ${seconpart}`;
};

const getThumbIcon = (status) => {
  if (status === 'success') return upIcon;
  if (status === 'fail') return downIcon;

  return;
};

const PlayerName = ({ name, statusPick }) => {
  const thumbIcon = getThumbIcon(statusPick);
  return (
    <PlayerNameContainer>
      <StatusImgContainer>{thumbIcon && <StatusImg src={thumbIcon} />}</StatusImgContainer>
      <PlayerNameStyled>{name}</PlayerNameStyled>
    </PlayerNameContainer>
  );
};

function PlayerNames({ players, total, isContestActive, ...rest }) {
  const renderPlayerName = (index) =>
    compose(
      PlayerName,
      when(
        isNotDefined,
        compose(
          when(always(isContestActive), assoc('name', 'Pick a player')),
          assoc('name', 'Did no make pick'),
          when(always(not(isContestActive)), assoc('statusPick', 'fail')),
        ),
      ),
      when(has('name'), evolve({ name: evolveName })),
      nth(__, players || []),
    )(index);

  const renderPlayerNames = times(renderPlayerName);

  return <NamesContainer>{renderPlayerNames(total)}</NamesContainer>;
}

export default PlayerNames;

// styles
const PlayerNameStyled = styled.span`
  display: inline-block;
  /* width: ${({ showThumbs }) => showThumbs && '87px'}; */
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  color: #fff;
  font-size: 11px;
  line-height: 1;
`;

const StatusImg = styled.img`
  width: 11px;
`;

const StatusImgContainer = styled.div`
  min-width: 11px;
  margin-right: 4px;
`;

const PlayerNameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;
`;

const NamesContainer = styled.div`
  margin-top: -5px;
`;
