// @flow

import React from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import players from 'v2/components/shared/Image/players.png';
import nflPlayers from 'v2/components/shared/Image/NFL_5Players.png';
import { animateScroll } from 'react-scroll';

const getImageByLeague = (league?: string) => {
  // if (league) {
  //   if (league.toLowerCase() === 'nfl') return nflPlayers;

  //   return players;
  // }

  return nflPlayers;
};

function PlayNow({ match: { params } }) {
  // const query = useQuery();
  // const type = query.get('type');

  return (
    <PlayNowContainer>
      <PlayNowSection>
        <PlayNowDescription>
          <PlayNowTitle>Free to join</PlayNowTitle>
          <Tab to="/" onClick={() => animateScroll.scrollToTop()}>
            Play now
          </Tab>
        </PlayNowDescription>
      </PlayNowSection>
      {/* <PlayNowSection position="left">
        <JoinImage src={getImageByLeague(params.league)} alt="players" />
      </PlayNowSection> */}
    </PlayNowContainer>
  );
}

export default withRouter(PlayNow);

// styles

const PlayNowTitle = styled.h3`
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0px;
  padding: 0;
  text-transform: none;
  margin: 0;
  text-transform: capitalize;
`;

const JoinImage = styled.img`
  padding-top: 60px;
  max-width: 440px;

  @media (max-width: 558px) {
    padding-top: 10px;
    width: 100%;
  }
`;

const Tab = styled(NavLink)`
  margin-top: 15px;
  font-size: 18px;
  text-transform: uppercase;
  padding: 11px 0;
  border-radius: 4px;
  font-weight: bolder;
  cursor: pointer;
  background: #5d5ce2;
  text-decoration: none !important;
  transition: filter 0.2s linear;
  display: flex;
  justify-content: center;

  &:hover {
    filter: brightness(115%);
  }
`;

const PlayNowDescription = styled.div`
  /* z-index: 9; */
`;

const PlayNowSection = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: ${({ position }) => {
    if (position === 'left') return 'flex-start';
    return 'center';
  }};
`;

const PlayNowContainer = styled.div`
  position: relative;
  /* background: #17171c; */
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  /* &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.2;
    background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%);
    z-index: 9;
  } */

  @media (max-width: 558px) {
    padding-top: 50px;
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column;
    align-items: center;
  }
`;
