/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import * as R from 'ramda';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { getResourceContestDetail } from 'v2/selectors/contests';

import { isNotDefined } from 'v2/helpers/ramda';

import Player from './Player';

const propTypes = {};

const defaultProps = {};

function Players(props) {
  const {
    makePicksPlayers,
    contestSelectedPlayersIds,
    onPlayerSelect,
    contestCategory,
    selectionsRequired,
    isUserEliminated,
    warningPlayers,
    match: {
      params: { categoryId },
    },
    kind,
    statCategoriesSelectedPlayersIds,
  } = props;
  const maxPlayers = contestSelectedPlayersIds && contestSelectedPlayersIds.length >= selectionsRequired;

  const [isFocusHide, setHideFocus] = useState(false);
  // const [isSelected, setIsSelected] = useState(false);
  // const onSelected = () => {
  //   isSelected ? setIsSelected(false) : setIsSelected(true);
  // };
  // const [currentPlayer, setCurrentPlayer] = useState({});

  const getWarningStatusById = (id) => {
    if (kind === 'survive') {
      return R.compose(R.contains(id), R.last, R.prop(categoryId))(warningPlayers);
    }

    return R.contains(id, statCategoriesSelectedPlayersIds);
  };

  return (
    <StyledPlayers>
      <PlayerList hideFocus={isFocusHide}>
        {makePicksPlayers.map((player) => (
          <Player
            kind={kind}
            isWarning={getWarningStatusById(player.id)}
            key={player.id}
            player={player}
            hideFocus={setHideFocus}
            contestSelectedPlayersIds={contestSelectedPlayersIds}
            onPlayerSelect={onPlayerSelect}
            contestCategory={contestCategory}
            isDisable={maxPlayers}
            isUserEliminated={isUserEliminated}
          />
        ))}
      </PlayerList>
    </StyledPlayers>
  );
}

const StyledPlayers = styled.div`
  @media (max-width: 700px) {
    padding-right: 10px;
  }
`;

const PlayerList = styled.ul`
  margin: 0;

  &:before {
    content: '';
    display: ${({ hideFocus }) => (hideFocus ? 'block' : 'none')};
    width: 105%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.35);
    z-index: 1;
    filter: blur(1px);
    backdrop-filter: blur(1px);
  }
`;

Players.propTypes = propTypes;
Players.defaultProps = defaultProps;

const mapStateToProps = (state, props) => ({
  warningPlayers: R.compose(R.prop('categories'), getResourceContestDetail)(state),
  statCategoriesSelectedPlayersIds: R.compose(
    R.uniq,
    R.reduce(R.concat, []),
    R.values,
    R.reject(isNotDefined),
    R.map(R.prop('selectedPlayers')),
    R.pathOr([], ['entities', 'statCategories']),
  )(state),
});

const enhance = R.compose(withRouter, connect(mapStateToProps));

// export default enhance(HomePage);
export default enhance(Players);
