import { isNil, isEmpty } from 'ramda';
import { dateIsValid } from 'v2/utils/format';

const REG_EMAIL = /^(([^<>()\]\\.,;:\s@“]+(\.[^<>()\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function validateRequired({ message = 'Can’t be blank' } = {}) {
  return (value) => {
    if (isNil(value)) return message;
    return undefined;
  };
}

export function validateCheckbox({ message = 'Checkbox must be selected' } = {}) {
  return (value) => {
    if (isNil(value)) return message;
    return undefined;
  };
}

export function validateEmpty({ message = 'Can’t be blank' } = {}) {
  return (value) => {
    if (isEmpty(value)) return message;
    return undefined;
  };
}

export function validateRequiredNotStrict({ message = 'Can’t be blank' } = {}) {
  return (value) => {
    if (value === undefined) return message;
    return undefined;
  };
}
export function validateEmail({ message = 'Is invalid', reg = REG_EMAIL } = {}) {
  return (email) => {
    if (email && !reg.test(String(email).toLowerCase())) return message;
    return undefined;
  };
}
export function validatePhone({ message = 'Is invalid' } = {}) {
  return (phone) => {
    const onlyNums = phone ? phone.replace(/[^\d]/g, '') : undefined;
    // const onlyNums = phone ? phone.replace(/[+ | ( | ) | - ]/g, '') : undefined;
    if (onlyNums && onlyNums.length !== 11) return message;
    return undefined;
  };
}
export function validateDate({ message = 'Is invalid' } = {}) {
  return (date) => {
    if (dateIsValid(date)) return undefined;
    return message;
  };
}
export function composeValidators(...validators) {
  return (...args) => validators.reduce((error, validator) => error || validator(...args), undefined);
}
