// @flow

import React, { useEffect, useState } from 'react';
import { prop, isEmpty, propOr, when, equals, compose, and, not } from 'ramda';
import styled from 'styled-components';

import CategoryHead from '../shared/CategoryHead';
import PreviewCard from '../shared/PreviewCardNew';
import Pagination from '../shared/Pagination';
import NoContent from '../NoContent';

type CategoryPageProps = {};

const getSportNamebyLeague = compose(
  when(equals('nfl'), () => 'Football'),
  when(equals('nba'), () => 'Basketball'),
);

function CategoryPage({
  match: {
    params: { categoryId },
  },
  league,
  fetchBlogCategory,
  openVideoModal,
  ...rest
}: CategoryPageProps) {
  const [data, setData] = useState({});
  const [meta, setMeta] = useState({});
  const [isLoading, setLoading] = useState(true);

  const getData = async (page) => {
    try {
      setLoading(true);
      const res = await fetchBlogCategory(categoryId, { page });
      setData(res.data);
      setMeta(res.meta);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const isCentered = compose(equals('Winner Circle'), prop('title'))(data);

  if (compose(and(not(isLoading)), isEmpty, propOr([], 'posts'))(data)) {
    return <NoContent />;
  }

  return (
    <>
      <CategoryHead subtitle={getSportNamebyLeague(league)} {...rest} {...data}>
        <ContentContainer>
          {propOr([], 'posts', data).map((post) => (
            <PreviewCard.Container isActive playVideo={openVideoModal} {...post} isCentered={isCentered}>
              <PreviewCard />
            </PreviewCard.Container>
          ))}
        </ContentContainer>
      </CategoryHead>

      <Pagination {...meta} handler={getData} />
    </>
  );
}

export default CategoryPage;

// styles
const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 700px) {
    grid-template-columns: auto;
    justify-content: center;
  }
`;
