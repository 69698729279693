import React from 'react';
import PhotoBlock from 'v2/components/PromoPages/shared/PhotoBlock';
import image from 'v2/components/shared/Image/promo/3.png';

import BlockDescription, { BlockDescriptionaAccent, BlockDescriptionContainer } from '../../shared/DescriptionBlock';

function block() {
  return (
    <PhotoBlock.Wrapper>
      <PhotoBlock src={image} contentDirection="bottom">
        <BlockDescriptionContainer>
          <BlockDescription quotes style={{ fontSize: '21px', lineHeight: '1.2' }} italic>
            MEET SOME REAL{'\n'} ATHLETES, WIN SOME REAL{'\n'} PRIZES, AND HAVE SOME{'\n'} MAD FUN WITH IT
          </BlockDescription>
          <BlockDescriptionaAccent style={{ color: '#e1073a' }}>ROB GRONKOWSKI </BlockDescriptionaAccent>
          <BlockDescriptionaAccent style={{ color: '#e1073a' }}>@GRONK</BlockDescriptionaAccent>
        </BlockDescriptionContainer>
      </PhotoBlock>
    </PhotoBlock.Wrapper>
  );
}

export default block;
