// @flow
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React, { type Node, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { indexOf, identity, split, head, pick, map, prop, equals, not, propOr, length, compose, when, or } from 'ramda';
import Slider from 'react-slick';

import { isDefined } from 'v2/helpers/ramda';
import { addclass } from 'v2/helpers/common';

import SliderArrow from './Arrow';
import withDots from '../withDots';

type ContainerProps = {
  goToNext?: () => any,
  goToPrev?: () => any,
  activeIndex: number,
  children: Node,
  showDots?: boolean,
};

const defaultProps = {
  goToNext: null,
  goToPrev: null,
  showDots: false,
};

function CategoriesContainer({ activeIndex, goToNext, goToPrev, children }: ContainerProps) {
  const sliderRef = useRef();

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    swipeToSlide: true,
    centerMode: false,
    arrows: isDefined(goToNext) && isDefined(goToPrev),
    nextArrow: <SliderArrow handler={goToNext} next />,
    prevArrow: <SliderArrow handler={goToPrev} prev />,
  };

  useEffect(() => {
    if (sliderRef.current) {
      // fixme
      if (activeIndex < 3) {
        sliderRef.current.slickGoTo(0);
      }

      if (activeIndex >= 3) {
        sliderRef.current.slickGoTo(3);
      }
    }
  }, [activeIndex]);

  const categoriesCount = when(isDefined, compose(length, React.Children.toArray))(children);

  const dots = compose(map(pick(['id', 'isFilled'])), map(prop('props')), React.Children.toArray)(children);

  useEffect(() => {
    try {
      map(compose(addclass('slick-extra-wrapper'), head, prop('childNodes')))(
        document.getElementsByClassName('slick-slide'),
      );
    } catch (error) {
      console.log(error);
    }
  }, [children]);

  if (not(categoriesCount)) return null;

  if (categoriesCount > 3) {
    return (
      <StyledSLider {...settings} ref={sliderRef}>
        {React.Children.map(children, (child) => (
          <CategoryWrapper>{React.cloneElement(child)}</CategoryWrapper>
        ))}
      </StyledSLider>
    );
  }

  return (
    <Container>
      {React.Children.map(children, (child) => (
        <CategoryWrapper>{React.cloneElement(child)}</CategoryWrapper>
      ))}
    </Container>
  );
}

CategoriesContainer.defaultProps = defaultProps;
export default React.memo(withDots(CategoriesContainer), equals);

// styles
const CategoryWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 2px;
  max-width: 250px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 425px) {
    width: 100%;
  }
`;

const StyledSLider = styled(Slider)`
  .slick-track {
    display: flex;
  }
  .slick-track .slick-slide {
    display: flex;
    height: auto;
  }
`;
