const stats = [
  {
    id: 0,
    kind: 'Eliminated',
    type: 'error',
  },
  {
    id: 1,
    kind: 'users_advanced_next_week',
    type: 'warning',
  },
  {
    id: 2,
    kind: 'Won',
    type: [
      { id: 0, contestkind: 'survive', type: 'warning' },
      { id: 1, contestkind: 'weekly', type: 'success' },
    ],
  },
  {
    id: 3,
    kind: 'new_contest',
    type: [
      { id: 0, contestkind: 'survive', type: 'warning' },
      { id: 1, contestkind: 'weekly', type: 'success' },
    ],
  },
  {
    id: 4,
    kind: 'success',
    type: 'success',
  },
  {
    id: 4,
    kind: 'error',
    type: 'error',
  },
];

const getTypeByKind = ({ kind, contestKind }) => {
  if (kind && (kind === 'new_contest' || kind === 'Won')) {
    const findStat = stats.find((stat) => stat.kind === kind);
    return findStat.type.find((statType) => statType.contestkind === contestKind).type;
  }

  if (kind && (kind === 'Eliminated' || kind === 'users_advanced_next_week')) {
    const findStat = stats.find((stat) => stat.kind === kind);
    return findStat.type;
  }

  const findSTat = stats.find((stat) => stat.kind === kind);

  if (findSTat) {
    return findSTat.type;
  }

  return '';
};

export default getTypeByKind;
