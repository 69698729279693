// @flow

import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';

import { getPath, CONTEST_CATEGORY_PATH } from 'v2/constants/router';
import { history } from 'v2/stores/configureStore';
import { isSafari } from 'v2/utils/detectPlatform';

import getIcon from 'v2/components/Contests/shared/Category/getIcon';

import { PlayerHeadshots } from '../PlayerHeadshot';

import { statusStyles, prizeBlockStyles } from '../../../styles';

import PlayersNames from './PlayersNames';

type CategoryItemProps = {};

const getName = (name) => {
  const firstNamePart = R.compose(R.head, R.split(' '))(name) || name;
  const secondNamePart = R.compose(R.join(' '), R.tail, R.split(' '))(name);

  return R.join('\n', [firstNamePart, secondNamePart]);
};

function CategoryItem({
  amount,
  subject,
  league,
  kind,
  id,
  selectionsRequired,
  contestId,
  hideNames,
  categoriesCount,
  showThumbs,
  showHeadshots = true,
  isContestActive,
  weekOrder,
  selectedPlayers,
  ...rest
}: CategoryItemProps) {
  const redirectAdvancedUrl = getPath(CONTEST_CATEGORY_PATH, { id: contestId, weekOrder, categoryId: id });

  return (
    <Container>
      <StyledPrize key={id} marginRight>
        <StyledStatus>{getName(subject)}</StyledStatus>
        <StyledPrizeStat>
          <StyledPrizeText>{amount}</StyledPrizeText>
          <StyledCategoryIcon
            src={getIcon({ subject, league })}
            alt="americanFootballIcon"
            // small={statCategoriesList.length > 2}
          />
        </StyledPrizeStat>
      </StyledPrize>

      {showHeadshots && (
        <div style={{ flex: 1 }} onClick={() => history.push(redirectAdvancedUrl)}>
          <PlayerHeadshots
            {...rest}
            selectionsRequired={selectionsRequired}
            league={league}
            id={id}
            contestId={contestId}
            currentWeek={weekOrder}
            hideNames={hideNames}
            categoriesCount={categoriesCount}
            showThumbs={showThumbs}
            isContestActive={isContestActive}
            players={selectedPlayers}
            kind={kind}
          />
        </div>
      )}

      {kind == 'weekly' && (
        <PlayersNames players={selectedPlayers} total={selectionsRequired} isContestActive={isContestActive} />
      )}
    </Container>
  );
}

export default React.memo(CategoryItem, R.equals);

const Container = styled.div`
  width: 100%;
  /* margin: 0 5px; */
  margin-right: 5px;
  height: 100%;
  margin-top: 1px;
  display: flex;
  flex-direction: column;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const StyledPrizeText = styled.span`
  font-size: 20px;
  line-height: 18px;
  color: #fff;
  letter-spacing: 0.5px;
  display: block;
  flex: 1;
`;

const StyledCategoryIcon = styled.img`
  /* height: 36px; */
  width: ${({ small }) => (small ? '28px' : '36px')};
  position: absolute;
  right: 0;
`;

const StyledPrizeStat = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
`;

const StyledStatus = styled.span`
  ${statusStyles}
  white-space: break-spaces;
`;

const StyledPrize = styled.div`
  ${prizeBlockStyles}
  flex: 2;
  flex-direction: column;
  max-width: 165px;
  margin: auto !important;
  margin-bottom: 8px !important;
  max-height: 80px;
  min-height: 80px;
  /* flex: 1; */

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;
