import { GAMES_COUNT } from 'v2/constants/router';

import { getRequest } from '../requests';
import fetch from '../fetch';

export default class GamesCountApiAdapter {
  static getGamesCountApiAdapter() {
    return fetch(GAMES_COUNT, getRequest());
  }
}
