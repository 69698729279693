// @flow

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Switch, Route } from 'react-router-dom';
import ModalVideo from 'react-modal-video';
import { path, prop, compose, not, isEmpty } from 'ramda';
import { animateScroll } from 'react-scroll';

import { parseVideoUrl } from 'v2/decorators/refs';
import BlogController from 'v2/Controllers/BlogController';

import { BLOG_PATH, BLOG_CATEGORY_PATH, BLOG_CATEGORIES_PATH, BLOG_ARTICLE_PATH } from 'v2/constants/router';

import CategoriesPage from './CategoriesPage';
import CategoryPage from './CategoryPage';
import ArticlePage from './ArticlePage';
import BlogVideoContext from './shared/helpers/context';

type BlogProps = {};

function Blog(props: BlogProps) {
  const [currentVideo, setCurrentVideo] = useState({});

  const closeVideoModal = () => setCurrentVideo({});
  const openVideoModal = compose(setCurrentVideo, parseVideoUrl);

  useEffect(() => {
    animateScroll.scrollToTop();
  }, [prop('match', props)]);

  return (
    <BlogVideoContext.Provider value={{ openVideoModal, closeVideoModal }}>
      <ModalVideo
        channel={prop('videoChannel', currentVideo)}
        isOpen={compose(not, isEmpty)(currentVideo)}
        onClose={closeVideoModal}
        videoId={prop('videoId', currentVideo)}
        className="modalVideo"
      />

      <Container>
        <Switch>
          <Route
            path={[BLOG_PATH, BLOG_CATEGORIES_PATH]}
            render={(routerProps) => <CategoriesPage {...props} {...routerProps} openVideoModal={openVideoModal} />}
            exact
          />

          <Route
            path={BLOG_CATEGORY_PATH}
            render={(routerProps) => <CategoryPage {...props} {...routerProps} openVideoModal={openVideoModal} />}
            exact
          />

          <Route
            path={BLOG_ARTICLE_PATH}
            articleId={path(['match', 'params', 'articleId'])(props)}
            render={(routerProps) => <ArticlePage {...props} {...routerProps} openVideoModal={openVideoModal} />}
            exact
          />
        </Switch>
      </Container>
    </BlogVideoContext.Provider>
  );
}

export default BlogController(Blog);

// styles
const Container = styled.div`
  max-width: 1100px;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  @media (max-width: 755px) {
    justify-content: center;
  }

  .slick-list {
    min-height: fit-content;
    @media (min-width: 768px) {
      margin: 0;
    }
  }

  /* .slick-slide {
    width: calc(1100px / 3 + 25px) !important;
  } */

  /* .slick-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  } */
`;
