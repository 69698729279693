import React, { useRef, useEffect, Fragment, useState } from 'react';
import { curry, prop, not, compose } from 'ramda';
import styled from 'styled-components';

import { FAQModalProvider } from 'v2/contexts/FAQModal';

const HowToPlayWrapper = (FAQComponent, WrappedComponent) => {
  return (props) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [howToPlayContest, setHowToPlayContest] = useState(null);

    const wrapperRef = useRef(null);

    const closeModal = () => {
      setModalOpen(false);
      setHowToPlayContest(null);
    };

    useEffect(() => {
      if (howToPlayContest) return setModalOpen(true);
      if (!howToPlayContest) return setModalOpen(false);
    }, [howToPlayContest]);

    useEffect(() => {
      if (isModalOpen) {
        document.body.style.overflow = 'hidden';
      }

      if (not(isModalOpen)) {
        document.body.style.overflow = 'scroll';
      }
    }, [isModalOpen]);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          compose(closeModal, prop('onCloseHandler'))(howToPlayContest);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [wrapperRef, howToPlayContest]);

    return (
      <FAQModalProvider
        value={{
          showHowToPlayModal: setHowToPlayContest,
          closeHowToPlayModal: () => compose(closeModal, prop('onCloseHandler'))(howToPlayContest),
        }}
      >
        {isModalOpen && (
          <ModalContainer>
            <FAQComponent
              kind={prop('kind', howToPlayContest)}
              onClick={prop('onClickHandler', howToPlayContest)}
              onClose={() => compose(closeModal, prop('onCloseHandler'))(howToPlayContest)}
              ref={wrapperRef}
            />
          </ModalContainer>
        )}

        <WrappedComponent {...props} />
      </FAQModalProvider>
    );
  };
};

export default curry(HowToPlayWrapper);

// styled

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;

  backdrop-filter: brightness(0.15) blur(2px);
  background: rgba(0, 0, 0, 0.1);

  padding: 25px;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  overflow: scroll;
  max-height: 100vh;
  padding-bottom: 50px;
`;
