import { createSelector } from 'reselect';
import { identity } from 'ramda';

export const initialState = {
  index: [],
  show: null,
};

function PlayersSelector(state) {
  return state.players || initialState;
}

export const getGamesCount = createSelector(PlayersSelector, identity);

export const getPlayer = createSelector(getGamesCount, (players) => players.show);
