import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

// import useClassNames from 'hooks/useClassNames';

import { getMask, getPlaceholder } from 'v2/utils/form';

import Input from '../Input';

const propTypes = {
  baseClass: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  mask: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.func, PropTypes.string]),
  typeOfMask: PropTypes.string,
  placeholder: PropTypes.string,
};

const defaultProps = {
  mask: undefined,
  typeOfMask: undefined,
  baseClass: undefined,
  className: undefined,
  placeholder: undefined,
  type: 'text',
};

function Mask({ type, className, baseClass, mask, typeOfMask, placeholder, ...props }) {
  // const inputClass = useClassNames([baseClass, className]);
  const resultMask = useMemo(() => getMask(mask, typeOfMask), [mask, typeOfMask]);
  const maskPlaceholder = getPlaceholder(placeholder, typeOfMask);

  if (!mask && !typeOfMask) return <Input className={className} baseClass={baseClass} {...props} />;

  return (
    <MaskedInput
      {...props}
      mask={resultMask}
      placeholder={maskPlaceholder}
      render={(ref, p) => <input ref={ref} {...p} />}
      className={className}
    />
  );
}

Mask.propTypes = propTypes;
Mask.defaultProps = defaultProps;

export default Mask;
