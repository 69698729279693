import {
  CONTESTS_AVAILABLE_PATH,
  CONTEST_PATH,
  MAKE_PICKS_PATH,
  CONTEST_LEADER_PARTICIPATIONS_PATH,
  CONTEST_BREAKDOWN_OVERALL_PATH,
  CONTEST_BREAKDOWN_WEEK_PICK_PATH,
  CONTEST_LEADER_DIAGRAM_STATS_PATH,
  CONTEST_JOIN_PATH,
  CONTEST_SUCCES_PLAYER,
  SELECTED_PLAYERS_PATH,
  CONTEST_PAYMENT_PENDING_PATH,
  getPath,
} from 'v2/constants/router';

import { deleteRequest, getRequest, postRequest, putRequest } from '../requests';
import fetch from '../fetch';

export class ContestsApiAdapter {
  static getContestsAvailable() {
    return fetch(CONTESTS_AVAILABLE_PATH, getRequest());
  }

  static joinToContest(id) {
    return fetch(getPath(CONTEST_JOIN_PATH, { id }), postRequest({}));
  }

  static getContest(id) {
    return fetch(getPath(CONTEST_PATH, { id }), getRequest());
  }

  static getPlayerContestInfo({ playerId, categoryId, contestId }) {
    return fetch(getPath(CONTEST_SUCCES_PLAYER, { playerId, categoryId, contestId }), getRequest());
  }

  static getContestMakePicks(params) {
    const url = getPath(MAKE_PICKS_PATH, params);

    return fetch(url, getRequest());
  }

  static getContestPicks(params) {
    const url = getPath(SELECTED_PLAYERS_PATH, params);

    return fetch(url, getRequest());
  }

  static getContestLeaderParticipations(id) {
    return fetch(getPath(CONTEST_LEADER_PARTICIPATIONS_PATH, { id }), getRequest());
  }

  static getContestLeaderDiagramStats(id) {
    return fetch(getPath(CONTEST_LEADER_DIAGRAM_STATS_PATH, { id }), getRequest());
  }

  static getContestBreackdownOverall(id) {
    return fetch(getPath(CONTEST_BREAKDOWN_OVERALL_PATH, { id }), getRequest());
  }

  static getContestBreakdownWeekPick(id) {
    return fetch(getPath(CONTEST_BREAKDOWN_WEEK_PICK_PATH, { id }), getRequest());
  }

  static getPaymentContestStatus(contestId) {
    return fetch(getPath(CONTEST_PAYMENT_PENDING_PATH, { contestId }), getRequest());
  }
}

export default ContestsApiAdapter;
