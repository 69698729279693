import styled from 'styled-components';

import ReactSelect from '../../formBase/ReactSelect/SimpleValueAdapter';
// import { style } from './SimpleValueAdapter';

const ClassicSelect = styled(ReactSelect)`
  /* ${style} */
`;

export default ClassicSelect;
