// @flow

import React from 'react';
import useWindowSize from 'v2/services/useWindowSize';
import withFooter from 'v2/components/PromoPages/shared/Footer/withFooter';
import DesktopVersion from './shared/versions/Desktop';
import MobileVersion from './shared/versions/Mobile';

function PromoPage() {
  const [documentWidth] = useWindowSize();
  const isMobile = documentWidth < 450;

  if (isMobile) return <MobileVersion />;
  return <DesktopVersion />;
}

export default withFooter(PromoPage);
