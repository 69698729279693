import React from 'react';
import * as R from 'ramda';

import { ContestsApiAdapter, MakePicksApiAdapter } from 'v2/apiAdapters';
import { getCookieByName, setCookieByName } from 'v2/utils/cookies';
import { isNotDefined } from 'v2/helpers/ramda';
// import { animateScroll } from 'react-scroll';
import toast from 'v2/components/PushToasts/toast';
import { getResourceContestDetail } from 'v2/selectors/contests';

import * as requestTypes from '../../constants/requestTypes';
import * as types from '../../constants/actionTypes';
import { history } from '../../stores/configureStore';
import { contestsNormalizr, contestNormalizr, statCategoriesNormalizr } from '../../normalizrs';
import { CONTEST_CATEGORY_PATH, MY_GAMES, getPath } from '../../constants/router';

import { fetchGamesCount } from '../gamesCount';

import { mergeEntities } from '../entities';
import setRequestInProcess from '../request';

export function fetchAvailableContests() {
  const requestType = requestTypes.AVALAIBLE_CONTESTS_FETCH;

  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));

    ContestsApiAdapter.getContestsAvailable().then((res) => {
      const { entities, result } = contestsNormalizr(res.contests);
      dispatch(mergeEntities(entities));

      dispatch(fetchAvailableContestsSuccess(result));
      dispatch(setRequestInProcess(false, requestType));
    });
  };
}

export const fetchSuccessPlayerInfo = ({ playerId, categoryId, contestId }) => async (dispatch) => {
  const requestType = requestTypes.SUCCESS_PLAYER_INFO_FETCH;

  try {
    dispatch(setRequestInProcess(true, requestType));
    const res = await ContestsApiAdapter.getPlayerContestInfo({ playerId, categoryId, contestId });
    dispatch(fetchSuccessPlayerInfoSuccess(res));
  } catch (error) {
    toast({ title: <span>Error</span>, body: 'Something went wrong', kind: 'danger' });
  } finally {
    dispatch(setRequestInProcess(false, requestType));
  }
};

export function fetchContest(id) {
  const requestType = requestTypes.CONTEST_FETCH;

  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));

    ContestsApiAdapter.getContest(id).then((res) => {
      const { entities, result } = contestNormalizr(res.contest);
      // console.log(entities);
      dispatch(mergeEntities(entities));
      dispatch(fetchContestSuccess({ id: result }));

      dispatch(setRequestInProcess(false, requestType));
    });
  };
}

export const resetContest = () => (dispatch) => dispatch(fetchContestSuccess({ id: null }));

export const joinToContest = (contestId) => async (dispatch) => {
  const requestType = requestTypes.CONTEST_FETCH;
  dispatch(setRequestInProcess(true, requestType));

  try {
    await ContestsApiAdapter.joinToContest(contestId);
    dispatch(fetchGamesCount());
  } catch (error) {
    // console.log('error :', error);
  } finally {
    dispatch(setRequestInProcess(false, requestType));
  }
};

export const fetchContestMakePicks = (params) => async (dispatch, getState) => {
  const requestType = requestTypes.CONTEST_MAKE_PICKS_FETCH;
  dispatch(setRequestInProcess(true, requestType));
  try {
    const res = await ContestsApiAdapter.getContestMakePicks(params);

    try {
      const playerIteration = (players) => R.compose(R.propEq('selectedOtherWeeks', true))(players);

      const weekIteration = (week) =>
        R.assoc('players', R.compose(R.map(R.prop('id')), R.filter(playerIteration), R.prop('players'))(week))(week);

      R.compose(
        dispatch,
        mergeEntities,
        R.objOf('contests'),
        R.objOf(params.id),
        R.assoc(
          'categories',
          R.compose(R.indexBy(R.head), R.map(R.props(['id', 'players'])), R.map(weekIteration))(res.categories),
        ),
        getResourceContestDetail,
        getState,
      )();
    } catch (error) {
      console.log(error);
    }

    const { entities } = statCategoriesNormalizr(res.categories);

    dispatch(mergeEntities(R.dissoc('selectedPlayers', entities)));
    dispatch(fetchContestMakePicksSuccess(res.weeks));
  } catch (error) {
    toast({ title: <span>Error</span>, body: 'Something went wrong', kind: 'danger' });
  } finally {
    dispatch(setRequestInProcess(false, requestType));
  }
};

export const fetchContestPicks = (params) => async (dispatch) => {
  const requestType = requestTypes.CONTEST_SELECTED_PLAYER_FETCH;
  dispatch(setRequestInProcess(true, requestType));
  try {
    const res = await ContestsApiAdapter.getContestPicks(params);
    const {
      entities: { selectedPlayers },
    } = statCategoriesNormalizr(res);

    dispatch(mergeEntities({ selectedPlayers }));
  } catch (error) {
    toast({ title: <span>Error</span>, body: 'Something went wrong', kind: 'danger' });
  } finally {
    dispatch(setRequestInProcess(false, requestType));
  }
};

export function fetchContestLeaderParticipations(id) {
  const requestType = requestTypes.CONTEST_LEADER_PARTICIPATIONS_FETCH;

  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));

    ContestsApiAdapter.getContestLeaderParticipations(id).then((res) => {
      dispatch(fetchContestLeaderParticipationsSuccess(res.users));

      dispatch(setRequestInProcess(false, requestType));
    });
  };
}

export function fetchContestLeaderDiagramStats(id) {
  const requestType = requestTypes.CONTEST_LEADER_DIAGRAM_STATS_FETCH;

  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));

    ContestsApiAdapter.getContestLeaderDiagramStats(id).then((res) => {
      dispatch(fetchContestLeaderDiagramStatsSuccess(res));

      dispatch(setRequestInProcess(false, requestType));
    });
  };
}

export function fetchContestBreackdownOverall(id) {
  const requestType = requestTypes.CONTEST_BREACKDOWN_OVERALL_FETCH;

  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));

    ContestsApiAdapter.getContestBreackdownOverall(id).then((res) => {
      dispatch(fetchContestBreackdownOverallSuccess(res));

      dispatch(setRequestInProcess(false, requestType));
    });
  };
}

export function fetchContestBreakdownWeekPick(id) {
  const requestType = requestTypes.CONTEST_BREAKDOWN_WEEK_PICK_FETCH;

  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));

    ContestsApiAdapter.getContestBreakdownWeekPick(id).then((res) => {
      dispatch(fetchContestBreakdownWeekPickSuccess(res));

      dispatch(setRequestInProcess(false, requestType));
    });
  };
}

export function toggleStatCategoryPlayerId(contestCategory, playerId, selectionsRequired, options) {
  return (dispatch, getState) => {
    const newSelectedPlayers = R.includes(playerId, contestCategory.selectedPlayers)
      ? R.without([playerId], contestCategory.selectedPlayers)
      : R.append(playerId, contestCategory.selectedPlayers);

    const newstatCategories = {
      ...contestCategory,
      selectedPlayers: newSelectedPlayers,
    };

    const { id } = newstatCategories;

    // fixme add selector
    dispatch(
      mergeEntities({ selectedPlayers: R.compose(R.pick([playerId]), R.path(['entities', 'players']))(getState()) }),
    );
    dispatch(mergeEntities({ statCategories: { [id]: newstatCategories } }));

    if (options) {
      const { categoryWithSelectedPlayers, contestId, currentWeek, notFilledCategoryId } = options;

      if (newstatCategories.selectedPlayers.length == selectionsRequired) {
        const newCategories = categoryWithSelectedPlayers.map((stat) => {
          if (stat.id == newstatCategories.id) {
            return { ...stat, selectedPlayers: newstatCategories.selectedPlayers };
          }
          return stat;
        });

        const makeRedirect = R.compose(
          R.equals(options.currentWeek),
          R.prop('currentWeek'),
          getResourceContestDetail,
          getState,
        )();

        dispatch(
          submitContestMakePicks(
            newCategories,
            contestId,
            currentWeek,
            notFilledCategoryId,
            makeRedirect,
            R.prop('id', contestCategory),
          ),
        );
        // animateScroll.scrollToTop();
      }
    }
  };
}

export function currentContestCategoryId(contests, categoryId) {
  return (dispatch) => {
    const newCurrentCategoryId = {
      ...contests,
      urlContest: {
        weekOrder: contests.urlContest.weekOrder,
        categoryId,
      },
    };
    const { id } = newCurrentCategoryId;
    dispatch(mergeEntities({ contests: { [id]: newCurrentCategoryId } }));
  };
}

export function submitContestMakePicks(
  body,
  id,
  weekOrder,
  notFilledCategoryId,
  makeRedirect = false,
  contestCategory,
) {
  const requestType = requestTypes.SUBMIT;
  const urlCategory = getPath(CONTEST_CATEGORY_PATH, {
    id,
    weekOrder,
    categoryId: notFilledCategoryId || contestCategory,
  });
  const url = notFilledCategoryId ? urlCategory : makeRedirect ? `${MY_GAMES}?activeId=${id}` : urlCategory; // fixme
  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));
    MakePicksApiAdapter.submitMakePicks(body, id, weekOrder).then(() => {
      dispatch(setRequestInProcess(false, requestType));

      if (R.not(notFilledCategoryId) && makeRedirect) {
        // return (window.location.href = url);
        return history.push(url);
      }

      history.push(url);
    });
  };
}

export function resetResourceContest() {
  return {
    type: types.CONTEST_RESET,
  };
}
export const checkPaymentStatus = (id, setStatusMsg) => async (dispatch) => {
  const requestType = requestTypes.CONTEST_LEADER_PARTICIPATIONS_FETCH;
  dispatch(setRequestInProcess(true, requestType));

  try {
    const { transaction } = await ContestsApiAdapter.getPaymentContestStatus(id);

    if (transaction.status) {
      if (transaction.status === 2) {
        const {
          contest: { urlContest },
        } = await ContestsApiAdapter.getContest(id);

        return history.push(getPath(CONTEST_CATEGORY_PATH, { id, ...urlContest }));
      }

      return setStatusMsg(transaction.statusMsg || 'Something went wrong');
    }

    setTimeout(() => dispatch(checkPaymentStatus(id)), 2000);
  } catch (err) {
    setStatusMsg(err);
  } finally {
    dispatch(setRequestInProcess(false, requestType));
  }
};

function fetchContestSuccess(payload) {
  return {
    type: types.CONTEST_FETCH_SUCCESS,
    payload,
  };
}

function fetchAvailableContestsSuccess(payload) {
  return {
    type: types.CONTESTS_AVAILABLE_FETCH_SUCCESS,
    payload,
  };
}

function fetchContestMakePicksSuccess(payload) {
  return {
    type: types.CONTEST_MAKE_PICKS_FETCH_SUCCESS,
    payload,
  };
}

function fetchContestLeaderParticipationsSuccess(payload) {
  return {
    type: types.CONTEST_LEADER_PARTICIPATIONS_FETCH_SUCCESS,
    payload,
  };
}

function fetchContestLeaderDiagramStatsSuccess(payload) {
  return {
    type: types.CONTEST_LEADER_DIAGRAM_STATS_FETCH_SUCCESS,
    payload,
  };
}

function fetchContestBreackdownOverallSuccess(payload) {
  return {
    type: types.CONTEST_BREACKDOWN_OVERALL_FETCH_SUCCESS,
    payload,
  };
}

function fetchContestBreakdownWeekPickSuccess(payload) {
  return {
    type: types.CONTEST_BREAKDOWN_WEEK_PICK_FETCH_SUCCESS,
    payload,
  };
}

function fetchSuccessPlayerInfoSuccess(payload) {
  return {
    type: types.SUCCESS_PLAYER_INFO_FETCH_SUCCESS,
    payload,
  };
}

export default fetchAvailableContests;
