import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const defaultProps = {
  type: 'submit',
};
const propTypes = {
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
};

function Button({ type, children, disable }) {
  return (
    <StyledButton type={type} disable={disable}>
      {children}
    </StyledButton>
  );
}

const StyledButton = styled.button`
  // margin: 15px;
  width: 100%;
  border: 0;
  color: #fff;
  background: rgb(93, 92, 226);
  border-radius: 4px;
  letter-spacing: 0.5px;
  line-height: 18px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  padding: 11px 30px;
  cursor: pointer;
  pointer-events: ${({ disable }) => (disable ? 'none' : 'auto')};
  filter: ${({ disable }) => (disable ? 'brightness(40%)' : 'none')};

  &:hover {
    transition: filter 0.2s linear;
    filter: brightness(105%);
  }

  &:active {
    filter: brightness(65%);
  }
`;

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;
export default Button;
