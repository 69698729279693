import { createSelector } from 'reselect';
import { identity } from 'ramda';

export const initialState = {};

function signOutSelector(state) {
  return state.signOut || initialState;
}

export const getSignOut = createSelector(
  signOutSelector,
  identity,
);

// export const getAvailableContests = createSelector(
//   getContests,
//   (contests) => contests.available,
// );

// export const getDecorateAvailableContestsPrizes = createSelector(
//   getAvailableContests,
//   decorateAvailableContestsPrizes,
// );
