// @flow

import React from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { SIGN_IN_PATH, SETUP_ACCOUNT_PATH } from 'v2/constants/router';
import { isAuthorized, isUserStatusAcceptedTermsOfService } from 'v2/selectors/session';
// import { useAuthenticatedStatus } from 'v2/utils/auth';

type privateRouteProps = {
  isAuthorized: boolean,
  isUserStatusAcceptedTermsOfService: boolean,
  component: any,
};

type privateComponentProps = {
  location: any,
};

function PrivateRoute({
  component: Componnet,
  isAuthorized,
  isUserStatusAcceptedTermsOfService,
  ...rest
}: privateRouteProps) {
  // const isAuthenticated = useAuthenticatedStatus();

  return (
    <Route
      {...rest}
      render={({ location, ...props }: privateComponentProps) => {
        if (!isAuthorized) {
          return (
            <Redirect
              to={{
                pathname: SIGN_IN_PATH,
                state: { from: location },
              }}
            />
          );
        }

        if (!isUserStatusAcceptedTermsOfService) {
          return (
            <Redirect
              to={{
                pathname: SETUP_ACCOUNT_PATH,
                state: { from: location },
              }}
            />
          );
        }

        return <Componnet {...props} />;
      }}
    />
  );
}

const mapStateToProps = (state) => ({
  isAuthorized: isAuthorized(state),
  isUserStatusAcceptedTermsOfService: isUserStatusAcceptedTermsOfService(state),
});

export default withRouter(connect(mapStateToProps)(PrivateRoute));
