export const STAT_CATEGORIES = {
  nfl: [
    { order: 0, subject: 'PASSING TDS' },
    { order: 1, subject: 'RUSHING TDS' },
    { order: 2, subject: 'RECEIVING TDS' },
    { order: 3, subject: 'PASSING YARDS' },
    { order: 4, subject: 'RUSHING YARDS' },
    { order: 5, subject: 'RECEIVING YARDS' },
  ],
  nba: [
    { order: 0, subject: 'POINTS' },
    { order: 1, subject: 'REBOUNDS' },
    { order: 2, subject: 'ASSISTS' },
    { order: 3, subject: '3 POINTERS MADE' },
    { order: 4, subject: 'STEALS' },
    { order: 5, subject: 'DOUBLE-DOUBLE' },
  ],
};

export const PASSING_TDS = 'PASSING TDS';
export const RUSHING_TDS = 'RUSHING TDS';
export const RECEIVING_TDS = 'RECEIVING TDS';
export const PASSING_YARDS = 'PASSING YARDS';
export const RUSHING_YARDS = 'RUSHING YARDS';
export const RECEIVING_YARDS = 'RECEIVING YARDS';

export const POINTS = 'POINTS';
export const REBOUNDS = 'REBOUNDS';
export const ASSISTS = 'ASSISTS';
export const POINTERS_MADE = 'THREE-POINTERS';
export const STEALS = 'STEALS';
export const DOUBLE_DOUBLE = 'DOUBLE-DOUBLE';

export const HITS = 'HITS';
export const HOMERUNS = 'HOMERUNS';
export const STOLEN_BASES = 'STOLEN BASES';
export const WINS = 'WINS';
export const SAVES = 'SAVES';
export const STRIKEOUTS = 'STRIKEOUTS';
export const RBIS = 'RBIS';
