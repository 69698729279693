import GameCard from './GameCard';
import SliderGameCard from './SliderGameCard';
import Paragraph from './Paragraph';
import Title from './Title';
import Button from './Button';
import Loader from './Loader';
import Notification from './Notification';
import DropDownNotifications from './DropDownNotifications';
import ModalLoader from './ModalLoader';
import Counter from './Counter';
import InstagramCarousel from './InstagramCarousel';
import PlayerQuote from './PlayerQuote';
import SliderArrow from './SliderArrow';

export {
  GameCard,
  SliderGameCard,
  Paragraph,
  Title,
  Button,
  Loader,
  DropDownNotifications,
  Notification,
  ModalLoader,
  Counter,
  InstagramCarousel,
  PlayerQuote,
  SliderArrow,
};
