import { createSelector } from 'reselect';
import { always, identity, propOr, find, propEq, pick, filter } from 'ramda';
import { getIn } from 'v2/utils/ramda';
import { getEntities } from '../entities';
import { entityDetailGetter, entityListGetter } from '../../utils/entities';

// import { decorateAvailableContestsPrizes } from 'v2/decorators/contests';

export const initialState = {
  available: [],
  resourceContest: {},
};

const getProps = (state, props) => props;

function contestsSelector(state, props) {
  return state.contests || initialState;
}

export const getContests = createSelector(contestsSelector, identity);

export const getAvailableContestIds = createSelector(getContests, (contests) => contests.available);

export const getAvailableContestsList = createSelector(
  [always('contests'), getAvailableContestIds, getEntities],
  entityListGetter,
);

export const getAvailableContestsByLeague = createSelector(getAvailableContestsList, getProps, (contests, { league }) =>
  filter((contest) => (league ? contest.league.toLowerCase() === league.toLowerCase() : true), contests),
);

export const getResourceContest = createSelector(getContests, (contests) => contests.resourceContest);

export const getResourceContestId = createSelector(getResourceContest, (resourceContest) => resourceContest.id);

export const getResourceContestDetail = createSelector(
  [always('contests'), getResourceContestId, getEntities],
  entityDetailGetter,
);

export const getContestDetailLeague = createSelector(getResourceContestDetail, (contest) => contest && contest.league);

export const getResourceContestWeeks = createSelector(
  getContests,
  (contests) =>
    (contests.breackdownWeekPickCollection && contests.breackdownWeekPickCollection.weeks) || contests.weeks,
);

export const getResourceContestParticipations = createSelector(getContests, (contests) => contests.users);

export const getResourceContestGameStatsWeeksCollection = createSelector(
  getContests,
  (contests) => contests.weeksCollection,
);

export const getResourceContestBreackdownOverallCollection = createSelector(
  getContests,
  propOr([], 'breackdownOverallCollection'),
  // (contests) => contests.breackdownOverallCollection || [],
);

export const getResourceContestBreackdownWeekPickCollection = createSelector(
  getContests,
  propOr([], 'breackdownWeekPickCollection'),
  // (contests) => contests.breackdownWeekPickCollection,
);

export const getResourceContestBreackdownWeekPickCategories = createSelector(
  getResourceContestBreackdownWeekPickCollection,
  propOr([], 'categories'),
  // (contests) => contests.breackdownWeekPickCollection,
);

export const getResourceContestPrizesIds = createSelector(getResourceContestDetail, propOr([], 'prizes'));

export const getResourceContestPrizes = createSelector(
  [always('prizes'), getResourceContestPrizesIds, getEntities],
  entityListGetter,
);

export const getResourceContestStatCategoryIds = createSelector(getResourceContestDetail, propOr([], 'statCategories'));

export const getResourceContestStatCategories = createSelector(
  [always('statCategories'), getResourceContestStatCategoryIds, getEntities],
  entityListGetter,
);

export const getResourceContestStatCategory = createSelector(
  getResourceContestStatCategories,
  getProps,
  (categories, props) => {
    const categoryId = getIn('categoryId', props);
    const integer = parseInt(categoryId, 10);
    const statCategory = find(propEq('id', integer))(categories);

    return statCategory;
  },
);

export const getResourceContestSelectedPlayersIds = createSelector(
  getResourceContestStatCategory,
  propOr([], 'selectedPlayers'),
);

export const getIsFinalChoise = createSelector(
  getResourceContestSelectedPlayersIds,
  getProps,
  (arr, { selectionsRequired }) => arr.length === selectionsRequired,
);

export const getResourceContestSelectedPlayers = createSelector(
  [always('selectedPlayers'), getResourceContestSelectedPlayersIds, getEntities],
  entityListGetter,
);

export const getResourceContestPlayersIds = createSelector(getResourceContestStatCategory, propOr([], 'players'));

export const getResourceContestPlayers = createSelector(
  [always('players'), getResourceContestPlayersIds, getEntities],
  entityListGetter,
);

export const getCategoryWithSelectedPlayers = createSelector(getResourceContestStatCategories, (statCategories) => {
  const categoryWithSelectedPlayers = statCategories.map((category) => pick(['id', 'selectedPlayers'], category));
  return categoryWithSelectedPlayers;
});

export const getSelectedPlayersCount = createSelector(
  getResourceContestStatCategories,
  (statCategories) => {
    let count = 0;
    const selectedPlayersCount = statCategories.map((category) => {
      if (category.selectedPlayers) {
        count = count + category.selectedPlayers.length;
      }
    });
    return count;
  },
  // (statCategories) => statCategories,
);

export const getBreackdownOverallCurrentCategory = createSelector(
  getResourceContestBreackdownOverallCollection,
  getProps,
  (categories, props) => {
    const categoryId = getIn('', props);
    const integer = parseInt(categoryId, 10);
    const statCategory = find(propEq('id', integer))(categories);

    return statCategory;
  },
);

export const getBreackdownWeekCurrentCategory = createSelector(
  getResourceContestBreackdownWeekPickCategories,
  getProps,
  (categories, props) => {
    const categoryId = getIn('', props);
    const integer = parseInt(categoryId, 10);
    const statCategory = find(propEq('id', integer))(categories);

    return statCategory;
  },
);

// export const getResourceContestId = createSelector(
//   getResourceContest,
//   (resourceContest) => resourceContest.id,
// );

// function makePicksContestSelector(state) {
//   return state.makePicks || initialState;
// }

// export const getMakePicksContestId = createSelector(
//   makePicksContestSelector,
//   (makePicks) => makePicks.contestId,
// );

// export const getContest = createSelector(
//   [always('contests'), getMakePicksContestId, getEntities],
//   entityDetailGetter,
// );
