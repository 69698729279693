// @flow
import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const defaultProps = {
  logo: null,
  alt: null,
  title: null,
  handleClick: () => console.log('handleClick'),
  isLink: false,
  hrefTo: null,
  activeStatus: false,
  disabled: false,
};

type ButtonProps = {
  logo?: string,
  alt?: string,
  title?: string,
  hrefTo?: string,
  activeStatus?: boolean,
  isLink?: boolean,
  disabled?: boolean,
  handleClick?: () => void,
};

function Button({ isLink, handleClick, logo, alt, title, hrefTo, activeStatus, disabled }: ButtonProps) {
  // const {
  //   params: { league: leagueParam },
  // } = match;

  if (isLink) {
    return (
      <Tab to={hrefTo} active={activeStatus} disabled={disabled}>
        {logo && <TabImage src={logo} alt={alt} />}
        {title && <TabTitle>{title}</TabTitle>}
      </Tab>
    );
  }

  return (
    <StyledButton as="button" onClick={handleClick} active={activeStatus} disabled={disabled}>
      {logo && <ButtonImage src={logo} alt={alt} />}
      {title && <ButtonTitle>{title}</ButtonTitle>}
    </StyledButton>
  );
}

const ButtonImage = styled.img`
  /* margin: 0 7px 0 5px; */
  /* margin-right: 0.5em; */
  width: 25px;

  @media (max-width: 576px) {
    width: 20px;
  }
`;

const ButtonTitle = styled.span`
  /* margin: 0 5px; */
  /* margin-top: 1px; */
  color: white;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 1;
  text-transform: uppercase;
  text-align: left;

  @media (max-width: 1050px) {
    font-size: 12px;
  }

  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

const StyledButton = styled(NavLink)`
  background-color: ${({ active }) => (active ? '#5d5ce2' : '#26262e')};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  font: inherit;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  border: solid 1px transparent;
  border-radius: 6px;

  padding: 0.4em 0.75em;

  /* display: inline-flex; */
  flex-direction: row;
  transition: filter 0.2s ease-out;
  text-decoration: none !important;
  transition: width 2.4s ease-out;

  &:hover {
    filter: brightness(110%);
  }

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  ${ButtonImage} {
    /* filter: ${({ active }) => (active ? 'invert(0.6)' : 'invert(0)')}; */
    filter: ${({ active }) => active && 'brightness(1.1)'};
  }

  cursor: ${({ disabled }) => disabled && 'not-allowed'};
  /* pointer-events: ${({ disabled }) => disabled && 'none'}; */
  ${ButtonImage} {
    filter: ${({ disabled }) => disabled && 'grayscale(1)'};
  }
  ${ButtonTitle} {
    color: ${({ disabled }) => disabled && '#656579'};
    filter: ${({ active }) => (active ? 'invert(0)' : 'invert(0.6)')};
  }

  @media (max-width: 768px) {
    padding: 8px;
  }

  @media (max-width: 576px) {
    ${ButtonTitle} {
      display: ${({ children, active }) => children[0] && 'none'};
      padding: 0 0.5em;
    }
    ${ButtonImage} {
      margin: 0;
      height: 1.6em;
      width: 1.8em;
      font-size: 14px;
    }
    width: 100%;
    justify-content: center;
    padding: 0.35em;
    /* padding-right: 0.75em; */
  }
`;

const Tab = styled(StyledButton)``;
const TabTitle = styled(ButtonTitle)``;
const TabImage = styled(ButtonImage)``;

Button.defaultProps = defaultProps;

export default Button;
