import { isNil, is } from 'ramda';
import urlParser from 'js-video-url-parser';

export function parseVideoUrl(media) {
  const videoInfo = urlParser.parse(media.videoUrl ? media.videoUrl : is(String, media) ? media : undefined);

  const videoId = videoInfo ? videoInfo.id : undefined;
  const videoChannel = videoInfo ? videoInfo.provider : undefined;

  return { videoId, videoChannel };
}

export function decorateRefsMedia(media) {
  if (isNil(media)) return [];

  return media.map((m) => ({
    ...m,
    ...parseVideoUrl(m),
  }));
}

export function decoratedRefsWinners(media) {
  if (isNil(media)) return [];

  return media.map((m) => ({
    ...m,
    ...parseVideoUrl(m),
  }));
}
