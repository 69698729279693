import * as React from 'react';
import { toast } from 'react-toastify';

import CloseButton from '../CloseButton';

// export const toastOptions = {
//   onOpen: (props) => console.log('onOpen', props),
//   onClose: (props) => console.log('onClose', props),
// };
const toastContainerOptions = {
  closeButton: <CloseButton />,
  position: toast.POSITION.TOP_RIGHT,
  closeOnClick: false,
  hideProgressBar: false,
  autoClose: false,
};

export default toastContainerOptions;
