import { MyGamesActiveApiAdapter } from 'v2/apiAdapters';

import { contestsNormalizr, contestNormalizr, statCategoriesNormalizr } from '../../normalizrs';
import { mergeEntities } from '../entities';
import * as requestTypes from '../../constants/requestTypes';
import * as types from '../../constants/actionTypes';

import setRequestInProcess from '../request';

export function fetchMyGamesActive() {
  const requestType = requestTypes.MY_GAMES_ACTIVE_FETCH;

  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));

    MyGamesActiveApiAdapter.getMyGamesActiveApiAdapter().then(({ activeContest }) => {
      const { entities, result } = contestsNormalizr(activeContest);
      dispatch(mergeEntities(entities));

      dispatch(fetchMyGamesActiveApiAdapterSuccess(result));
      dispatch(setRequestInProcess(false, requestType));
    });
    // .catch((error) => {
    //   if (error.response) {
    //     openNotification('error', error.response.data.message);
    //   }
    // });
  };
}

function fetchMyGamesActiveApiAdapterSuccess(payload) {
  return {
    type: types.MY_GAMES_ACTIVE_FETCH_SUCCESS,
    payload,
  };
}

export default fetchMyGamesActive;
