import React from 'react';
import PhotoBlock from 'v2/components/PromoPages/shared/PhotoBlock';
import GsLogo from 'v2/components/shared/Image/promo/GS_logo.svg';

import BlockDescription from '../../shared/DescriptionBlock';

function block() {
  return (
    <PhotoBlock.Wrapper>
      <PhotoBlock src={GsLogo}>
        <BlockDescription>
          <BlockDescription size="large" italic>
            WIN THE ULTIMATE{'\n'}
            VIP EXPERIENCE!
          </BlockDescription>
        </BlockDescription>
      </PhotoBlock>
    </PhotoBlock.Wrapper>
  );
}

export default block;
