// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose, type HOC } from 'recompose';

import * as actions from 'v2/actions';
import { getPlayer } from 'v2/selectors/players';
import { getStatCategoryFullName, getStatCategory } from 'v2/selectors/statCategories';
import { getResourceContestDetail } from 'v2/selectors/contests';
import { requestInProcess } from 'v2/selectors/request';
import * as requestTypes from 'v2/constants/requestTypes';

type PlayerTypes = {
  hitsCount: number,
  gamesCount: number,
  season: string,
  rate: string,
  photo: string,
  name: string,
  lastGames?: Array<{
    startsAt: string,
    homeTeam: string,
    awayTeam: string,
    started: boolean,
    missGame: boolean,
    hitInGame: boolean,
  }>,
  teamName: string,
  categoryName: string,
  position: string,
};

type WrappedTypes = {
  fetchSuccessPlayerInfo: Function,
  player: PlayerTypes,
  isLoading: boolean,
  categoryName: 'N/A',
  playerId: number,
  contestId: number,
  categoryId: number,
};

const SuccessPageController = (WrappedComponent) => {
  return ({ fetchSuccessPlayerInfo, player, playerId, contestId, categoryId, ...rest }: WrappedTypes) => {
    useEffect(() => {
      const fetch = async () => {
        fetchSuccessPlayerInfo({ playerId, contestId, categoryId });
      };

      fetch();
    }, []);

    return <WrappedComponent {...player} {...rest} />;
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSuccessPlayerInfo: ({ playerId, contestId, categoryId }) =>
    dispatch(actions.fetchSuccessPlayerInfo({ playerId, contestId, categoryId })),
});

const mapStateToProps = (state, { categoryId }) => ({
  player: getPlayer(state),
  isLoading: requestInProcess(state, { requestType: requestTypes.CONTEST_FETCH }),
  categoryName: getStatCategoryFullName(state, { statCategoryIds: [categoryId] }),
  contest: getResourceContestDetail(state),
});

const enhance: HOC<*, { playerId: number, categoryId: number, contestId: number }> = compose(
  connect(mapStateToProps, mapDispatchToProps),
  SuccessPageController,
);

export default enhance;
