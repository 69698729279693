import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';

import defaultImg from '../../../shared/Image/default_img.jpg';
import PreviewImage from '../PreviewImg';

const propTypes = {
  mediaCollection: PropTypes.arrayOf(PropTypes.object),
};

const defaultProps = {
  mediaCollection: undefined,
};

function PreviewSlider(props) {
  const { mediaCollection, settings } = props;
  const [isOpen, setIsOpen] = useState(undefined);
  const [currentVideo, setCurrentVideo] = useState({});

  console.log('currentVideo :', currentVideo);

  if (!mediaCollection) {
    return false;
  }
  return (
    <Fragment>
      <Slider {...settings}>
        {mediaCollection.map((media) => (
          <div className="slider_wrap" key={media.id}>
            <PreviewImg>
              {/* <img alt="" style={{ width: '100%' }} src={media.pictureUrl ? media.pictureUrl : defaultImg} /> */}
              <StyledPreviewImage pictureUrl={media.pictureUrl} />
              <StyledButton
                onClick={() => {
                  setIsOpen(true);
                  setCurrentVideo({ id: media.videoId, channel: media.videoChannel });
                }}
              />
            </PreviewImg>
            <WrapText>
              {media.description && <Description>{media.description}</Description>}
              <PreviewText>{media.title}</PreviewText>
            </WrapText>
          </div>
        ))}
      </Slider>

      <ModalVideo
        channel={currentVideo.channel}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        videoId={currentVideo.id}
        className="modalVideo"
      />
    </Fragment>
  );
}

const StyledPreviewImage = styled(PreviewImage)`
  height: 214px;

  @media (max-width: 770px) {
    height: 390px;
  }

  @media (max-width: 425px) {
    height: 172px;
  }
`;

const Description = styled.span`
  display: inline-block;
`;

const WrapText = styled.div`
  margin-top: 10px;
  @media (min-width: 768px) {
    padding-left: 15px;
  }
`;

const PreviewText = styled.h3`
  margin-bottom: 5px;
`;

const StyledButton = styled.button`
  background: #ce3c4d;
  padding: 24px;
  border-radius: 50px;
  border: 0;
  box-shadow: 0px 2px 4px 0px;
  position: absolute;
  top: calc(50% - 24px);
  left: 0;
  right: 0;
  margin: 0 auto;
  cursor: pointer;
  bottom: 0;
  margin: 0 auto;
  cursor: pointer;
  height: 24px;
  width: 24px;
  &:hover {
    background: #e8495b;
    color: #fff;
    &:after {
      border-color: #e8495b #e8495b #e8495b #fff;
    }
  }
  &:after {
    width: 0px;
    height: 0px;
    background: white;
    position: absolute;
    top: calc(50% - -5px);
    left: calc(50% - 4px);
    margin-top: -12px;
    content: ' ';
    border-style: solid;
    border-width: 7px 0 7px 12px;
    border-color: #ce3c4d #ce3c4d #ce3c4d #fff;
  }
`;

const PreviewImg = styled.div`
  position: relative;
  margin-bottom: 10px;
`;

PreviewSlider.propTypes = propTypes;
PreviewSlider.defaultProps = defaultProps;

// export default enhance(HomePage);
export default PreviewSlider;
