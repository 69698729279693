import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import SurviveAvatars from '../SurviveAvatars';
import WeeklyAvatars from '../WeeklyAvatars';

const propTypes = {
  kind: PropTypes.string,
};

const defaultProps = {
  kind: '',
};

function Avatars(props) {
  const { kind } = props;
  if (kind === 'survive') return <SurviveAvatars {...props} />;
  return <WeeklyAvatars {...props} />;
}

Avatars.propTypes = propTypes;
Avatars.defaultProps = defaultProps;

export default Avatars;
