// @flow

import React, { useState } from 'react';
import { compose } from 'ramda'; // Replace with any compose() function of your choice
import styled from 'styled-components';
import { darken, desaturate } from 'polished';
import defaultImg from 'v2/components/shared/Image/default_img.jpg';

type PreviewImgProps = {
  pictureUrl?: string,
  alt?: string,
  className?: string,
};

const defaultProps = {
  pictureUrl: null,
  alt: 'preview image',
  className: null,
};

function PreviewImg({ pictureUrl, alt, className }: PreviewImgProps) {
  const [previewImg, setPreviewImg] = useState(pictureUrl || defaultImg);
  const [loading, setLoading] = useState(true);

  return (
    <>
      {loading && <PreviewLoader className={className} />}
      <StyledPreviewImg
        alt={alt}
        onError={() => setPreviewImg(defaultImg)}
        onLoad={() => setLoading(false)}
        src={previewImg}
      />
    </>
  );
}

PreviewImg.defaultProps = defaultProps;

export default PreviewImg;

// styles

const PreviewLoader = styled.div`
  width: 100%;
  height: 220px;
  background: ${darken(0.05, '#26262e')};
  overflow: hidden;
  position: relative;

  @media (max-width: 630px) {
    height: 316px;
  }

  @keyframes light {
    from {
      transform: translateX(-20em) skewX(-45deg);
    }
    to {
      transform: translateX(76em) skewX(-45deg);
    }
  }

  @media (max-width: 425px) {
    height: 172px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 5em;
    height: 100%;
    background-color: #fff;
    transform: translateX(-20em) skewX(-45deg);

    animation: light 2s infinite ease;
    opacity: 0.05;

    @media (max-width: 630px) {
      width: 4em;
    }

    @media (max-width: 425px) {
      width: 3em;
    }
  }
`;

const StyledPreviewImg = styled.img`
  width: 100%;

  &:before {
    content: '';
    background-color: rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 3em;
    display: block;
    position: absolute;
    top: 0;
    left: -4.5em;
    transform: skewX(-45deg) translateX(0);
    transition: none;
  }
`;
