/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import moment from 'moment';
import { connect } from 'react-redux';
import { history } from 'v2/stores/configureStore';
import { getPath, SETUP_ACCOUNT_PATH } from 'v2/constants/router';
import { isUserStatusAcceptedTermsOfService } from 'v2/selectors/session';
import { getPrizesList } from 'v2/selectors/prizes';
import ModalVideo from 'react-modal-video';
import { StatisticItem, GrandPrize, OtherPrize } from 'v2/components/blocks';
import pickem from 'v2/components/shared/Image/pickem.svg';
import survive from 'v2/components/shared/Image/survive.svg';
import defaultImg from 'v2/components/shared/Image/default_img.jpg';
import bitmapBasketball from 'v2/components/shared/Image/Bitmap_basketball.svg';
import { bitmap } from '../shared/Image';
import HowToPlayLink from './HowToPlayLink';

const propTypes = {};

const defaultProps = {
  contest: {},
};

function MakePicksContest(props) {
  const { contest, isShowContest, contestPrizes, pictureUrl, prizeList, isUserStatusAcceptedTermsOfService } = props;
  const { participantsMaxCount, startsAt, cost, description, name, prizeVideoId, league } = contest;

  const [isOpen, setIsOpen] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
  // const [currentVideo, setCurrentVideo] = useState({});

  const contestStartAt = moment(startsAt).format('M/DD h:mm A');
  const [grandPrizes, otherPrizes] = R.partition(R.where({ priority: R.equals(0) }), prizeList || '');

  const getPlayerImagebyLeague = () => {
    if (league) {
      if (league === 'nba') {
        return <ImageContest src={bitmapBasketball} alt="" />;
      }

      return <ImageContest src={bitmap} alt="" />;
    }
  };

  useEffect(() => {
    if (!isUserStatusAcceptedTermsOfService) return history.push(getPath(SETUP_ACCOUNT_PATH, {}));
    setShowModal(true);

    return () => {
      setShowModal(false);
    };
  }, []);

  return (
    <StyledMakePicksContest className={isShowContest ? 'show_contest' : ''} isOpen={showModal}>
      <MakePicksContestLogo src={contest.kind === 'survive' ? survive : pickem} alt="logo" />
      <MakePicksContestTitle>{contest && contest.title}</MakePicksContestTitle>
      <HowToPlayLink kind={contest.kind} />
      <Statistics>
        <StatisticItem title="Streakin’" text={participantsMaxCount} />
        <StatisticItem title="Cost" text={cost && cost > 0 ? `$${cost}` : 'FREE'} />
        <StatisticItem title="Starts At" text={contestStartAt} />
      </Statistics>
      {grandPrizes && <GrandPrize mysteryMemorablia={contest.mysteryMemorablia} grandPrize={grandPrizes[0]} />}
      {prizeVideoId ? (
        <PrizeVideo>
          <PreviewImg>
            <img alt="" style={{ width: '100%', borderRadius: '8px' }} src={pictureUrl || defaultImg} />
            <StyledButton
              onClick={() => {
                setIsOpen(true);
              }}
            />
          </PreviewImg>
          <ContestText>
            {description && <span>{description}</span>}
            {name && <h3>{name}</h3>}
          </ContestText>
          <ModalVideo
            channel="youtube"
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            videoId={prizeVideoId}
            className="modalVideo"
          />
        </PrizeVideo>
      ) : (
        <PrizeVideo />
      )}
      {otherPrizes && <OtherPrize otherPrizes={otherPrizes} />}
      {getPlayerImagebyLeague()}
    </StyledMakePicksContest>
  );
}

const StyledMakePicksContest = styled.div`
  z-index: 2;
  position: relative;
  /* height: min-content; */
  min-width: 350px;
  background: #1b1b21;
  padding: 20px;
  padding-top: 30px;
  box-sizing: border-box;
  overflow: hidden;

  @media (max-width: 901px) {
    display: none;
    z-index: 9;
  }
  &.show_contest {
    position: absolute;
    width: 100%;
    /* height: 100%; */
    min-width: auto;
    display: block;
  }
`;

const MakePicksContestTitle = styled.h2`
  text-align: center;
  margin: 12px;
  /* margin: 10px 0 15px; */
`;

const MakePicksContestLogo = styled.img`
  display: block;
  margin: 0 auto;
`;

const Statistics = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
`;

const ContestText = styled.div`
  margin: 10px 0;
`;

const PrizeVideo = styled.div`
  margin-bottom: 20px;
`;

const ImageContest = styled.img`
  display: block;
  margin: 20px auto;
`;

const PreviewImg = styled.div`
  border-radius: 8px;
  margin-top: 20px;
  width: 100%;
  max-width: 350px;
  position: relative;
  @media (max-width: 901px) {
    max-width: none;
  }
`;

const StyledButton = styled.button`
  background: #ce3c4d;
  padding: 24px;
  border-radius: 50px;
  border: 0;
  box-shadow: 0px 2px 4px 0px;
  position: absolute;
  top: calc(50% - 24px);
  left: 0;
  right: 0;
  margin: 0 auto;
  cursor: pointer;
  bottom: 0;
  margin: 0 auto;
  cursor: pointer;
  height: 24px;
  width: 24px;
  &:hover {
    background: #e8495b;
    color: #fff;
    &:after {
      border-color: #e8495b #e8495b #e8495b #fff;
    }
  }
  &:after {
    width: 0px;
    height: 0px;
    background: white;
    position: absolute;
    top: calc(50% - -5px);
    left: calc(50% - 4px);
    margin-top: -12px;
    content: ' ';
    border-style: solid;
    border-width: 7px 0 7px 12px;
    border-color: #ce3c4d #ce3c4d #ce3c4d #fff;
  }
`;

MakePicksContest.propTypes = propTypes;
MakePicksContest.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => ({
  prizeList: getPrizesList(state, { prizeIds: ownProps.contestPrizes }),
  isUserStatusAcceptedTermsOfService: isUserStatusAcceptedTermsOfService(state),
});
// export default enhance(HomePage);
export default connect(mapStateToProps)(MakePicksContest);
