/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';

import { getAvailableContestIds } from 'v2/selectors/contests';
import { ROOT_PATH, SIGN_UP } from 'v2/constants/router';

import SlickCarousel from '../SlickCarousel';
import { Background } from '../VideoWinners';

const propTypes = {};

const defaultProps = {};

const settings = {
  className: 'promo_slider',
  infinite: true,
  centerMode: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerPadding: '0px',
  variableWidth: false,
  arrows: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: { autoplay: false, slidesToShow: 1, arrows: true, centerPadding: '80px', variableWidth: false },
    },
    {
      breakpoint: 768,
      settings: { autoplay: false, slidesToShow: 1, arrows: false, centerPadding: '30px', variableWidth: false },
    },
  ],
};

function Promo(props) {
  const { mediaCollection, mainContestId, session, isPresenceAvailableContests } = props;
  return (
    <div>
      <Title>Make Your Fantasy a Reality</Title>
      <StyledPromo>
        <Background />
        <SlickCarousel mediaCollection={mediaCollection} settings={settings} />
        <PromoDescription>
          <PromoText>
            Play free for a chance to win once in a lifetime Class Act VIP experiences, exclusive autographed
            memorabilia, and much more!
          </PromoText>
          <StyledButton
            href={
              session.user ? (isPresenceAvailableContests ? mainContestId : `${ROOT_PATH}#AvailableGames`) : SIGN_UP
            }
          >
            Play Now
          </StyledButton>
        </PromoDescription>
      </StyledPromo>
    </div>
  );
}

const Title = styled.h1`
  text-align: center;
  padding: 0 30px;
  @media (min-width: 768px) {
    padding: 0;
    margin-bottom: 0;
  }
`;

const StyledPromo = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 20px 0 20px;
  padding-top: 10px;
  border-radius: 8px;
  overflow: hidden;
  @media (min-width: 768px) {
    padding: 10px 0px 0;
    flex-direction: column-reverse;
    background: transparent;
    margin: 0 0 60px;
  }
`;

const PromoText = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: #656579;
  margin: 15px 0 0;
  padding: 0 15px;
  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 25px;
    margin: 0;
  }
`;

const StyledButton = styled.a`
  width: 82%;
  height: 40px;
  margin: 20px 0;
  background: #5d5ce2;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
  color: #fff;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  &:hover {
    color: #fff;
    background: #0b5492;
    text-decoration: none;
  }
  @media (min-width: 768px) {
    width: 125px;
    margin-bottom: 60px;
  }
`;

const PromoDescription = styled.div`
  ${'' /* padding: 0 15px; */}
`;

const mapStateToProps = (state) => ({
  isPresenceAvailableContests: !isEmpty(getAvailableContestIds(state)),
});

Promo.propTypes = propTypes;
Promo.defaultProps = defaultProps;
// export default enhance(HomePage);
export default connect(mapStateToProps)(Promo);
