import React from 'react';
import styled from 'styled-components';

type TitleProps = {
  children: string,
};

function Title({ children }: TitleProps) {
  return <StyledTitle>{children}</StyledTitle>;
}

const StyledTitle = styled.h2`
  color: #fff;
  text-align: center;
  margin-bottom: 40px;
`;

export default Title;
