// @flow

import React from 'react';
import styled from 'styled-components';
import defaultImgPlayer from 'v2/components/shared/Image/NFLplayer.svg';
import startQuote from 'v2/components/shared/Image/start_quote.png';
import endQuote from 'v2/components/shared/Image/end_quote.png';

import RandomQuoteContainer from './RandomQuoteContainer';
import PlayerImage from './PlayerImage';

type PlayerQuoteProps = {
  text: string,
  name: string,
  photo?: {
    src: string,
    alt: string,
  },
};

const defaultProps = {
  photo: {
    src: defaultImgPlayer,
    alt: 'alt',
  },
};

function PlayerQuote({ text, name, photo, color }: PlayerQuoteProps) {
  return (
    <PlayerQuoteContainer>
      <QuoteDescriptionContainer>
        <QuoteDescriptionParagraph>{`“${text}”`}</QuoteDescriptionParagraph>
        <QuoteDescriptionName color={color}>{`- ${name}`}</QuoteDescriptionName>
      </QuoteDescriptionContainer>
      <PlayerImage {...photo} />
    </PlayerQuoteContainer>
  );
}

PlayerQuote.Random = RandomQuoteContainer;
PlayerQuote.defaultProps = defaultProps;
export default PlayerQuote;

// styles

const QuoteDescriptionName = styled.span`
  display: block;
  margin-top: 15px;
  color: ${({ color }) => color || '#eb3624'};
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0px;
  text-transform: uppercase;

  @media (max-width: 425px) {
  }
`;

const QuoteDescriptionParagraph = styled.p`
  color: #fff;
  display: inline;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 44px;
  text-transform: uppercase;
  margin-top: 0;
  position: relative;
  white-space: pre;
  /* padding-top: 40px;  */

  &:after,
  &:before {
    content: '';
    display: block;
    background-repeat: no-repeat;
    height: 39px;
    width: 51px;
    position: absolute;
    background-size: contain;
  }

  &:after {
    background-image: url(${endQuote});
    bottom: 0;
    right: 0;
    transform: translate(20%, 40%);
  }

  &:before {
    background-image: url(${startQuote});
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-20%, -40%);
  }

  @media (max-width: 825px) {
    font-size: 22px;
    letter-spacing: 0px;
    line-height: 24px;
  }

  @media (max-width: 425px) {
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 24px;

    &:before,
    &:after {
      height: 29px;
    }
  }
`;

const QuoteDescriptionContainer = styled.div`
  /* margin-right: 80px;

  @media (max-width: 425px) {
    margin-right: 22px;
  } */
`;

const PlayerQuoteContainer = styled.div`
  background-color: #26262e;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 10px 25px 60px;
  border-radius: 8px;

  @media (max-width: 425px) {
    background-color: transparent;
    padding: 25px 15px;
  }
`;
