// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';
import useWindowSize from 'v2/services/useWindowSize';
import Controller from 'v2/Controllers/InstagramCarousel';

import CarouselItem from './CarouselItem';
import Section from './Section';
import CarouselIcon from './Icon';
import config from './config';

// type InstagramCarouselProps = {};

const isDarkTheme = config.theme === 'dark';
function InstagramCarousel(props) {
  const [documentWidth] = useWindowSize();

  const isMobile = documentWidth < 520;

  return (
    <Fragment>
      {!isMobile && (
        <Section>
          <DescktopTitleText>Gone Streakin'</DescktopTitleText>
        </Section>
      )}
      <InstagramCarouselContainer>
        <Section>
          {config.icons.map(({ key, ...iconOptions }) => {
            if (key === config.icons[0].key && isMobile) {
              return (
                <MobileTitleContainer>
                  <CarouselIcon key={key} {...iconOptions} />
                  <MobileTitleText>Gone Streakin'</MobileTitleText>
                </MobileTitleContainer>
              );
            }
            return <CarouselIcon key={key} {...iconOptions} />;
          })}
        </Section>
        <CarouselItem.Container speed={config.speed}>
          {config.mockPhotos.map(({ key, ...mockPhoto }) => (
            <CarouselItem key={key} {...mockPhoto} />
          ))}
        </CarouselItem.Container>
        <Section>
          <CarouselText>{config.footerText}</CarouselText>
        </Section>
      </InstagramCarouselContainer>
    </Fragment>
  );
}

export default Controller(InstagramCarousel);

// styles

const DescktopTitleText = styled.h3`
  color: #fff;
  font-size: 22px;
  margin: 0;
  line-height: 1;
  margin-bottom: 10px;
`;

const CarouselText = styled.span`
  font-size: 12px;
  color: ${() => (isDarkTheme ? '#fff' : '#131317')};
  font-weight: bold;
  line-height: 18px;
  text-align: center;
  width: 100%;
  text-align: center;
`;

const MobileTitleText = styled.h3`
  color: ${() => (isDarkTheme ? '#fff' : '#131317')};
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0px;
  text-transform: uppercase;
  margin: 0;
  margin-left: 0.65em;
`;

const MobileTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5em;
`;

const InstagramCarouselContainer = styled.div`
  background-color: ${() => (isDarkTheme ? '#26262e' : '#fff')};
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @media (max-width: 468px) {
    overflow: visible;
    ${CarouselText} {
      font-size: 10px;
    }
  }
`;
