/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import Oval from '../../../shared/Image/oval.svg';
import PreviewSlider from '../PreviewSlider';

const propTypes = {};

const defaultProps = {};

const settings = {
  className: 'slider_winners',
  infinite: true,
  centerMode: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerPadding: '0',
  arrows: false,
  responsive: [
    { breakpoint: 1200, settings: { autoplay: false, slidesToShow: 3, arrows: false, centerPadding: '10px' } },
    { breakpoint: 768, settings: { autoplay: false, slidesToShow: 1, arrows: false, centerPadding: '30px' } },
  ],
};

function VideoWinners(props) {
  const { title, instagram, text, mediaCollection } = props;
  return (
    <StyledPreview>
      <Background />
      <PreviewTitle>
        <Icon>
          <img alt="icon" src={Oval} />
        </Icon>
        {title}
      </PreviewTitle>
      <PreviewSlider settings={settings} mediaCollection={mediaCollection} instagram={instagram} text={text} />
    </StyledPreview>
  );
}

export const StyledPreview = styled.div`
  width: 100%;
  position: relative;
  padding: 20px 0;
  border-radius: 8px;
  margin: 30px 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  @media (min-width: 768px) {
    background: transparent;
  }
  @media (min-width: 1200px) {
    max-width: 1200px;
  }
  @media (max-width: 425px) {
    margin: 0;
    padding: 10px 0;
  }
`;

export const Background = styled.div`
  position: absolute;
  background: #1b1b21;
  height: 100%;
  box-sizing: border-box;
  left: 15px;
  right: 15px;
  bottom: 0;
  top: 0;
  border-radius: 8px;
  z-index: -1;
  @media (min-width: 768px) {
    background: none;
  }
`;

const PreviewTitle = styled.h2`
  position: relative;
  font-size: 22px;
  line-height: 28px;
  text-transform: uppercase;
  margin-top: 0;
  padding-left: 30px;
  @media (min-width: 768px) {
    padding-left: 10px;
  }
`;

const Icon = styled.span`
  display: inline-block;
  margin-right: 5px;
  @media (min-width: 768px) {
    padding-left: 0px;
  }
`;

VideoWinners.propTypes = propTypes;
VideoWinners.defaultProps = defaultProps;

// export default enhance(HomePage);
export default VideoWinners;
