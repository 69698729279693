// @flow

import React from 'react';
import { or, filter, complement, compose, equals, head } from 'ramda';
import styled from 'styled-components';

import { KIND_TABS, LEAGUE_TABS } from 'v2/constants/UI/tabs';
import { KindTabs, LegueTabs } from 'v2/components/Tabs';

import { kindKey, leagueKey } from './helpers/params';

const defaultLeagueKey = 'nfl';
const defaultKindKey = 'survive';

type NavigationBlockProps = {
  getStatus: (v: string) => string,
  onChange: (v: string) => void,
};

function NavigationBlock({ onChange, getStatus }: NavigationBlockProps) {
  return (
    <Container>
      <ContainerBlock>
        <LegueTabs
          onChange={onChange(leagueKey)}
          activeKey={or(getStatus(leagueKey), defaultLeagueKey)}
          tabs={filter(complement(compose(equals('all'), head)))(LEAGUE_TABS)}
        />
      </ContainerBlock>

      <ContainerBlock>
        <Title>Available Games</Title>
      </ContainerBlock>

      <ContainerBlock>
        <KindTabs onChange={onChange(kindKey)} activeKey={or(getStatus(kindKey), defaultKindKey)} tabs={KIND_TABS} />
      </ContainerBlock>
    </Container>
  );
}

export default React.memo(NavigationBlock);

// styles

const Title = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
`;

const ContainerBlock = styled.div`
  margin-bottom: 0.55rem;
`;

const Container = styled.div`
  /* background: ${({ theme }) => theme.colors.dark}; */
  /* margin-bottom: 0.75rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
`;
