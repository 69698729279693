// @flow

import React from 'react';
import styled from 'styled-components';
import { equals, path } from 'ramda';

type propTypes = {
  onChange: (key: string) => void,
  activeKey: string,
  tabs?: Array<[string, { label: string, img: string, activeColor: string }]>,
};

const defaultProps = {
  tabs: [],
};

function KindTabs({ onChange, activeKey, tabs }: propTypes) {
  return (
    <Container>
      {tabs.map(([key, { label, img, activeColor }]) => (
        <TabItem
          key={key}
          onClick={() => onChange(key)}
          isActive={equals(activeKey, key)}
          activeColor={activeColor}
          isAll={key == 'all'}
        >
          <TabImage src={img} alt={label} />
        </TabItem>
      ))}
    </Container>
  );
}

KindTabs.defaultProps = defaultProps;
export default React.memo(KindTabs);

// styles

const TabImage = styled.img`
  width: 7rem;
  max-height: 3rem;
`;

const TabItem = styled.div`
  background: ${({ theme }) => theme.colors.gray};
  padding: 0.6em 1.1em;
  opacity: 0.75;
  opacity: ${({ isActive }) => isActive && 1};
  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-color: ${({ theme, activeColor, isActive }) => isActive && path(['colors', activeColor], theme)};
  transition: all 0.25s;
  border-radius: 6px;
  margin-right: 0.5rem;
  padding: ${({ isAll }) => isAll && '0.6em 0em'};

  &:hover {
    filter: brightness(1.15);
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
