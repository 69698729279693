import React, { useEffect, useContext } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { history } from 'v2/stores/configureStore';
import howToPlayModalContext from 'v2/contexts/FAQModal';
import contestDescriptionModalContext from 'v2/contexts/ContestDescriptionModal';
import { getPrizesList } from 'v2/selectors/prizes';
import { HOW_TO_PLAY, CONTEST_CATEGORY_PATH, getPath } from 'v2/constants/router';
import { getQuerystring } from 'v2/apiAdapters/fetch';
import { isDefined } from 'v2/helpers/ramda';

import infoIcon from 'v2/components/Contests/Header/icons/info.svg';
import { IconGame, GrandPrize, Statistics } from '../../blocks';

// import Description from './Description';
import Prizes from './Prizes';
import Button from './Button';
import Progress from './Progress';
import PriceTag from './PriceTag';

const propTypes = {
  gameState: PropTypes.string,
  kind: PropTypes.string,
  mysteryMemorablia: PropTypes.bool,
  prizes: PropTypes.arrayOf(PropTypes.number),
  prizeList: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
  showFAQ: PropTypes.bool,
};

const defaultProps = {
  gameState: '',
  kind: '',
  mysteryMemorablia: false,
  prizeList: [],
  prizes: [],
  title: '',
  showFAQ: false,
};

function GameCard(props) {
  const {
    kind,
    id,
    showHeadshots,
    gameState,
    mysteryMemorablia,
    isContestAvailiable,
    submitPicks,
    totalSelectionsRequired,
    session,
    currentWeek,
    prizeList,
    isContestActive,
    isContestLive,
    isProgressResult,
    correctPicks,
    cost,
    status,
    showFAQ,
    urlContest,
    isJoin,
  } = props;

  const [grandPrizes, otherPrizes] = R.partition(R.where({ priority: R.equals(0) }), prizeList);
  // <StyledGameCard className={gameState === 'recent' ? 'grayscale' : ''}>

  const { showContestDescriptionModal } = useContext(contestDescriptionModalContext);
  const { showHowToPlayModal, closeHowToPlayModal } = useContext(howToPlayModalContext);

  // const navToContest = () => history.push(getPath(CONTEST_CATEGORY_PATH, { id, ...urlContest }));
  return (
    <StyledGameCard>
      {/* {isContestLive && <span>live</span>} */}
      <TagsContainer>
        <TagWrapper>
          <InfoIconContainer
            // onClick={() => showHowToPlayModal({ kind, link: getPath(CONTEST_CATEGORY_PATH, { id, ...urlContest }) })}
            onClick={() =>
              showContestDescriptionModal({
                contest: props,
                onClickHandler: () =>
                  showHowToPlayModal({
                    kind,
                    onClickHandler: closeHowToPlayModal,
                  }),
              })
            }
          >
            <img src={infoIcon} alt="info" />
          </InfoIconContainer>
        </TagWrapper>

        {isContestLive && isContestActive && (
          <TagWrapper>
            <StyledTagLive>Live</StyledTagLive>
          </TagWrapper>
        )}
      </TagsContainer>

      <PriceTag price={cost} />

      <StyledGameCardInfo>
        <GameCardContent>
          <GameCardContentMain>
            <IconGame {...props} />

            {/* <StyledTitleGame>{title}</StyledTitleGame> */}

            {/* <Description kind={kind} /> */}
            {(isContestActive || isContestAvailiable || isProgressResult) && (
              <Prizes {...props} otherPrizes={otherPrizes} />
            )}

            {isDefined(grandPrizes) && R.not(isJoin) && (
              <MainContentWrapper>
                <MainContentContainer>
                  <GrandPrize
                    mysteryMemorablia={mysteryMemorablia}
                    grandPrize={grandPrizes[0]}
                    kind={kind}
                    {...props}
                  />
                </MainContentContainer>
              </MainContentWrapper>
            )}
          </GameCardContentMain>

          {isJoin && (status || gameState === 'recent' || currentWeek > 1) && session.isAuthorized && (
            <ProgressWrapper>
              <Progress {...props} />
            </ProgressWrapper>
          )}

          {/* <Statistics {...props} /> */}
        </GameCardContent>
      </StyledGameCardInfo>
      <Button {...props} />
    </StyledGameCard>
  );
}

const TagWrapper = styled.div`
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
`;

const TagsContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 5px;
  left: 10px;
  z-index: 2;
`;

const StyledBaseTag = styled.div`
  padding: 2px 4px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 14px;
  border-radius: 2px;
  color: #fff;
  background: gray;
`;

const StyledTag = styled(StyledBaseTag)`
  padding: ${(props) => (props.isLive ? '2px 4px 2px 6px' : '2px 4px')};
  border-radius: ${(props) => (props.isLive ? '0 2px 2px 0' : '2px')};
  background: ${(props) => props.color};
  text-shadow: 1px 1px 1px #2b2b2b;
`;

const StyledTagLive = styled(StyledTag)`
  position: relative;
  border-radius: 2px 0 0 2px;
  background: #e80c51;
  margin-top: -4px;
  /* &::before {
    content: '';
    position: absolute;
    top: 0;
    right: -2px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 18px 2px 0 0;
    border-color: #e80c51 transparent transparent transparent;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: -2px;
    width: 1px;
    height: 100%;
    background-color: #1b1b21;
    transform: rotate(6deg);
  } */
`;

const InfoIconContainer = styled.span`
  /* position: absolute;
  top: 25px;
  left: 25px;
  transform: translate(calc(-25px / 2), calc(-25px / 2)); */
  z-index: 1;

  filter: brightness(0.25);
  transition: filter 1s;
  cursor: pointer;

  &:focus,
  &:hover {
    filter: brightness(0.5);
  }
`;

const GameCardContentMain = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 5px;
  height: 100%;
  justify-content: space-around;

  z-index: 0;
`;

const MainContentWrapper = styled.div`
  width: 100%;
  padding: 5px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

const ProgressWrapper = styled.div`
  min-height: 90px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  padding: 0 5px;
`;

const MainContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #26262e;
  /* margin-top: 0.75em; */
  padding: 0.75em 0.75em;
  border-radius: 6px;
`;

const GameCardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-between;
`;

const StyledGameCard = styled.section`
  width: 100%;
  height: auto;
  position: relative;
  background: #26262e;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 25px 0 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 425px;

  &.grayscale {
    filter: grayscale(65%);
  }
  @media (min-width: 640px) {
    margin: 20px 0 0;
    width: 49%;
    margin-right: 2%;
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
  @media (min-width: 1024px) {
    width: 32%;
    margin-right: 2%;
    &:nth-child(2n) {
      margin-right: 2%;
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
  @media (max-width: 425px) {
    margin: 0;
    height: 100%;
  }
`;

const StyledGameCardInfo = styled.div`
  width: 100%;
  background: #1b1b21;
  padding: 5px 0;
  box-sizing: border-box;
  border-radius: 8px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

GameCard.propTypes = propTypes;
GameCard.defaultProps = defaultProps;

const mapStateToProps = (state, { prizes }) => ({
  prizeList: getPrizesList(state, { prizeIds: prizes }),
});

function areEqual(prevProps, nextProps) {
  return R.equals(prevProps, nextProps);
}
// export default enhance(HomePage);
export default connect(mapStateToProps)(React.memo(GameCard, areEqual));
