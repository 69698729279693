// @flow

import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import useWindowSize from 'v2/services/useWindowSize';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import LeagueTab from './shared/LeagueTab';

const defaultProps = {
  showTitle: true,
};

// type LeagueTabsProps = {};

function LeagueTabs({ showTitle, kind }) {
  const sliderRef = useRef();
  const [windowSize] = useWindowSize();
  const isMobile = windowSize <= 375;

  const settings = {
    infinite: false,
    centerMode: true,
    centerPadding: '23px 0 18px',
    focusOnSelect: true,
    slidesToShow: 2,
    dots: true,
    arrows: false,
    slidesToScroll: 3,
    appendDots: (dots) => <SlickDotsContainer>{dots}</SlickDotsContainer>,
  };

  // useEffect(() => {
  //   sliderRef.current.slickGoTo(1);
  // }, []);

  return (
    <LeagueTabsContainer>
      {showTitle && <LeagueTabsTitle>Available Games</LeagueTabsTitle>}
      <LeagueTabsGroup>
        {false ? (
          <Slider {...settings} ref={sliderRef}>
            <LeagueTab kind="weekly" activeKind={kind} />
            <LeagueTab kind="survive" activeKind={kind} />
            <LeagueTab kind="bracket" activeKind={kind} />
            <LeagueTab kind="streak" activeKind={kind} />
          </Slider>
        ) : (
          <>
            <LeagueTab kind="survive" activeKind={kind} />
            <LeagueTab kind="weekly" activeKind={kind} />
          </>
        )}
      </LeagueTabsGroup>
    </LeagueTabsContainer>
  );
}

const SlickDotsContainer = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 5px 0 !important;
  li {
    display: inline-flex;
    width: 5px !important;
    height: 5px !important;

    button {
      background: transparent;
      border: none;
      width: 5px !important;
      height: 5px !important;
      border-radius: 50% !important;
      transition: background-color 0.5s;
    }

    &.slick-active {
      width: 7px !important;
      height: 7px !important;
      button {
        width: 7px !important;
        height: 7px !important;
      }
    }
  }
`;

const LeagueTabsTitle = styled.span`
  font-size: 14px;
  margin-bottom: 15px;
  margin-top: 5px;
  text-transform: uppercase;
  color: #fff;
`;

const LeagueTabsGroup = styled.div`
  .slick-list {
    position: relative;
    height: 80px;
  }

  .slick-track {
    position: absolute !important;
    left: -139px;
  }

  /* @media (max-width: 375px) {
    width: 375px;
  } */
`;

const LeagueTabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

LeagueTabs.defaultProps = defaultProps;
export default withRouter(LeagueTabs);
