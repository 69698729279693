import basketball from 'v2/components/shared/Image/Sports/color/new_basketball.svg';
import baseball from 'v2/components/shared/Image/Sports/color/baseball.svg';
import football from 'v2/components/shared/Image/Sports/color/football.svg';
import golf from 'v2/components/shared/Image/Sports/color/golf.svg';
import hockey from 'v2/components/shared/Image/Sports/color/hockey.svg';
import soccer from 'v2/components/shared/Image/Sports/color/soccer.svg';

export default {
  nba: { src: basketball, alt: 'basketball' },
  mlb: { src: baseball, alt: 'baseball' },
  nfl: { src: football, alt: 'football' },
  golf: { src: golf, alt: 'golf' },
  hockey: { src: hockey, alt: 'hockey' },
  soccer: { src: soccer, alt: 'soccer' },
};
