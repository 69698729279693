/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as R from 'ramda';

import Loader from 'v2/components/shared/Loader';

import DefaultPlayers from './shared/DefaultPlayers';
import SelectedPlayers from './shared/SelectedPlayers';

const propTypes = {
  selectionsRequired: PropTypes.number,
};

const defaultProps = {
  selectionsRequired: 0,
};

const CollectionPlayers = ({
  isLoading,
  selectionsRequired,
  selectedPlayers,
  onPlayerSelect,
  contestCategory,
  isContestEnded,
  ...rest
}) => {
  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  const playersCollection = selectedPlayers.map(
    (player, index) =>
      player && (
        <SelectedPlayers
          isLarge={selectionsRequired < 2}
          key={player.id}
          player={player}
          index={index + 1}
          onPlayerSelect={onPlayerSelect}
          contestCategory={contestCategory}
          isContestEnded={isContestEnded}
        />
      ),
  );

  const mapWithIndex = R.addIndex(R.map);

  const collection = R.pipe(
    R.times((index) => (
      <DefaultPlayers
        isContestEnded={isContestEnded}
        playerKey={index}
        key={index}
        {...rest}
        isLarge={selectionsRequired < 2}
      />
    )),
    mapWithIndex((defaultPlayer, index) => playersCollection[index] || defaultPlayer),
  )(selectionsRequired);

  return collection;
};

function CategoryPlayers(props) {
  return (
    <StyledCategoryPlayers>
      <CollectionPlayers {...props} />
    </StyledCategoryPlayers>
  );
}

const StyledCategoryPlayers = styled.div`
  display: flex;
  justify-content: center;

  /* min-height: 180px; */
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: -25px;
`;

CategoryPlayers.propTypes = propTypes;
CategoryPlayers.defaultProps = defaultProps;

// export default enhance(HomePage);
export default CategoryPlayers;
