import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { compose, pure } from 'recompose';
import * as Router from 'v2/constants/router';
import { connect } from 'react-redux';
import * as actions from 'v2/actions';

import pickem from '../../../shared/Image/pickem.svg';

const propTypes = {};

const defaultProps = {};

// injectGlobal`
//   body.ReactModal__Body--open {
//     overflow: hidden;
//   }
// `;

function Notification(props) {
  const { isOpenNotification, session, fetchDismissNotification } = props;
  const { notifications } = session;

  return (
    <StyledNotification className={isOpenNotification ? 'show_dropdown' : ''}>
      <NotificationBackground>
        <NotificationList>
          {notifications.map((notification, id) => (
            <NotificationItem key={id}>
              <TextNotification>
                <Title>{notification.title}</Title>
                <span>{moment(notification.created_at).format('MM/DD/YYYY h:mm')}</span>
                <Description>{notification.body}</Description>
              </TextNotification>
            </NotificationItem>
          ))}
        </NotificationList>
        <DismissNotification>
          <a style={{ color: '#656579' }} onClick={() => fetchDismissNotification()}>
            See all incoming activity
          </a>
        </DismissNotification>
      </NotificationBackground>
    </StyledNotification>
  );
}

const StyledNotification = styled.nav`
  position: absolute;
  width: 100%;
  top: 70px;
  left: 0;
  right: 0;
  display: none;
  transition: opacity 0.15s ease-in-out;
  border-radius: 8px;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  @media (min-width: 768px) {
    min-width: 150px;
    max-width: 340px;
    width: auto;
    height: auto;
    margin-top: 15px;
    left: auto;
    right: 0;
    top: 60px;
  }
`;

const NotificationBackground = styled.div`
  width: 100%;
  background: #1b1b21;
  padding-bottom: 20px;
  @media (min-width: 768px) {
    padding-bottom: 0;
  }
`;

const NotificationList = styled.ul`
  position: relative;
  margin: 0;
  padding: 20px;
  box-sizing: border-box;
  @media (min-width: 768px) {
    padding: 20px 30px;
    align-items: flex-start;
    &:before {
      position: absolute;
      content: '';
      width: 24px;
      height: 4px;
      background: #e80c51;
      bottom: 45px;
      left: 30px;
      top: 0;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
`;

const NotificationItem = styled.li`
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #26262e;
`;

const TextNotification = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 5px;
`;

const Description = styled.p`
  text-transform: none;
`;

const DismissNotification = styled.div`
  width: 100%;
  text-align: center;
  padding: 0;
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  @media (min-width: 768px) {
    padding: 0px 0 20px;
  }
`;

const mapDispatchToProps = (dispatch) => ({
  fetchDismissNotification: () => dispatch(actions.fetchDismissNotification()),
});

const enhance = compose(
  connect(
    undefined,
    mapDispatchToProps,
  ),
  pure,
);

Notification.propTypes = propTypes;
Notification.defaultProps = defaultProps;

// export default enhance(HomePage);
export default enhance(Notification);
