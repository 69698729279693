import React from 'react';
import { contains } from 'ramda';
import styled from 'styled-components';
import { withRouter } from 'react-router';

import mobileBG from 'v2/components/shared/Image/footer/mobile_tribune.jpg';
import desktopBG from 'v2/components/shared/Image/footer/desktop_BG.svg';
import nflPlayers from 'v2/components/shared/Image/footer/nflPlayers.svg';

import rootBackground from 'v2/components/shared/Image/footer_background.png';

// ROOT_PATH
import { CONTACT_US, TERMS, PRIVACY, ROOT_PATH, LEAGUE_PATH, CONTACT_US_PATH } from 'v2/constants/router';

import LogoImage from '../shared/Image';
import logo from '../shared/Image/logo-footer.svg';

import SoicalLinksList from './shared/SoicalLinksList';

const propTypes = {};

const defaultProps = {};

const getDom = (str) => {
  const res = str.match(/\/\w+/);
  if (res) return res[0];
  return str;
};

const getIsActiveStatusLink = (currentPathname) => {
  return contains(getDom(currentPathname), [ROOT_PATH, getDom(LEAGUE_PATH), CONTACT_US_PATH]);
};
function Footer({ location: { pathname } }) {
  if (getIsActiveStatusLink(pathname)) {
    return (
      <StyledFooter>
        {/* <LogoFooter>
          <LogoImage img={logo} />
        </LogoFooter> */}

        <PlayersBlock>
          <img src={nflPlayers} />
        </PlayersBlock>

        <SoicalLinksList />

        <Nav>
          <NavItem>
            <a href={CONTACT_US}>CONTACT</a>
          </NavItem>
          <NavItem>
            <a href={TERMS}>Terms</a>
          </NavItem>
          <NavItem>
            <a href={PRIVACY}>Privacy</a>
          </NavItem>
        </Nav>

        <Copyright>
          <span>© Class Act Sports, LLC. All Rights Reserved.</span>
        </Copyright>
      </StyledFooter>
    );
  }
  return null;
}

const StyledFooter = styled.footer`
  /* background-image: url(${rootBackground});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain; */

  flex: 0 0 auto;
  position: relative;
  width: 100%;
  padding: 20px 0;
  margin-top: 60px;
  margin-bottom: 30px;
  text-align: center;
  /* border-top: 1px solid #26262e; */
  flex: 0 0 auto;
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const LogoFooter = styled.div`
  width: 100px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: -30px;
  /* background: #131317; */
  text-align: center;
`;

const Nav = styled.ul`
  display: flex;
  justify-content: center;
  margin: 0;
  max-width: 1100px;
  margin: auto;
  max-width: 250px;

  @media (max-width: 768px) {
    max-width: 190px;
  }
`;

const NavItem = styled.li`
  flex: 1;
  display: flex;
  justify-content: center;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.5px;
  font-weight: 700;
  color: #656579;
  margin: 0 5px;
  text-transform: uppercase;

  &:first-child {
    justify-content: flex-end;
  }
  &:last-child {
    justify-content: flex-start;
  }
`;

const Copyright = styled.div`
  margin-top: 10px;
`;

const PlayersBlock = styled.div`
  background-image: url(${desktopBG});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: auto;
  padding-bottom: 8%;

  img {
    width: 370px;
  }

  @media (max-width: 768px) {
    background-image: url(${mobileBG});
    min-height: 340px;
    padding-bottom: 15%;

    display: flex;
    align-items: flex-end;
    justify-content: center;

    img {
      width: 270px;
    }
  }
`;

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

// export default enhance(HomePage);
export default withRouter(Footer);
