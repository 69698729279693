// @flow

import React from 'react';
import { join } from 'ramda';
import styled from 'styled-components';

type gamesCounterProps = {
  hitsCount: number,
  gamesCount: number,
};

function gamesCounter({ hitsCount, gamesCount, kind }: gamesCounterProps) {
  return <Counter kind={kind}>{join(' of ', [hitsCount, gamesCount])}</Counter>;
}

export default gamesCounter;

// styles

const Counter = styled.span`
  padding: 10px 20px;
  /* background-color: ${({ theme }) => theme.colors.purpleAccent}; */
  color: ${({ theme }) => theme.colors.white};

  font-size: 20px;
  border-radius: 6px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  z-index: 10;
  left: 50%;
  transform: translate(-50%, -17px);

  background-color: ${({ theme, kind }) => theme.colors.getColorByKind(kind)};
`;

const Separator = styled.div`
  background: #26262e;
  position: absolute;
  height: 1px;
  width: 100%;
`;

const Container = styled.div`
  position: relative;
  height: 1px;
`;
