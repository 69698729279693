import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { contains, split } from 'ramda';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { animateScroll } from 'react-scroll';

import useWindowSize from 'v2/services/useWindowSize';
import { getUserStatusAcceptedTermsOfService } from 'v2/selectors/session';
import { BLOG_CATEGORIES_PATH, BLOG_PATH, MY_GAMES, ROOT_PATH, SETUP_ACCOUNT_PATH } from 'v2/constants/router';
import * as actions from 'v2/actions';
import DropDownNotifications from 'v2/components/shared/DropDownNotifications';
import { isDefined } from 'v2/helpers/ramda';

import BackButton from './shared/BackButton';
import NewBackButton from './shared/NewBackButton';
import { getGamesCountActive } from '../../selectors/gamesCount';
import Profile from './shared/Profile';
import SignIn from './shared/SignIn';
import logo from '../shared/Image/logo.svg';
import Burger from './shared/Burger';

const propTypes = {};

const defaultProps = {};

function Header(props) {
  const [opacity, setOpacity] = useState(1);
  const {
    session,
    gamesCount,
    isUserAcceptedTermsOfService,
    isActiveMenu,
    setMenu,
    location: { pathname },
  } = props;
  const { isAuthorized, user } = session;
  const [documentWidth] = useWindowSize();

  const isMobile = documentWidth < 425;

  const calculateOpacity = () => (document.documentElement.scrollTop * 1.5) / 100;

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    setOpacity(calculateOpacity());
  }, []);

  const handleScroll = () => setOpacity(calculateOpacity());

  if (contains('my_games', pathname.split('/')) && isMobile) {
    return (
      <Container style={{ backgroundColor: `rgba(19, 19, 23, ${opacity})` }}>
        <NewHeaderContainer>
          <NewHeaderSection>
            <BackButton />
          </NewHeaderSection>
          <NewHeaderSection>
            <HeaderTitle>My Games</HeaderTitle>
            {isDefined(gamesCount) && <GameCount>{gamesCount}</GameCount>}
          </NewHeaderSection>
          <NewHeaderSection>
            {isAuthorized && <Profile user={user} session={session} isMenuOpen={isActiveMenu} setMenuOpen={setMenu} />}
            {!isAuthorized && <SignIn />}
          </NewHeaderSection>
        </NewHeaderContainer>
      </Container>
    );
  }

  if (compose(contains('blog'), split('/'))(pathname) && isMobile) {
    return (
      <Container style={{ backgroundColor: `rgba(19, 19, 23, ${opacity})` }}>
        <NewHeaderContainer>
          <NewHeaderSection>
            {pathname !== BLOG_PATH && pathname !== BLOG_CATEGORIES_PATH ? <NewBackButton /> : <BackButton />}
          </NewHeaderSection>
          <NewHeaderSection>
            <HeaderTitle>Blog</HeaderTitle>
            {/* <LogoLink to={isUserAcceptedTermsOfService ? ROOT_PATH : SETUP_ACCOUNT_PATH}>
              <HeaderLogo alt="logo" src={logo} />
            </LogoLink> */}
          </NewHeaderSection>
          <NewHeaderSection>
            {isAuthorized && <Profile user={user} session={session} isMenuOpen={isActiveMenu} setMenuOpen={setMenu} />}
            {!isAuthorized && <SignIn />}
          </NewHeaderSection>
        </NewHeaderContainer>
      </Container>
    );
  }

  if (contains('contests', pathname.split('/')) && isMobile) {
    return null;
  }

  return (
    <Container style={{ backgroundColor: `rgba(19, 19, 23, ${opacity})` }}>
      <StyledHeader>
        <StyledHeaderSection>
          {/* <Menu /> */}
          {isAuthorized && (
            <GamesCount to={isUserAcceptedTermsOfService ? MY_GAMES : SETUP_ACCOUNT_PATH}>
              <GamesText>my games</GamesText>
              <span>{gamesCount}</span>
            </GamesCount>
          )}
        </StyledHeaderSection>
        <StyledHeaderSection style={{ justifyContent: 'center' }} onClick={animateScroll.scrollToTop}>
          <LogoLink to={isUserAcceptedTermsOfService ? ROOT_PATH : SETUP_ACCOUNT_PATH}>
            <HeaderLogo alt="logo" src={logo} />
          </LogoLink>
        </StyledHeaderSection>
        <StyledHeaderSection style={{ justifyContent: 'flex-end' }}>
          {isAuthorized && <DropDownNotifications />}
          {/* <Burger isOpen={isActiveMenu} setOpen={setMenu} /> */}
          {isAuthorized && <Profile user={user} session={session} isMenuOpen={isActiveMenu} setMenuOpen={setMenu} />}
          {!isAuthorized && <SignIn />}
        </StyledHeaderSection>
      </StyledHeader>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  gamesCount: getGamesCountActive(state),
  isUserAcceptedTermsOfService: getUserStatusAcceptedTermsOfService(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchGamesCount: () => dispatch(actions.fetchGamesCount()),
});

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { fetchGamesCount } = this.props;
      fetchGamesCount();
    },
  }),
);

const Container = styled.div`
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 9;
  padding: 2px 0;

  /* &:before {
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    background-color: #131317;
    opacity: ${({ active }) => (active ? 1 : 0)};
    transition: opacity 0.25s;
  } */
`;

const HeaderTitle = styled.h1`
  text-transform: uppercase;

  @media (max-width: 480px) {
    padding: 0;
    margin: 0;
    font-size: 20px;
  }
`;

const NewHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 50px;
  padding: 0px 15px;
  width: 100%;
  font-size: 25px;

  /* @media (max-width: 425px) {
    z-index: 99;
    background: #131317;
    position: fixed;
  } */
`;

const GameCount = styled.div`
  margin-left: 5px;
  font-size: 13px;
  padding: 4px 8px;
  background: #e80c51;
  font-weight: bold;
  color: #fff;
  border-radius: 20px;
  letter-spacing: 0px;
  color: #fff;
  &:hover {
    text-decoration: none;
  }
  @media (min-width: 480px) {
    padding: 4px 8px;
  }
`;

const NewHeaderSection = styled.div`
  flex: 1;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-child {
    justify-content: flex-start;
  }

  &:last-child {
    justify-content: flex-end;
  }
`;

const StyledHeader = styled.header`
  position: relative;
  width: 100%;
  max-width: 1090px;
  height: 50px;
  display: flex;
  padding: 0px 15px;
  box-sizing: border-box;
  padding-top: 5px;
  padding-bottom: 2px;
  margin: 0 auto;
  /* @media (min-width: 768px) {
    margin-bottom: 35px;
  } */

  /* @media (max-width: 425px) {
    z-index: 999999999999;
    background: #131317;
    position: fixed;
  } */
`;

const StyledHeaderSection = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const GamesCount = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.5px;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  p {
    color: white;
    text-transform: uppercase;
    &:hover {
      text-decoration: underline;
    }
  }
  span {
    margin-left: 5px;
    /* padding: 1px 5px; */
    padding: 4px 8px;
    /* border: 1px solid #e80c51; */
    background: #e80c51;
    font-weight: bold;
    color: #fff;
    border-radius: 20px;
    letter-spacing: 0px;
    color: #fff;
    &:hover {
      text-decoration: none;
    }
    @media (min-width: 480px) {
      padding: 4px 8px;
    }
  }
`;

const LogoLink = styled(Link)`
  display: flex;
  height: 100%;
`;

const GamesText = styled.p`
  min-width: fit-content;
  font-size: 12px;
  line-height: 16px;
  @media (min-width: 480px) {
    font-size: 14px;
    line-height: 18px;
  }

  @media (max-width: 425px) {
    font-size: 15px;
  }
`;

const HeaderLogo = styled.img`
  width: 36px;
  @media (min-width: 480px) {
    width: 50px;
  }
  @media (max-width: 425px) {
    width: 32px;
    margin-top: -5px;
  }
`;

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

// export default enhance(HomePage);
export default enhance(Header);
