/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as actions from 'v2/actions';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { getResourceContestGameStatsWeeksCollection } from 'v2/selectors/contests';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { requestInProcess } from 'v2/selectors/request';
import * as requestTypes from 'v2/constants/requestTypes';

import { Loader } from 'v2/components/shared';
import 'react-circular-progressbar/dist/styles.css';
import './custom.css';

import { icoGroup, icoGroupGreen } from '../../../shared/Image';

const propTypes = {};

const defaultProps = {
  weeksCollection: {
    advancedAndEliminatedUsersCount: [],
    currentWeek: null,
  },
};

function DiagramStats(props) {
  const { weeksCollection, isLoading } = props;
  const {
    currentWeek,
    advancedUsersCount,
    pendingUsersCount,
    lastWeekEliminatedUsersCount,
    participantsCount,
    advancedAndEliminatedUsersCount,
  } = weeksCollection;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <StyledDiagramStats>
      <CurrentWeek>
        <NumberWeek>{`Week ${currentWeek}`}</NumberWeek>
        <Statistic>
          <Item>
            <Indicator className="advanced" />
            <ItemCount>{advancedUsersCount}</ItemCount>
            <ItemName style={{ color: '#0bd855' }}>advanced</ItemName>
          </Item>
          <Item>
            <Indicator className="pending" />
            <ItemCount>{pendingUsersCount}</ItemCount>
            <ItemName style={{ color: '#ffb236' }}>pending</ItemName>
          </Item>
          <Item>
            <Indicator className="eliminated" />
            <ItemCount>{lastWeekEliminatedUsersCount}</ItemCount>
            <ItemName style={{ color: '#e80c51' }}>eliminated</ItemName>
          </Item>
        </Statistic>
      </CurrentWeek>

      <PastWeeks>
        {advancedAndEliminatedUsersCount.map((week, index) => (
          <PastWeek key={index}>
            <NumberWeek>Week {week.weekOrder}</NumberWeek>
            <StatisticWeekWrapper>
              <StatisticWeek>
                <ItemCount>{week.advanced}</ItemCount>
                <ItemName>advanced</ItemName>
              </StatisticWeek>
              <CircularProgressbarWithChildren
                value={week.percent}
                styles={buildStyles({
                  rotation: 1,
                  strokeLinecap: 'round',
                  pathTransitionDuration: 1,
                  pathColor: `rgba(${week.percent / 100})`,
                  textColor: '#f88',
                  trailColor: '#E80C51',
                  backgroundColor: '#0BD855',
                })}
                counterClockwise
              >
                <img style={{ width: 40, marginTop: -5 }} src={icoGroup} alt="icoGroup" />
              </CircularProgressbarWithChildren>

              <StatisticWeek>
                <ItemCount>{week.eliminated}</ItemCount>
                <ItemName>eliminated</ItemName>
              </StatisticWeek>
            </StatisticWeekWrapper>
          </PastWeek>
        ))}
      </PastWeeks>
      <StreakersEntering>
        <img src={icoGroupGreen} alt="icoGroupGreen" />
        {`${participantsCount} ENTRIES`}
        {/* {`${participantsCount} streakers entering`} */}
      </StreakersEntering>
    </StyledDiagramStats>
  );
}

const StyledDiagramStats = styled.div``;

const CurrentWeek = styled.div`
  background: rgba(38, 38, 46, 0.5);
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
`;

const Statistic = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const Item = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const Indicator = styled.div`
  width: 100%;
  height: 8px;
  margin-bottom: 10px;
  border-radius: 8px;
  &.advanced {
    background: #0bd855;
  }
  &.pending {
    width: calc(100% - 10px);
    background: #ffb236;
    margin: 0 auto 10px;
  }
  &.eliminated {
    background: #e80c51;
  }
`;

const ItemName = styled.h4`
  text-transform: uppercase;
  font-size: 12px;
  margin: 0;
  margin-top: 5px;
`;

const ItemCount = styled.span`
  font-size: 18px;
  line-height: 24px;
  color: #fff;
`;

const NumberWeek = styled.h4`
  width: 100%;
`;

const PastWeeks = styled.div`
  padding: 0 10px;
`;

const PastWeek = styled.div`
  margin-top: 20px;
`;

const StatisticWeekWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  margin-top: 25px;
  padding: 10px 45px;
  background: rgba(38, 38, 46, 0.5);
  margin-bottom: 40px;
  @media (max-width: 768px) {
    padding: 10px 15px;
  }
`;

const StreakersEntering = styled.div`
  padding: 20px;
  background: rgba(38, 38, 46, 0.5);
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
  justify-content: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  font-weight: bold;
  text-transform: uppercase;
  color: #0bd855;
  img {
    margin-right: 10px;
  }
`;

const StatisticWeek = styled.div`
  text-align: center;
`;

const mapStateToProps = (state) => {
  return {
    weeksCollection: getResourceContestGameStatsWeeksCollection(state),
    isLoading: requestInProcess(state, { requestType: requestTypes.CONTEST_LEADER_DIAGRAM_STATS_FETCH }),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchContestLeaderDiagramStats: (params) => dispatch(actions.fetchContestLeaderDiagramStats(params)),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { fetchContestLeaderDiagramStats, match } = this.props;
      const { id } = match.params;

      fetchContestLeaderDiagramStats(id);
    },
  }),
);

DiagramStats.propTypes = propTypes;
DiagramStats.defaultProps = defaultProps;

// export default enhance(HomePage);
export default enhance(DiagramStats);
