// @flow

import React from 'react';
import styled from 'styled-components';

import PlayerSuccessCard from 'v2/components/PlayerSuccessCard';

type PlayerSuccessModalProps = {
  show: boolean,
  handleClick: () => void,
  contestId: number,
  playerId: number,
  categoryId: number,
  handleClose: () => void,
};

function PlayerSuccessModal({
  show,
  handleClick,
  contestId,
  playerId,
  categoryId,
  handleClose,
  ...rest
}: PlayerSuccessModalProps) {
  if (show) {
    return (
      <Container onClick={handleClose}>
        <PlayerSuccessCard
          {...rest}
          contestId={contestId}
          playerId={playerId}
          categoryId={categoryId}
          handleClick={handleClick}
          handleClose={handleClose}
        />
      </Container>
    );
  }

  return null;
}

export default React.memo(PlayerSuccessModal);

// styles

const Container = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  /* padding-top: 65px; */
  padding: 20px 10px;
  overflow-y: scroll;
  justify-content: center;
  backdrop-filter: blur(3px);
`;
