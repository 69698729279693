import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { ROOT_PATH, getPath } from 'v2/constants/router';
import * as actions from 'v2/actions';
import { ModalLoader } from 'v2/components/shared';

type PaymentScreenProps = {
  match: {
    params: { contestId: number },
  },
};

function PaymentScreen({
  match: {
    params: { contestId },
  },
}: PaymentScreenProps) {
  const [statusMsg, setStatusMsg] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.checkPaymentStatus(contestId, setStatusMsg));
  }, []);

  if (statusMsg) {
    return (
      <PaymentScreenWrapper>
        <PaymentStatusMsg>{statusMsg}</PaymentStatusMsg>
        <HomePageText>
          Go to <HomePageTab to={getPath(ROOT_PATH, {})}>Home Page</HomePageTab>
        </HomePageText>
      </PaymentScreenWrapper>
    );
  }

  return <ModalLoader isLoading isShowSpinner={false} text="Your transaction is processing" />;
}

export default PaymentScreen;

// styles

const PaymentStatusMsg = styled.h1``;

const HomePageText = styled.h2`
  text-align: center;
  font-size: 25px;
`;

const HomePageTab = styled(NavLink)`
  color: #5d5ce2;
`;

const PaymentScreenWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
