// @flow

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { isEmpty } from 'ramda';

import { getUserReferalCode, getUserFirstName } from 'v2/selectors/session';

import Title from '../Title';
import SubTitle from '../SubTitle';
import MyReferalCode from './shared/MyReferalCode';

import ReferalButtonsGroup from './shared/ReferalButtonsGroup';

type InviteFriendsFormProps = {
  userReferalCode: string,
  firstName: string,
};

function InviteFriendsForm({ userReferalCode, firstName }: InviteFriendsFormProps) {
  return (
    <div>
      <Title>{`${!isEmpty(firstName) ? `${firstName},` : ''} Invite your friends`}</Title>
      <SubTitle>
        Gone Streakin’ is the world’s first fantasy sports survivor game with awesome prizes such as once in a lifetime
        athlete experiences, cash prizes, exclusive merchandise, and much more.
      </SubTitle>
      <MyReferalCode referalCode={userReferalCode} />
      <ReferalButtonsGroup />
    </div>
  );
}

const mapStateToProps = (store) => ({
  userReferalCode: getUserReferalCode(store),
  firstName: getUserFirstName(store),
});

const enhance = compose(connect(mapStateToProps));

export default enhance(InviteFriendsForm);
