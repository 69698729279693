import {
  ACCOUNT_SETTINGS_PATH,
  getPath,
  SIGN_UP_PATH,
  SIGN_IN_PATH,
  FAVOURITE_TEAMS,
  SETUP_ACCOUNT_PATH,
  RESTORE_PASSWORD_PATH,
  CHANGE_PASSWORD_VIA_EMAIL_PATH,
  NOTIFICATIONS_PATH,
  DISMISS_NOTIFICATION_PATH,
  MY_ACCOUNT_PATH,
  ACCOUNT_EMAIL_PATH,
  FEEDBACK_PATH,
} from 'v2/constants/router';

import fetch from '../fetch';
import { postRequest, getRequest, patchRequest } from '../requests';

export default class SessionApiAdapter {
  static postSignInCredentials(body) {
    return fetch(SIGN_IN_PATH, postRequest({ body }));
  }

  static postSignUpCredentials(body) {
    return fetch(SIGN_UP_PATH, postRequest({ body }));
  }

  static fetchFavoriteTeams() {
    return fetch(FAVOURITE_TEAMS, getRequest());
  }

  static fetchMyAccount() {
    return fetch(MY_ACCOUNT_PATH, getRequest());
  }

  static setupAccount(body) {
    return fetch(SETUP_ACCOUNT_PATH, postRequest({ body }));
  }

  static sendEmailForRestorePassword(body) {
    return fetch(RESTORE_PASSWORD_PATH, postRequest({ body }));
  }

  static changePasswordViaEmail(body, id) {
    return fetch(getPath(CHANGE_PASSWORD_VIA_EMAIL_PATH, { id }), patchRequest({ body }));
  }

  static updateAccountData(body) {
    return fetch(ACCOUNT_SETTINGS_PATH, postRequest({ body }));
  }

  static updateAccountEmail(body) {
    return fetch(ACCOUNT_EMAIL_PATH, patchRequest({ body }));
  }

  static fetchNotifications() {
    return fetch(getPath(NOTIFICATIONS_PATH, {}), getRequest());
  }

  static deleteNotification(id) {
    return fetch(getPath(DISMISS_NOTIFICATION_PATH, { id }), patchRequest({ notification_id: id }));
  }

  static sendFeedback(body) {
    return fetch(FEEDBACK_PATH, postRequest({ body }));
  }
}
