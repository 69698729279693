import React from 'react';
import PhotoBlock from 'v2/components/PromoPages/shared/PhotoBlock';
import GsLogo from 'v2/components/shared/Image/promo/GS_logo.svg';

import BlockDescription from '../../shared/DescriptionBlock';

function block() {
  return (
    <PhotoBlock.Wrapper style={{ maxHeight: '150px' }}>
      <PhotoBlock src={GsLogo}>
        <BlockDescription size="large" style={{ fontSize: '21px', lineHeight: '25px' }} italic>
          UP CLOSE AND PERSONAL WITH THE{'\n'} BEST OF THE BEST.
        </BlockDescription>
      </PhotoBlock>
    </PhotoBlock.Wrapper>
  );
}

export default block;
