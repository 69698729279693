import React from 'react';
import styled from 'styled-components';
import BaseTextArea from '../../formBase/TextArea';

function ClassicInput({ append, ...props }) {
  if (append) {
    return (
      <InputContainer>
        <StyledInput {...props} />
        <Append>{append}</Append>
      </InputContainer>
    );
  }

  return <StyledInput {...props} />;
}

const Append = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-30%, -50%);
  background: #26262e;
`;

const InputContainer = styled.div`
  /* display: flex; */
  position: relative;
`;

const StyledInput = styled(BaseTextArea)`
  /* flex: 1; */
  width: 100%;
  background: #26262e;
  font-size: 14px;
  color: #fff;
  letter-spacing: 0.5px;
  border: 0;
  border-radius: 4px;
  transition: filter 0.2s linear;
  border: ${({ isError }) => isError && '1px solid #e80c51'};
  padding: ${({ isError }) => (isError ? '11px' : '12px')};
  cursor: pointer;

  &:focus {
    border: 1px solid #5d5ce2;
    padding: 11px;
    cursor: auto;
  }

  &::placeholder {
    color: #656579;
  }

  &:hover {
    filter: brightness(105%);
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #26262e inset !important;
    -webkit-text-fill-color: #fff !important;
    background-clip: content-box !important;
  }
`;

export default ClassicInput;
