import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import Drawer from './Drawer';

const propTypes = {};

const defaultProps = {};

function Menu() {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const showDrawer = () => {
    isOpen ? setIsOpen(false) : setIsOpen(true);
    if (!isOpen) {
      document.body.className = 'body_overflow_hidden';
    } else {
      document.body.className = 'body_overflow_show';
    }
  };

  return (
    <div onClick={showDrawer} ref={wrapperRef}>
      <StyledMenu>
        <Item className={isOpen ? 'toggle_menu-open' : ''} />
      </StyledMenu>
      <Drawer isOpen={isOpen} />
    </div>
  );

  function useOutsideAlerter(ref) {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    });
  }
}

const StyledMenu = styled.ul`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  padding: 10px 0;
  margin-right: 10px;
  cursor: pointer;
`;

const Item = styled.li`
  position: relative;
  width: 25px;
  display: block;
  height: 3px;
  background: #656579;
  &:before {
    position: absolute;
    content: '';
    height: 3px;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 6px;
    background-color: #656579;
    transition: top 0.1s 0.1s ease, transform 0.2s ease;
  }
  &:after {
    position: absolute;
    content: '';
    height: 3px;
    width: 100%;
    top: 6px;
    background-color: #656579;
    transition: top 0.1s 0.1s ease, transform 0.2s ease;
  }
`;

Menu.propTypes = propTypes;
Menu.defaultProps = defaultProps;

// export default enhance(HomePage);
export default Menu;
