import React, { useState, forwardRef } from 'react';
import { not, prop, or } from 'ramda';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import closeIcon from 'v2/components/Contests/Header/icons/close.svg';
import HOCWrapper from './Wrapper';

import MakePicksHelp from 'v2/components/Contests/MakePicksHelp';

type FAQModalProps = {
  contest?: any,
};

const defaultProps = {
  contest: null,
};

const FAQModal = forwardRef(({ contest, onClose, onClick }: FAQModalProps, ref) => {
  return (
    <Container ref={ref}>
      <MakePicksHelp contest={contest} onClick={onClick} />
    </Container>
  );
});

FAQModal.defaultProps = defaultProps;

FAQModal.HOCWrapper = HOCWrapper;
export default FAQModal;

// styled
const ButtonLink = styled(NavLink)`
  background: ${({ theme }) => theme.colors.purpleAccent};
  border-radius: 6px;
  padding: 11px;
  width: 100%;
  max-width: 360px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
`;

const CloseIcon = styled.img`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-100%, -75%);
`;

const Container = styled.div`
  position: relative;
  width: 100%;
`;
