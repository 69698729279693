import React, { Fragment } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';

import { getPath, MY_GAMES_LEAGUE_PATH, MY_GAMES } from 'v2/constants/router';
import useWindowSize from 'v2/services/useWindowSize';
import { ButtonsData } from 'v2/constants/UI/buttons';
import Button from 'v2/components/HomePage/shared/AvailableGames/Button';
import LeagueTabs from 'v2/components/HomePage/shared/AvailableGames/NavigationPanel/LeagueTabs/index';
import Tooltip from 'v2/components/HomePage/shared/AvailableGames/NavigationPanel/Tooltip';

import StatusGameNavigation from '../StatusGameNavigation';

function NavigationPanel({ match: { params }, location: { search }, showTitle, kind }) {
  const [documentWidth] = useWindowSize();

  const isMobile = documentWidth < 560;

  const getActiveStatusByLeague = (league, defaultActive = false) => {
    if (params.league) {
      return params.league === league;
    }

    return defaultActive;
  };

  return (
    <MainGamesFilter>
      <MainButtonGroup>
        {ButtonsData.filter((item) => (isMobile ? true : !item.inactive)).map(
          ({ alt, id, title, logo, league, inactive, activeByDefault }) => (
            <ButtonContainer key={id}>
              {inactive ? (
                <Tooltip message="Coming soon">
                  <Button
                    isLink
                    logo={logo}
                    alt={alt}
                    title={title}
                    activeStatus={getActiveStatusByLeague(league, activeByDefault)}
                    hrefTo={
                      !inactive &&
                      (league ? `${getPath(MY_GAMES_LEAGUE_PATH, { league })}${search}` : `${MY_GAMES}${search}`)
                    }
                    disabled={inactive}
                  />
                </Tooltip>
              ) : (
                <Button
                  isLink
                  logo={logo}
                  alt={alt}
                  title={title}
                  activeStatus={getActiveStatusByLeague(league, activeByDefault)}
                  hrefTo={
                    !inactive &&
                    (league ? `${getPath(MY_GAMES_LEAGUE_PATH, { league })}${search}` : `${MY_GAMES}${search}`)
                  }
                  disabled={inactive}
                />
              )}
            </ButtonContainer>
          ),
        )}
      </MainButtonGroup>
      {/* <Separator /> */}
      <LeagueTabs showTitle={showTitle} kind={kind} />
      <StatusGameNavigation />
    </MainGamesFilter>
  );
}

const Separator = styled.div`
  display: block;
  width: 100vw;
  height: 1px;
  background-color: #26262e;
  bottom: 0;
  margin-top: 20px;
  margin-bottom: 15px;
`;

const MainButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;

  @media (max-width: 425px) {
    padding-top: 70px;
  }
`;

const MainGamesFilter = styled.div`
  display: none;
  @media (max-width: 425px) {
    display: block;
    margin-bottom: 0.75em;
  }
`;

const ButtonContainer = styled.div`
  margin-left: 0.45em;

  &:first-child {
    margin-left: 0;
  }
`;

export default withRouter(NavigationPanel);
