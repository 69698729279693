import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import avatar1 from '../Image/avatar1.png';
import avatar2 from '../Image/avatar2.png';
import avatar3 from '../Image/avatar3.png';
import avatar4 from '../Image/avatar4.png';
import { avatarBlockStyles, avatarBlockLeftStyles } from './styles';

const propTypes = {
  isSubmitAll: PropTypes.bool,
};

const defaultProps = {
  isSubmitAll: false,
};

function SurviveAvatars(props) {
  const { isSubmitAll, currentWeek } = props;
  return (
    <Fragment>
      <StyledAvatarBlockLeft img={!isSubmitAll && currentWeek > 1 ? avatar4 : avatar3} />
      <StyledAvatarBlock img={isSubmitAll ? avatar1 : avatar4} main />
    </Fragment>
  );
}

const StyledAvatarBlock = styled.div`
  ${avatarBlockStyles}
`;

const StyledAvatarBlockLeft = styled(StyledAvatarBlock)`
  ${avatarBlockLeftStyles}
`;

SurviveAvatars.propTypes = propTypes;
SurviveAvatars.defaultProps = defaultProps;

export default SurviveAvatars;
