/* eslint-disable */
import { equals } from 'ramda';

import {
  NOTIFICATIONS_FETCH_SUCCESS,
  UPDATE_NOTIFICATION_ACTIVE_STATUS,
  RECORD_NOTIFICATION_STATUS,
  MY_ACCOUNT_FETCH_SUCCESS,
} from 'v2/constants/actionTypes';
import { initialState } from '../../selectors/session';

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case MY_ACCOUNT_FETCH_SUCCESS:
      return { ...state, user: payload };

    case NOTIFICATIONS_FETCH_SUCCESS:
      if (!equals(state.notifications, payload)) return { ...state, notifications: payload };

    case UPDATE_NOTIFICATION_ACTIVE_STATUS:
      return {
        ...state,
        notifications: state.notifications.filter((notification) => notification.id !== payload),
      };

    default:
      return state;
  }
};
