export default {
  colors: {
    getColorByKind: (kind) => (kind == 'survive' ? '#5d5ce2' : '#0bd855'),
    purpleAccent: '#5d5ce2',
    greenAccent: '#0bd855',
    redAccent: '#e1073a',
    white: '#fff',
    gray: '#26262e',
    fg: '#26262e',
    dark: '#131317',
    lighGray: '#1b1b21',
    lightenGray: '#656579',
  },
  dropDown: {
    sizes: {
      normal: {
        font: '16px',
        img: '1em',
      },
    },
    colors: {
      label: '#fff',
      item: '#fff',
      activeBackground: '#656579',
    },
  },
  gameCard: {
    headshot: {
      avatarSize: {
        extraLarge: '70px',
        large: '60px',
        normal: '50px',
        small: '40px',
      },
    },
    noGames: {
      textSize: {
        large: '23px',
        normal: '20px',
        small: '18px',
      },
    },
  },
  fonts: ['sans-serif', 'Roboto'],
};
