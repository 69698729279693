import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import StatCategory from './shared/StatCategory';
// const propTypes = {
//   gamesCollection: PropTypes.arrayOf(PropTypes.shape({})),
//   session: PropTypes.shape({}).isRequired,
// };

// const defaultProps = {
//   gamesCollection: [],
// };

const settings = {
  infinite: true,
  slidesToShow: 1,
  rows: 1,
  dots: false,
  arrows: false,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3500,
  speed: 800,
  easing: 'easeInOutBack',
  vertical: true,
  swipeToSlide: false,
  draggable: false,
  // responsive: [
  //   {
  //     breakpoint: 768,
  //     settings: { slidesToShow: 1, variableWidth: false, centerPadding: '0px', dots: true, unslick: false },
  //   },
  // ],
};

function StatCategoriesSlider({ statCategories, league }) {
  return (
    <StatCategoriesContainer>
      <Slider {...settings}>
        {statCategories.map((statCategory, index) => (
          <StatCategory
            key={statCategory.id}
            index={index + 1}
            summary={statCategories.length}
            {...statCategory}
            league={league}
          />
        ))}
      </Slider>
    </StatCategoriesContainer>
  );
}

// const StatCategoriesTitle = styled.span`
//   display: inline-block;
//   /* margin-bottom: 8px; */
// `;

const StatCategoriesContainer = styled.div`
  position: relative;
  border-radius: 8px;
  border: 1px solid #26262e;
  padding: 8px 10px 5px;
  max-height: 75px;
  overflow-y: hidden;
  min-height: 70px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;

  .slick-track {
    display: block !important;
  }

  .slick-list {
    min-height: fit-content;
    @media (min-width: 768px) {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
`;

export default StatCategoriesSlider;
