import { normalize, schema } from 'normalizr';

export const game = new schema.Entity('games');

export const player = new schema.Entity('players');

export const selectedPlayer = new schema.Entity('selectedPlayers');

export const players = [player];

export const selectedPlayers = [selectedPlayer];

export const statCategory = new schema.Entity('statCategories', {
  players,
  selectedPlayers,
});
export const statCategories = [statCategory];

export const prize = new schema.Entity('prizes');
export const prizes = [prize];

export const contest = new schema.Entity('contests', {
  statCategories,
  prizes,
});
export const contests = [contest];

export const playersNormalizr = (data) => normalize(Array.isArray(data) ? data : [data], players);

export const contestNormalizr = (data) => normalize(data, contest);
export const contestsNormalizr = (data) => normalize(Array.isArray(data) ? data : [data], contests);

export const statCategoryNormalizr = (data) => normalize(data, statCategory);
export const statCategoriesNormalizr = (data) => normalize(Array.isArray(data) ? data : [data], statCategories);
