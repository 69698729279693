import qs from 'qs';
import {
  always,
  evolve,
  fromPairs,
  identity,
  init,
  isNil,
  join,
  last,
  map,
  pipe,
  reject,
  split,
  toPairs,
  unless,
} from 'ramda';

import { isNotDefined, isDefined } from 'v2/helpers/ramda';

import { camelizeKeys, decamelizeKeys } from './humps';

function booleanDecoder(value, decoder) {
  switch (value) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return decoder(value);
  }
}

const baseParseOptions = {
  encode: false,
  ignoreQueryPrefix: true,
  arrayFormat: 'brackets',
  skipNulls: true,
  decoder: booleanDecoder,
};

const baseStringifyOptions = {
  encode: false,
  arrayFormat: 'brackets',
  skipNulls: true,
  addQueryPrefix: true,
};

export function stringifyParams(params, options = {}) {
  return qs.stringify(params, {
    ...baseStringifyOptions,
    ...options,
  });
}

export function stringifyParamsForGetRequest(params) {
  return stringifyParams(params, { addQueryPrefix: false });
}

export function parseSearchParams(queryString, options = {}) {
  return qs.parse(queryString, { ...baseParseOptions, ...options });
}

export function setParams(params) {
  return pipe(
    reject(isNotDefined),
    decamelizeKeys,
    stringifyParams,
    unless(isDefined, always(window.location.pathname)),
    (queryString) => window.history.replaceState({}, '', queryString),
  )(params);
}

export function getParams(options) {
  return pipe(
    () => window.location.search,
    (q) => parseSearchParams(q, options),
    camelizeKeys,
  )();
}

export function parseOrders(orders = []) {
  return pipe(
    map(
      pipe(split('_'), (arr) => [
        join('_', init(arr)),
        last(arr) === 'asc',
      ]),
    ),
    fromPairs,
    camelizeKeys,
  )(orders);
}

export function preStringifyOrders(orders = {}) {
  return pipe(
    reject(isNil),
    decamelizeKeys,
    toPairs,
    map(evolve([identity, (ord) => (ord ? 'asc' : 'desc')])),
    map(join('_')),
  )(orders);
}
