// import { notificationTypes } from 'v2/types';
import sendNotificationsByTimeOut from './sendNotificationsByTimeOut';

// type ToastsContainerProps = {
//   notifications: Array<{ ...notificationTypes }>,
//   fetchNotifications: () => any,
// };

// fixme
function sendPackNotifications(props) {
  if (props.notifications.length > 0) {
    return sendNotificationsByTimeOut({ ...props });
  }

  props.fetchNotifications();
  return sendPackNotifications({ ...props });
}

export default sendPackNotifications;
