import React from 'react';
import styled from 'styled-components';
import LogoImage from '../../shared/Image';
import star from '../../shared/Image/star.svg';
import avatar from '../../shared/Image/avatar.svg';

const propTypes = {};

const defaultProps = {
  grandPrize: {
    description: '',
  },
};

const countPrize = 2;

function OtherPrize(props) {
  const { otherPrizes } = props;
  return (
    <StyledPrize>
      {otherPrizes.slice(0, countPrize).map((prize, id) => (
        <Prize key={id}>
          <Status>
            {prize.priority}
            {prize.priority && prize.priority === 1 ? 'st' : 'nd'} place
          </Status>
          <PrizeText>{prize.description}</PrizeText>
        </Prize>
      ))}
    </StyledPrize>
  );
}

const StyledPrize = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Prize = styled.div`
  width: 48%;
  padding: 10px;
  border: 1px solid #26262e;
  border-radius: 8px;
  box-sizing: border-box;
`;

const PrizeText = styled.span`
  font-size: 14px;
  line-height: 18px;
  margin-top: 12px;
  color: #fff;
  letter-spacing: 0.5px;
  display: block;
`;

const Status = styled.span`
  display: flex;
  margin-bottom: -4px;
`;

OtherPrize.propTypes = propTypes;
OtherPrize.defaultProps = defaultProps;

// export default enhance(HomePage);
export default OtherPrize;
