// @flow

import * as React from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import pickemLogo from '../img/new_pickem.svg';
import survivorLogo from '../img/new_survivor.svg';
import bracketLogo from '../img/bracket.svg';
import streakLogo from '../img/streak.svg';

type leagueTypes = 'survive' | 'weekly' | 'bracket' | 'streak';

type LeagueTabProps = {
  kind: leagueTypes,
  inactive?: boolean,
  activeKind: string,
};

const defaultProps = {
  inactive: false,
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const getImage = (kind: leagueTypes) => {
  if (kind === 'weekly') {
    return <TabImage src={pickemLogo} alt="pick em" />;
  }

  if (kind === 'survive') {
    return <TabImage src={survivorLogo} alt="survive" />;
  }

  if (kind === 'bracket') {
    return <TabImage src={bracketLogo} alt="survive" />;
  }

  if (kind === 'streak') {
    return <TabImage src={streakLogo} alt="survive" />;
  }
};

function LeagueTab({ kind, inactive, activeKind }: LeagueTabProps) {
  const query = useQuery();

  const currentStatus = query.get('status');

  const htefTo = currentStatus ? `?status=${currentStatus}&` : '?';

  return (
    <Tab to={`${htefTo}kind=${kind}`}>
      <TabImageContainer isActive={activeKind === kind} kind={kind} inactive={inactive}>
        {getImage(kind)}
      </TabImageContainer>
    </Tab>
  );
}

const TabImage = styled.img`
  width: 9em;
`;

const TabImageContainer = styled.div`
  padding: 0.6em 1.1em;
  display: inline-flex;
  transition: filter 0.25s ease, opacity 0.5s ease;
  height: 79px;
  background: #26262e;
  border-radius: 6px;
  border: 1px solid #26262e;
  opacity: ${({ isActive }) => (isActive ? '1' : '0.65')};
  border-color: ${({ isActive, kind }) => {
    if (isActive) {
      if (kind === 'weekly') {
        return '#0bd855';
      }

      if (kind === 'survive') {
        return '#5d5ce2';
      }

      if (kind === 'bracket') {
        return '#00B3FF';
      }

      if (kind === 'streak') {
        return '#E1073A';
      }
    }
  }};
  filter: ${({ inactive }) => inactive && 'grayscale(1)'};

  &:hover {
    filter: brightness(1.15);
  }
`;

const Tab = styled(NavLink)`
  font-size: 14px;
  margin-right: 1em;

  &:last-child {
    margin-right: 0;
  }
`;
LeagueTab.defaultProps = defaultProps;
export default LeagueTab;
