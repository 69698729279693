import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React, { Fragment, useState, useEffect } from 'react';
import Slider from 'react-slick';
import { equals, sortWith, ascend, path, prop, addIndex, splitWhen } from 'ramda';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getOrderedStatCategoriesList } from 'v2/selectors/statCategories';

import americanFootballIcon from '../Image/american-football.svg';
import { statusStyles, prizesBlockStyles, prizeBlockStyles } from './styles';

import StatCategoriesSlider from './StatCategoriesSlider';

import SurviveStatCategories from './shared/SurviveStatCategories';
import NewCategories from './shared/NewCategories';

const propTypes = {
  kind: PropTypes.string,
  statCategoriesList: PropTypes.arrayOf(PropTypes.shape({})),
  otherPrizes: PropTypes.arrayOf(PropTypes.shape({})),
};

const defaultProps = {
  kind: '',
  statCategoriesList: [],
  otherPrizes: [],
  showHeadshots: true,
};

function Prizes(props) {
  const settings = {
    infinite: false,
    slidesToShow: 1,
    rows: 1,
    dots: false,
    arrows: true,
    slidesToScroll: 1,
    // easing: 'easeInOutBack',
    swipeToSlide: false,
    draggable: false,
    swipe: false,
    nextArrow: <Arrow next />,
    prevArrow: <Arrow prev />,
  };

  const {
    kind,
    otherPrizes,
    statCategoriesList,
    statCategoriesWithPlayers,
    league,
    statCategories,
    id,
    selectionsRequired,
    showHeadshots,
    currentWeek,
    isContestActive,
    isJoin,
  } = props;
  // fixme

  const sortByOrder = sortWith([ascend(prop('order'))]);

  if (kind == 'weekly' && !showHeadshots) {
    return <StatCategoriesSlider statCategories={sortByOrder(statCategoriesList)} league={league} />;
  }

  return (
    <Fragment>
      {/* <StyledPrizeTitle>STAT OBJECTIVES</StyledPrizeTitle> */}
      {selectionsRequired && (
        <NewCategories
          contestId={id}
          kind={kind}
          league={league}
          currentWeek={currentWeek}
          selectionsRequired={selectionsRequired}
          statCategories={sortByOrder(statCategoriesWithPlayers)}
          showHeadshots={showHeadshots}
          weekOrder={path(['urlContest', 'weekOrder'], props)}
          isContestActive={isContestActive}
        />
      )}
    </Fragment>
  );
}

const WeeklySliderContainer = styled.div`
  position: relative;
  max-width: 100%;
`;

const StyledPrizes = styled.div`
  ${prizesBlockStyles}
  justify-content: center;
`;

const StyledPrizeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const StyledPrizeText = styled.span`
  font-size: 20px;
  line-height: 18px;
  color: #fff;
  letter-spacing: 0.5px;
  display: block;
`;

const StyledPrizeTextWeekly = styled(StyledPrizeText)`
  font-size: 14px;
`;

const Arrow = styled.div`
  position: absolute;
  height: 100%;
  left: ${({ prev }) => prev && '-8px'};
  right: ${({ next }) => next && '-7px'};
  top: 52%;
  z-index: 9;

  &:before {
    opacity: 1;
    line-height: 1;
    z-index: 1;
    display: flex;
    width: 75px;
    height: 75px;
    align-items: center;
    justify-content: flex-start;
  }
`;

Prizes.propTypes = propTypes;
Prizes.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => ({
  statCategoriesList: getOrderedStatCategoriesList(state, { statCategoryIds: ownProps.statCategories }),
});

export default connect(mapStateToProps)(React.memo(Prizes));
