// @flow

import React from 'react';
import styled from 'styled-components';

import BaseRadioButton from '../../formBase/RadioButton';

type RadioButtonProps = {
  children: string,
  className: string,
  name: string,
  isError: boolean,
};

function RadioButton({ className, children, name, isError, ...props }: RadioButtonProps) {
  return (
    <RadioButtonContainer isError={isError}>
      <HideBaseRadioButton name={name} {...props} />
      <StyledRadioButton active={props.checked} isError={isError} />
      <RadioButtonTitle>{children}</RadioButtonTitle>
    </RadioButtonContainer>
  );
}

const HideBaseRadioButton = styled(BaseRadioButton)`
  position: absolute;
  appearance: none;
  cursor: pointer;
`;

const StyledRadioButton = styled.div`
  display: block;
  height: 20px;
  width: 20px;
  background: #1b1b21;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 2s;

  &:before {
    content: '';
    display: none;
    display: ${({ active }) => active && 'block'};
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: #5d5ce2;
  }
`;

const RadioButtonTitle = styled.span`
  margin-left: 10px;
`;

const RadioButtonContainer = styled.label`
  display: flex;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;

  &:hover {
    filter: brightness(105%);
  }

  /* background: ${({ active }) => (active ? '#5d5ce2' : '#1b1b21')}; */
`;

export default RadioButton;
