// Entities
export const ENTITIES_DELETE = 'ENTITIES_DELETE';
export const ENTITIES_MERGE = 'ENTITIES_MERGE';
export const ENTITIES_SYNC = 'ENTITIES_SYNC';

export const SIGN_OUT_FETCH_SUCCESS = 'SIGN_OUT_FETCH_SUCCESS';

export const NOTIFICATIONS_FETCH_SUCCESS = 'NOTIFICATIONS_FETCH_SUCCESS';
export const DELETE_NOTIFICATION_BY_ID = 'DELETE_NOTIFICATION_BY_ID';
export const UPDATE_NOTIFICATION_ACTIVE_STATUS = 'UPDATE_NOTIFICATION_ACTIVE_STATUS';

export const DISMISS_NOTIFICATION_FETCH_SUCCESS = 'DISMISS_NOTIFICATION_FETCH_SUCCESS';

export const REFS_FETCH_SUCCESS = 'REFS_FETCH_SUCCESS';
export const REFS_MERGE = 'REFS_MERGE';

export const CONTESTS_AVAILABLE_FETCH_SUCCESS = 'CONTESTS_AVAILABLE_FETCH_SUCCESS';
export const CONTEST_MAKE_PICKS_FETCH_SUCCESS = 'CONTEST_MAKE_PICKS_FETCH_SUCCESS';

export const CONTEST_LEADER_PARTICIPATIONS_FETCH_SUCCESS = 'CONTEST_LEADER_PARTICIPATIONS_FETCH_SUCCESS';
export const CONTEST_LEADER_DIAGRAM_STATS_FETCH_SUCCESS = 'CONTEST_LEADER_DIAGRAM_STATS_FETCH_SUCCESS';
export const CONTEST_BREACKDOWN_OVERALL_FETCH_SUCCESS = 'CONTEST_BREACKDOWN_OVERALL_FETCH_SUCCESS';
export const CONTEST_BREAKDOWN_WEEK_PICK_FETCH_SUCCESS = 'CONTEST_BREAKDOWN_WEEK_PICK_FETCH_SUCCESS';

export const SUCCESS_PLAYER_INFO_FETCH_SUCCESS = 'SUCCESS_PLAYER_INFO_FETCH_SUCCESS';

export const CONTEST_FETCH_SUCCESS = 'CONTEST_FETCH_SUCCESS';
export const CONTEST_RESET = 'CONTEST_RESET';
export const TOGGLE_PLAYERS_SUCCESS = 'TOGGLE_PLAYERS_SUCCESS';

export const MY_ACCOUNT_FETCH_SUCCESS = 'MY_ACCOUNT_FETCH_SUCCESS';
export const MY_GAMES_ACTIVE_FETCH_SUCCESS = 'MY_GAMES_ACTIVE_FETCH_SUCCESS';
export const MY_GAMES_RECENT_FETCH_SUCCESS = 'MY_GAMES_RECENT_FETCH_SUCCESS';
export const GAMES_COUNT_FETCH_SUCCESS = 'GAMES_COUNT_FETCH_SUCCESS';

// Request in progress
export const REQUEST_IN_PROCESS_SET = 'REQUEST_IN_PROCESS_SET';
