import { createSelector } from 'reselect';
import { identity } from 'ramda';

export const initialState = {
  user: undefined,
  isAuthorized: false,
  notifications: [],
};

function sessionSelector(state) {
  return state.session || initialState;
}

export const getSession = createSelector(sessionSelector, identity);

export const getUser = createSelector(getSession, (session) => session.user);

export const getUserFirstName = createSelector(getUser, (user) => user.firstName);

export const getUserReferalCode = createSelector(getUser, (user) => user.ownReferalCode);

export const getUserEmail = createSelector(getUser, (user) => user.email);

export const getUserFullName = createSelector(getUser, (user) =>
  user.firstName ? `${user.firstName} ${user.lastName}` : '',
);

export const getUserStatusAcceptedTermsOfService = createSelector(
  getSession,
  // (user) => !!user.phone_number,
  (session) => session.isAuthorized && !!session.user.phoneNumber,
);

export const isUserStatusAcceptedTermsOfService = createSelector(
  getSession,
  // (user) => !!user.phone_number,
  (session) => (session.isAuthorized ? !!session.user.phone_number || !!session.user.phoneNumber : false),
);

export const isAuthorized = createSelector(getSession, (session) => session.isAuthorized);

// notifications

export const getAllNotifications = createSelector(getSession, (session) => session.notifications || []);
