import { useMemo } from 'react';

function useFormGroupAdapter(props) {
  const {
    children,
    className,
    help,
    id,
    input,
    inputComponent,
    label,
    meta,
    placeholder,
    render,
    required,
    type,
    ...rest
  } = useMemo(() => props, [props]);

  const { error, touched, submitError, dirtySinceLastSubmit } = useMemo(() => meta, [meta]);

  const invalid = useMemo(() => !!((error && touched) || (submitError && !dirtySinceLastSubmit)), [
    dirtySinceLastSubmit,
    submitError,
    touched,
    error,
  ]);

  const inputProps = useMemo(
    () => ({
      children,
      id: id || input.name,
      placeholder,
      ...input,
      ...rest,
    }),
    [invalid, input, placeholder],
  );

  const formGroupProps = useMemo(
    () => ({
      className,
      component: inputComponent,
      error: error || submitError,
      help,
      invalid,
      label,
      name: input.name,
      required,
    }),
    [invalid, input.name, error, submitError, label, help, submitError, className, required],
  );

  return [formGroupProps, inputProps];
}

export default useFormGroupAdapter;
