// @flow

import React from 'react';
import styled from 'styled-components';
import { identity, compose, prop, props, join, split } from 'ramda';

import { getCategiryIcon } from './helpers';

import CategoryItemsContainer from './Container';

type CategoryItemProps = {
  onClick?: () => any,
};

const defaultProps = {
  onClick: identity,
};

function CategoryItem({ onClick, league, isActive, ...statCategory }: CategoryItemProps) {
  const categoryImg = compose(getCategiryIcon(league), prop('subject'))(statCategory);
  // const categoryTitle = compose(join(' '), props(['subject', 'amount']))(statCategory);
  const categoryTitle = compose(join('\n'), split(' '), prop('subject'))(statCategory);
  const categoryAmount = prop('amount', statCategory);
  const clickHandler = () => onClick(statCategory);

  return (
    <Container onClick={clickHandler} isActive={isActive}>
      <CategoryTitle>{categoryTitle}</CategoryTitle>
      <CategoryAmount>{categoryAmount}</CategoryAmount>
      <CategoryImg src={categoryImg} alt={categoryTitle} />
    </Container>
  );
}

CategoryItem.defaultProps = defaultProps;
CategoryItem.Container = CategoryItemsContainer;
export default CategoryItem;

// styles
const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-radius: 6px;
  padding: 8px;
  position: relative;
  width: 100%;
  min-width: 105px;
  opacity: 0.5;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 75px;
  height: 100%;
  transition: opacity 0.5;

  opacity: ${({ isActive }) => isActive && 1};
  border-color: ${({ isActive, theme }) => isActive && theme.colors.greenAccent};
`;

const CategoryImg = styled.img`
  width: 35px;
  position: absolute;
  bottom: 50%;
  right: 0;
  transform: translate(-25%, 75%);
`;

const CategoryTitle = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.5px;
  white-space: pre;
`;

const CategoryAmount = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0px;
`;
