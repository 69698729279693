// @flow
import React from 'react';
import styled from 'styled-components';

type LoaderProps = {
  minHeight?: String,
};

const defaultProps = {
  minHeight: '200px',
};

function Loader({ minHeight }: LoaderProps) {
  return (
    <LoaderContainer minHeight={minHeight}>
      <LoaderFragments>
        <LoaderFragment />
        <LoaderFragment />
        <LoaderFragment />
      </LoaderFragments>
    </LoaderContainer>
  );
}
const LoaderFragment = styled.div`
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #fff;
  opacity: 0.3;
  animation: loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;

  &:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  &:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }

  &:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes loader {
    0% {
      top: 8px;
      height: 64px;
    }
    50%,
    100% {
      top: 24px;
      height: 32px;
    }
  }
`;

const LoaderFragments = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${({ minHeight }) => minHeight};
`;

Loader.defaultProps = defaultProps;
export default Loader;
