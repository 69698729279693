import React from 'react';
import styled from 'styled-components';

import { detectIE } from 'v2/helpers/common';

export default (WrappedComponent) => (props) => {
  const isIE = detectIE();

  if (isIE) {
    return (
      <Container>
        <ModalContainer>
          <Title>Warning</Title>
          <Subtitle>This browser version is too outdated. Please update</Subtitle>
        </ModalContainer>
      </Container>
    );
  }

  return <WrappedComponent {...props} />;
};

// styles
const Container = styled.div``;

const ModalContainer = styled.div`
  width: 500px;
  background: ${({ theme }) => theme.colors.fg};
  padding: 50px;
  margin: auto;
  margin-top: 50px;
  border-radius: 8px;
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.redAccent};
  line-height: 1;
  padding: 0;
  margin: 0;
  margin-bottom: 15px;
  text-align: center;
`;

const Subtitle = styled.span`
  text-align: center;
  width: 100%;
  color: #fff;
  opacity: 0.85;
`;
