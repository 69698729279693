// @flow

import React from 'react';
import styled from 'styled-components';

import closeIcon from 'v2/components/Contests/Header/icons/close.svg';
type HeaderProps = {
  categoryName?: string,
  handleClose?: () => void,
};

const defaultProps = {
  categoryName: 'N/A',
  handleClose: null,
};

function Header({ categoryName, handleClose }: HeaderProps) {
  return (
    <Container>
      <CategoryName>{categoryName}</CategoryName>
      {handleClose && <CloseIcon src={closeIcon} alt="close" onClick={handleClose} />}
    </Container>
  );
}

Header.defaultProps = defaultProps;
export default Header;

// styles
const CloseIcon = styled.img`
  position: absolute;
  right: 15px;
`;

const CategoryName = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 18px;
  font-weight: bold;
  white-space: break-spaces;
`;

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.gray};
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  /* border-top-left-radius: 8px;
  border-top-right-radius: 8px; */
`;
