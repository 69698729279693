import { curry, compose, prop, equals, not, indexOf, split, without, join } from 'ramda';

export const secondsToHms = (d) => {
  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' h ') : '';
  const mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' min') : '';
  // const sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';
  return hDisplay + mDisplay;
};

export const toHHMMSS = curry(({ isAmply, hideSeconds }, s) => {
  var secNum = parseInt(s, 10);
  var hours = Math.floor(secNum / 3600);
  var minutes = Math.floor((secNum - hours * 3600) / 60);
  var seconds = secNum - hours * 3600 - minutes * 60;

  if (hours < 10) hours = hours;
  if (minutes < 10) minutes = minutes;
  if (seconds < 10) seconds = seconds;

  const pretty = (d, f) => (Number(d) ? d + f : '');
  if (isAmply) {
    if (hideSeconds) return `${pretty(hours, ' h')} ${pretty(minutes, ' min')}`;
    return `${pretty(hours, ' h')} ${pretty(minutes, ' min')} ${pretty(seconds, ' s')}`;
  }

  if (hideSeconds) return hours + ':' + minutes;
  return hours + ':' + minutes + ':' + seconds;
});

// export const addclass = (el) => {
//   el.className += ' ' + 'default_slide';
// };

export const copyToClipboard = (text, cb) => {
  return () => {
    const textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();

    if (cb) cb();
  };
};

export const removeclass = curry((className, el) => {
  const hasClassName = compose(not, equals(-1), indexOf(className), split(' '), prop('className'));
  if (hasClassName(el)) {
    el.className = compose(join(' '), without(className), split(' '), prop('className'))(el);
  }
});

export const addclass = curry((className, el) => {
  const hasNotClassName = compose(equals(-1), indexOf(className), split(' '), prop('className'));
  if (hasNotClassName(el)) {
    el.className += ' ' + className;
  }
});

export const placeSuffix = (i) => {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + 'st';
  }
  if (j == 2 && k != 12) {
    return i + 'nd';
  }
  if (j == 3 && k != 13) {
    return i + 'rd';
  }
  return i + 'th';
};

export function detectIE() {
  const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
  const msie = ua.indexOf('MSIE '); // IE 10 or older
  const trident = ua.indexOf('Trident/'); //IE 11

  return msie > 0 || trident > 0;
}
