import React from 'react';
import styled from 'styled-components';

const propTypes = {};

const defaultProps = {};

function StatisticItem(props) {
  const { title, text, className } = props;
  return (
    <StyledStatisticItem>
      <span>{title}</span>
      <StatisticItemText className={className}>{text}</StatisticItemText>
    </StyledStatisticItem>
  );
}

const StyledStatisticItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
`;

const StatisticItemText = styled.h3`
  margin-top: 2px;
  white-space: nowrap;
  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

StatisticItem.propTypes = propTypes;
StatisticItem.defaultProps = defaultProps;

// export default enhance(HomePage);
export default StatisticItem;
