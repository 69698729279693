/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';

const propTypes = {};

const defaultProps = {};

function Paragraph(props) {
  const { text } = props;
  return <StyledParagraph>{text}</StyledParagraph>;
}

const StyledParagraph = styled.p`
  text-align: center;
  padding-top: 6px;
  margin-top: -7px;
  text-transform: uppercase;
`;

Paragraph.propTypes = propTypes;
Paragraph.defaultProps = defaultProps;

// export default enhance(HomePage);
export default Paragraph;
