import React from 'react';
import styled from 'styled-components';
import { findIndex, find, propEq, equals, contains, ifElse } from 'ramda';
import { withRouter } from 'react-router';
import { getPath, CONTEST_CATEGORY_PATH, CONTEST_BREAKDOWN_CATEGORY_PATH } from 'v2/constants/router';

function Arrow(props) {
  const {
    className,
    style,
    onClick,
    history,
    prevUrl,
    nextUrl,
    categories,
    next,
    prev,
    match: {
      params: { categoryId, id, weekOrder },
    },
    location: { pathname },
  } = props;

  const url = ifElse(
    contains('pick_breakdown'),
    () => CONTEST_BREAKDOWN_CATEGORY_PATH,
    () => CONTEST_CATEGORY_PATH,
  )(pathname);

  const findCategory = (category) => findIndex((spec) => equals(spec, category))(categories);

  const getCategoryByIndex = (i) => categories[i];

  const getNextIndex = (category) => {
    const nextIndex = findCategory(category) + 1;

    if (nextIndex > categories.length - 1) {
      return 0;
    }

    return nextIndex;
  };

  const getPrevIndex = (category) => {
    const prevIndex = findCategory(category) - 1;

    if (prevIndex < 0) {
      return categories.length - 1;
    }

    return prevIndex;
  };

  const getNextCategory = () =>
    getCategoryByIndex(getNextIndex(find((category) => category.id == categoryId)(categories)));

  const getPrevCategory = () =>
    getCategoryByIndex(getPrevIndex(find((category) => category.id == categoryId)(categories)));

  const navToCategory = ({ id: categoryId }) => history.push(getPath(url, { id, weekOrder, categoryId }));

  // const navToCategory = (categoryId) =>

  if (prev) {
    return <ArrowContainer className={className} onClick={() => navToCategory(getPrevCategory())} prev />;
  }

  if (next) {
    return <ArrowContainer className={className} onClick={() => navToCategory(getNextCategory())} next />;
  }

  return null;
}

export default withRouter(Arrow);

const ArrowContainer = styled.div`
  position: absolute;
  height: 100%;
  left: ${({ prev }) => prev && '-5px'};
  right: ${({ next }) => next && '-5px'};
  top: 55%;
  z-index: 8;

  &:before {
    opacity: 1;
    line-height: 1;
    z-index: 7;
    display: flex;
    width: 75px;
    height: 75px;
    align-items: center;
    justify-content: flex-start;
  }

  &:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: #26262e;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
    z-index: -1;
    opacity: 0.5;
  }
`;
