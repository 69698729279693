import logo from 'v2/components/shared/Image/logo.svg';
import Instagram from 'v2/components/shared/Image/Icon/instagram_icon.svg';

import mockPic from './mockPicts/Rectangle.png';
import mockPic1 from './mockPicts/1.png';
import mockPic2 from './mockPicts/23.png';

export default {
  speed: 50000,
  theme: 'dark',
  icons: [
    {
      key: 1,
      src: logo,
      alt: 'Logo',
      size: 'large',
    },
    {
      key: 2,
      src: Instagram,
      alt: 'instagram',
    },
  ],
  mockPhotos: [
    {
      key: 1,
      src: mockPic1,
      alt: 'mock',
    },
    {
      key: 2,
      src: mockPic2,
      alt: 'mock',
    },
    {
      key: 3,
      src: mockPic,
      alt: 'mock',
    },
    {
      key: 1,
      src: mockPic1,
      alt: 'mock',
    },
    {
      key: 2,
      src: mockPic2,
      alt: 'mock',
    },
    {
      key: 3,
      src: mockPic,
      alt: 'mock',
    },
    {
      key: 1,
      src: mockPic1,
      alt: 'mock',
    },
    {
      key: 2,
      src: mockPic2,
      alt: 'mock',
    },
    {
      key: 3,
      src: mockPic,
      alt: 'mock',
    },
  ],
  footerText:
    'Keep up on the latest prizes, and behind the scenes experiences with Gone Streakin’, as well as the fantasy and sports world.',
};
