import * as R from 'ramda';

import { setParams, getParams, parseOrders, preStringifyOrders } from 'v2/utils/params';

export const kindKey = 'kind';

export const defaultKindKey = 'all';

const filterKey = 'f';
const orderKey = '0';

export function preStringifyParams(params) {
  return R.evolve(
    {
      [filterKey]: {
        transactionTypes: R.map(R.join(',')),
      },
      [orderKey]: preStringifyOrders,
    },
    params,
  );
}

export function parseParams() {
  return R.pipe(
    getParams,
    R.evolve({
      [filterKey]: {
        transactionTypes: R.map(R.split(',')),
      },
      [orderKey]: parseOrders,
    }),
  )();
}

export function stringifyParams(params) {
  R.pipe(preStringifyParams, setParams)(params);
}

export const test = () => 0;
