// @flow

import React from 'react';
import styled from 'styled-components';

type CarouselIconProps = {
  src: string,
  alt: string,
  size?: 'small' | 'normal' | 'large',
};

const defaultProps = {
  size: 'normal',
};

function CarouselIcon({ src, alt, size }: CarouselIconProps) {
  return <ImageIcon src={src} alt={alt} size={size} />;
}

CarouselIcon.defaultProps = defaultProps;
export default CarouselIcon;

// styles
const ImageIcon = styled.img`
  width: ${({ size }) => {
    if (size === 'small') return '20px';
    if (size === 'large') return '34px';

    return '24px';
  }};
`;
