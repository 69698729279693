import React from 'react';
import styled from 'styled-components';

const propTypes = {};

const defaultProps = {};

function Title(props) {
  const { text } = props;
  return <StyledTitle {...props}>{text}</StyledTitle>;
}

const StyledTitle = styled.h2`
  text-align: center;
  margin: 0;
  text-transform: capitalize;
  @media (min-width: 768px) {
    text-align: left;
    padding: 0;
  }
`;

Title.propTypes = propTypes;
Title.defaultProps = defaultProps;

// export default enhance(HomePage);
export default Title;
