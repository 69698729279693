/* eslint-disable react/prop-types */
// @flow

import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { SliderGameCard, Loader } from 'v2/components/shared';
import * as requestTypes from 'v2/constants/requestTypes';
import { requestInProcess } from 'v2/selectors/request';
import NoAvailableGames from '../NoAvailableGames';

const defaultProps = {
  gamesCollection: [],
  activeId: null,
  showHeadshots: false,
};

type gamesProps = {
  session: any,
  gamesCollection?: Array<{ id: number }>,
  isLoading: boolean,
  activeId?: Number,
};

function Games({ session, gamesCollection, isLoading, activeId, showHeadshots, ...rest }: gamesProps) {
  const getGameCards = () => {
    if (isLoading) {
      return (
        <NoAvailableGamesContainer>
          <Loader />
        </NoAvailableGamesContainer>
      );
    }

    if (gamesCollection && gamesCollection.length === 0) {
      return <NoAvailableGames {...rest} gamesCollection={gamesCollection} />;
    }

    return (
      <SliderGameCard
        session={session}
        gamesCollection={gamesCollection}
        activeId={activeId}
        showHeadshots={showHeadshots}
        {...rest}
      />
    );
  };

  return <StyledGames id="games_container">{getGameCards()}</StyledGames>;
}

// backdrop-filter: blur(8px);
// filter: brightness(0.5) blur(2px);
// opacity: 0.85;
const NoAvailableGamesContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 465px;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
  /* background: #26262e; */
  /* border: 1px dashed #26262e; */
  border-radius: 8px;

  /* @media (min-width: 368px) {
    margin: 15px 0 15px 0;
  } */
`;

const StyledGames = styled.div`
  position: relative;
  transition: all 0.35s;
`;

Games.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  isLoading:
    requestInProcess(state, { requestType: requestTypes.AVALAIBLE_CONTESTS_FETCH }) ||
    requestInProcess(state, { requestType: requestTypes.MY_GAMES_RECENT_FETCH }) ||
    requestInProcess(state, { requestType: requestTypes.MY_GAMES_ACTIVE_FETCH }),
});

export default connect(mapStateToProps)(React.memo(Games));
