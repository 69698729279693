import React from 'react';
import PhotoBlock from 'v2/components/PromoPages/shared/PhotoBlock';
import promoImg3 from 'v2/components/shared/Image/promo/2/3.png';

import BlockDescription, { BlockDescriptionaAccent, BlockDescriptionContainer } from '../../shared/DescriptionBlock';

function block() {
  return (
    <PhotoBlock.Wrapper>
      <PhotoBlock src={promoImg3} contentDirection="bottom">
        <BlockDescriptionContainer>
          <BlockDescription quotes>
            PLAY GONE STREAKIN’ TO{'\n'} WIN SOME GEAR, AND{'\n'} MEET ATHLETES LIKE ME
          </BlockDescription>
          <BlockDescriptionaAccent style={{ color: '#ffffff', opacity: 0.6 }}>JARVIS LANDRY</BlockDescriptionaAccent>
          <BlockDescriptionaAccent style={{ color: '#ffffff', opacity: 0.6 }}>@juicelandry</BlockDescriptionaAccent>
        </BlockDescriptionContainer>
      </PhotoBlock>
    </PhotoBlock.Wrapper>
  );
}

export default block;
