// @flow

import React from 'react';
import styled from 'styled-components';
import Downshift from 'downshift';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import bell from 'v2/components/shared/Image/bell.svg';

import { getAllNotifications } from 'v2/selectors/session';
import ListOfNotifications from './ListOfNotifications';

type DropDownNotificationsProps = {
  notifications: Array<{ title: string, body: string, created_at: string, id: number }>,
};

// const defaultProps = {
//   notifications: [],
// };

function DropDownNotifications({ notifications }: DropDownNotificationsProps) {
  if (notifications.length === 0) {
    return <Bell alt="notifications" src={bell} />;
  }

  return (
    <Downshift itemToString={(option) => (option ? option.name : '')}>
      {({ isOpen, getToggleButtonProps, getItemProps, highlightedIndex }) => (
        <div>
          <BellContainer {...getToggleButtonProps()}>
            <CountNotification />
            <Bell alt="notifications" src={bell} />
          </BellContainer>
          <DownshiftContainer>
            {isOpen ? <ListOfNotifications notifications={notifications} /> : null}
          </DownshiftContainer>
        </div>
      )}
    </Downshift>
  );
}

const mapStateToProps = (state) => ({
  notifications: getAllNotifications(state),
});

const mapDispatchToProps = (dispatch) => ({});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

// DropDownNotifications.defaultProps = defaultProps;
export default enhance(DropDownNotifications);

// styles

const Bell = styled.img`
  display: flex;
  /* margin-right: 10px; */
`;

const BellContainer = styled.div`
  position: relative;
  cursor: pointer;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;

  &:hover {
    filter: brightness(1.3);
  }
`;

const CountNotification = styled.span`
  position: absolute;
  width: 8px;
  height: 8px;
  top: 1px;
  right: -1px;
  /* padding: 1px 5px; */
  background: #e80c51;
  transition: filter ease-in 0.2s;
  /* border: 1px solid #e80c51; */
  border-radius: 20px;
  letter-spacing: 0px;
  color: #fff;

  &:hover {
    filter: brightness(80%);
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 200%;
    height: 200%;
    top: 0;
    transform: translate(-25%, -25%);
  }

  /* @media (min-width: 480px) {
    padding: 2px 6px;
  } */

  -moz-appearance: none !important;
  -webkit-appearance: none !important;
`;

// const CountNotification = styled.div`
//   font-size: 15px;
//   margin-left: 5px;
//   padding: 3px 8px;
//   line-height: 1;
//   background: #e80c51;
//   border-radius: 50%;
//   letter-spacing: 0px;
//   color: #fff;
//   cursor: pointer;
//   font-weight: bold;
//   -moz-appearance: none !important;
//   -webkit-appearance: none !important;
// }
// `;

const DownshiftContainer = styled.div`
  z-index: 99999;
  position: absolute;
  top: 50px;
  right: 20px;
  cursor: pointer;

  @media screen and (max-width: 485px) {
    right: 0;
  }
`;
