// @flow
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React, { useState, useRef, useEffect } from 'react';
import { not, and, prop, when, equals, length, dissoc, curry, compose, assoc, pathOr } from 'ramda';
import styled from 'styled-components';

import useWindowSize from 'v2/services/useWindowSize';

import CategoryHead from '../CategoryHead';
import PreviewCard from '../PreviewCardNew';
import DefaultSlider from '../Sliders/Default';
import CenteredSlider from '../Sliders/Centered';

type CategoryBlogProps = {
  posts?: [any],
  withSlider?: boolean,
};

const defaultProps = {
  posts: [],
  withSlider: true,
};

const nextSlide = pathOr(null, ['current', 'slickNext']);
const prevSlide = pathOr(null, ['current', 'slickPrev']);

const slideLength = 5;

const getSportNamebyLeague = compose(
  when(equals('nfl'), () => 'Football'),
  when(equals('nba'), () => 'Basketball'),
);

function CategoryBlog({ openVideoModal, showSlider, posts, league, withSlider, ...rest }: CategoryBlogProps) {
  const [documentWidth] = useWindowSize();
  const sliderRef = useRef(null);
  const [sliderActions, setSliderActions] = useState({});
  const isMobile = documentWidth < 660;

  const checkValidArrows = curry((slidesToshow, index) => {
    setSliderActions(compose(assoc('nextAction', nextSlide(sliderRef)), assoc('prevAction', prevSlide(sliderRef))));

    if (index == slideLength - slidesToshow) {
      setSliderActions(dissoc('nextAction'));
    }

    if (index == 0) {
      setSliderActions(dissoc('prevAction'));
    }
  });

  useEffect(() => {
    if (pathOr(false, ['current', 'props', 'infinite'], sliderRef)) {
      return setSliderActions(
        compose(assoc('nextAction', nextSlide(sliderRef)), assoc('prevAction', prevSlide(sliderRef))),
      );
    }
    return setSliderActions(assoc('nextAction', nextSlide(sliderRef)));
  }, [sliderRef]);

  if (length(posts) == 0) return null;

  if ((length(posts) < 3 && isMobile) || not(withSlider)) {
    return (
      <CategoryHead subtitle={getSportNamebyLeague(league)} showMoreBtn {...sliderActions} {...rest}>
        <MinContainer>
          {posts.map((post) => (
            <MinContainerCardWrapper>
              <PreviewCard.Container playVideo={openVideoModal} {...post}>
                <PreviewCard />
              </PreviewCard.Container>
            </MinContainerCardWrapper>
          ))}
        </MinContainer>
      </CategoryHead>
    );
  }

  if (compose(equals('Winner Circle'), prop('title'))(rest)) {
    if (isMobile && length(posts) >= 1) {
      return (
        <CategoryHead {...sliderActions} subtitle={getSportNamebyLeague(league)} {...rest} showMoreBtn>
          <CenteredSlider checkValidArrows={checkValidArrows} forwardedRef={sliderRef} league={league} {...rest}>
            {posts.map((post) => (
              <PreviewCard.Container playVideo={openVideoModal} {...post}>
                <PreviewCard />
              </PreviewCard.Container>
            ))}
          </CenteredSlider>
        </CategoryHead>
      );
    }

    if (length(posts) >= 3) {
      return (
        <CategoryHead {...sliderActions} subtitle={getSportNamebyLeague(league)} {...rest} showMoreBtn>
          <CenteredSlider checkValidArrows={checkValidArrows} forwardedRef={sliderRef} league={league} {...rest}>
            {posts.map((post) => (
              <PreviewCard.Container playVideo={openVideoModal} {...post}>
                <PreviewCard />
              </PreviewCard.Container>
            ))}
          </CenteredSlider>
        </CategoryHead>
      );
    }

    return null;
  }

  return (
    <CategoryHead {...sliderActions} subtitle={getSportNamebyLeague(league)} {...rest} showMoreBtn>
      <DefaultSlider checkValidArrows={checkValidArrows} forwardedRef={sliderRef} league={league} {...rest}>
        {posts.map((post) => (
          <PreviewCard.Container playVideo={openVideoModal} {...post}>
            <PreviewCard />
          </PreviewCard.Container>
        ))}
      </DefaultSlider>
    </CategoryHead>
  );
}

CategoryBlog.defaultProps = defaultProps;
export default CategoryBlog;

// styles
const MinContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 450px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const MinContainerCardWrapper = styled.div`
  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 450px) {
    margin-right: 0;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
`;
