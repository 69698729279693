// @flow
import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import styled from 'styled-components';
import playIcon from './playIcon.svg';

const PlayVideo = ({ videoId, size }) => {
  const [isOpen, setIsOpen] = useState(false);
  const openVideoModal = () => setIsOpen(true);
  const closeVideoModal = () => setIsOpen(false);
  return (
    <>
      <ModalVideo
        channel="vimeo"
        vimeo={{
          autoplay: true,
          mute: true,
          loop: false,
          autopause: false,
          api: true,
        }}
        videoId={videoId}
        isOpen={isOpen}
        onClose={(e) => closeVideoModal(e)}
      />
      <Container onClick={openVideoModal}>
        <PlayButton src={playIcon} alt="play video" size={size} />
      </Container>
    </>
  );
};

export default PlayVideo;
// styles
const PlayButton = styled.img`
  width: ${({ size }) => {
    if (size === 'smaller') {
      return '40px';
    }

    if (size === 'small') {
      return '60px';
    }

    return '70px';
  }};

  height: ${({ size }) => {
    if (size === 'smaller') {
      return '40px';
    }

    if (size === 'small') {
      return '60px';
    }

    return '70px';
  }};
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
