import React from 'react';
import PhotoBlock from 'v2/components/PromoPages/shared/PhotoBlock';
import promoImg7 from 'v2/components/shared/Image/promo/2/7.png';
import BlockDescription, { BlockDescriptionaAccent, BlockDescriptionContainer } from '../../shared/DescriptionBlock';

function block() {
  return (
    <PhotoBlock.Wrapper>
      <PhotoBlock src={promoImg7} contentDirection="bottom">
        <BlockDescriptionContainer>
          <BlockDescription quotes>
            GONE STREAKIN' ...{'\n'}
            BIG THINGS, BIG THINGS ...{'\n'}
            LET'S GO!
          </BlockDescription>
          <BlockDescriptionaAccent style={{ color: '#001f2f' }}>DANNY AMENDOLA</BlockDescriptionaAccent>
          <BlockDescriptionaAccent style={{ color: '#001f2f' }}>@dannyamendola</BlockDescriptionaAccent>
        </BlockDescriptionContainer>
      </PhotoBlock>
    </PhotoBlock.Wrapper>
  );
}

export default block;
