// @flow

import React, { type Node } from 'react';
import styled from 'styled-components';
import { and, not, prop } from 'ramda';
import { NavLink } from 'react-router-dom';

import { history } from 'v2/stores/configureStore';
import { getPath, BLOG_ARTICLE_PATH } from 'v2/constants/router';

type PreviewCardContainerProps = {
  children: Node,
  isActive?: Boolean,
};

const defaultProps = {
  isActive: true,
};

function PreviewCardContainer({ children, ...rest }: PreviewCardContainerProps) {
  const articleId = prop('id', rest);
  const isCentered = prop('isCentered', rest);
  const isActive = prop('isActive', rest);
  const hideDescription = isCentered && not(isActive);

  const navToArticlePage = () => isActive && history.push(getPath(BLOG_ARTICLE_PATH, { articleId }));

  if (isActive) {
    return (
      <StyledNavLink to={getPath(BLOG_ARTICLE_PATH, { articleId })}>
        <Container isCentered={isCentered} isActive={isActive}>
          {React.cloneElement(children, { isCentered, hideDescription, ...rest })}
        </Container>
      </StyledNavLink>
    );
  }

  return (
    <Container isCentered={isCentered} isActive={isActive}>
      {React.cloneElement(children, { isCentered, hideDescription, ...rest })}
    </Container>
  );
}

PreviewCardContainer.defaultProps = defaultProps;
export default PreviewCardContainer;

// styles
const Container = styled.div`
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.lighGray};
  transition: all 0.25s;
  cursor: pointer;
  position: relative;
  height: 100%;

  min-width: 250px;
  max-width: 350px;

  &:hover {
    filter: ${({ isCentered }) => not(isCentered) && 'brightness(0.85)'};
  }
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none !important;
`;
