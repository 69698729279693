import { DismissNotificationApiAdapter } from 'v2/apiAdapters';

import * as requestTypes from '../../constants/requestTypes';
import * as types from '../../constants/actionTypes';

import setRequestInProcess from '../request';

export function fetchDismissNotification() {
  const requestType = requestTypes.DISMISS_NOTIFICATION_FETCH;

  return (dispatch) => {
    dispatch(setRequestInProcess(true, requestType));

    DismissNotificationApiAdapter.getDismissNotification().then((res) => {
      window.location.reload();
      dispatch(fetchDismissNotificationSuccess(res.dismissNotification));
      dispatch(setRequestInProcess(false, requestType));
    });
    // .catch((error) => {
    //   if (error.response) {
    //     openNotification('error', error.response.data.message);
    //   }
    // });
  };
}

function fetchDismissNotificationSuccess(payload) {
  return {
    type: types.DISMISS_NOTIFICATION_FETCH_SUCCESS,
    payload,
  };
}

export default fetchDismissNotificationSuccess;
