import {
  getPath,
  BLOG_CATEGORIES_PATH,
  BLOG_ARTICLE_PATH,
  BLOG_CATEGORY_PATH,
  BLOG_ARTICLES_PATH,
} from 'v2/constants/router';

import { getRequest } from '../requests';
import fetch from '../fetch';

export default class BlogApiAdapter {
  static getBlogCategories() {
    return fetch(`/v1${BLOG_CATEGORIES_PATH}`, getRequest());
  }

  static getBlogCategory(categoryId, params) {
    return fetch(`/v1${getPath(BLOG_CATEGORY_PATH, { categoryId })}`, getRequest(), params);
  }

  static getArticle(articleId) {
    return fetch(`/v1/${getPath(BLOG_ARTICLE_PATH, { articleId })}`, getRequest());
  }

  static getArticles(params) {
    return fetch(`/v1/${BLOG_ARTICLES_PATH}`, getRequest(), params);
  }
}
