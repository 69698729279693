import React, { useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import ModalVideo from 'react-modal-video';
import PlayerPickemContest from './shared/PlayerPickemContest';
import SurviveAdvanceContest from './shared/SurviveAdvanceContest';
import pickem from '../shared/Image/pickem.svg';
import survive from '../shared/Image/survive.svg';
import defaultImg from '../shared/Image/default_img.jpg';

const propTypes = {};

const defaultProps = {};

function HowToPlay({
  match: {
    params: { kind },
  },
}) {
  const [isActive, setIsActive] = useState(kind === 'survive');
  const [isOpen, setIsOpen] = useState(undefined);

  return (
    <HowToPlayStyled>
      <HowToPlaytext>
        <Title>How to play</Title>
        <Discription>
          Gone Streakin’ is the world’s first fantasy sports survivor game with awesome prizes such as once in a
          lifetime athlete experiences, cash prizes, exclusive merchandise, and much more.
        </Discription>
      </HowToPlaytext>
      <TabContecsts>
        <Contest
          className={isActive ? 'active' : ''}
          onClick={() => {
            setIsActive(true);
          }}
        >
          <ContestImg className="img_filter_survive" src={survive} />
          <ContestName>Survive & Advance</ContestName>
        </Contest>
        <Contest
          className={!isActive ? 'active' : ''}
          onClick={() => {
            setIsActive(false);
          }}
        >
          <ContestImg className="img_filter_weekly" src={pickem} />
          <ContestName>Player Pick`em</ContestName>
        </Contest>
      </TabContecsts>
      <ContestDiscription>
        {isActive ? <SurviveAdvanceContest /> : <PlayerPickemContest />}
        <ContestVideo>
          <PreviewImg>
            <img alt="" style={{ width: '100%' }} src={defaultImg} />
            <StyledButton
              onClick={() => {
                setIsOpen(true);
              }}
            />
          </PreviewImg>
          <PreviewText>Survive & Advance is a survivor style contest offered weekly.</PreviewText>
          {isActive && (
            <ModalVideo
              channel="youtube"
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              videoId="18H5kr60JsI"
              className="modalVideo"
            />
          )}
        </ContestVideo>
      </ContestDiscription>
    </HowToPlayStyled>
  );
}

HowToPlay.propTypes = propTypes;
HowToPlay.defaultProps = defaultProps;

// export default HomePage;
export default withRouter(HowToPlay);

// styles

const HowToPlayStyled = styled.div`
  max-width: 700px;
  margin: 0 auto;
`;

const HowToPlaytext = styled.div`
  text-align: center;
`;

const Title = styled.h1`
  font-size: 30px;
  line-height: 38px;
  margin: 0;
`;

const Discription = styled.p`
  font-size: 17px;
  line-height: 25px;
  letter-spacing: 0;
  margin-top: 10px;
  padding: 0 80px;
  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;

const TabContecsts = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  @media (max-width: 768px) {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
  }
`;

const Contest = styled.div`
  display: flex;
  width: 50%;
  margin: 0 20px;
  cursor: pointer;
  justify-content: center;
  &:hover {
    color: #fff;
  }
  &.active {
    position: relative;
    color: #fff;
    &:before {
      position: absolute;
      content: '';
      height: 4px;
      width: 100%;
      bottom: -5px;
      background: #5d5ce2;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      @media (max-width: 768px) {
        bottom: 0;
        left: 0;
      }
    }
  }
  @media (max-width: 768px) {
    width: 50%;
    padding: 20px;
    text-align: center;
    margin: 0;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
    ${'' /* &:hover {
      background: #1b1b21;
    } */}
  }
`;

const ContestName = styled.div`
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.5px;
  margin: 5px 0;
  font-weight: 700;
  text-transform: uppercase;
  margin-left: 15px;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 14px;
    margin-left: 0;
    margin-bottom: 0;
  }
`;

const ContestDiscription = styled.div`
  margin-top: 5px;
  padding: 60px;
  background: #1b1b21;
  @media (max-width: 768px) {
    padding: 30px 20px;
    margin-top: 0;
  }
`;

const ContestVideo = styled.div``;

const PreviewText = styled.h3`
  margin-top: 15px;
  padding: 0 30px;
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const StyledButton = styled.button`
  background: #ce3c4d;
  padding: 24px;
  border-radius: 50px;
  border: 0;
  box-shadow: 0px 2px 4px 0px;
  position: absolute;
  top: calc(50% - 24px);
  left: 0;
  right: 0;
  margin: 0 auto;
  cursor: pointer;
  bottom: 0;
  margin: 0 auto;
  cursor: pointer;
  height: 24px;
  width: 24px;
  &:hover {
    background: #e8495b;
    color: #fff;
    &:after {
      border-color: #e8495b #e8495b #e8495b #fff;
    }
  }
  &:after {
    width: 0px;
    height: 0px;
    background: white;
    position: absolute;
    top: calc(50% - -5px);
    left: calc(50% - 4px);
    margin-top: -12px;
    content: ' ';
    border-style: solid;
    border-width: 7px 0 7px 12px;
    border-color: #ce3c4d #ce3c4d #ce3c4d #fff;
  }
`;

const PreviewImg = styled.div`
  position: relative;
  margin-bottom: 10px;
  img {
    border-radius: 8px;
  }
`;

const ContestImg = styled.img`
  width: 40px;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    width: 60px;
    &.img_filter_survive {
      margin-bottom: 26px;
    }
  }
`;
