import { useLayoutEffect, useState, useEffect } from 'react';
import debounce from 'debounce';

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  function updateSize() {
    setSize(() => [window.innerWidth, window.innerHeight]);
  }

  useEffect(() => {
    updateSize();
  }, []);

  useLayoutEffect(() => {
    const debouncedUpdateSize = debounce(updateSize, 500);

    window.addEventListener('resize', debouncedUpdateSize);
    debouncedUpdateSize();
    return () => window.removeEventListener('resize', debouncedUpdateSize);
  }, []);
  return size;
}

export default useWindowSize;
