import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { not, and, equals, head, toString, replace, compose } from 'ramda';

import { history } from 'v2/stores/configureStore';
import { placeSuffix } from 'v2/helpers/common';

import {
  prizeBlockStyles,
  prizesBlockStyles,
  prizeStyles,
  progressIconsStyles,
  progressInfoStyles,
  progressTextStyles,
  statusStyles,
} from '../styles';

import ResultIconItems from './ResultIconItems';

const propTypes = {
  kind: PropTypes.string,
};

const defaultProps = {
  kind: '',
};

const getProgressText = (kind, league, isWin, isAdvanced, eliminationWeek) => {
  if (isWin) return 'winner';

  if (kind === 'survive') {
    if (isAdvanced) return 'advanced';
    if (league == 'nfl') if (!isWin) return `ELIMINATED in week ${eliminationWeek}`;
    return `ELIMINATED in day ${eliminationWeek}`;
  }

  if (kind === 'weekly') {
    if (!isWin) return 'POSTGAME';
  }

  if (!isWin) return 'lost';
};

function ProgressResult(props) {
  const {
    kind,
    isUserWin,
    isUserEliminated,
    rank,
    isUserAdvanced,
    id,
    currentWeek,
    redirectUrl,
    participantsMaxCount,
    totalSelectionsRequired,
    submitPicks,
    league,
    urlContest,
  } = props;

  const getAccentColor = () => {
    if (isUserWin) return '#0bd855';
    if (isUserAdvanced) return '#0bd855';

    if (kind === 'weekly') {
      if (!isUserWin) return '#656579';
    }

    if (isUserEliminated) return '#E80C51';
    if (!isUserWin) return '#E80C51';

    return '#FFB236';
  };

  const numberWithCommas = compose(replace(/\B(?=(\d{3})+(?!\d))/g, ','), toString);

  const getText = () => {
    // if (totalSelectionsRequired > submitPicks) {
    //   return <StyledProgressText>DID NOT MAKE ALL PICKS</StyledProgressText>;
    // }

    if (isUserWin && (rank == 1 || !rank)) {
      return (
        <StyledProgressText>CONGRATULATIONS, YOU WON! {'\n'} CHECK YOUR EMAIL TO CLAIM GRAND PRIZE!</StyledProgressText>
      );
    }

    if (isUserWin && !(rank == 1) && rank) {
      return (
        <StyledProgressText>
          {`CONGRATULATIONS, YOU WON ${rank}rd of ${numberWithCommas(
            participantsMaxCount,
          )} Place! {'\n'}CHECK YOUR EMAIL TO CLAIM YOUR PRIZE`}
          .
        </StyledProgressText>
      );
    }

    if (isUserAdvanced && isSurvive) {
      return (
        <StyledProgressText>
          YOU’VE Advanced to next week. {'\n'}
          <AccentText color={getAccentColor}>Make your picks!</AccentText>
        </StyledProgressText>
      );
    }

    if (isUserEliminated && isSurvive) {
      return <StyledProgressText>YOU’VE BEEN ELIMINATED {'\n'}WITH AN INCORRECT PICK</StyledProgressText>;
    }

    if (!isUserWin && !isSurvive) {
      return (
        <StyledProgressText>
          {rank
            ? `YOU FINISHED IN ${placeSuffix(rank)} PLACE \nCHECK OUT THE LEADERBOARD`
            : `YOU FINISHED WITHOUT PLACE \nCHECK OUT THE LEADERBOARD`}
        </StyledProgressText>
      );
    }

    if (!isUserWin && isSurvive) {
      return <StyledProgressText>{`YOU’VE BEEN ELIMINATED\nWITH AN INCORRECT PICK.`}</StyledProgressText>;
    }
  };

  const isSurvive = kind === 'survive';
  return (
    <StyledPrizes>
      <StyledPrize
        showBg={compose(not, and(!isUserWin))(kind)}
        borderColor={getAccentColor()}
        isSuccess={isUserWin || isUserAdvanced}
      >
        <StyledProgressInfo>
          <StyledStatus color={getAccentColor()} isGray>
            {getProgressText(kind, league, isUserWin, isUserAdvanced, urlContest.weekOrder)}
          </StyledStatus>

          {/* {isUserAdvanced && isSurvive && ( */}
          {getText()}
        </StyledProgressInfo>

        <StyledProgressIcons>
          <ResultIconItems isResultSuccess={isUserWin} kind={kind} {...props} />
        </StyledProgressIcons>
      </StyledPrize>
    </StyledPrizes>
  );
}

const StyledStatus = styled.span`
  ${statusStyles}
  color: ${({ color }) => color}
`;

const StyledPrizes = styled.div`
  ${prizesBlockStyles}
  height: 100% !important;
  max-height: 94px;
`;

const StyledPrize = styled.div`
  ${prizeBlockStyles}
  ${prizeStyles}
  background-color: ${({ isSuccess, showBg }) => showBg && (isSuccess ? '#2a4843' : '#57162d')};
  min-height: 90px;
`;

const StyledProgressText = styled.span`
  ${progressTextStyles}
  white-space: pre-line;
`;

const StyledProgressInfo = styled.div`
  ${progressInfoStyles}
  width: 100%;
`;

const StyledProgressIcons = styled.div`
  ${progressIconsStyles}
`;

const AccentText = styled.span`
  color: ${({ color }) => color};
  font-size: 14px;
`;

ProgressResult.propTypes = propTypes;
ProgressResult.defaultProps = defaultProps;

export default ProgressResult;
