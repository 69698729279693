// @flow

import * as React from 'react';
import styled from 'styled-components';

type CarouselSectionProps = {
  children: React.Node,
};

function CarouselSection({ children }: CarouselSectionProps) {
  return <CarouselSectionContent>{children}</CarouselSectionContent>;
}

export default CarouselSection;

// styles
const CarouselSectionContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`;
