// @flow

import React from 'react';
import styled from 'styled-components';
import { equals } from 'ramda';

type propTypes = {
  onChange: (key: string) => void,
  activeKey: string,
  tabs?: Array<[string, { label: string, img: string }]>,
};

const defaultProps = {
  tabs: [],
};

function LeagueTabs({ onChange, activeKey, tabs }: propTypes) {
  return (
    <Container>
      {tabs.map(([key, { label, img }]) => (
        <TabItem key={key} onClick={() => onChange(key)} isActive={equals(activeKey, key)}>
          {img && <TabItemImg src={img} alt={label} />}
          {!img && <span>{label}</span>}
        </TabItem>
      ))}
    </Container>
  );
}

LeagueTabs.defaultProps = defaultProps;
export default React.memo(LeagueTabs);

// styles

const TabItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.15rem;
  padding: 0.5rem 0.6rem;
  border-radius: 6px;
  transition: background-color 0.2s linear;
  background: ${({ theme }) => theme.colors.gray};
  background: ${({ theme, isActive }) => isActive && theme.colors.purpleAccent};

  span {
    color: ${({ theme }) => theme.colors.white};
    padding: 0 0.2rem;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const TabItemImg = styled.img`
  height: 1.6em;
  width: 1.8em;
  font-size: 14px;
  margin: 0;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: stretch;
`;
