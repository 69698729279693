// @flow

import React from 'react';
import styled from 'styled-components';

type FAQsListProps = {};

function FAQsList({ question, answer, children }: FAQsListProps) {
  return (
    <Container>
      <Content>
        <Question dangerouslySetInnerHTML={{ __html: question }} />
        <Answer dangerouslySetInnerHTML={{ __html: answer }} />
      </Content>
      <List>{children}</List>
    </Container>
  );
}

export default FAQsList;

// styles

const Container = styled.div``;

const Question = styled.h1`
  font-size: 27px;
  text-align: center;
`;

const Answer = styled.span`
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 25px;
  font-weight: normal;
  text-align: center;
`;

const Content = styled.div`
  max-width: 540px;
  display: flex;
  flex-direction: column;
  margin: 30px auto 40px;
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
