import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node.isRequired,
};

function ErrorMessage({ children }) {
  return <StyledErrorMessage>{children}</StyledErrorMessage>;
}

const StyledErrorMessage = styled.span`
  color: #e80c51;
`;

ErrorMessage.propTypes = propTypes;
export default ErrorMessage;
