import React, { useEffect } from 'react';
import styled from 'styled-components';
import { history } from 'v2/stores/configureStore';

import ChangeEmailForm from './ChangeEmailForm';
// type ModalChangeEmailProps = {};

function ModalChangeEmail() {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return function cleanup() {
      document.body.style.overflow = 'unset';
    };
  });
  return (
    <ModalContainer onClick={() => history.goBack()}>
      <ChangeEmailForm />
    </ModalContainer>
  );
}

// const BackButton = styled.div`
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   z-index: 2;
// `;

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 10px;

  backdrop-filter: brightness(25%) blur(5px);
`;

export default ModalChangeEmail;
