import React from 'react';
import styled from 'styled-components';
import getIcon from 'v2/components/Contests/shared/Category/getIcon';

// const getDescription = ({ subject }) => {
//   return statCategories.find((statCategory) => statCategory.subject === subject).description;
// };

const StatCategory = ({ subject, index, summary, amount, league, ...props }) => {
  return (
    <>
      <StatCategorySubtitle>{`${index}/${summary}   STAT OBJECTIVES`}</StatCategorySubtitle>
      <StatCategoryContainer>
        <StatCategoryDescription>
          <StatCategoryTitle>{`${amount} ${subject}`}</StatCategoryTitle>
          {/* <StatCategoryText>{getDescription({ subject })}</StatCategoryText> */}
        </StatCategoryDescription>
        <StyledCategoryIcon src={getIcon({ subject, league })} />
      </StatCategoryContainer>
    </>
  );
};

export const StatCategorySubtitle = styled.span`
  display: block;
  font-size: 12px;
  line-height: 18px;
  color: #656579;
  letter-spacing: 0.5px;
  white-space: pre-wrap;

  /* margin-bottom: 10px; */

  /* @media (max-width: 475px) {
    font-size: 14px;
  } */
`;

export const StatCategoryTitle = styled.span`
  display: block;
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  color: #fff;
  letter-spacing: 0.5px;

  /* @media (max-width: 475px) {
    font-size: 18px;
  } */
`;

export const StatCategoryDescription = styled.div``;

const StyledCategoryIcon = styled.img`
  height: 38px;
  width: 38px;
  position: absolute;
  right: 15px;
  top: -8px;
  /* top: 50%; */
  /* transform: translate(-10px, -100%); */
`;

const StatCategoryContainer = styled.div`
  z-index: 999;
  display: flex;
  justify-content: space-between;
  position: relative;
  min-height: 42px;
  max-height: 42px;
  margin-top: 1px;
  /* margin-bottom: 5px; */

  align-items: center;
`;

export default StatCategory;
