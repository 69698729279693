import React from 'react';
import styled from 'styled-components';

function SliderDots({ dots }) {
  return (
    <SliderDotsContainer>
      <SliderDotsStyled>
        {dots.map((dot, i) => (
          <React.Fragment key={i}>{dot}</React.Fragment>
        ))}
      </SliderDotsStyled>
    </SliderDotsContainer>
  );
}

export default SliderDots;

// const SliderDot = styled.li`
//   /* display: inline-block; */
// `;

const SliderDotsStyled = styled.ul`
  width: 100%;
  /* margin-top: 25px; */

  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px !important;
  margin-top: 10px !important;

  li {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    font-size: 0;
    line-height: 0;
    color: transparent;
    margin: 0 2px;
    background: #26262e;
    transition: background-color 0.5s;

    &.slick-active {
      border-radius: 50%;
      background: #5d5ce2;

      width: 7px;
      height: 7px;
    }

    button {
      background: transparent;
      border: none;
    }
  }
`;

const SliderDotsContainer = styled.div`
  width: 100%;
  displat: flex;
`;
