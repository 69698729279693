import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import SurviveIconItems from './SurviveIconItems';
import WeeklyIconItems from './WeeklyIconItems';

const propTypes = {
  kind: PropTypes.string,
};

const defaultProps = {
  kind: '',
};

function IconItems(props) {
  const { kind } = props;
  // if (kind === 'survive') return <SurviveIconItems {...props} />;
  return <WeeklyIconItems {...props} />;
}

IconItems.propTypes = propTypes;
IconItems.defaultProps = defaultProps;

export default IconItems;
