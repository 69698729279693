import { GET_MEDIA } from 'v2/constants/router';

import { deleteRequest, getRequest, postRequest, putRequest } from '../requests';
import fetch from '../fetch';

export default class RefsApiAdapter {
  static getRefs() {
    return fetch(GET_MEDIA, getRequest());
  }
}
