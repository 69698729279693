// @flow

import React from 'react';
import type { Element } from 'react';
import styled from 'styled-components';

import socialLinks from 'v2/constants/UI/socialLinks';

import SocialLinkItem from '../SocialLinkItem';

function SocialLinksList() {
  return (
    <SocialLinks>
      {socialLinks.map<Element<*>>((socialLink) => (
        <SocialLinkItemContainer key={socialLink.id}>
          <SocialLinkItem {...socialLink} />
        </SocialLinkItemContainer>
      ))}
    </SocialLinks>
  );
}

const SocialLinks = styled.ul`
  display: flex;
  justify-content: space-evenly;
  margin-top: 40px;

  max-width: 1100px;
  display: flex;
  justify-content: center;
  margin: auto;
  padding: 20px 0;
  max-width: 250px;

  /* @media (max-width: 768px) {
    max-width: 210px;
  } */
`;

const SocialLinkItemContainer = styled.li`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: grayscale(1) brightness(3.5);
  transition: all 0.25s;

  &:hover {
    filter: grayscale(0) brightness(1);
  }

  &:first-child {
    justify-content: flex-end;
  }

  &:last-child {
    justify-content: flex-start;
  }
`;

export default SocialLinksList;
