// @flow
import React, { Fragment, useEffect } from 'react';
import { animateScroll } from 'react-scroll';
import { PROMO_PATH, getPath } from 'v2/constants/router';
import { NavLink } from 'react-router-dom';
import { getIn } from 'v2/helpers/ramda';
import { ifElse, prop, equals, add, subtract } from 'ramda';
import styled from 'styled-components';

const withArrows = (WrappedComponent) => {
  const PAGES_COUNT = 3;
  return (props) => {
    const currentId = Number(getIn('match.params.id', props));

    useEffect(() => {
      animateScroll.scrollToTop();
    }, [currentId]);

    const getPromoId = ifElse(
      prop('next'),
      ifElse(
        () => equals(currentId, PAGES_COUNT),
        () => 1,
        () => add(currentId, 1),
      ),
      ifElse(
        () => equals(currentId, 1),
        () => PAGES_COUNT,
        () => subtract(currentId, 1),
      ),
    );

    const getLink = (props) => getPath(PROMO_PATH, { id: getPromoId(props) });

    return (
      <Fragment>
        <LeftArrow to={getLink({ prev: true })} />
        <WrappedComponent {...props} />
        <RightArrow to={getLink({ next: true })} />
      </Fragment>
    );
  };
};

export default withArrows;

// styles

const Arrow = styled(NavLink)`
  display: inline-flex;
  position: fixed;
  z-index: 9;
  border-radius: 50%;
  font-size: 24px;
  top: 40%;
  cursor: pointer;
  text-decoration: none !important;

  &:before {
    font-family: 'slick';
    line-height: 1;
    z-index: 1;
    display: flex;
    width: 75px;
    height: 75px;
    align-items: center;
    justify-content: center;
  }

  &:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: #26262e;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
    z-index: 0;
    opacity: 0.5;
  }
`;

const LeftArrow = styled(Arrow)`
  left: 0;
  transform: translate(0, -250%);

  &:before {
    content: '←';
  }
`;

const RightArrow = styled(Arrow)`
  right: 0;
  transform: translate(0, -250%);

  &:before {
    content: '→';
  }
`;
