// @flow

import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { not } from 'ramda';

import lightningIcon from 'v2/components/shared/Image/Icon/ico_lightning.svg';

import useWindowSize from 'v2/services/useWindowSize';

type SeeMoreBtnProps = {
  navigateUrl: String,
};

function SeeMoreBtn({ navigateUrl }: SeeMoreBtnProps) {
  const [documentWidth] = useWindowSize();
  const isMobile = documentWidth < 660;

  if (not(navigateUrl)) return null;

  if (isMobile) {
    return <SeeMoreBnt to={navigateUrl}>see more</SeeMoreBnt>;
  }

  if (not(isMobile)) {
    return (
      <SeeMoreBntWrapper>
        <SeeMoreBnt to={navigateUrl}>
          see <img src={lightningIcon} alt="lightning" /> more
        </SeeMoreBnt>
      </SeeMoreBntWrapper>
    );
  }

  return null;
}

export default SeeMoreBtn;

// styles
const SeeMoreBnt = styled(NavLink)`
  position: relative;
  padding-right: 20px;
  margin-right: 15px;
  color: ${({ theme }) => theme.colors.lightenGray};
  cursor: pointer;
  transition: all 0.5s;
  line-height: 1;
  font-size: 12px;
  text-decoration: none !important;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  z-index: 1;
  display: inline-flex;
  align-items: center;

  img {
    padding: 0 3px;
  }

  &::after {
    content: '';
    display: inline-block;
    transform: rotate(-45deg);
    height: 8px;
    width: 8px;
    border-bottom: 2px solid ${({ theme }) => theme.colors.lightenGray};
    border-right: 2px solid ${({ theme }) => theme.colors.lightenGray};
    position: absolute;
    top: 0;
    right: 0;
    transition: all 0.25s;
  }

  &::before {
    content: '';
    display: block;
    height: 300%;
    width: 150%;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;
    transform: translate(-50%, -50%);
  }

  &:hover {
    filter: brightness(1.5);

    &::after {
      filter: brightness(1.25);
    }
  }
`;

const SeeMoreBntWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${SeeMoreBnt} {
    margin-right: 0;
    padding-right: 0;

    &::after {
      display: none;
    }
  }
`;
