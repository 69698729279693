import React from 'react';
import PhotoBlock from 'v2/components/PromoPages/shared/PhotoBlock';
import GsLogo from 'v2/components/shared/Image/promo/GS_logo.svg';

import BlockDescription from '../../../2/shared/DescriptionBlock';

function block({ size }) {
  return (
    <PhotoBlock.Wrapper style={{ maxHeight: '154px', overflow: 'hidden' }}>
      <PhotoBlock src={GsLogo}>
        <BlockDescription>
          <BlockDescription style={{ textTransform: 'capitalize', lineHeight: 1.2, fontSize: '34px' }} isItalic>
            Win FaceTime With{'\n'} Your Favorite Players
          </BlockDescription>
        </BlockDescription>
      </PhotoBlock>
    </PhotoBlock.Wrapper>
  );
}

export default block;
