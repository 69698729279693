// @flow

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { equals, path, contains } from 'ramda';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Dots from 'v2/components/shared/SliderGameCard/shared/Dots';

import SlideItem from './SlideItem';

type propTypes = {
  onChange: (key: string) => void,
  activeKey: string,
  tabs?: Array<[string, { label: string, img: string, activeColor: string }]>,
};

const defaultProps = {
  tabs: [],
};

const settings = {
  infinite: false,
  centerMode: true,
  centerPadding: '23px 0 18px',
  focusOnSelect: true,
  slidesToShow: 2,
  dots: true,
  arrows: false,
  slidesToScroll: 3,
  appendDots: (dots) => <SlickDotsContainer>{dots}</SlickDotsContainer>,
};

function KindTabs({ tabs, ...rest }: propTypes) {
  const [sliderRef, setSliderRef] = useState(null);

  useEffect(() => {
    if (sliderRef && contains(rest.activeKey, ['survive', 'weekly'])) {
      sliderRef.slickNext();
    }
  }, [sliderRef]);

  return (
    <Container>
      <Slider {...settings} ref={setSliderRef}>
        {tabs.map(([key, opt]) => (
          <SlideContainer key={key}>
            <SlideItem keyId={key} {...opt} {...rest} />
          </SlideContainer>
        ))}
      </Slider>
    </Container>
  );
}

KindTabs.defaultProps = defaultProps;
export default React.memo(KindTabs);

// styles

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: 'red';

  .slick-slider {
    width: 100%;
  }

  .slick-slide {
    width: auto !important;
  }

  .slick-list {
    position: relative;
    height: 80px;
  }

  .slick-track {
    position: absolute !important;
    /* left: -100px; */
  }
`;

const SlideContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 5px;
`;

const SlickDotsContainer = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 5px 0 !important;
  li {
    display: inline-flex;
    width: 5px !important;
    height: 5px !important;

    button {
      background: transparent;
      border: none;
      width: 5px !important;
      height: 5px !important;
      border-radius: 50% !important;
      transition: background-color 0.5s;
    }

    &.slick-active {
      width: 7px !important;
      height: 7px !important;
      button {
        width: 7px !important;
        height: 7px !important;
      }
    }
  }
`;
