import React from 'react';
import styled from 'styled-components';
import { compose, pure } from 'recompose';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import * as Router from 'v2/constants/router';
import { getUserStatusAcceptedTermsOfService } from 'v2/selectors/session';
import * as actions from 'v2/actions';

const propTypes = {};

const defaultProps = {};

// injectGlobal`
//   body.ReactModal__Body--open {
//     overflow: hidden;
//   }
// `;

function DropDown(props) {
  const { isOpen, fetchSignOut, isUserAcceptedTermsOfService } = props;

  return (
    <StyledDropDown className={isOpen ? 'show_dropdown' : ''}>
      <DropDownBackground>
        <Nav>
          <NavItem>
            <a href={Router.ACCOUNT}>My Account</a>
          </NavItem>
          {isUserAcceptedTermsOfService && (
            <NavItem>
              <a href={Router.MY_GAMES}>My Games</a>
            </NavItem>
          )}
          <NavItem>
            {/* <a href={isUserAcceptedTermsOfService ? Router.HOW_TO_PLAY : Router.SETUP_ACCOUNT_PATH}>How to play</a> */}
            <NavLink to={isUserAcceptedTermsOfService ? Router.HOW_TO_PLAY : Router.SETUP_ACCOUNT_PATH}>
              How to play
            </NavLink>
          </NavItem>
          <NavItem>
            <a href={isUserAcceptedTermsOfService ? Router.PRIZES : Router.SETUP_ACCOUNT_PATH}>Prizes</a>
          </NavItem>
          <NavItem>
            <a href={isUserAcceptedTermsOfService ? Router.FAQ : Router.SETUP_ACCOUNT_PATH}>Faq</a>
          </NavItem>
          <NavItem>
            <a href={isUserAcceptedTermsOfService ? Router.CONTACT_US : Router.SETUP_ACCOUNT_PATH}>CONTACT us</a>
          </NavItem>
          <SignOut>
            <a style={{ color: '#656579' }} onClick={() => fetchSignOut()}>
              sign out
            </a>
          </SignOut>
        </Nav>
      </DropDownBackground>
    </StyledDropDown>
  );
}

const StyledDropDown = styled.nav`
  position: absolute;
  width: 100%;
  top: 50px;
  left: 0;
  right: 0;
  display: none;
  transition: opacity 0.15s ease-in-out;
  border-radius: 8px;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.5);
  @media (min-width: 768px) {
    min-width: 150px;
    width: auto;
    height: auto;
    margin-top: 15px;
    left: auto;
    top: 60px;
    margin: 0;
    position: absolute;
    top: 40px;
  }
`;

const Nav = styled.ul`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 0px;
  @media (min-width: 768px) {
    padding: 10px 40px 0 20px;
    align-items: flex-start;
    &:before {
      position: absolute;
      content: '';
      width: 24px;
      height: 4px;
      background: #5d5ce2;
      bottom: 45px;
      right: 20px;
      top: 0;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
`;

const SignOut = styled.div`
  width: 100%;
  text-align: center;
  background: #26262e;
  padding: 15px 0;
  border-radius: 8px;
  margin-top: 20px;
  font-weight: 700;
  @media (min-width: 768px) {
    margin-top: 0px;
    background: transparent;
    padding-left: 0px;
    text-align: left;
    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 1px;
      background: #26262e;
      bottom: 45px;
      right: 0;
    }
  }
`;

const NavItem = styled.li`
  margin: 10px 0;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5;
  font-weight: 700;
  text-transform: uppercase;
`;

const DropDownBackground = styled.div`
  width: 100%;
  opacity: 0.85;
  background: #1b1b21;
  padding-bottom: 20px;
  @media (min-width: 768px) {
    padding-bottom: 0;
  }
`;

const mapDispatchToProps = (dispatch) => ({
  fetchSignOut: () => dispatch(actions.fetchSignOut()),
});

const mapStateToProps = (state) => ({
  isUserAcceptedTermsOfService: getUserStatusAcceptedTermsOfService(state),
});
const enhance = compose(connect(mapStateToProps, mapDispatchToProps), pure);

DropDown.propTypes = propTypes;
DropDown.defaultProps = defaultProps;

// export default enhance(HomePage);
export default enhance(DropDown);
