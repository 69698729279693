// @flow

import React from 'react';
import styled from 'styled-components';

import SportItem from '../SportItem';

type SportItemsProps = {
  sportsCollections: any,
  currentSport: any,
};

function SportItems({ sportsCollections, currentSport }: SportItemsProps) {
  return (
    <SportItemsContainer>
      {sportsCollections.map((sport) => (
        <SportItem isLink key={sport.id} {...sport} isCurrentSport={currentSport.id === sport.id}>
          {sport.title}
        </SportItem>
      ))}
    </SportItemsContainer>
  );
}

const SportItemsContainer = styled.div`
  width: 100%;
  filter: brightness(85%);
`;

export default SportItems;
