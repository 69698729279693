import * as R from 'ramda';
import { isNotDefined, isDefined } from 'v2/helpers/ramda';

import CONTEST_LOGOS from 'v2/constants/UI/contestLogos';

const eqValues = R.curry((a1, a2) => R.equals(new Set(a1), new Set(a2)));

const getContestLogo = R.curry((league, kind, contestStats) =>
  R.compose(
    R.when(isDefined, (logos) =>
      R.ifElse(
        R.compose(R.gte(0), R.length, R.prop('scopes')),
        getOverlap(contestStats),
        R.compose(
          R.ifElse(isNotDefined, () => getOverlap(contestStats, logos), R.last),
          R.find(R.pipe(R.head, eqValues(contestStats))),
          R.prop('scopes'),
        ),
      )(logos),
    ),
    R.path([league || 'nfl', kind]),
  )(CONTEST_LOGOS),
);

const splitContestStats = R.compose(R.uniq, R.split(' '), R.join(' '));

const getOverlap = R.curry((contestStats, logos) => {
  return R.compose(
    R.ifElse(
      R.compose(R.gte(0), R.length, R.prop('overlaps')),
      R.prop('default'),
      R.compose(
        R.ifElse(isNotDefined, () => R.prop('default', logos), R.last),
        R.find(
          R.pipe(R.head, (over) => R.compose(eqValues(over), R.intersection(splitContestStats(contestStats)))(over)),
        ),
        R.prop('overlaps'),
      ),
    ),
  )(logos);
});

export { getContestLogo };
