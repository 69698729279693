// @flow

import React from 'react';
import styled from 'styled-components';

function Loader() {
  return (
    <LoaderFragments>
      <LoaderFragment />
      <LoaderFragment />
      <LoaderFragment />
    </LoaderFragments>
  );
}
const LoaderFragment = styled.div`
  z-index: 99999;
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #fff;
  opacity: 0.7;
  animation: loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;

  &:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  &:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }

  &:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes loader {
    0% {
      top: 16px;
      height: 128px;
    }
    50%,
    100% {
      top: 48px;
      height: 64px;
    }
  }
`;

const LoaderFragments = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
`;

export default Loader;
