// @flow

import React from 'react';
import styled from 'styled-components';

type InfoIconProps = {};

function InfoIcon({}: InfoIconProps) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
      <g id="Player-Pick’em" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Player_Pick’em---Prematch---1440" transform="translate(-1046.000000, -375.000000)">
          <g id="web-t-event_card-game" transform="translate(925.000000, 234.000000)">
            <g id="Group-13" transform="translate(117.000000, 136.000000)">
              <g id="Icon/Interface/16x16/ico_info" transform="translate(0.000000, 1.000000)">
                <g id="baseline-info-24px">
                  <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                  <g id="info_icon" transform="translate(4.500000, 4.500000)" fill="#5D5CE2" fillRule="nonzero">
                    <path
                      d="M7.5,0 C3.36,0 0,3.36 0,7.5 C0,11.64 3.36,15 7.5,15 C11.64,15 15,11.64 15,7.5 C15,3.36 11.64,0 7.5,0 Z M8.25,11.25 L6.75,11.25 L6.75,6.75 L8.25,6.75 L8.25,11.25 Z M8.25,5.25 L6.75,5.25 L6.75,3.75 L8.25,3.75 L8.25,5.25 Z"
                      id="Shape"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default InfoIcon;
