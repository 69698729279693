import React from 'react';
import PhotoBlock from 'v2/components/PromoPages/shared/PhotoBlock';
import photo from 'v2/components/shared/Image/promo/3/video-preview-3.png';

import PlayNowButton from '../../../shared/PlayVideoButton';
import Description from '../../shared/Description';

const descData = {
  instagram: '@kuppnfl',
  name: 'C. KUPP Video',
};

const defaultProps = {
  size: 'small',
};

function block({ size }) {
  return (
    <PhotoBlock.Wrapper style={{ position: 'relative' }}>
      <Description {...descData}>
        <PlayNowButton videoId="274739963" size={size} />
        <PhotoBlock src={photo} />
      </Description>
    </PhotoBlock.Wrapper>
  );
}

block.defaultProps = defaultProps;
export default block;
