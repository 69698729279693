import React from 'react';
import styled from 'styled-components';

type SubTitleProps = {
  children: string,
};

function SubTitle({ children }: SubTitleProps) {
  return <StyledSubTitle>{children}</StyledSubTitle>;
}

const StyledSubTitle = styled.span`
  text-align: center;
  margin-bottom: 25px;
  font-size: 17px;
  text-align: center;
  max-width: 540px;
  text-transform: capitalize;
  letter-spacing: 0px;
  line-height: 25px;
  font-weight: 500;
`;

export default SubTitle;
