/* eslint-disable react/prop-types */
import React, { Fragment, useContext, useState } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import ContestContext from 'v2/contexts/contest';
import { getCookieByName } from 'v2/utils/cookies';
import { isNotDefined } from 'v2/helpers/ramda';

import { shape, check, correctPick, incorrectPick, pendingPick } from '../../Image';
import Avatar from '../../Avatar';
import Modal from './Modal';

const propTypes = {};

const defaultProps = {
  player: {
    statusPick: '',
  },
};

const getStatusImg = (val) => {
  const { statusPick } = val;

  if (statusPick === 'success') return correctPick;
  if (statusPick === 'pending') return pendingPick;
  if (statusPick === 'fail') return incorrectPick;
  return '';
};

function Player(props) {
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const {
    contestCategory,
    onPlayerSelect,
    contestSelectedPlayersIds,
    player,
    isDisable,
    isUserEliminated,
    kind,
    hideFocus,
    match: {
      params: { categoryId, weekOrder, id: contestId },
    },
    isWarning,
  } = props;
  const { id, position, name, photo, game, statusPick, teamId, selectedOtherWeeks } = player;

  const { awayTeam, homeTeam, startsAt, awayTeamId, homeTeamId } = game;

  const gameStartAt = R.replace(
    'EDT',
    'ET',
    moment(startsAt)
      .tz('America/New_York')
      .format('ddd h:mm z'),
  );
  const isSelected = R.includes(player.id, contestSelectedPlayersIds);

  const { setSuccessPlayerInfo } = useContext(ContestContext);

  const hideWarningModal = () => {
    // hideFocus(false);
    setShowWarningModal(false);
  };
  const openWarningModal = () => {
    // hideFocus(true);
    setShowWarningModal(true);
  };

  const withWarningMiddleWare = (f) => {
    if (!isSelected && isWarning) {
      return openWarningModal();
    }

    return f();
  };

  return (
    <Fragment>
      {!player.blocked && !isUserEliminated ? (
        <StyledPlayer
          isWarning={!isSelected && !showWarningModal && isWarning}
          className={isSelected && !player.blocked ? 'selected' : ''}
          style={isDisable && !isSelected ? { opacity: '0.7', cursor: 'none' } : { opacity: '1' }}
        >
          {showWarningModal && (
            <Modal
              type={player.blocked ? 'fail' : 'warning'}
              handleClose={hideWarningModal}
              handleClick={hideWarningModal}
              kind={kind}
            />
          )}
          <PlayerButton
            onClick={(e) => {
              e.stopPropagation();
              withWarningMiddleWare(() => onPlayerSelect(contestCategory, player.id));
            }}
            // onClick={() => setShowModal(true)}
            disabled={!isSelected ? isDisable : ''}
          >
            <AvatarContainer
              indexOfPlayer={isSelected && contestSelectedPlayersIds.indexOf(player.id)}
              onClick={(e) =>
                withWarningMiddleWare(() => {
                  e.stopPropagation();
                  setSuccessPlayerInfo((old) => ({
                    ...old,
                    id,
                    show: true,
                    action: R.compose(
                      () => old.reset(),
                      () => onPlayerSelect(contestCategory, player.id),
                    ),
                    categoryId: R.path(['match', 'params', 'categoryId'], props),
                  }));
                })
              }
            >
              <Avatar source={player.photo} />
              {isSelected && (
                <PlayerSelectedIndex>{contestSelectedPlayersIds.indexOf(player.id) + 1}</PlayerSelectedIndex>
              )}
            </AvatarContainer>

            <PlayerInfo>
              <span>
                <PlayerTeam>
                  <PlayerPosition>{position}</PlayerPosition>
                  <PlayerName>{name}</PlayerName>
                </PlayerTeam>
              </span>
              <AdditionalInfo>
                <AdditionalInfoItem>
                  <PlayerDescription>
                    <PlayerDescriptionTeam isActive={teamId == homeTeamId}>{homeTeam}</PlayerDescriptionTeam> @{' '}
                    <PlayerDescriptionTeam isActive={teamId == awayTeamId}>{awayTeam}</PlayerDescriptionTeam>
                  </PlayerDescription>
                </AdditionalInfoItem>
                <PlayerDescription>
                  <DesctopText>{gameStartAt}</DesctopText>
                  <MobileText>{gameStartAt}</MobileText>
                </PlayerDescription>
              </AdditionalInfo>
            </PlayerInfo>

            {isSelected && <PlayerIndicator src={check} alt="" />}

            {!isDisable && !isSelected && <PlayerIndicator src={shape} alt="" />}
          </PlayerButton>
        </StyledPlayer>
      ) : (
        <StyledPlayer
          isWarning={!isSelected && !showWarningModal && isWarning}
          className={statusPick}
          style={isDisable && !isSelected ? { opacity: '0.7', cursor: 'none' } : { opacity: '1' }}
        >
          <PlayerButton disabled={isDisable}>
            <AvatarContainer>
              <Avatar source={player.photo} />
              {isSelected && (
                <PlayerSelectedIndex>{contestSelectedPlayersIds.indexOf(player.id) + 1}</PlayerSelectedIndex>
              )}
            </AvatarContainer>

            <PlayerInfo>
              <span>
                <PlayerPosition>{position}</PlayerPosition> {homeTeam} @ {awayTeam}
              </span>
              <DesctopText>{gameStartAt}</DesctopText>
              <h3>{name}</h3>
              <MobileText>{gameStartAt}</MobileText>
            </PlayerInfo>

            {isSelected && <PlayerIndicator src={getStatusImg({ statusPick })} alt="" />}
            {/* {!isDisable && !isSelected && <PlayerIndicator src={shape} alt="" />} */}
          </PlayerButton>
        </StyledPlayer>
      )}
    </Fragment>
  );
}

const PlayerName = styled.span`
  color: #fff;
  font-size: 19px;

  @media (max-width: 450px) {
    font-size: ${({ size }) => size || '18px'};
    max-width: 100%;
    white-space: nowrap;
    /* overflow: hidden;
    text-overflow: ellipsis; */
  }
`;

const PlayerPosition = styled.span`
  color: #0bd855;
  margin-right: 5px;
  font-size: 17px;
`;

const PlayerTeam = styled.span`
  color: #fff;
  display: flex;
  align-items: center;
`;

const PlayerDescription = styled.div`
  color: #656579;
  font-size: 14px;
  /* margin-top: 5px; */
  /* margin-bottom: 3px; */
`;

const PlayerDescriptionTeam = styled.span`
  color: ${({ isActive }) => isActive && '#fff'};
  font-size: 14px;
  font-weight: ${({ isActive }) => isActive && 'bold'};
`;

const StyledPlayer = styled.li`
  height: 70px;
  display: flex;
  align-items: center;
  opacity: ${({ isWarning }) => (isWarning ? '0.5' : 1)} !important;
  transition: opacity 0.25s;

  position: relative;
  background: #26262e;
  border-radius: 8px;
  padding: 0px 0px;
  margin-left: 40px;
  cursor: pointer;
  &:not(:first-child) {
    margin-top: 5px;
  }
  &.selected {
    background: #28302e;
  }
  &.succues {
    background: #28302e;
  }
  &.pending {
    background: #302828;
  }
  &.pending {
    background: #302f28;
  }
`;

const PlayerSelectedIndex = styled.span`
  background: #0bd855;
  color: #fff;
  border-radius: 50%;

  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 0;
  left: 0;
`;

const PlayerButton = styled.button`
  background: transparent;
  outline: none;
  border: navajowhite;
  padding: 0;
  text-align: left;
  width: 100%;
  cursor: pointer;
  /* padding: 20px 0px; */
  padding-left: 45px;
`;

const PlayerIndicator = styled.img`
  position: absolute;
  right: 25px;
  top: calc(50% - 15px);
  @media (max-width: 901px) {
    right: -10px;
  }
`;

const AvatarContainer = styled.div`
  position: absolute;
  top: calc(50% - 35px);
  left: -40px;
  width: 70px;
  display: flex;
`;

const MobileText = styled.span`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const DesctopText = styled.span`
  /* margin-left: 5px; */
  font-size: 14px;
  display: inline-block;
  @media (max-width: 768px) {
    display: none;
  }
`;

const PlayerInfo = styled.div`
  margin-top: 2px;
  overflow: hidden;
`;

const AdditionalInfo = styled.div`
  align-items: center;
  margin-top: 8px;
  display: flex;
  justify-content: flex-start;
  /* max-width: 70%; */
`;

const AdditionalInfoItem = styled.div`
  margin: 0 20px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;

Player.propTypes = propTypes;
Player.defaultProps = defaultProps;

// export default enhance(HomePage);
export default withRouter(Player);
