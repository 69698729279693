// @flow

import React, { useState } from 'react';
import styled from 'styled-components';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/css/modal-video.min.css';

import useWindowSize from 'v2/services/useWindowSize';
import firstIamge from 'v2/components/shared/Image/promo/2.jpg';
import GsLogo from 'v2/components/shared/Image/promo/GS_logo.svg';
import SecondImage from 'v2/components/shared/Image/promo/4.png';
import ThirdImage from 'v2/components/shared/Image/promo/5.jpg';
import SixthImage from 'v2/components/shared/Image/promo/Group 6.jpg';
import SeventhImage from 'v2/components/shared/Image/promo/7.jpg';
import EighthImage from 'v2/components/shared/Image/promo/8.png';
import NinthImage from 'v2/components/shared/Image/promo/9.jpg';
import PlayNowImage from 'v2/components/shared/Image/promo/10.jpg';
import VidePreviewImage from 'v2/components/shared/Image/promo/new_preview.jpg';
import WatchButton from 'v2/components/shared/Image/promo/watchButton.png';
import startQuote from 'v2/components/shared/Image/start_quote.png';
import endQuote from 'v2/components/shared/Image/end_quote.png';

import PhotoBlock from '../shared/PhotoBlock';

// type PromoPageProps = {};

function PromoPage() {
  const [windowSize] = useWindowSize();
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = windowSize < 425;

  return (
    <PromoPageWrapper>
      {isOpen && (
        <div>
          <ModalVideo
            channel="vimeo"
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            videoId="274739963"
            // className="modalVideo"
            vimeo={{
              autoplay: 1,
              controls: false,
              mute: 1,
              start: 1,
              allow: 'autoplay',
              autopause: 0,
              responsive: true,
            }}
          />
        </div>
      )}
      <VidePreviewSection onClick={() => setIsOpen(true)}>
        <PhotoBlock src={VidePreviewImage}>
          <PreviewContainer>
            {/* fixme */}
            <PreviewButtonImg src={WatchButton} />
            <BlockDescription size="large">WIN THE ULTIMATE {'\n'}VIP EXPERIENCE!</BlockDescription>
          </PreviewContainer>
        </PhotoBlock>
      </VidePreviewSection>
      <Container>
        {/* line 1 */}
        <Section>
          <PhotoBlock src={firstIamge} />
        </Section>

        <Section>
          <PhotoBlock src={GsLogo}>
            <BlockDescription>
              WIN CASH PRIZES,{'\n'} EXCLUSIVE MERCHANDISE, &{'\n'} ONCE IN A LIFETIME EXPERIENCES.
            </BlockDescription>
          </PhotoBlock>

          <PhotoBlock src={SecondImage} />
        </Section>

        <Section>
          <PhotoBlock src={ThirdImage} contentDirection="bottom" isShadow>
            <BlockDescription size="large">GO BEYOND THE GAME,{'\n'} BEHIND THE SCENES.</BlockDescription>
          </PhotoBlock>
        </Section>

        {/* line 2 */}
        <Section>
          <PhotoBlock src={SixthImage} contentDirection="bottom" isShadow>
            <BlockDescriptionContainer>
              <BlockDescription quotes>
                MEET SOME REAL{'\n'} ATHLETES, WIN SOME REAL{'\n'} PRIZES, AND HAVE SOME{'\n'} MAD FUN WITH IT
              </BlockDescription>
              <BlockDescriptionaAccent>ROB GRONKOWSKI</BlockDescriptionaAccent>
              <BlockDescriptionaAccent>@GRONK</BlockDescriptionaAccent>
            </BlockDescriptionContainer>
          </PhotoBlock>
        </Section>

        <Section>
          <PhotoBlock src={SeventhImage} />
        </Section>

        <Section>
          <PhotoBlock src={GsLogo}>
            <BlockDescription style={{ fontSize: '20px' }}>
              UP CLOSE AND PERSONAL WITH THE{'\n'} BEST OF THE BEST.
            </BlockDescription>
          </PhotoBlock>

          <PhotoBlock src={EighthImage} />
        </Section>

        {/* line 3 */}
        <Section>
          <PhotoBlock src={NinthImage} contentDirection="bottom" isShadow>
            <BlockDescriptionContainer>
              <BlockDescription>
                SIGN UP FOR GONE STREAKIN’ AND WIN SOME AWESOME EXPERIENCES MEETING{'\n'} A LOT OF PROS LIKE ME
              </BlockDescription>
              <BlockDescriptionaAccent>JAMAL ADAMS</BlockDescriptionaAccent>
              <BlockDescriptionaAccent>@PRESIDENTMAL</BlockDescriptionaAccent>
            </BlockDescriptionContainer>
          </PhotoBlock>
        </Section>

        <MainSection>
          <PhotoBlock src={PlayNowImage} contentDirection="bottom" isShadow>
            {isMobile ? (
              <BlockDescription style={{ fontStyle: 'normal' }} size="large">
                A NEW ERA {'\n'}OF FANTASY SPORTS{' '}
              </BlockDescription>
            ) : (
              <BlockDescriptionContainer>
                <BlockDescription style={{ fontStyle: 'normal' }} size="large">
                  A NEW ERA {'\n'}OF FANTASY SPORTS{' '}
                </BlockDescription>
                {/* <ButtonTab to="/">Play Now</ButtonTab> */}
              </BlockDescriptionContainer>
            )}
          </PhotoBlock>
        </MainSection>
      </Container>
    </PromoPageWrapper>
  );
}

export default PromoPage;

// styles

const BlockDescription = styled.span`
  color: #fff;
  font-size: ${({ size }) => {
    if (size === 'large') return '32px';

    return '22px';
  }};
  font-style: italic;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: ${({ size }) => {
    if (size === 'large') return '38px';

    return '28px';
  }};
  text-align: center;
  white-space: pre-wrap;
  text-transform: uppercase;
  position: relative;
  display: inline;

  &:after,
  &:before {
    content: '';
    display: ${({ quotes }) => (quotes ? 'block' : 'none')};
    background-repeat: no-repeat;
    height: 30px;
    width: 35px;
    position: absolute;
    background-size: contain;
  }

  &:after {
    background-image: url(${endQuote});
    bottom: 5px;
    right: 0;
    transform: translate(20%, 40%);
  }

  &:before {
    background-image: url(${startQuote});
    position: absolute;
    top: 3px;
    left: 10px;
    transform: translate(-20%, -40%);
  }
`;

const BlockDescriptionaAccent = styled(BlockDescription)`
  color: #e1073a;
  font-size: 18px;
  line-height: 24px;
`;

const BlockDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;

  ${BlockDescription} {
    margin-bottom: 10px;
  }

  ${BlockDescriptionaAccent} {
    margin-bottom: 0;
  }
`;

const VidePreviewSection = styled.div`
  max-width: 577px;
  margin: 0 auto;
`;

const Section = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1.5em;
  justify-items: center;

  @media (max-width: 425px) {
    width: 350px;
    margin: 0 auto;
  }
`;

const MainSection = styled(Section)`
  grid-column: span 2;

  /* fix me ios safari */
  @media (max-width: 425px) {
    img {
      max-height: 225px;
    }
  }
`;

const Container = styled.div`
  max-width: 1100px;
  margin: 0 auto;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 1.5em;
  margin-top: 1.5em;

  @media (max-width: 425px) {
    margin-top: -1em;
    z-index: 9;
  }
`;

const PreviewButtonImg = styled.img`
  width: 150px;
  height: 60px;
`;

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${PreviewButtonImg} {
    margin-bottom: 25px;
  }
`;

const PromoPageWrapper = styled.div`
  padding-bottom: 50px;
  @media (max-width: 425px) {
    ${MainSection} {
      grid-column: span 1;
    }

    padding-bottom: 60px;
  }
`;
