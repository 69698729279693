import { keys, reduce, curry, assoc, anyPass, isEmpty, isNil, complement, path, pathOr, split } from 'ramda';

export const getIn = (p, a) => {
  if (typeof p === 'string') {
    return path(split('.', p), a);
  }

  return path(p, a);
};

export const getInOr = (d, p, a) => {
  if (typeof p === 'string') {
    return pathOr(d, split('.', p), a);
  }

  return pathOr(d, p, a);
};

export const isNotDefined = anyPass([isEmpty, isNil]);

export const isDefined = complement(isNotDefined);

export const renameKeys = curry((keysMap, obj) =>
  reduce((acc, key) => assoc(keysMap[key] || key, obj[key], acc), {}, keys(obj)),
);
