// @flow

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { map, compose, head, prop } from 'ramda';

type DefaultSliderProps = {};

function DefaultSlider({ children, forwardedRef, checkValidArrows }: DefaultSliderProps) {
  const [activeIndex, setActiveIndex] = useState(0);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    rows: 1,
    centerPadding: '0px',
    swipeToSlide: true,
    arrows: false,
    dots: true,
    focusOnSelect: true,
    className: 'center',
    centerMode: true,
    beforeChange: (current, next) => setActiveIndex(next),
    responsive: [
      {
        breakpoint: 950,
        settings: { slidesToShow: 1, centerPadding: '0px' },
      },
    ],
    appendDots: (dots) => (
      <DotsContainer>
        <ul> {dots} </ul>
      </DotsContainer>
    ),
  };

  function addclass(el) {
    el.className += ' ' + 'default_slide';
  }

  useEffect(() => {
    try {
      map(compose(addclass, head, prop('childNodes')))(document.getElementsByClassName('slick-slide'));
    } catch (error) {
      console.log(error);
    }
  }, []);

  const childrenWithProps = React.Children.map(children, (child, i) => {
    if (React.isValidElement(child)) return React.cloneElement(child, { isCentered: true, isActive: activeIndex == i });
    return child;
  });

  return (
    <Container>
      <Slider {...settings} ref={forwardedRef}>
        {childrenWithProps}
      </Slider>
    </Container>
  );
}

export default DefaultSlider;

// styles

const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute !important;
  bottom: 0px;
  padding: 0px 30px !important;

  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 300px;
  }
`;

const Container = styled.div`
  .slick-track {
    display: flex;
  }
  .slick-track .slick-slide {
    display: flex;
    height: auto;
  }

  .default_slide {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  @media (max-width: 700px) {
    .slick-slide {
      padding: 5px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
`;
