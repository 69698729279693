import { toast as toastify } from 'react-toastify';
import asyncForEach from 'v2/utils/asyncForEach';
import { history } from 'v2/stores/configureStore';
import { isMobileDevice } from 'v2/utils/detectPlatform';
import { MAKE_PICKS_PATH, getPath, CONTEST_CATEGORY_PATH,CONTEST_PAY_PATH, CONTEST_LEADERBOARD_PATH } from 'v2/constants/router';
import toast from '../toast';

// fixme
const NOTIFICATION_COUNT_SHOW = 4;
const TIMEOUT_BETWEEN_POPUP = 1500;

const navigateFromToastToMakePicks = (notification, deleteNotification) => {
  if (notification.contestId) {
    window.location.href = getPath(CONTEST_PAY_PATH, { id: notification.contestId });
    // history.push(
    //   getPath(CONTEST_PAY_PATH, { id: notification.contestId }),
    // getPath(CONTEST_CATEGORY_PATH, {
    //   id: notification.contestId,
    //   weekOrder: notification.contestWeekId,
    //   categoryId: notification.contestCategoryId,
    // }),
    // );
    // window.location.href = getPath(MAKE_PICKS_PATH, { id: notification.contestId, weekOrder: 1 });
    toastify.dismiss(notification.id);
    deleteNotification(notification.id);
  }
};

const navigateFromToastToLedearboard = (notification, deleteNotification) => {
  if (notification.contestId) {
    history.push(
      getPath(CONTEST_LEADERBOARD_PATH, {
        id: notification.contestId,
      }),
    );
    // window.location.href = getPath(MAKE_PICKS_PATH, { id: notification.contestId, weekOrder: 1 });
    toastify.dismiss(notification.id);
    deleteNotification(notification.id);
  }
};

const sendNotifications = ({ notifications, deleteNotification, fetchNotifications, ...ot }) => {
  let sentNotificationsIds = [];

  const notificate = () => {
    if (notifications.length === 0) {
      setTimeout(() => {
        fetchNotifications();
      }, 5000);
    }

    const cropedNotifications = notifications
      .slice(0, NOTIFICATION_COUNT_SHOW)
      .filter((notification) => sentNotificationsIds.indexOf(notification.id) === -1);

    const notificator = (notification) => {
      if (!sentNotificationsIds.includes(notification.id)) {
        sentNotificationsIds = [...sentNotificationsIds, notification.id];

        setTimeout(
          () => {
            toast(
              {
                ...notification,
              },
              {
                toastId: notification.id,
                onClick: () =>
                  (notification.kind === 'new_contest' &&
                    navigateFromToastToMakePicks(notification, deleteNotification)) ||
                  (notification.kind === 'users_eliminated' &&
                    navigateFromToastToLedearboard(notification, deleteNotification)) ||
                  (notification.kind === 'users_won' &&
                    navigateFromToastToLedearboard(notification, deleteNotification)),
                onClose: ({ id }) => deleteNotificationFromState(id),
                // onOpen: ({ id }) => deleteNotification(id),
              },
            );
          },
          isMobileDevice() ? 5000 : 0,
        );
      }
    };

    return asyncForEach(notificator, cropedNotifications, TIMEOUT_BETWEEN_POPUP);
  };

  const deleteNotificationFromState = (id) => {
    deleteNotification(id);
    notifications = notifications.filter((notification) => notification.id !== id);
    notificate();
  };

  notificate();
};

export default sendNotifications;
