import React from 'react';
import PhotoBlock from 'v2/components/PromoPages/shared/PhotoBlock';
import newEraImg from 'v2/components/shared/Image/promo/2/erz.png';
import BlockDescription from '../../shared/DescriptionBlock';

function block({ pict }) {
  return (
    <PhotoBlock.Wrapper style={{ maxWidth: '100%' }}>
      <PhotoBlock src={pict || newEraImg} contentDirection="bottom">
        <BlockDescription style={{ fontStyle: 'normal' }} size="large">
          A NEW ERA {'\n'}OF FANTASY SPORTS{' '}
        </BlockDescription>
      </PhotoBlock>
    </PhotoBlock.Wrapper>
  );
}

export default block;
