import { createSelector } from 'reselect';
import { identity } from 'ramda';

import { decorateRefsMedia, decoratedRefsWinners } from 'v2/decorators/refs';

export const initialState = {};

function refsSelector(state) {
  return state.refs || initialState;
}

export const getRefs = createSelector(refsSelector, identity);

export const getRefsMedia = createSelector(getRefs, (refs) => refs.media);

export const getDecoratedRefsMedia = createSelector(getRefsMedia, decorateRefsMedia);

export const getRefsWinners = createSelector(getRefs, (refs) => refs.videoWinners);

export const getDecoratedRefsWinners = createSelector(getRefsWinners, decoratedRefsWinners);

export const getRefsPromoPrizes = createSelector(getRefs, (refs) => refs.promoPrizes || []);

export const getRefsOffers = createSelector(getRefs, (refs) => refs.offers);

export const getRefsMainContestId = createSelector(getRefs, (refs) => refs.mainContestId);

// export const getPropertyTypesByApplicationType = createSelector(
//   [applicationTypeSelector, getPropertyActualTypesCollection, getPropertyRentalActualTypesCollection],
//   propertyTypesByApplicationTypeGetter,
// );
// // Property
// export const getPropertyRefs = createSelector(
//   getRefs,
//   prop('property'),
// );

// export const getPropertyActualTypesCollection = createSelector(
//   getPropertyRefs,
//   propOr([], 'actualTypesCollection'),
// );

// export const getPropertyRentalActualTypesCollection = createSelector(
//   getPropertyRefs,
//   propOr([], 'rentalActualTypesCollection'),
// );

// export const getPropertyTypesByApplicationType = createSelector(
//   [applicationTypeSelector, getPropertyActualTypesCollection, getPropertyRentalActualTypesCollection],
//   propertyTypesByApplicationTypeGetter,
// );

// // Property Valuation
// export const getPropertyValuationRefs = createSelector(
//   getRefs,
//   prop('propertyValuation'),
// );

// export const getPropertyValuationInspectionTypesCollection = createSelector(
//   getPropertyValuationRefs,
//   propOr([], 'inspectionTypesCollection'),
// );

// // Loan
// export const getLoanRefs = createSelector(
//   getRefs,
//   prop('loan'),
// );

// export const getReviewValueTermsTypesCollection = createSelector(
//   getLoanRefs,
//   propOr([], 'reviewValueTermsTypesCollection'),
// );

// // Contact
// export const getContactRefs = createSelector(
//   getRefs,
//   prop('contact'),
// );

// export const getAvailableNotifyTo = createSelector(
//   getContactRefs,
//   propOr([], 'availableNotifyTo'),
// );

// export const getAvailableOwners = createSelector(
//   getContactRefs,
//   propOr([], 'availableOwners'),
// );

// // Note
// export const getNoteRefs = createSelector(
//   getRefs,
//   prop('note'),
// );

// export const getAvailableNotesPriorities = createSelector(
//   getNoteRefs,
//   propOr([], 'availableNotesPriorities'),
// );

// export const getAvailableNoteTags = createSelector(
//   getNoteRefs,
//   propOr([], 'availableNoteTags'),
// );

// // Task
// export const getTaskRefs = createSelector(
//   getRefs,
//   prop('task'),
// );

// export const getAvailableTaskPriorities = createSelector(
//   getTaskRefs,
//   propOr([], 'availableTaskPriorities'),
// );

// export const getAvailableTaskTypes = createSelector(
//   getTaskRefs,
//   propOr([], 'availableTaskTypes'),
// );

// export const getAvailableTaskSteps = createSelector(
//   getTaskRefs,
//   propOr([], 'availableTaskSteps'),
// );

// export const getAvailableConditionTypes = createSelector(
//   getTaskRefs,
//   propOr([], 'availableConditionTypes'),
// );
