import { normalize, schema } from 'normalizr';

// import { application, contact } from '..';

const store = new schema.Object({
  // applicationStore: {
  //   application,
  // },
  // currentContact: contact,
});

const storeNormalizr = (data) => normalize(data, store);

export default storeNormalizr;
