import React, { useState } from 'react';
import styled from 'styled-components';

function Tooltip({ message, children }) {
  const [isShow, setIsShow] = useState(false);

  const hideTooltip = () => setIsShow(false);
  const showTooltip = () => setIsShow(true);

  return (
    <TooltipContainer className="tooltip" onMouseLeave={hideTooltip}>
      {isShow && (
        <TooltipBubble>
          <TooltipMessage>{message}</TooltipMessage>
        </TooltipBubble>
      )}
      <TooltipTrigger onMouseOver={showTooltip}>{children}</TooltipTrigger>
    </TooltipContainer>
  );
}

export default Tooltip;

// styles
const TooltipContainer = styled.div`
  position: relative;
  height: 100%;
`;

const TooltipTrigger = styled.span`
  display: inline-block;
  text-decoration: underline;
  height: 100%;
  text-decoration: none;
`;

const TooltipBubble = styled.div`
  background: #26262e;
  position: absolute;
  z-index: 10;
  bottom: 100%;
  left: 50%;
  margin-bottom: 9px;
  transform: translateX(-50%);
  border-radius: 5px;
  z-index: 999999999999999;

  &::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #26262e;
    left: 50%;
    transform: translate(-50%, 0);
  }
`;

const TooltipMessage = styled.div`
  border-radius: 3px;
  color: $tooltip-text-color;
  font-size: 0.75rem;
  line-height: 1.4;
  padding: 0.75em;
  text-align: center;
  white-space: nowrap;
  text-transform: capitalize;
`;
