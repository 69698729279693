// @flow

import React from 'react';
import styled from 'styled-components';

type SocialLinkItemProps = {
  logo: string,
  url: string,
};

function SocialLinkItem({ logo, url }: SocialLinkItemProps) {
  return (
    <LinkItem>
      <StyledLink href={url}>
        <StyledLinkImg src={logo} />
      </StyledLink>
    </LinkItem>
  );
}

const StyledLinkImg = styled.img``;

const StyledLink = styled.a`
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    height: 50px;
    width: 50px;
    top: 0;
    left: 0;
    transform: translate(-40%, -50%);
  }
`;

const LinkItem = styled.div`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
  font-weight: 700;
  color: #fff;
`;

export default SocialLinkItem;
