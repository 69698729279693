import React from 'react';
import PhotoBlock from 'v2/components/PromoPages/shared/PhotoBlock';
import image from 'v2/components/shared/Image/promo/9.png';
import BlockDescription, { BlockDescriptionaAccent, BlockDescriptionContainer } from '../../shared/DescriptionBlock';

function block() {
  return (
    <PhotoBlock.Wrapper>
      <PhotoBlock src={image} contentDirection="bottom">
        <BlockDescriptionContainer>
          <BlockDescription quotes>
            SIGN UP FOR GONE STREAKIN’{'\n'}
            AND WIN SOME AWESOME EXPERIENCES MEETING{'\n'}A LOT OF PROS LIKE ME
          </BlockDescription>

          <BlockDescriptionaAccent style={{ color: '#e1073a' }}>JAMAL ADAMS </BlockDescriptionaAccent>
          <BlockDescriptionaAccent style={{ color: '#e1073a' }}>@PRESIDENTMAL</BlockDescriptionaAccent>
        </BlockDescriptionContainer>
      </PhotoBlock>
    </PhotoBlock.Wrapper>
  );
}

export default block;
