// @flow

import React from 'react';
import styled from 'styled-components';
import { when, equals, compose } from 'ramda';
import { NavLink } from 'react-router-dom';

import arrowTop from '../Pagination/img/arrow_top.svg';

type ExploreBlockProps = {
  league?: String,
};

const defaultProps = {
  league: null,
};

const getSportNamebyLeague = compose(
  when(equals('nfl'), () => 'Football'),
  when(equals('nba'), () => 'Basketball'),
);

function ExploreBlock({ league, navigateUrl }: ExploreBlockProps) {
  const text = league ? `Explore more \nabout ${getSportNamebyLeague(league)}` : 'explore more';

  return (
    <Container to={navigateUrl}>
      <ArrowContainer>
        <img src={arrowTop} alt="arrow" />
      </ArrowContainer>
      <ExploreText>{text}</ExploreText>
    </Container>
  );
}

ExploreBlock.defaultProps = defaultProps;
export default ExploreBlock;

// styles

const ArrowContainer = styled.div`
  background: ${({ theme }) => theme.colors.gray};
  border-radius: 50%;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

  img {
    width: 20px;
    color: #fff;
    filter: brightness(3);
    transform: rotate(90deg);
  }
`;

const ExploreText = styled.span`
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
  white-space: pre;
`;

const Container = styled(NavLink)`
  width: 100%;
  background: ${({ theme }) => theme.colors.lighGray};
  max-width: 325px;
  height: 100%;
  max-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.25s;

  &:hover {
    filter: brightness(1.1);
  }
`;
