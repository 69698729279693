// @flow

import React, { useEffect, useState } from 'react';
import { complement, filter, compose, last, propOr } from 'ramda';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { pure } from 'recompose';
import { connect } from 'react-redux';

import { getUserStatusAcceptedTermsOfService } from 'v2/selectors/session';
import * as actions from 'v2/actions';
import { ACCOUNT, MY_GAMES, HOW_TO_PLAY, PRIZES, FAQ, CONTACT_US, BLOG_CATEGORIES_PATH } from 'v2/constants/router';

// type MenuProps = {};

const isHidden = compose(propOr(false, 'isHidden'), last);

function Menu({ fetchSignOut, close, location: { pathname } }) {
  const [url, setUrl] = useState(pathname);

  useEffect(() => {
    if (pathname !== url) {
      close();
    }
  }, [pathname]);

  const links = [
    ['my_account', { href: ACCOUNT, label: 'My Account' }],
    ['my_games', { href: MY_GAMES, label: 'My Games' }],
    ['blog', { href: BLOG_CATEGORIES_PATH, label: 'Blog' }],
    ['how_to_play', { href: HOW_TO_PLAY, label: 'How To Play' }],
    ['prizes', { href: PRIZES, label: 'Prizes' }],
    ['faq', { href: FAQ, label: 'FAQ' }],
    ['contact_us', { href: CONTACT_US, label: 'Contact Us' }],
    ['sign_out', { action: fetchSignOut, label: 'Sign Out', isHidden: true }],
  ];

  return (
    <Container>
      {filter(complement(isHidden), links).map(([key, { href, label }]) => (
        <MenuItem key={key}>
          <NavLink to={href}>{label}</NavLink>
        </MenuItem>
      ))}

      {/* <Separate /> */}

      {filter(isHidden, links).map(([key, { href, label, action }]) => (
        <MenuItem key={key} isHidden onClick={action}>
          <NavLink to={href || '#'}>{label}</NavLink>
        </MenuItem>
      ))}
    </Container>
  );
}

const MenuItem = styled.li`
  font-size: 19px;
  padding: 0.5em;
  font-weight: 700;
  opacity: ${({ isHidden }) => isHidden && '0.25'};
`;

const Separate = styled.span`
  display: block;
  width: 100%;
  height: 1px;
  border-top: 1px solid #656579;
  opacity: 0.2;
`;

const Container = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20%;

  @media (max-width: 768px) {
    margin-top: 30%;
  }
`;

const mapDispatchToProps = (dispatch) => ({
  fetchSignOut: () => dispatch(actions.fetchSignOut()),
});

const mapStateToProps = (state) => ({
  isUserAcceptedTermsOfService: getUserStatusAcceptedTermsOfService(state),
});

const enhance = compose(withRouter, connect(mapStateToProps, mapDispatchToProps), pure);

export default enhance(Menu);
