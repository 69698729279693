// @flow

import React, { type Node } from 'react';
import styled from 'styled-components';
import { not, when, compose, length } from 'ramda';

import { isDefined } from 'v2/helpers/ramda';

type DotsContainerProps = {
  children: Node,
};

function DotsContainer({ children }: DotsContainerProps) {
  const dotsCount = when(isDefined, compose(length, React.Children.toArray))(children);

  if (not(dotsCount)) return null;

  return (
    <Container>
      {React.Children.map(children, (child) => (
        <DotWrapper>{React.cloneElement(child)}</DotWrapper>
      ))}
    </Container>
  );
}

export default DotsContainer;

// styles
const DotWrapper = styled.div`
  padding: 0 2px;
  width: 100%;
`;

const Container = styled.div`
  width: 100%;
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
