import 'react-toastify/dist/ReactToastify.css';

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getAllNotifications, isAuthorized } from 'v2/selectors/session';
// import { notificationTypes } from 'v2/types';
import * as actions from 'v2/actions';

import { configure } from './shared';
import sendNotifications from '../sendNotifications';

// const defaultProps = {
//   notifications: [],
// };
// type ToastsContainerProps = {
//   notifications: Array<{ ...notificationTypes }>,
//   fetchNotifications: () => void,
//   deleteNotification: () => void,
//   isUserAuthorized: boolean,
// };

function ToastsContainer({ notifications, fetchNotifications, deleteNotification, isUserAuthorized }) {
  useEffect(() => {
    isUserAuthorized && fetchNotifications();
  }, []);

  if (notifications.length > 0) {
    sendNotifications({
      notifications,
      deleteNotification,
      fetchNotifications,
    });
  }

  return (
    <WrappedToastContainer>
      <ToastContainer {...configure} />
    </WrappedToastContainer>
  );
}

const mapStateToProps = (state) => ({
  notifications: getAllNotifications(state),
  isUserAuthorized: isAuthorized(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchNotifications: () => dispatch(actions.fetchNotifications()),
  deleteNotification: (id) => dispatch(actions.deleteNotification(id)),
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

// ToastsContainer.defaultProps = defaultProps;
export default enhance(ToastsContainer);

// styles
const WrappedToastContainer = styled.div`
  .Toastify__toast-body {
    background: #1b1b21;
    margin: 0;
    padding: 18px 40px 18px 18px;
  }

  .Toastify__toast-container {
    padding: 0;
    min-width: 350px;
    opacity: 0.9;
    z-index: 99999999999;
  }

  .Toastify__toast {
    background: #656579;
    padding: 0 0 0 4px;
    margin: 0;
    border-radius: 6px;
    transition: filter ease-in 0.2s;
    margin-bottom: 15px;
    /* cursor: auto; */

    &:hover {
      filter: brightness(110%);
    }
  }

  .Toastify__toast--success {
    background: #0bd855;
  }
  .Toastify__toast--warning {
    background: #5d5ce2;
  }
  .Toastify__toast--error {
    background: #e80c51;
  }
`;
