import React from 'react';
import styled from 'styled-components';
import { EmailShareButton, FacebookShareButton, TwitterShareButton } from 'react-share';

import facebookIcon from 'v2/components/shared/Image/Icon/facebook.svg';
import TwitterIcon from 'v2/components/shared/Image/Icon/twitter.svg';
import emailIcon from 'v2/components/shared/Image/Icon/email.svg';

import ReferalButton from './ReferalButton';

const referalButtons = [
  { id: 0, title: 'Facebook invite', icon: facebookIcon, url: '#', color: '#3b5999', Adapter: FacebookShareButton },
  { id: 1, title: 'Twitter invite', icon: TwitterIcon, url: '#', color: '#55acee', Adapter: TwitterShareButton },
  { id: 2, title: 'Email invite', icon: emailIcon, url: '#', Adapter: EmailShareButton },
];

// type ReferalButtonsGroupProps = {};

function ReferalButtonsGroup() {
  return (
    <ReferalButtonsGroupContainer>
      {referalButtons.map((referalButton) => (
        <ReferalButton key={referalButton.id} {...referalButton} />
      ))}
    </ReferalButtonsGroupContainer>
  );
}

const ReferalButtonsGroupContainer = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    width: 100%;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }

  // @media (max-width: 700px) {
  //   flex-direction: column;
  // }
`;

export default ReferalButtonsGroup;
