import * as types from '../../constants/actionTypes';

const setRequestInProcess = (inProcess, requestType, errors) => ({
  type: types.REQUEST_IN_PROCESS_SET,
  payload: {
    inProcess,
    requestType,
    errors,
  },
});

export default setRequestInProcess;
