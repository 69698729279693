import React, { Fragment } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { not } from 'ramda';

import { isSafari } from 'v2/utils/detectPlatform';

import { getOrderedStatCategoriesList } from 'v2/selectors/statCategories';
import ContestLogo from './shared/ContestLogo';

import Period from '../Period';
// import ContestIcon from './shared/ContestIcon';

const propTypes = {};

const defaultProps = {};

const getTagColor = (val) => {
  if (val === 'survive') return '#5D5CE2';
  return '#0BD855';
};

function IconGame(props) {
  const {
    gameState,
    status,
    kind,
    currentWeek,
    endsAt,
    startsAt,
    isContestActive,
    isUserAdvanced,
    isUserEliminated,
    isContestLive,
    statCategoriesList,
    isUserWin,
    league,
  } = props;

  // const tagColor = getTagColor(kind);
  // const tagText = isSurvive ? 'survivor' : 'PICK’ EM';

  return (
    <StyledIconGame>
      {/* {gameState === 'recent' && <div className="icon_type_game icon_type_game--recent">Recent</div>} */}
      {/* {gameState === 'active' && <GameIndicator className={status} />} */}

      <Fragment>
        <Period {...props} />

        <ContestLogoWrapper>
          <ContestLogo {...props} />
        </ContestLogoWrapper>
      </Fragment>
    </StyledIconGame>
  );
}

const ContestLogoWrapper = styled.div`
  /* margin-bottom: 10px; */
`;

const StyledIconGame = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: ${isSafari && '15px'}; */
`;

const StyledTagWrapper = styled.div`
  position: absolute;
  top: 3px;
  left: 12px;
  display: flex;
`;

const StyledTagWrapperRight = styled(StyledTagWrapper)`
  position: absolute;
  top: 0;
  right: 0;
  left: auto;
  display: flex;
`;

const StyledBaseTag = styled.div`
  padding: 2px 4px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 14px;
  border-radius: 2px;
  color: #fff;
  background: gray;
`;

const StyledTag = styled(StyledBaseTag)`
  padding: ${(props) => (props.isLive ? '2px 4px 2px 6px' : '2px 4px')};
  border-radius: ${(props) => (props.isLive ? '0 2px 2px 0' : '2px')};
  background: ${(props) => props.color};
  text-shadow: 1px 1px 1px #2b2b2b;
`;

const StyledTagLive = styled(StyledTag)`
  position: relative;
  border-radius: 2px 0 0 2px;
  background: #e80c51;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: -2px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 18px 2px 0 0;
    border-color: #e80c51 transparent transparent transparent;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: -2px;
    width: 1px;
    height: 100%;
    background-color: #1b1b21;
    transform: rotate(6deg);
  }
`;

const StyledTagActive = styled(StyledBaseTag)`
  color: #ffb236;
  background: #302f28;
`;

const StyledTagEliminated = styled(StyledBaseTag)`
  color: #e80c51;
  background: #302828;
`;

const StyledTagAdvanced = styled(StyledBaseTag)`
  color: #0bd855;
  background: #28302e;
`;

const GameIndicator = styled.div`
  width: 10px;
  height: 10px;
  position: absolute;
  right: 0px;
  top: 0px;
  border-radius: 10px;
`;

IconGame.propTypes = propTypes;
IconGame.defaultProps = defaultProps;

const mapStateToProps = (state, { statCategories }) => ({
  statCategoriesList: getOrderedStatCategoriesList(state, { statCategoryIds: statCategories }),
});

export default connect(mapStateToProps)(withRouter(IconGame));
