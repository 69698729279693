import { mergeDeepRight } from 'ramda';

import * as actionTypes from '../../constants/actionTypes';
import { initialState } from '../../selectors/entities';

export default function(state = initialState, { type, payload } = {}) {
  switch (type) {
    case actionTypes.ENTITIES_DELETE:
      return deleteEntities(state, payload);
    case actionTypes.ENTITIES_MERGE:
      return mergeDeepRight(state, payload);
    case actionTypes.ENTITIES_SYNC:
      return syncEntities(state, payload);
    default:
      return state;
  }
}

function syncEntities(state, { entity, key }) {
  return { ...state, [key]: { ...state[key], [entity.id]: entity } };
}

function deleteEntities(state, { entityId, key }) {
  return { ...state, [key]: { ...state[key], [entityId]: undefined } };
}
