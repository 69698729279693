import React from 'react';
import PhotoBlock from 'v2/components/PromoPages/shared/PhotoBlock';
import videoPreviewImg from 'v2/components/shared/Image/promo/3/video-preview-1.png';

import PlayNowButton from '../../../shared/PlayVideoButton';

function block() {
  return (
    <PhotoBlock.Wrapper style={{ maxWidth: '100%', position: 'relative' }}>
      <PlayNowButton videoId="274739963" />
      <PhotoBlock src={videoPreviewImg} size="small" />
    </PhotoBlock.Wrapper>
  );
}

export default block;
