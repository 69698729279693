import numeral from 'numeral';
import moment from 'moment';

import pluralize from 'pluralize';

export function currencyFormat(value) {
  return numeral(value).format('$0,0');
}

export function decimalCurrencyFormat(value) {
  return numeral(value).format('$0,0.00');
}

export function percentFormat(value) {
  return numeral(value / 100).format('0.00%');
}

export function dateFormat(date) {
  return moment(date).format('MM/DD/YYYY');
}

export function dateHumanFormat(date) {
  return moment(date).format('DD MMM YYYY');
}

export function dateWithTimeFormat(date) {
  return moment(date).format('YYYY-MM-DD h:mm A');
}

export function dateIsValid(date) {
  return moment(date).isValid();
}

export function displayTerm(term) {
  return `${term} ${pluralize('month', term)}`;
}
