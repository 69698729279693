/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
// import moment from 'moment';
import Avatar from 'v2/components/Contests/shared/Avatar';
import ContestContext from 'v2/contexts/contest';

import { shape, check, defaultAvatar, correctPick, incorrectPick, pendingPick } from '../../../Image';

const propTypes = {};

const defaultProps = {};

const getStatusImg = (statusPick) => {
  if (statusPick === 'success') return correctPick;
  if (statusPick === 'pending') return pendingPick;
  if (statusPick === 'fail') return incorrectPick;
  return '';
};

const getStatusColor = (statusPick) => {
  if (statusPick === 'success') return '#2a4843';
  if (statusPick === 'pending') return '#26262e';
  if (statusPick === 'fail') return '#57162d';
};

function SelectedPlayers({ player, index, onPlayerSelect, contestCategory, teamId, isLarge, ...rest }) {
  // const { position, name, photo, game } = player;
  // const { awayTeam, homeTeam, startsAt } = game;
  // const gameStartAt = moment(player && player.game && player.game.startsAt).format('ddd h:mm A');

  const getPlayerName = (name) => {
    const firstNamePart = R.compose(R.head, R.split(' '))(name) || name;
    const secondNamePart = R.compose(R.join(' '), R.tail, R.split(' '))(name);

    return R.join('\n', [firstNamePart, secondNamePart]);
  };

  const { setSuccessPlayerInfo } = useContext(ContestContext);

  return (
    <Player color={getStatusColor(player.statusPick)} kye={player && player.id} className="selected" large={isLarge}>
      <PlayerNumber>{index}</PlayerNumber>
      <AvatarContainer
        onClick={() =>
          setSuccessPlayerInfo((old) => ({
            ...old,
            id: player.id,
            show: true,
            categoryId: R.path(['match', 'params', 'categoryId'], rest),
          }))
        }
      >
        <Avatar source={player.photo} isDefault />
      </AvatarContainer>
      {/* <Position>{player && player.position}</Position> */}
      <PlayerName>{getPlayerName(player.name)}</PlayerName>
      {/* <span>{gameStartAt}</span> */}
      <TeamDescription>
        <TeamName isActive={player.game.awayTeamId == player.teamId}>{player.game.awayTeam}</TeamName>
        {'  '}@{'  '}
        <TeamName isActive={player.game.homeTeamId == player.teamId}>{player.game.homeTeam}</TeamName>
      </TeamDescription>

      {player && !player.blocked ? (
        <RemoveCategoryPlayer onClick={() => onPlayerSelect(contestCategory, player.id)} />
      ) : (
        <PlayerIndicator src={getStatusImg(player.statusPick)} />
      )}
    </Player>
  );
}

const PlayerIndicator = styled.img`
  width: 30px;
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translate(-50%, -10%);
  /* @media (max-width: 901px) {
  } */
`;

const TeamName = styled.span`
  color: ${({ isActive }) => isActive && '#fff'};
  margin: 0 5px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const TeamDescription = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;
`;

const AvatarContainer = styled.div`
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: block;
  width: 80px;
  border-radius: 50%;
  display: flex;
  overflow: hidden;

  @media (max-width: 480px) {
    width: 75px;
  }
`;

const PlayerName = styled.h3`
  margin-top: 0;
  margin-top: 0;
  font-size: 16px;
  line-height: 17px;

  @media (max-width: 480px) {
    margin-top: 0;
    white-space: break-spaces;
    font-size: 16px;
    line-height: 17px;
    margin-bottom: 5px;
  }
`;

const PlayerNumber = styled.span`
  width: 24px;
  height: 24px;
  background: #656579;
  border-radius: 24px;
  display: block;
  position: absolute;
  top: -40px;
  left: 0;
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  /* background: #0bd855; */
  @media (max-width: 480px) {
    width: 18px;
    height: 18px;
    font-size: 12px;
    line-height: 18px;
  }
`;

const Position = styled.span`
  position: absolute;
  width: 100%;
  color: #0bd855;
  top: 40px;
  left: 0;
  right: 0;
  text-align: center;
  @media (max-width: 480px) {
    top: 45px;
  }
`;

const RemoveCategoryPlayer = styled.span`
  width: 30px;
  height: 30px;
  background: #1b1b21;
  position: absolute;
  bottom: -14px;
  left: calc(50% - 15px);
  right: 0;
  border-radius: 30px;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    width: 12px;
    height: 2px;
    background: #fff;
    lfet: 0;
    left: calc(50% - 6px);
    right: 0;
    top: 14px;
    border-radius: 10px;
  }
  &:hover {
    background: #26262e;
  }
`;

const Player = styled.div`
  background: ${({ color }) => color || '#28302e'};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  width: 100%;
  max-width: 103px;
  box-sizing: border-box;
  margin: 75px 0 30px;
  padding: 60px 10px 45px;
  text-align: center;
  border-radius: 8px;
  &:not(:first-child) {
    margin-left: 20px;
  }
  /* &.selected {
    background: #28302e;
  } */
  @media (max-width: 480px) {
    padding: 45px 5px 25px;
    &:not(:first-child) {
      margin-left: 10px;
    }
    margin: 55px 0 30px;
    margin-bottom: 0;

    /* max-width: ${({ large }) => large && '190px'};
    padding: ${({ large }) => large && '65px 5px 15px'}; */
    ${Position} {
      top: ${({ large }) => large && '65px'};
    }
    ${AvatarContainer} {
      width: ${({ large }) => large && '75px'};
    }
  }
`;

SelectedPlayers.propTypes = propTypes;
SelectedPlayers.defaultProps = defaultProps;

// export default enhance(HomePage);
export default withRouter(SelectedPlayers);
