// @flow

import React, { useState } from 'react';
import styled from 'styled-components';

import copyIcon from 'v2/components/shared/Image/Icon/copy_icon.svg';

type myReferalCodeProps = {
  referalCode: string,
};

function MyReferalCode({ referalCode }: myReferalCodeProps) {
  const [text, setText] = useState(referalCode);

  const copyToClipboard = () => {
    const referalCodefield = document.createElement('textarea');
    referalCodefield.innerText = referalCode;
    document.body.appendChild(referalCodefield);
    referalCodefield.select();
    document.execCommand('copy');
    referalCodefield.remove();
    setText(() => 'Copied!');
  };

  const onMouseEnteredEvent = () => setText(() => 'Click to copy');
  const onMouseLeaveEvent = () => setText(() => referalCode);

  return (
    <MyReferalCodeContainer>
      <MyReferalCodeTitle>My referal code</MyReferalCodeTitle>
      <MyReferalCodeInput>
        <ReferalCode>{text}</ReferalCode>
        <CopyIcon onMouseEnter={onMouseEnteredEvent} onMouseLeave={onMouseLeaveEvent} onClick={copyToClipboard}>
          <img src={copyIcon} alt="copy code" />
        </CopyIcon>
      </MyReferalCodeInput>
    </MyReferalCodeContainer>
  );
}

const ReferalCode = styled.span`
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #fff;
  text-transform: capitalize;
`;

const CopyIcon = styled.div`
  transition: filter 0.25s linear;
  cursor: pointer;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 100px;
    height: 50px;
    position: absolute;
    top: -100%;
    left: -100%;
    transform: translate(-25%, 10%);
  }

  &:hover {
    filter: brightness(250%);
  }
`;

const MyReferalCodeInput = styled.div`
  width: 100%;
  background: #26262e;
  padding: 12px 15px;
  border-radius: 4px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  // &:after {
  //   content: url(${CopyIcon});
  // }
`;

const MyReferalCodeTitle = styled.span`
  margin-bottom: 6px;
`;

const MyReferalCodeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export default MyReferalCode;
