import { css } from 'styled-components';

const linkStyles = css`
  display: block;
  padding: 15px 0;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  color: #fff;
  ${'' /* color: ${(props) => (props.color !== '#fff' ? props.color : '#fff')}; */};
  color: #fff;
  background: ${(props) => (props.color !== '#fff' ? props.color : '#5b5b64')};
  ${'' /* background: #33333e; */};
  filter: brightness(0.9);
  transition: 0.3s linear;
  text-decoration: none;
  font-weight: 700;
  ${'' /* text-shadow: 1px 1px 1px #2b2b2b; */}
  &:hover {
    cursor: pointer;
    ${'' /* filter: brightness(105%); */}
    ${'' /* background: ${(props) => (props.color !== '#fff' ? props.color : '#5b5b64')}; */};
    filter: brightness(1);
    ${'' /* text-shadow: 1px 2px 2px #2b2b2b; */}
  }

  text-decoration: none !important;
`;

export default linkStyles;
