import { createSelector } from 'reselect';
import { always, identity, propOr, find, propEq, pick, filter } from 'ramda';
import { entityDetailGetter, entityListGetter } from '../../utils/entities';
import { getEntities } from '../entities';

export const initialState = {};

// const myGamesRecentIdSelector = (state, { myGamesRecentId }) => myGamesRecentId;

// const myGamesRecentIdsSelector = (state, { myGamesRecentIds }) => myGamesRecentIds;

function myGamesSelector(state) {
  return state.myGamesRecent || initialState;
}

export const getMyGames = createSelector(
  myGamesSelector,
  identity,
);

export const getMyGamesRecent = createSelector(
  getMyGames,
  (myGamesRecent) => myGamesRecent.recent,
);

export const getMyGamesRecentList = createSelector(
  [always('contests'), getMyGamesRecent, getEntities],
  entityListGetter,
);
